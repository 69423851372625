import {WalletActionTypes} from './constants';

export type WalletActionType = {
    type:
        | WalletActionTypes.API_RESPONSE_SUCCESS
        | WalletActionTypes.API_RESPONSE_ERROR
        | WalletActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | WalletActionTypes.FIND_WALLET
        | WalletActionTypes.GET_WALLETS
        | WalletActionTypes.GET_WALLET
        | WalletActionTypes.GET_WALLET_BY_ACCOUNT_ID

        | WalletActionTypes.OPEN_SEARCH
        | WalletActionTypes.CLOSE_SEARCH
        | WalletActionTypes.SEARCH_WALLET
        | WalletActionTypes.CLEAR_SEARCH

        | WalletActionTypes.EXPORT

        | WalletActionTypes.RESET
        | WalletActionTypes.RESET_SEARCH;

    payload: {} | string;
};

type Wallet = {
    id: number;
    name: string;
    guard_name: string;
};

type QueryParams = {
    accountId?: string;
    page: number;
    limit: number;
    orderBy: string,
    sort: string,
    walletName?: string
}

class WalletActions {

    apiResponseSuccess = (actionType: string, data: Wallet | {}): WalletActionType => ({
        type: WalletActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): WalletActionType => ({
        type: WalletActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): WalletActionType => ({
        type: WalletActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): WalletActionType => ({
        type: WalletActionTypes.RESET,
        payload: {},
    });

    getWallets = (queryParams: QueryParams): WalletActionType => ({
        type: WalletActionTypes.GET_WALLETS,
        payload: { queryParams },
    });

    getWallet = (name: string): WalletActionType => ({
        type: WalletActionTypes.GET_WALLET,
        payload: { name },
    });

    getWalletByAccountID = (accountId: string, wallet: string): WalletActionType => ({
        type: WalletActionTypes.GET_WALLET_BY_ACCOUNT_ID,
        payload: { accountId },
    });

    find = (id: number) : WalletActionType => ({
        type: WalletActionTypes.FIND_WALLET,
        payload: {
            id,
        }
    });

    openSearch = (): WalletActionType => ({
        type: WalletActionTypes.OPEN_SEARCH,
        payload: {}
    });

    closeSearch = (): WalletActionType => ({
        type: WalletActionTypes.CLOSE_SEARCH,
        payload: {}
    });

    search = (queryParams: QueryParams): WalletActionType => ({
        type: WalletActionTypes.SEARCH_WALLET,
        payload: { queryParams }
    });

    clearSearch = (): WalletActionType => ({
        type: WalletActionTypes.CLEAR_SEARCH,
        payload: {}
    });

    export = (queryParams: QueryParams): WalletActionType => ({
        type: WalletActionTypes.EXPORT,
        payload: { queryParams }
    });

    resetSearch = (): WalletActionType => ({
        type: WalletActionTypes.RESET_SEARCH,
        payload: {}
    });
}

const walletActions = new WalletActions();
export { walletActions };
