import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEn from 'locales/en/translation.json';
import translationFa from 'locales/fa/translation.json';

// translations
const resources = {
    en: {
        translation: translationEn,
    },
    fa: {
        translation: translationFa,
    },
};

i18n.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'en',
        fallbackLng: 'en', // use en if detected lng is not available

        // keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },

        react: {
            // ...
            hashTransKey: function(defaultValue) {
                // return a key based on defaultValue or if you prefer to just remind you should set a key return false and throw an error
            },
            defaultTransParent: 'div', // a valid react element - required before react 16
            transEmptyNodeValue: '', // what to return for empty Trans
            transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'], // don't convert to <1></1> if simple react elements
            transWrapTextNodes: '', // Wrap text nodes in a user-specified element.
                                    // i.e. set it to 'span'. By default, text nodes are not wrapped.
                                    // Can be used to work around a well-known Google Translate issue with React apps. See: https://github.com/facebook/react/issues/11538
                                    // (v11.10.0)
        }
    });

export default i18n;
