import {ExchangeActionTypes} from './constants';

export type ExchangeActionType = {
    type:
        | ExchangeActionTypes.API_RESPONSE_SUCCESS
        | ExchangeActionTypes.API_RESPONSE_ERROR
        | ExchangeActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | ExchangeActionTypes.GET_EXCHANGES
        | ExchangeActionTypes.GET_EXCHANGE
        | ExchangeActionTypes.CHECK_EXCHANGE
        | ExchangeActionTypes.INSERT_EXCHANGE
        | ExchangeActionTypes.GET_TRANSACTIONS

        | ExchangeActionTypes.OPEN_SEARCH
        | ExchangeActionTypes.CLOSE_SEARCH
        | ExchangeActionTypes.SEARCH_EXCHANGE
        | ExchangeActionTypes.SEARCH_TRANSACTION
        | ExchangeActionTypes.CLEAR_SEARCH

        | ExchangeActionTypes.USD_RATE

        | ExchangeActionTypes.PREVIEW_EXCHANGE_DETAIL
        | ExchangeActionTypes.RESET_PREVIEW_DETAIL

        | ExchangeActionTypes.CLEAR_CHECKED

        | ExchangeActionTypes.RESET

        | ExchangeActionTypes.EXPORT_TRANSACTIONS
        | ExchangeActionTypes.EXPORT;
    payload: {} | string;
};

type Exchange = {
    id: number;
    name: string;
    guard_name: string;
};

type QueryParams = {
    page: number;
    limit: number;
    orderBy: string,
    sort: string,
    exchangeName?: string
    accountId?: string
}

class ExchangeActions {

    apiResponseSuccess = (actionType: string, data: Exchange | {}): ExchangeActionType => ({
        type: ExchangeActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): ExchangeActionType => ({
        type: ExchangeActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): ExchangeActionType => ({
        type: ExchangeActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): ExchangeActionType => ({
        type: ExchangeActionTypes.RESET,
        payload: {},
    });

    getExchanges = (queryParams: QueryParams): ExchangeActionType => ({
        type: ExchangeActionTypes.GET_EXCHANGES,
        payload: { queryParams },
    });

    checkExchange = (data: any): ExchangeActionType => ({
        type: ExchangeActionTypes.CHECK_EXCHANGE,
        payload: { data }
    });

    insertExchange = (data: any): ExchangeActionType => ({
        type: ExchangeActionTypes.INSERT_EXCHANGE,
        payload: { data }
    });

    getTransactions = (queryParams: QueryParams): ExchangeActionType => ({
        type: ExchangeActionTypes.GET_TRANSACTIONS,
        payload: { queryParams },
    });

    getExchange = (id: string): ExchangeActionType => ({
        type: ExchangeActionTypes.GET_EXCHANGE,
        payload: { id },
    });

    openSearch = (): ExchangeActionType => ({
        type: ExchangeActionTypes.OPEN_SEARCH,
        payload: {}
    });

    closeSearch = (): ExchangeActionType => ({
        type: ExchangeActionTypes.CLOSE_SEARCH,
        payload: {}
    });

    search = (queryParams: QueryParams): ExchangeActionType => ({
        type: ExchangeActionTypes.SEARCH_EXCHANGE,
        payload: { queryParams }
    });

    searchTransaction = (queryParams: QueryParams): ExchangeActionType => ({
        type: ExchangeActionTypes.SEARCH_TRANSACTION,
        payload: { queryParams }
    });

    clearSearch = (): ExchangeActionType => ({
        type: ExchangeActionTypes.CLEAR_SEARCH,
        payload: {}
    });

    getUsdRate = (): ExchangeActionType => ({
        type: ExchangeActionTypes.USD_RATE,
        payload: {}
    });

    previewExchangeDetail = (data: any): ExchangeActionType => ({
        type: ExchangeActionTypes.PREVIEW_EXCHANGE_DETAIL,
        payload: {data}
    });

    resetPreviewDetail = (): ExchangeActionType => ({
        type: ExchangeActionTypes.RESET_PREVIEW_DETAIL,
        payload: {}
    });

    clearChecked = (): ExchangeActionType => ({
        type: ExchangeActionTypes.CLEAR_CHECKED,
        payload: {}
    });

    export = (queryParams: QueryParams): ExchangeActionType => ({
        type: ExchangeActionTypes.EXPORT,
        payload: { queryParams }
    });

    exportTransactions = (queryParams: QueryParams): ExchangeActionType => ({
        type: ExchangeActionTypes.EXPORT_TRANSACTIONS,
        payload: { queryParams }
    });
}

const exchangeActions = new ExchangeActions();
export { exchangeActions };
