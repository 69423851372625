export enum TokenActionTypes {
    API_RESPONSE_SUCCESS = '@@token/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@token/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@token/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@token/RESET',

    GET_TOKENS = '@@token/GET_TOKENS',
    GET_TOKEN = '@@token/GET_TOKEN',
    INSERT_TOKEN = '@@token/INSERT_TOKEN',
    UPDATE_TOKEN = '@@token/UPDATE_TOKEN',
    DELETE_TOKEN = '@@token/DELETE_TOKEN',

    OPEN_SEARCH = '@@token/OPEN_SEARCH',
    CLOSE_SEARCH = '@@token/CLOSE_SEARCH',
    SEARCH_TOKEN = '@@token/SEARCH_TOKEN',
    CLEAR_SEARCH = '@@token/CLEAR_SEARCH',
}

export enum TokenErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



