import { ManageVerificationActionTypes } from './constants';

export type ManageVerificationActionType = {
    type:
        | ManageVerificationActionTypes.API_RESPONSE_SUCCESS
        | ManageVerificationActionTypes.API_RESPONSE_ERROR
        | ManageVerificationActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | ManageVerificationActionTypes.RESET

        | ManageVerificationActionTypes.GET_VERIFICATION_ITEMS;

    payload: {} | string;
};

type ManageVerification = {
    id: number;
    email: string;
    emailVerify: string;
    role: string;
    token: string;

    process: any
};

class ManageVerificationActions {

    apiResponseSuccess = (actionType: string, data: ManageVerification | {}): ManageVerificationActionType => ({
        type: ManageVerificationActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: any): ManageVerificationActionType => ({
        type: ManageVerificationActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: any): ManageVerificationActionType => ({
        type: ManageVerificationActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): ManageVerificationActionType => ({
        type: ManageVerificationActionTypes.RESET,
        payload: {},
    });

    getVerificationItems = (id: string): ManageVerificationActionType => ({
        type: ManageVerificationActionTypes.GET_VERIFICATION_ITEMS,
        payload: {id},
    });

}

const manageVerificationActions = new ManageVerificationActions();
export { manageVerificationActions };
