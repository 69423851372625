
export enum ProfileActionTypes {
    API_RESPONSE_SUCCESS = '@@profile/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@profile/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@profile/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@profile/RESET',

    SEND_VERIFICATION_EMAIL_LINK = '@@profile/SEND_VERIFICATION_EMAIL_LINK',
    VERIFY_EMAIL = '@@profile/VERIFY_EMAIL',

    GET_COMPLETE_PROFILE = '@@profile/GET_COMPLETE_PROFILE',

    GET_PROFILE_IMAGE = '@@profile/GET_PROFILE_IMAGE',
    UPLOAD_PROFILE_IMAGE = '@@profile/UPLOAD_PROFILE_IMAGE',
}

export enum ProfileErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



