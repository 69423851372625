export enum ProviderActionTypes {
    API_RESPONSE_SUCCESS = '@@provider/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@provider/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@provider/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@provider/RESET',

    PROVIDERS = '@@provider/PROVIDERS',
    PROVIDER = '@@provider/PROVIDER',
    UPDATE = '@@provider/UPDATE',
    CHANGE_STATUS = '@@provider/CHANGE_STATUS',
}

export enum ProviderErrors {
    RESPONSE_200 = 'Unfortunately, an error has ocproviderrred. Please try again.'
}



