import {Suspense, useEffect} from 'react';
import { Outlet } from 'react-router-dom';
import { changeBodyAttribute } from 'utils';
import {useSelector} from "react-redux";
import {memoize} from "proxy-memoize";

const loading = () => <div className=""></div>;

type DefaultLayoutProps = {};

const DefaultLayout = (props: DefaultLayoutProps) => {

    const { layoutColor } = useSelector(memoize((state: any) => ({
        layoutColor: state.Layout.layoutColor,
    })));

    useEffect(() => {
        changeBodyAttribute('data-layout-color', layoutColor);
    }, [layoutColor]);

    return (
        <Suspense fallback={loading()}>
            <Outlet />
        </Suspense>
    );
};
export default DefaultLayout;
