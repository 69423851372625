import { IBANActionTypes } from './constants';

export type IBANActionType = {
    type:
        | IBANActionTypes.API_RESPONSE_SUCCESS
        | IBANActionTypes.API_RESPONSE_ERROR
        | IBANActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | IBANActionTypes.RESET

        | IBANActionTypes.GET_IBANS
        | IBANActionTypes.GET_IBAN
        | IBANActionTypes.INSERT_IBAN
        | IBANActionTypes.UPDATE_IBAN
        | IBANActionTypes.DELETE_IBAN;

    payload: {} | string;
};

type IBAN = {
    id: number;
    email: string;
    emailVerify: string;
    role: string;
    token: string;

    process: any
};

class IBANAction {

    apiResponseSuccess = (actionType: string, data: IBAN | {}): IBANActionType => ({
        type: IBANActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: any): IBANActionType => ({
        type: IBANActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: any): IBANActionType => ({
        type: IBANActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): IBANActionType => ({
        type: IBANActionTypes.RESET,
        payload: {},
    });

    getIBANs = (queryParams: any): IBANActionType => ({
        type: IBANActionTypes.GET_IBANS,
        payload: {queryParams},
    });

    getIBAN = (id: string): IBANActionType => ({
        type: IBANActionTypes.GET_IBAN,
        payload: {id},
    });

    insertIBAN = (data: any): IBANActionType => ({
        type: IBANActionTypes.INSERT_IBAN,
        payload: {data},
    });

    delete = (id: string): IBANActionType => ({
        type: IBANActionTypes.DELETE_IBAN,
        payload: {id},
    });
}

const IBANActions = new IBANAction();
export { IBANActions };
