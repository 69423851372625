import { PermissionActionTypes } from './constants';
import {isEmptyObject} from "../../helpers/functions";

const INIT_STATE = {
    permissions: {},
    queryParams: {},
    loading: false,
    error: "",
    validationErrors: {},

    searchModal: false,
    tmpPermissions: {},
    tmpQueryParams: {},
    searchMode: false,
    searchLoading: false,
    searchError: "",
    searchValidationErrors: {}
};

type PermissionData = {
    id: number;
    name: string;
    guard_name: string;
};

type PermissionQueryParams = {
    limit: number;
    page: number;
    orderBy: string;
    sort: string;
    permissionName: string;
};

type PermissionsResult = {
    permissions: PermissionData | {},
    queryParams: PermissionQueryParams
}

type PermissionActionType = {
    type:
        | PermissionActionTypes.API_RESPONSE_SUCCESS
        | PermissionActionTypes.API_RESPONSE_ERROR
        | PermissionActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | PermissionActionTypes.GET_PERMISSIONS
        | PermissionActionTypes.RESET

        | PermissionActionTypes.OPEN_SEARCH
        | PermissionActionTypes.CLOSE_SEARCH
        | PermissionActionTypes.SEARCH_PERMISSION
        | PermissionActionTypes.CLEAR_SEARCH;
    payload: {
        actionType?: string;
        data?: PermissionsResult;
        error?: string;
        validationErrors?: [];
    };
};

type State = {
    permissions?: PermissionData | {};
    queryParams?: {};
    loading?: boolean;
    value?: boolean;

    tmpPermissions?: any;
    tmpQueryParams?: any;
};

const Permission = (state: State = INIT_STATE, action: PermissionActionType) => {
    switch (action.type) {
        case PermissionActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case PermissionActionTypes.GET_PERMISSIONS: {
                    return {
                        ...state,
                        permissions: action.payload.data?.permissions,
                        queryParams: action.payload.data?.queryParams,
                        loading: false,
                    };
                }
                case PermissionActionTypes.SEARCH_PERMISSION: {
                    return {
                        ...state,
                        tmpPermissions: isEmptyObject(state.tmpPermissions)? state.permissions: state.tmpPermissions,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,
                        permissions: action.payload.data?.permissions,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case PermissionActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case PermissionActionTypes.GET_PERMISSIONS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case PermissionActionTypes.SEARCH_PERMISSION: {
                    return {
                        ...state,
                        searchError: action.payload.error,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case PermissionActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case PermissionActionTypes.GET_PERMISSIONS: {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case PermissionActionTypes.SEARCH_PERMISSION: {
                    return {
                        ...state,
                        searchValidationErrors: action.payload.validationErrors,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case PermissionActionTypes.GET_PERMISSIONS:
            return {
                ...state,
                // permissions: {},
                loading: true,
                error: "",
            };

        case PermissionActionTypes.RESET:
            return {
                ...state,
                permissions: {},
                queryParams: {},

                loading: false,
                error: "",
                validationErrors: {},

                searchModal: false,
                searchLoading: false,
                searchMode: false,
                searchError: "",
                searchValidationErrors: {},

                tmpPermissions: {},
                tmpQueryParams: {},
            };


        // search actions
        case PermissionActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case PermissionActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},
            };
        case PermissionActionTypes.SEARCH_PERMISSION:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case PermissionActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                permissions: state.tmpPermissions,
                queryParams: state.tmpQueryParams,
                tmpPermissions: {},
                tmpQueryParams: {},
                searchMode: false,
            };
        default:
            return { ...state };
    }
};

export default Permission;
