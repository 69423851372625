import {ProfileImageActionTypes} from './constants';

const INIT_STATE = {
    loading: false,
    error: "",
    validationErrors: {},

    // profile image
    profileImage: [],
};

type ProfileImageActionType = {
    type:
        | ProfileImageActionTypes.API_RESPONSE_SUCCESS
        | ProfileImageActionTypes.API_RESPONSE_ERROR
        | ProfileImageActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | ProfileImageActionTypes.RESET

        | ProfileImageActionTypes.GET_PROFILE_IMAGE
        | ProfileImageActionTypes.UPLOAD_PROFILE_IMAGE
        | ProfileImageActionTypes.UPLOAD_FILE_PROGRESS;

    payload: {
        actionType?: string;
        data?: any;
        error?: any;
        validationErrors?: any;

        key?: any;
        value?: any;
        file: {fileId: string} & File;
        fileId: string;
        progress: number;
        retry: boolean
    };
};

type State = {
    profileImage: any
};

type FileType = {
    uploaded?: boolean;
    progress?: number;
    fileId?: string;
    error?: string;
} & File;

const ProfileImage = (state: State = INIT_STATE, action: ProfileImageActionType) => {
    switch (action.type) {
        case ProfileImageActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ProfileImageActionTypes.GET_PROFILE_IMAGE: {
                    return {
                        ...state,
                        loading: false,
                        profileImage: action.payload.data
                    };
                }
                case ProfileImageActionTypes.UPLOAD_PROFILE_IMAGE: {
                    const resFileId = action.payload.data.fileId;
                    return {
                        ...state,
                        profileImage: [
                            ...state.profileImage.map((file: FileType) => {
                                if(file.fileId === resFileId) {
                                    file.uploaded = true;
                                    file.progress = 0;
                                }
                                return file;
                            }),
                        ]
                    };
                }
                default:
                    return { ...state };
            }

        case ProfileImageActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ProfileImageActionTypes.GET_PROFILE_IMAGE:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                case ProfileImageActionTypes.UPLOAD_PROFILE_IMAGE: {

                    return {
                        ...state,
                        profileImage: [
                            ...state.profileImage.map((file: FileType) => {
                                if(file.fileId === action.payload.error.fileId) {
                                    file.error = action.payload.error.error;
                                    file.progress = 0;
                                }
                                return file;
                            }),
                        ]
                    };
                }

                default:
                    return { ...state };
            }

        case ProfileImageActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case ProfileImageActionTypes.GET_PROFILE_IMAGE:
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                case ProfileImageActionTypes.UPLOAD_PROFILE_IMAGE: {
                    return {
                        ...state,
                        profileImage: [
                            ...state.profileImage.map((file: FileType) => {
                                if(file.fileId === action.payload.validationErrors.fileId) {
                                    file.error = action.payload.validationErrors.error.file;
                                }
                                return file;
                            })
                        ],
                    };
                }
                default:
                    return { ...state };
            }

        case ProfileImageActionTypes.RESET:
            return {
                ...state,

                loading: false,
                error: "",
                validationErrors: {},
            };

        case ProfileImageActionTypes.UPLOAD_PROFILE_IMAGE:
            if(!action.payload.retry) {
                state.profileImage = [
                    action.payload.file,
                    ...state.profileImage
                ];
            } else {
                state.profileImage = [
                    ...state.profileImage.map((file: any) => {
                        if(file.fileId === action.payload.file.fileId) {
                            file.error = "";
                        }
                        return file;
                    })
                ];
            }
            return {
                ...state,
                error: ""
            };

        case ProfileImageActionTypes.UPLOAD_FILE_PROGRESS:
            return {
                ...state,
                profileImage: [
                    ...state.profileImage.map((file: FileType) => {
                        if(file.fileId === action.payload.fileId) {
                            file.progress = action.payload.progress;
                        }
                        return file;
                    })
                ],
            };

        case ProfileImageActionTypes.GET_PROFILE_IMAGE:
            return {
                ...state,
                loading: true,
                error: "",
            };

        default:
            return { ...state };
    }
};

export default ProfileImage;
