import {IpActionTypes} from './constants';
import { isEmptyObject } from '../../helpers/functions';

const INIT_STATE = {
    loading: false,
    error: "",
    validationErrors: {},

    ips: {},
    queryParams: {},

    ip: {},
    ipInserted: false,
    ipDeleted: false,

    searchModal: false,
    tmpIps: {},
    tmpQueryParams: {},
    searchMode: false,
    searchLoading: false,
    searchError: "",
    searchValidationErrors: {}
};

type IpActionType = {
    type:
        | IpActionTypes.API_RESPONSE_SUCCESS
        | IpActionTypes.API_RESPONSE_ERROR
        | IpActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | IpActionTypes.RESET

        | IpActionTypes.GET_IPS
        | IpActionTypes.GET_IP
        | IpActionTypes.INSERT_IP
        | IpActionTypes.DELETE_IP

        | IpActionTypes.OPEN_SEARCH
        | IpActionTypes.CLOSE_SEARCH
        | IpActionTypes.SEARCH_IP
        | IpActionTypes.CLEAR_SEARCH;

    payload: {
        actionType?: string;
        data?: any;
        error?: any;
        validationErrors?: any;

        otp: string;
    };
};

type State = {
    ip: any;

    ips: any;
    queryParams?: {};

    tmpIps?: any;
    tmpQueryParams?: any;
};

const Ip = (state: State = INIT_STATE, action: IpActionType) => {
    switch (action.type) {
        case IpActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case IpActionTypes.GET_IPS: {
                    return {
                        ...state,
                        loading: false,
                        ips: action.payload.data.ips.ips,
                        queryParams: action.payload.data.ips.queryParams,
                    };
                }
                case IpActionTypes.GET_IP: {
                    return {
                        ...state,
                        loading: false,
                        ip: action.payload.data,
                    };
                }
                case IpActionTypes.INSERT_IP: {
                    return {
                        ...state,
                        loading: false,
                        ipInserted: action.payload.data,
                    };
                }

                case IpActionTypes.DELETE_IP: {
                    return {
                        ...state,
                        loading: false,
                        ipDeleted: true,
                        ips: {
                            ...state.ips,
                            data: state.ips.data.filter((item: any) => {
                                return item.id !== action.payload.data;
                            })
                        }
                    };
                }

                case IpActionTypes.SEARCH_IP: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpIps: isEmptyObject(state.tmpIps)? state.ips: state.tmpIps,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        ips: action.payload.data?.ips,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,
                        searchLoading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case IpActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case IpActionTypes.GET_IP:
                case IpActionTypes.INSERT_IP:
                case IpActionTypes.DELETE_IP:
                case IpActionTypes.GET_IPS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case IpActionTypes.SEARCH_IP: {
                    return {
                        ...state,
                        searchError: action.payload.error,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case IpActionTypes.API_RESPONSE_VALIDATION_ERRORS:
            switch (action.payload.actionType) {
                case IpActionTypes.GET_IP:
                case IpActionTypes.INSERT_IP:
                case IpActionTypes.DELETE_IP: {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case IpActionTypes.SEARCH_IP: {
                    return {
                        ...state,
                        searchValidationErrors: action.payload.validationErrors,
                        searchLoading: false,
                    };
                }
                case IpActionTypes.GET_IPS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case IpActionTypes.RESET:
            return {
                ...state,

                ips: {},
                queryParams: {},

                loading: false,
                error: "",
                validationErrors: {},

                ip: {},
                ipInserted: false,
                ipDeleted: false,

                // reset search
                searchModal: false,
                searchLoading: false,
                searchMode: false,
                searchError: "",
                searchValidationErrors: {},

                tmpIps: {},
                tmpQueryParams: {},
            };

        case IpActionTypes.GET_IPS:
            return {
                ...state,
                loading: true,
                error: "",
                // ips: {},
            };
        case IpActionTypes.GET_IP:
            return {
                ...state,
                loading: true,
                error: "",
                ip: {},
            };
        case IpActionTypes.INSERT_IP:
            return {
                ...state,
                loading: true,
                error: "",
                ipInserted: false,
            };
        case IpActionTypes.DELETE_IP:
            return {
                ...state,
                loading: true,
                error: "",
                ipDeleted: false,
            };

        // search actions
        case IpActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case IpActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},
            };
        case IpActionTypes.SEARCH_IP:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case IpActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                ips: state.tmpIps,
                queryParams: state.tmpQueryParams,
                tmpIps: {},
                tmpQueryParams: {},
                searchMode: false,
            };

        default:
            return { ...state };
    }
};

export default Ip;
