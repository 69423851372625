import { ApiServiceActionTypes } from './constants';

export type ApiServiceActionType = {
    type:
        | ApiServiceActionTypes.API_RESPONSE_SUCCESS
        | ApiServiceActionTypes.API_RESPONSE_ERROR
        | ApiServiceActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | ApiServiceActionTypes.RESET

        | ApiServiceActionTypes.GET_API_TOKEN
        | ApiServiceActionTypes.CREATE_API_TOKEN;

    payload: {} | string;
};

type ApiService = {
    id: number;
    email: string;
    emailVerify: string;
    role: string;
    token: string;

    process: any
};

class ApiServiceActions {

    apiResponseSuccess = (actionType: string, data: ApiService | {}): ApiServiceActionType => ({
        type: ApiServiceActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: any): ApiServiceActionType => ({
        type: ApiServiceActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: any): ApiServiceActionType => ({
        type: ApiServiceActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): ApiServiceActionType => ({
        type: ApiServiceActionTypes.RESET,
        payload: {},
    });

    getApiToken = (): ApiServiceActionType => ({
        type: ApiServiceActionTypes.GET_API_TOKEN,
        payload: {},
    });

    createApiToken = (): ApiServiceActionType => ({
        type: ApiServiceActionTypes.CREATE_API_TOKEN,
        payload: {},
    });
}

const apiServiceActions = new ApiServiceActions();
export { apiServiceActions };
