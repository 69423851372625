
export enum DLActionTypes {
    API_RESPONSE_SUCCESS = '@@dl/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@dl/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@dl/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@dl/RESET',

    SET_DL = '@@dl/SET_DL',

    UPLOAD_DRIVING_LICENCE = '@@dl/UPLOAD_DRIVING_LICENCE',
    UPLOAD_DL_PROGRESS = '@@dl/UPLOAD_DL_PROGRESS',
    DELETE_DRIVING_LICENCE = '@@dl/DELETE_DRIVING_LICENCE',

    UPDATE = '@@dl/UPDATE',
}

export enum DLErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



