import {DWTransactionActionTypes} from './constants';

export type DWTransactionActionType = {
    type:
        | DWTransactionActionTypes.API_RESPONSE_SUCCESS
        | DWTransactionActionTypes.API_RESPONSE_ERROR
        | DWTransactionActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | DWTransactionActionTypes.FIND_DWTRANSACTION
        | DWTransactionActionTypes.GET_DWTRANSACTIONS

        | DWTransactionActionTypes.OPEN_SEARCH
        | DWTransactionActionTypes.CLOSE_SEARCH
        | DWTransactionActionTypes.SEARCH_DWTRANSACTION
        | DWTransactionActionTypes.CLEAR_SEARCH

        | DWTransactionActionTypes.RESET
        | DWTransactionActionTypes.EXPORT
        | DWTransactionActionTypes.RESET_SEARCH;
    payload: {} | string;
};

type DWTransaction = {
    id: number;
    name: string;
    guard_name: string;
};

type QueryParams = {
    page: number;
    limit: number;
    orderBy: string,
    sort: string,
    DWTransactionName?: string
}

class DWTransactionAction {

    apiResponseSuccess = (actionType: string, data: DWTransaction | {}): DWTransactionActionType => ({
        type: DWTransactionActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): DWTransactionActionType => ({
        type: DWTransactionActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): DWTransactionActionType => ({
        type: DWTransactionActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): DWTransactionActionType => ({
        type: DWTransactionActionTypes.RESET,
        payload: {},
    });

    getDWTransactions = (queryParams: QueryParams): DWTransactionActionType => ({
        type: DWTransactionActionTypes.GET_DWTRANSACTIONS,
        payload: { queryParams },
    });

    find = (id: number) : DWTransactionActionType => ({
        type: DWTransactionActionTypes.FIND_DWTRANSACTION,
        payload: {
            id,
        }
    });

    openSearch = (): DWTransactionActionType => ({
        type: DWTransactionActionTypes.OPEN_SEARCH,
        payload: {}
    });

    closeSearch = (): DWTransactionActionType => ({
        type: DWTransactionActionTypes.CLOSE_SEARCH,
        payload: {}
    });

    search = (queryParams: QueryParams): DWTransactionActionType => ({
        type: DWTransactionActionTypes.SEARCH_DWTRANSACTION,
        payload: { queryParams }
    });

    clearSearch = (): DWTransactionActionType => ({
        type: DWTransactionActionTypes.CLEAR_SEARCH,
        payload: {}
    });

    export = (queryParams: QueryParams): DWTransactionActionType => ({
        type: DWTransactionActionTypes.EXPORT,
        payload: { queryParams }
    });

    resetSearch = (): DWTransactionActionType => ({
        type: DWTransactionActionTypes.RESET_SEARCH,
        payload: {}
    });
}

const DWTransactionActions = new DWTransactionAction();
export { DWTransactionActions };
