import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { graphql } from 'helpers';
import {DashboardActionTypes, DashboardErrors} from './constants';
import {
    checkResponseError,
    checkServerError,
    pluckResponse,
} from "../../helpers/functions";
import {dashboardActions} from "./actions";
import { walletActions } from '../wallet/actions';
import { WalletActionTypes } from '../wallet/constants';
import { TransactionActionTypes } from '../transaction/constants';
import { transactionActions } from '../transaction/actions';
import { TransferActionTypes } from '../transfer/constants';
import { transferActions } from '../transfer/actions';
import { exchangeActions } from '../exchange/actions';
import { ExchangeActionTypes } from '../exchange/constants';
import { paymentActions } from '../payment/actions';
import { PaymentActionTypes } from '../payment/constants';

type DashboardData = {
    payload: {
        id: string;
        name: string;
        permissions: string[];
        accountId: string;

        queryParams: {
            limit: string;
            page: string;
            orderBy: string;
            sort: string;

            dashboardName?: string;
        };

        data: any;
        items: any;
    };
    type: string;
};

function* getDashboard({ payload: { items } }: DashboardData): SagaIterator {

    try {

        let param = [
            {
                query:`query Wallets($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $accountId: String, $wallet: String) {
                    wallets(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId, wallet: $wallet) {
                        data {
                            name,
                            slug,
                            balanceFloatNum,
                            balanceHoldFloatNum,
                            holder {
                                id,
                                username,
                                email
                            }
                            meta {
                                sign
                                currency
                            }
                        }
                        total
                        per_page
                        from
                        to
                        current_page
                        last_page
                        has_more_pages
                    }
                }`,

                variables: {
                    ...items.wallets
                }
            },
        ];

        if(items?.transactions) {
            param = [
                ...param,
                {
                    query:`query Transactions($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $accountId: String) {
                        transactions(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId) {
                            data {
                                wallet {
                                    uuid
                                    name
                                    holder {
                                        id
                                        email
                                        username
                                        display_name
                                    }
                                    meta {
                                        sign
                                        currency
                                    }
                                }
                                type
                                amountFloat
                                balanceFloat
                                confirmed
                                meta {
                                    description
                                }
                                name
                                transaction_id
                                thread_id
                                status
                                created_at
                            }
                            total
                            per_page
                            from
                            to
                            current_page
                            last_page
                            has_more_pages
                        }
                    }`,

                    variables: {
                        ...items.transactions
                    }
                },
            ]
        }

        if(items?.transfers) {
            param = [
                ...param,
                {
                    query:`query Transfers($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $accountId: String) {
                        transfers(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId) {
                            data {
                                id
                                from {
                                    name
                                    balanceFloatNum
                                    meta {
                                        sign
                                        currency
                                    }
                                    holder {
                                        username
                                        email
                                    }
                                }
                                to {
                                    name
                                    balanceFloatNum
                                    meta {
                                        sign
                                        currency
                                    }
                                    holder {
                                        username
                                        email
                                    }
                                }
                                withdraw {
                                    amountFloat
                                    meta {
                                        description
                                    }
                                }
                                deposit {
                                    amountFloat
                                    meta {
                                        description
                                    }
                                    rate
                                }
                                fee
                                feeFloat
                                status
                                created_at
                            }
                            total
                            per_page
                            from
                            to
                            current_page
                            last_page
                            has_more_pages
                        }
                    }`,

                    variables: {
                        ...items.transfers
                    }
                },
            ];
        }

        if(items?.exchanges) {
            param = [
                ...param,
                {
                    query:`query Exchanges($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $accountId: String) {
                        exchanges(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId) {
                            data {
                                id
                                from {
                                    name
                                    balanceFloatNum
                                    meta {
                                        sign
                                        currency
                                    }
                                    holder {
                                        username
                                        email
                                    }
                                }
                                to {
                                    name
                                    balanceFloatNum
                                    meta {
                                        sign
                                        currency
                                    }
                                }
                                withdraw {
                                    amountFloat
                                    meta {
                                        description
                                    }
                                }
                                deposit {
                                    amountFloat
                                    meta {
                                        description
                                    }
                                    rate
                                }
                                fee
                                feeFloat
                                status
                                created_at
                            }
                            total
                            per_page
                            from
                            to
                            current_page
                            last_page
                            has_more_pages
                        }
                    }`,
                    variables: {
                        ...items.exchanges
                    }
                },
            ];
        }

        if(items?.payments) {
            param = [
                ...param,
                {
                    query:`query Payments($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $accountId: String) {
                        payments(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId) {
                            data {
                                id
                                from {
                                    name
                                    balanceFloatNum
                                    meta {
                                        sign
                                        currency
                                    }
                                    holder {
                                        username
                                        email
                                    }
                                }
                                to {
                                    name
                                    balanceFloatNum
                                    meta {
                                        sign
                                        currency
                                    }
                                    holder {
                                        username
                                        email
                                    }
                                }
                                withdraw {
                                    name
                                    amountFloat
                                    meta {
                                        description
                                    }
                                }
                                deposit {
                                    name
                                    amountFloat
                                    meta {
                                        description
                                    }
                                    rate
                                    status
                                }
                                fee
                                feeFloat
                                status
                                created_at
                            }
                            total
                            per_page
                            from
                            to
                            current_page
                            last_page
                            has_more_pages
                        }
                    }`,
                    variables: {
                        ...items.payments
                    }
                }
            ];
        }

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, DashboardErrors.RESPONSE_200);

        const data = response.data;
        const responseData:any = {};

        data.forEach((item: any) => {
            checkServerError(item);
            responseData[Object.keys(item.data)[0]] = pluckResponse(item, Object.keys(item.data)[0]);
        });

        if(responseData?.wallets) {
            yield put(walletActions.apiResponseSuccess(WalletActionTypes.GET_WALLETS, {
                wallets: responseData?.wallets,
                queryParams: items.wallets
            }));
        }

        if(responseData?.transactions) {
            yield put(transactionActions.apiResponseSuccess(TransactionActionTypes.GET_TRANSACTIONS, {
                transactions: responseData.transactions,
                queryParams: items.transactions
            }));
        }

        if(responseData?.exchanges) {
            yield put(exchangeActions.apiResponseSuccess(ExchangeActionTypes.GET_EXCHANGES, {
                exchanges: responseData.exchanges,
                queryParams: items.exchanges
            }));
        }

        if(responseData?.transfers) {
            yield put(transferActions.apiResponseSuccess(TransferActionTypes.GET_TRANSFERS, {
                transfers: responseData.transfers,
                queryParams: items.transfers
            }));
        }

        if(responseData?.payments) {
            yield put(paymentActions.apiResponseSuccess(PaymentActionTypes.GET_PAYMENTS, {
                payments: responseData.payments,
                queryParams: items.payments
            }));
        }

        yield put(dashboardActions.apiResponseSuccess(DashboardActionTypes.GET_DASHBOARD, true));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(dashboardActions.apiResponseError(DashboardActionTypes.GET_DASHBOARD, error));
        } else {
            yield put(dashboardActions.apiResponseValidationErrors(DashboardActionTypes.GET_DASHBOARD, error));
        }
    }
}


export function* watchGetDashboard() {
    yield takeEvery(DashboardActionTypes.GET_DASHBOARD, getDashboard);
}



function* dashboardsSaga() {
    yield all([
        fork(watchGetDashboard),
    ]);
}

export default dashboardsSaga;
