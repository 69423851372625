import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Setting from './setting/reducers';
import User from './user/reducers';
import Role from './role/reducers';
import Permission from './permission/reducers';
import PersonalDetail from './personalDetails/reducers';
import MobileNumber from './mobileNumber/reducers';
import BankCard from './bankCard/reducers';
import IBAN from './iban/reducers';
import TAC from './termsAndConditions/reducers';
import Wallet from './wallet/reducers';
import Deposit from './deposit/reducers';
import Withdraw from './withdraw/reducers';
import AccountSecurity from './myAccount/accountSecurity/reducers';
import ApiService from './myAccount/apiService/reducers';
import Profile from './myAccount/profile/reducers';
import ProfileImage from './myAccount/userImage/reducers';
import Token from './token/reducers';
import Transaction from './transaction/reducers';
import Payment from './payment/reducers';
import Transfer from './transfer/reducers';
import Exchange from './exchange/reducers';
import DWTransaction from './depositWithdraw/reducers';
import Product from './product/reducers';
import NCFront from './NCFront/reducers';
import NCBack from './NCBack/reducers';
import Passport from './passport/reducers';
import DrivingLicence from './drivingLicence/reducers';
import Log from './log/reducers';
import ManageVerification from './manageVerification/reducers';
import Dashboard from './dashboard/reducers';
import Rate from './rate/reducers';
import MyAccount from './myAccount/myAccount/reducers';
import IP from './ip/reducers';
import CU from './conversionUnit/reducers';
import CR from './conversionRate/reducers';
import Provider from './provider/reducers';
import Order from './order/reducers';

export default combineReducers({
    Auth,
    Layout,
    Setting,
    User,
    Role,
    Permission,
    AccountSecurity,
    ApiService,
    Profile,
    ProfileImage,
    PersonalDetail,
    MobileNumber,
    BankCard,
    IBAN,
    TAC,
    Wallet,
    Deposit,
    Withdraw,
    Token,
    Transaction,
    Payment,
    Transfer,
    Exchange,
    DWTransaction,
    Product,
    NCFront,
    NCBack,
    Passport,
    DrivingLicence,
    Log,
    ManageVerification,
    Dashboard,
    Rate,
    MyAccount,
    IP,
    CU,
    CR,
    Provider,
    Order,
});
