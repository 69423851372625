import { BankCardActionTypes } from './constants';

export type BankCardActionType = {
    type:
        | BankCardActionTypes.API_RESPONSE_SUCCESS
        | BankCardActionTypes.API_RESPONSE_ERROR
        | BankCardActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | BankCardActionTypes.RESET

        | BankCardActionTypes.GET_BANK_CARDS
        | BankCardActionTypes.GET_BANK_CARD
        | BankCardActionTypes.INSERT_BANK_CARD
        | BankCardActionTypes.UPDATE_BANK_CARD
        | BankCardActionTypes.DELETE_BANK_CARD;

    payload: {} | string;
};

type BankCard = {
    id: number;
    email: string;
    emailVerify: string;
    role: string;
    token: string;

    process: any
};

class BankCardActions {

    apiResponseSuccess = (actionType: string, data: BankCard | {}): BankCardActionType => ({
        type: BankCardActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: any): BankCardActionType => ({
        type: BankCardActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: any): BankCardActionType => ({
        type: BankCardActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): BankCardActionType => ({
        type: BankCardActionTypes.RESET,
        payload: {},
    });

    getBankCards = (queryParams: any): BankCardActionType => ({
        type: BankCardActionTypes.GET_BANK_CARDS,
        payload: {queryParams},
    });

    getBankCard = (id: string): BankCardActionType => ({
        type: BankCardActionTypes.GET_BANK_CARD,
        payload: {id},
    });

    insertBankCart = (data: any): BankCardActionType => ({
        type: BankCardActionTypes.INSERT_BANK_CARD,
        payload: {data},
    });

    updateBankCart = (data: any): BankCardActionType => ({
        type: BankCardActionTypes.UPDATE_BANK_CARD,
        payload: {data},
    });

    delete = (id: string): BankCardActionType => ({
        type: BankCardActionTypes.DELETE_BANK_CARD,
        payload: {id},
    });
}

const bankCardActions = new BankCardActions();
export { bankCardActions };
