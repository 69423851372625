import {ProductActionTypes} from './constants';

export type ProductActionType = {
    type:
        | ProductActionTypes.API_RESPONSE_SUCCESS
        | ProductActionTypes.API_RESPONSE_ERROR
        | ProductActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | ProductActionTypes.GET_PRODUCTS
        | ProductActionTypes.GET_PRODUCT
        | ProductActionTypes.INSERT_PRODUCT
        | ProductActionTypes.UPDATE_PRODUCT

        | ProductActionTypes.OPEN_SEARCH
        | ProductActionTypes.CLOSE_SEARCH
        | ProductActionTypes.SEARCH_PRODUCT
        | ProductActionTypes.SEARCH_TRANSACTION
        | ProductActionTypes.CLEAR_SEARCH

        | ProductActionTypes.RESET;
    payload: {} | string;
};

type Product = {
    id: number;
    name: string;
    guard_name: string;
};

type QueryParams = {
    page: number;
    limit: number;
    orderBy: string,
    sort: string,
    transactionName?: string,
    accountId?: string
}

class ProductActions {

    apiResponseSuccess = (actionType: string, data: Product | {}): ProductActionType => ({
        type: ProductActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): ProductActionType => ({
        type: ProductActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): ProductActionType => ({
        type: ProductActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): ProductActionType => ({
        type: ProductActionTypes.RESET,
        payload: {},
    });

    getProducts = (queryParams: QueryParams): ProductActionType => ({
        type: ProductActionTypes.GET_PRODUCTS,
        payload: { queryParams },
    });

    getProduct = (id: string, field: string): ProductActionType => ({
        type: ProductActionTypes.GET_PRODUCT,
        payload: { id, field },
    });

    insertProduct = (data: any): ProductActionType => ({
        type: ProductActionTypes.INSERT_PRODUCT,
        payload: { data }
    });

    updateProduct = (data: any): ProductActionType => ({
        type: ProductActionTypes.UPDATE_PRODUCT,
        payload: { data }
    });

    openSearch = (): ProductActionType => ({
        type: ProductActionTypes.OPEN_SEARCH,
        payload: {}
    });

    closeSearch = (): ProductActionType => ({
        type: ProductActionTypes.CLOSE_SEARCH,
        payload: {}
    });

    search = (queryParams: QueryParams): ProductActionType => ({
        type: ProductActionTypes.SEARCH_PRODUCT,
        payload: { queryParams }
    });

    searchTransaction = (queryParams: QueryParams): ProductActionType => ({
        type: ProductActionTypes.SEARCH_TRANSACTION,
        payload: { queryParams }
    });

    clearSearch = (): ProductActionType => ({
        type: ProductActionTypes.CLEAR_SEARCH,
        payload: {}
    });
}

const productActions = new ProductActions();
export { productActions };
