export enum DWTransactionActionTypes {
    API_RESPONSE_SUCCESS = '@@DWTransaction/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@DWTransaction/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@DWTransaction/API_RESPONSE_VALIDATION_ERRORS',

    FIND_DWTRANSACTION = '@@DWTransaction/FIND_DWTRANSACTION',
    GET_DWTRANSACTIONS = '@@DWTransaction/GET_DWTRANSACTIONS',

    OPEN_SEARCH = '@@DWTransaction/OPEN_SEARCH',
    CLOSE_SEARCH = '@@DWTransaction/CLOSE_SEARCH',
    SEARCH_DWTRANSACTION = '@@DWTransaction/SEARCH_DWTRANSACTION',
    CLEAR_SEARCH = '@@DWTransaction/CLEAR_SEARCH',

    RESET = '@@DWTransaction/RESET',

    EXPORT = '@@DWTransaction/EXPORT',
    RESET_SEARCH = '@@DWTransaction/RESET_SEARCH',
}

export enum DWTransactionErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



