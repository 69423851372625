import { ReactNode, createContext, useContext, useState } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

const NotificationContext = createContext<any>({});

export function useNotificationContext() {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotificationContext must be used within an NotificationProvider');
  }
  return context;
}

export function NotificationProvider({ children }: { children: ReactNode }) {

  const showSuccessToast = (content: any, options: any) => {
    toast.success(content, options);
  };

  const showErrorToast = (content: any, title: any, text: any, options: any) => {
    toast.error(content, options);
  };

  return (
      <NotificationContext.Provider value={{ showSuccessToast, showErrorToast }}>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
        {children}
      </NotificationContext.Provider>
  );
}
