export enum CUActionTypes {
    API_RESPONSE_SUCCESS = '@@cu/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@cu/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@cu/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@cu/RESET',

    UNITS = '@@cu/UNITS',
    INSERT = '@@cu/INSERT',
    DELETE = '@@cu/DELETE',
}

export enum CUErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.'
}



