export enum ProductActionTypes {
    API_RESPONSE_SUCCESS = '@@product/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@product/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@product/API_RESPONSE_VALIDATION_ERRORS',

    GET_PRODUCTS = '@@product/GET_PRODUCTS',
    GET_PRODUCT = '@@product/GET_PRODUCT',
    INSERT_PRODUCT = '@@product/INSERT_PRODUCT',
    UPDATE_PRODUCT = '@@product/UPDATE_PRODUCT',

    OPEN_SEARCH = '@@product/OPEN_SEARCH',
    CLOSE_SEARCH = '@@product/CLOSE_SEARCH',
    SEARCH_PRODUCT = '@@product/SEARCH_PRODUCT',
    SEARCH_TRANSACTION = '@@product/SEARCH_TRANSACTION',
    CLEAR_SEARCH = '@@product/CLEAR_SEARCH',

    RESET = '@@product/RESET',
}

export enum ProductErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



