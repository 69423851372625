import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {graphql} from 'helpers';
import {checkResponseError, checkServerError, pluckResponse} from "../../helpers/functions";
import { IpActionTypes, IpErrors } from './constants';
import { ipActions } from './actions';

type IpData = {
    payload: {
        id: number,

        data: any;

        queryParams: {
            limit: number;
            page: number;
        }
    };
    type: string;
};

function* getIps({ payload: { queryParams } }: IpData): SagaIterator {

    try {
        const param:any = {
            query:`query TrustedIps($page: Int!, $limit: Int!, $orderBy: String!, $sort: String!, $accountId: String) {
                    trustedIps(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId) {
                        data {
                            id
                            user {
                                id
                                username
                            }
                            ip
                            created_at
                        }
                        total
                        per_page
                        from
                        to
                        current_page
                        last_page
                        has_more_pages
                    }
                }`,
            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IpErrors.RESPONSE_200);

        const rT = response.data;

        checkServerError(rT);

        const ips = pluckResponse(rT, "trustedIps");

        const result = {
            ips: {
                ips,
                queryParams
            }
        }

        yield put(ipActions.apiResponseSuccess(IpActionTypes.GET_IPS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(ipActions.apiResponseError(IpActionTypes.GET_IPS, error));
        } else {
            yield put(ipActions.apiResponseValidationErrors(IpActionTypes.GET_IPS, error));
        }
    }
}

function* getIp({ payload: { id } }: IpData): SagaIterator {

    try {
        const param:any = {
            query:`query Ip($id: String!) {
                ip(id: $id) {
                    id
                    user {
                        id
                        username
                    }
                    ip
                    created_at
                }
            }`,
            variables: {
                id
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IpErrors.RESPONSE_200);

        const rT = response.data;

        checkServerError(rT);

        const ip = pluckResponse(rT, "trustedIp");

        yield put(ipActions.apiResponseSuccess(IpActionTypes.GET_IP, ip));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(ipActions.apiResponseError(IpActionTypes.GET_IP, error));
        } else {
            yield put(ipActions.apiResponseValidationErrors(IpActionTypes.GET_IP, error));
        }
    }
}

function* insertIp({ payload: {data} }: IpData): SagaIterator {
    try {
        const param:any = {
            query: `mutation CreateTrustedIp($accountId: String, $ip: String!){
                createTrustedIp(accountId: $accountId, ip: $ip)
            }`,
            variables: {
                ...data
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IpErrors.RESPONSE_200);

        const rIT = response.data;

        checkServerError(rIT);

        const createIp = pluckResponse(rIT, "createTrustedIp");

        yield put(ipActions.apiResponseSuccess(IpActionTypes.INSERT_IP, createIp));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(ipActions.apiResponseError(IpActionTypes.INSERT_IP, error));
        } else {
            yield put(ipActions.apiResponseValidationErrors(IpActionTypes.INSERT_IP, error));
        }
    }
}

function* deleteIp({ payload: {id} }: IpData): SagaIterator {
    try {
        const param:any = {
            query: `mutation DeleteTrustedIp($id: String!){
                deleteTrustedIp(id: $id)
            }`,
            variables: {
                id: id
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IpErrors.RESPONSE_200);

        const rDBC = response.data;

        checkServerError(rDBC);

        pluckResponse(rDBC, "deleteTrustedIp");

        yield put(ipActions.apiResponseSuccess(IpActionTypes.DELETE_IP, id));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(ipActions.apiResponseError(IpActionTypes.DELETE_IP, error));
        } else {
            yield put(ipActions.apiResponseValidationErrors(IpActionTypes.DELETE_IP, error));
        }
    }
}

function* searchIp({ payload: { queryParams } }: IpData): SagaIterator {

    try {
        const param = {
            query:`query TrustedIps(
                $page: Int!, 
                $limit: Int,
                $orderBy: String!,
                $sort: String!,
                $accountId: String,
                $ip: String
            ) {
                trustedIps (
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort,
                    accountId: $accountId,
                    ip: $ip
                ) {
                    data {
                        id
                        user {
                            id
                            username
                        }
                        ip
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, IpErrors.RESPONSE_200);

        const rT = response.data;

        checkServerError(rT);

        const ips = pluckResponse(rT, "trustedIps");

        const result = {
            ips,
            queryParams
        }

        yield put(ipActions.apiResponseSuccess(IpActionTypes.SEARCH_IP, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(ipActions.apiResponseError(IpActionTypes.SEARCH_IP, error));
        } else {
            yield put(ipActions.apiResponseValidationErrors(IpActionTypes.SEARCH_IP, error));
        }
    }
}

export function* watchGetIps(): any {
    yield takeEvery(IpActionTypes.GET_IPS, getIps);
}

export function* watchGetIp(): any {
    yield takeEvery(IpActionTypes.GET_IP, getIp);
}

export function* watchInsertIp(): any {
    yield takeEvery(IpActionTypes.INSERT_IP, insertIp);
}

export function* watchDeleteIp(): any {
    yield takeEvery(IpActionTypes.DELETE_IP, deleteIp);
}

export function* watchSearchIp(): any {
    yield takeEvery(IpActionTypes.SEARCH_IP, searchIp);
}

function* IpSaga() {
    yield all([
        fork(watchGetIps),
        fork(watchGetIp),
        fork(watchInsertIp),
        fork(watchDeleteIp),
        fork(watchSearchIp)
    ]);
}

export default IpSaga;
