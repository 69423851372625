import { RoleActionTypes } from './constants';
import {isEmptyObject} from "../../helpers/functions";

const INIT_STATE = {
    roles: {},
    queryParams: {},

    role: {},

    permissions: {},

    loading: false,
    error: "",
    validationErrors: {},
    selectedPermissions: [],
    roleInserted: false,
    roleUpdated: false,

    searchModal: false,
    tmpRoles: {},
    tmpQueryParams: {},
    searchMode: false,
    searchLoading: false,
    searchError: "",
    searchValidationErrors: {}
};

type RoleData = {
    id: number;
    name: string;
    guard_name: string;
};

type RoleQueryParams = {
    limit: number;
    page: number;
    orderBy: string;
    sort: string;
    roleName: string;
};

type RolesResult = {
    roles: RoleData | {},
    queryParams: RoleQueryParams
}

type RoleActionType = {
    type:
        | RoleActionTypes.API_RESPONSE_SUCCESS
        | RoleActionTypes.API_RESPONSE_ERROR
        | RoleActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | RoleActionTypes.GET_ROLES
        | RoleActionTypes.NEW_ROLE
        | RoleActionTypes.INSERT_ROLE
        | RoleActionTypes.EDIT_ROLE
        | RoleActionTypes.UPDATE_ROLE
        | RoleActionTypes.SET_VARIABLE
        | RoleActionTypes.RESET

        | RoleActionTypes.OPEN_SEARCH
        | RoleActionTypes.CLOSE_SEARCH
        | RoleActionTypes.SEARCH_ROLE
        | RoleActionTypes.CLEAR_SEARCH;
    payload: {
        actionType?: string;
        data?: RolesResult | any;
        error?: string;
        validationErrors?: [];

        key?: any
        value?: any
    };
};

type State = {
    roles?: RoleData | {};

    queryParams?: {};

    loading?: boolean;
    value?: boolean;
    tmpRoles?: any;

    tmpQueryParams?: any;

    permissions: any
};

const Role = (state: State = INIT_STATE, action: RoleActionType) => {
    switch (action.type) {
        case RoleActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case RoleActionTypes.GET_ROLES: {
                    return {
                        ...state,
                        roles: action.payload.data?.roles,
                        queryParams: action.payload.data?.queryParams,
                        loading: false,
                    };
                }
                case RoleActionTypes.NEW_ROLE: {
                    return {
                        ...state,
                        permissions: isEmptyObject(state.permissions)? action.payload.data: state.permissions,
                        loading: false,
                    };
                }
                case RoleActionTypes.INSERT_ROLE: {
                    return {
                        ...state,
                        roleInserted: true,
                        loading: false,
                    };
                }
                case RoleActionTypes.EDIT_ROLE: {
                    const permissions = action.payload.data.role.permissions.map((v: any) => {
                        return v.name;
                    })
                    return {
                        ...state,
                        role: action.payload.data.role,
                        permissions: isEmptyObject(state.permissions)? action.payload.data.permissions: state.permissions,
                        loading: false,
                        selectedPermissions: permissions
                    };
                }
                case RoleActionTypes.UPDATE_ROLE: {
                    return {
                        ...state,
                        roleUpdated: true,
                        loading: false,
                    };
                }
                case RoleActionTypes.SEARCH_ROLE: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpRoles: isEmptyObject(state.tmpRoles)? state.roles: state.tmpRoles,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        roles: action.payload.data?.roles,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case RoleActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case RoleActionTypes.GET_ROLES:
                case RoleActionTypes.NEW_ROLE:
                case RoleActionTypes.INSERT_ROLE:
                case RoleActionTypes.EDIT_ROLE:
                case RoleActionTypes.UPDATE_ROLE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case RoleActionTypes.SEARCH_ROLE: {
                    return {
                        ...state,
                        searchError: action.payload.error,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case RoleActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case RoleActionTypes.GET_ROLES: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case RoleActionTypes.NEW_ROLE:
                case RoleActionTypes.INSERT_ROLE:
                case RoleActionTypes.EDIT_ROLE:
                case RoleActionTypes.UPDATE_ROLE: {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case RoleActionTypes.SEARCH_ROLE: {
                    return {
                        ...state,
                        searchValidationErrors: action.payload.validationErrors,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case RoleActionTypes.GET_ROLES:
            return {
                ...state,
                // roles: {},
                loading: true,
                error: "",
            };

        case RoleActionTypes.NEW_ROLE:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case RoleActionTypes.INSERT_ROLE:
            return {
                ...state,
                roleInserted: true,
                loading: true,
                error: "",
            };

        case RoleActionTypes.EDIT_ROLE:
            return {
                ...state,
                role: {},
                loading: true,
                error: "",
            };

        case RoleActionTypes.UPDATE_ROLE:
            return {
                ...state,
                roleUpdated: false,
                loading: true,
                error: "",
            };

        case RoleActionTypes.SET_VARIABLE: {
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            };
        }

        case RoleActionTypes.RESET:
            return {
                ...state,
                roles: {},
                queryParams: {},

                role: {},
                // permissions: {},

                loading: false,
                error: "",
                validationErrors: {},
                roleInserted: false,
                roleUpdated: false,

                searchModal: false,
                searchLoading: false,
                searchMode: false,
                searchError: "",
                searchValidationErrors: {},

                tmpRoles: {},
                tmpQueryParams: {},
            };


        // search actions
        case RoleActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case RoleActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},
            };
        case RoleActionTypes.SEARCH_ROLE:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case RoleActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                roles: state.tmpRoles,
                queryParams: state.tmpQueryParams,
                tmpRoles: {},
                tmpQueryParams: {},
                searchMode: false,
            };

        default:
            return { ...state };
    }
};

export default Role;
