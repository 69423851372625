import {DLActionTypes} from './constants';

const INIT_STATE = {
    loading: false,
    error: "",
    validationErrors: {},

    drivingLicence: [],
    drivingLicenceStatus: "",

    updated: false,
};

type DLActionType = {
    type:
        | DLActionTypes.API_RESPONSE_SUCCESS
        | DLActionTypes.API_RESPONSE_ERROR
        | DLActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | DLActionTypes.RESET

        | DLActionTypes.UPLOAD_DRIVING_LICENCE
        | DLActionTypes.UPLOAD_DL_PROGRESS
        | DLActionTypes.DELETE_DRIVING_LICENCE

        | DLActionTypes.SET_DL

        | DLActionTypes.UPDATE;

    payload: {
        actionType?: string;
        data?: any;
        error?: any;
        validationErrors?: any;

        key?: any;
        value?: any;

        // for upload purpose
        file: {fileId: string} & File;
        fileId: string;
        progress: number;
        retry: boolean;
    };
};

type State = {
    drivingLicence: any;
};

type FileType = {
    uploaded?: boolean;
    progress?: number;
    fileId?: string;
    error?: string;
    uuid?: string;
} & File;

const DL = (state: State = INIT_STATE, action: DLActionType) => {
    switch (action.type) {
        case DLActionTypes.API_RESPONSE_SUCCESS:

            switch (action.payload.actionType) {

                case DLActionTypes.SET_DL: {
                    return {
                        ...state,
                        drivingLicence: action.payload.data?.media || [],
                        drivingLicenceStatus: action.payload.data?.status || ""
                    };
                }

                case DLActionTypes.UPLOAD_DRIVING_LICENCE: {
                    return {
                        ...state,
                        drivingLicence: [
                            ...state.drivingLicence.map((file: FileType) => {
                                if(file.fileId === action.payload.data.fileId) {
                                    file.uploaded = true;
                                    file.progress = 0;
                                    file.uuid = action.payload.data.uploadDrivingLicence.uuid;
                                }
                                return file;
                            }),
                        ]
                    };
                }
                case DLActionTypes.DELETE_DRIVING_LICENCE: {
                    return {
                        ...state,
                        drivingLicence: [
                            ...state.drivingLicence.filter((file: FileType) => {
                                return file.fileId !== action.payload.data;
                            }),
                        ],
                        loading: false
                    };
                }
                case DLActionTypes.UPDATE: {
                    return {
                        ...state,
                        updated: true,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case DLActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case DLActionTypes.UPDATE:
                case DLActionTypes.DELETE_DRIVING_LICENCE:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

                case DLActionTypes.UPLOAD_DRIVING_LICENCE: {
                    return {
                        ...state,
                        drivingLicence: [
                            ...state.drivingLicence.map((file: FileType) => {
                                if(file.fileId === action.payload.error.fileId) {
                                    file.error = action.payload.error.error;
                                    file.progress = 0;
                                }
                                return file;
                            }),
                        ]
                    };
                }

                default:
                    return { ...state };
            }

        case DLActionTypes.API_RESPONSE_VALIDATION_ERRORS:
            switch (action.payload.actionType) {
                case DLActionTypes.UPDATE: {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case DLActionTypes.UPLOAD_DRIVING_LICENCE: {
                    return {
                        ...state,
                        drivingLicence: [
                            ...state.drivingLicence.map((file: FileType) => {
                                if(file.fileId === action.payload.validationErrors.fileId) {
                                    file.error = action.payload.validationErrors.error.file;
                                }
                                return file;
                            })
                        ],
                    };
                }
                default:
                    return { ...state };
            }

        case DLActionTypes.RESET:
            return {
                ...state,

                loading: false,
                error: "",
                validationErrors: {},

                drivingLicence: [],
                drivingLicenceStatus: "",

                updated: false,

            };

        case DLActionTypes.UPLOAD_DRIVING_LICENCE:
            if(!action.payload.retry) {
                state.drivingLicence = [
                    action.payload.file,
                    ...state.drivingLicence
                ];
            } else {
                state.drivingLicence = [
                    ...state.drivingLicence.map((file: any) => {
                        if(file.fileId === action.payload.file.fileId) {
                            file.error = "";
                        }
                        return file;
                    })
                ];
            }
            return {
                ...state,
                error: ""
            };

        case DLActionTypes.UPLOAD_DL_PROGRESS:
            return {
                ...state,
                drivingLicence: [
                    ...state.drivingLicence.map((file: FileType) => {
                        if(file.fileId === action.payload.fileId) {
                            file.progress = action.payload.progress;
                        }
                        return file;
                    })
                ],
            };

        case DLActionTypes.DELETE_DRIVING_LICENCE:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case DLActionTypes.UPDATE:
            return {
                ...state,
                loading: true,
                error: "",
                updated: false
            };

        default:
            return { ...state };
    }
};

export default DL;
