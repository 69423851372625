import { OrderActionTypes } from './constants';

const INIT_STATE = {
    error: "",
    loading: false,
    conversionRates: [],
    conversionRate: {},
    inserted: false,
    updated: false,
    deleted: false,
};

type OrderActionType = {
    type:
        | OrderActionTypes.API_RESPONSE_SUCCESS
        | OrderActionTypes.API_RESPONSE_ERROR
        | OrderActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | OrderActionTypes.ORDERS
        | OrderActionTypes.ORDER
        | OrderActionTypes.CREATE
        | OrderActionTypes.UPDATE
        | OrderActionTypes.DELETE
        | OrderActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
        validationErrors?: [];

        key: string;
        value?: string;

        page?: string;
    };
};

type State = {
    loading: boolean,
    conversionRates: string[]
};

const Order = (state: State = INIT_STATE, action: OrderActionType) => {
    switch (action.type) {
        case OrderActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case OrderActionTypes.ORDERS: {
                    return {
                        ...state,
                        conversionRates: action.payload.data,
                        loading: false,
                    };
                }

                case OrderActionTypes.ORDER: {
                    return {
                        ...state,
                        conversionRate: action.payload.data,
                        loading: false,
                    };
                }

                case OrderActionTypes.CREATE: {
                    return {
                        ...state,
                        inserted: true,
                        loading: false,
                    };
                }

                case OrderActionTypes.UPDATE: {
                    return {
                        ...state,
                        updated: true,
                        loading: false,
                    };
                }

                case OrderActionTypes.DELETE: {
                    return {
                        ...state,
                        deleted: true,
                        loading: false,
                        conversionRates: state.conversionRates.filter(function(item: any) {
                            return item.id !== action.payload.data
                        })
                    };
                }
                default:
                    return { ...state };
            }

        case OrderActionTypes.API_RESPONSE_ERROR:

            switch (action.payload.actionType) {

                case OrderActionTypes.ORDERS:
                case OrderActionTypes.ORDER:
                case OrderActionTypes.CREATE:
                case OrderActionTypes.UPDATE:
                case OrderActionTypes.DELETE:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case OrderActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case OrderActionTypes.ORDERS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case OrderActionTypes.ORDER:
                case OrderActionTypes.CREATE:
                case OrderActionTypes.UPDATE:
                case OrderActionTypes.DELETE:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case OrderActionTypes.ORDERS:
            return {
                ...state,
                loading: true,
                error: "",
            };
        case OrderActionTypes.ORDER:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case OrderActionTypes.CREATE:
            return {
                ...state,
                inserted: false,
                loading: true,
                error: "",
            };
        case OrderActionTypes.UPDATE:
            return {
                ...state,
                inserted: false,
                loading: true,
                error: "",
            };
        case OrderActionTypes.DELETE:
            return {
                ...state,
                deleted: false,
                loading: true,
                error: "",
            };

        case OrderActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: "",

                conversionRates: [],
                conversionRate: {},
                inserted: false,
                updated: false,
                deleted: false,
            };

        default:
            return { ...state };
    }
};

export default Order;
