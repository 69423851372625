import {BankCardActionTypes} from './constants';

const INIT_STATE = {
    loading: false,
    error: "",
    validationErrors: {},

    bankCards: {},
    bankCardsQueryParams: {},
    bankCard: {},
    bankCardInserted: false,
    bankCardUpdated: false,
    bankCardDeleted: false
};

type BankCardActionType = {
    type:
        | BankCardActionTypes.API_RESPONSE_SUCCESS
        | BankCardActionTypes.API_RESPONSE_ERROR
        | BankCardActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | BankCardActionTypes.RESET

        | BankCardActionTypes.GET_BANK_CARDS
        | BankCardActionTypes.GET_BANK_CARD
        | BankCardActionTypes.INSERT_BANK_CARD
        | BankCardActionTypes.UPDATE_BANK_CARD
        | BankCardActionTypes.DELETE_BANK_CARD
    ;

    payload: {
        actionType?: string;
        data?: any;
        error?: any;
        validationErrors?: any;

        otp: string;
    };
};

type State = {
    bankCard: any;
    bankCards: any;
};

const BankCard = (state: State = INIT_STATE, action: BankCardActionType) => {
    switch (action.type) {
        case BankCardActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case BankCardActionTypes.GET_BANK_CARDS: {
                    return {
                        ...state,
                        loading: false,
                        bankCards: action.payload.data.bankCards.bankCards,
                        bankCardsQueryParams: action.payload.data.bankCards.queryParams,
                    };
                }
                case BankCardActionTypes.GET_BANK_CARD: {
                    return {
                        ...state,
                        loading: false,
                        bankCard: action.payload.data,
                    };
                }
                case BankCardActionTypes.INSERT_BANK_CARD: {
                    return {
                        ...state,
                        loading: false,
                        bankCardInserted: true
                    };
                }
                case BankCardActionTypes.UPDATE_BANK_CARD: {
                    return {
                        ...state,
                        loading: false,
                        bankCardUpdated: true,
                    };
                }

                case BankCardActionTypes.DELETE_BANK_CARD: {
                    return {
                        ...state,
                        loading: false,
                        bankCardDeleted: true,
                        bankCards: {
                            ...state.bankCards,
                            data: state.bankCards.data.filter((item: any) => {
                                return item.id !== action.payload.data;
                            })
                        }
                    };
                }

                default:
                    return { ...state };
            }

        case BankCardActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case BankCardActionTypes.GET_BANK_CARD:
                case BankCardActionTypes.INSERT_BANK_CARD:
                case BankCardActionTypes.UPDATE_BANK_CARD:
                case BankCardActionTypes.DELETE_BANK_CARD:
                case BankCardActionTypes.GET_BANK_CARDS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case BankCardActionTypes.API_RESPONSE_VALIDATION_ERRORS:
            switch (action.payload.actionType) {
                case BankCardActionTypes.GET_BANK_CARD:
                case BankCardActionTypes.INSERT_BANK_CARD:
                case BankCardActionTypes.UPDATE_BANK_CARD:
                case BankCardActionTypes.DELETE_BANK_CARD: {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case BankCardActionTypes.GET_BANK_CARDS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case BankCardActionTypes.RESET:
            return {
                ...state,

                loading: false,
                error: "",
                validationErrors: {},

                bankCards: {},
                bankCardsQueryParams: {},
                bankCard: {},
                bankCardInserted: false,
                bankCardUpdated: false,
                bankCardDeleted: false
            };

        case BankCardActionTypes.GET_BANK_CARDS:
            return {
                ...state,
                loading: true,
                error: "",
                // bankCards: {},
            };
        case BankCardActionTypes.GET_BANK_CARD:
            return {
                ...state,
                loading: true,
                error: "",
                bankCard: {},
            };
        case BankCardActionTypes.INSERT_BANK_CARD:
            return {
                ...state,
                loading: true,
                error: "",
                bankCardInserted: false,
            };
        case BankCardActionTypes.UPDATE_BANK_CARD:
            return {
                ...state,
                loading: true,
                error: "",
                bankCardUpdated: false,
            };
        case BankCardActionTypes.DELETE_BANK_CARD:
            return {
                ...state,
                loading: true,
                error: "",
                bankCardDeleted: false,
            };

        default:
            return { ...state };
    }
};

export default BankCard;
