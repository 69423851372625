import { NCardFrontActionTypes } from './constants';

export type NCardFrontActionType = {
    type:
        | NCardFrontActionTypes.API_RESPONSE_SUCCESS
        | NCardFrontActionTypes.API_RESPONSE_ERROR
        | NCardFrontActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | NCardFrontActionTypes.RESET

        | NCardFrontActionTypes.UPLOAD_NC_FRONT
        | NCardFrontActionTypes.UPLOAD_NC_FRONT_PROGRESS
        | NCardFrontActionTypes.DELETE_NC_FRONT

        | NCardFrontActionTypes.UPDATE
    ;

    payload: {} | string;
};

class NCardFrontActions {

    apiResponseSuccess = (actionType: string, data: any): NCardFrontActionType => ({
        type: NCardFrontActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: any): NCardFrontActionType => ({
        type: NCardFrontActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: any): NCardFrontActionType => ({
        type: NCardFrontActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): NCardFrontActionType => ({
        type: NCardFrontActionTypes.RESET,
        payload: {},
    });

    uploadNationalCardFront = (id:string, file: File, retry: boolean): NCardFrontActionType => ({
        type: NCardFrontActionTypes.UPLOAD_NC_FRONT,
        payload: {id, file, retry},
    });

    uploadNationalCardFrontProgress = (progress: any, fileId: string): NCardFrontActionType => {
        return {
            type: NCardFrontActionTypes.UPLOAD_NC_FRONT_PROGRESS,
            payload: {fileId, progress},
        }
    }

    deleteNcFront = (id:string, fileId: string, uuid: string): NCardFrontActionType => ({
        type: NCardFrontActionTypes.DELETE_NC_FRONT,
        payload: {id, fileId, uuid},
    });

    update = (data:any): NCardFrontActionType => ({
        type: NCardFrontActionTypes.UPDATE,
        payload: {data},
    });

}

const nCFrontActions = new NCardFrontActions();
export { nCFrontActions };
