import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {CUActionTypes, CUErrors} from "./constants";
import {cuActions} from "./actions";
import {graphql} from "../../helpers";
import {SagaIterator} from "@redux-saga/core";
import {checkResponseError, checkServerError, pluckResponse} from "../../helpers/functions";

type CUData = {
    payload: {
        id: string;

        limit: string;
        page: string;

        queryParams: {
            limit: string;
            page: string;
            orderBy: string;
            sort: string;
        };

        data: any;
    };
    type: string;
};

function* conversationUnits({ payload: { queryParams } }: CUData): SagaIterator {
    try {
        const param = {
            query:`query ConversationUnits {
                conversationUnits
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, CUErrors.RESPONSE_200);

        const rCU = response.data;

        checkServerError(rCU);

        const conversationUnits = pluckResponse(rCU, "conversationUnits");

        yield put(cuActions.apiResponseSuccess(CUActionTypes.UNITS, conversationUnits));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(cuActions.apiResponseError(CUActionTypes.UNITS, error));
        } else {
            yield put(cuActions.apiResponseValidationErrors(CUActionTypes.UNITS, error));
        }
    }
}

function* insert({ payload: {data} }: CUData): SagaIterator {
    try {
        const param = {

            query:`mutation CreateConversationUnit(
                $unit: String!,
            ) {
                createConversationUnit(
                    unit: $unit,
                )
            }`,

            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, CUErrors.RESPONSE_200);

        const rCU = response.data;

        checkServerError(rCU);

        pluckResponse(rCU, "createConversationUnit");

        yield put(cuActions.apiResponseSuccess(CUActionTypes.INSERT, data.unit));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(cuActions.apiResponseError(CUActionTypes.INSERT, error));
        } else {
            yield put(cuActions.apiResponseValidationErrors(CUActionTypes.INSERT, error));
        }
    }
}

function* Delete({ payload: {data} }: CUData): SagaIterator {
    try {
        const param = {

            query:`mutation DeleteConversationUnit(
                $unit: String!,
            ) {
                deleteConversationUnit(
                    unit: $unit,
                )
            }`,

            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, CUErrors.RESPONSE_200);

        const rCU = response.data;

        checkServerError(rCU);

        pluckResponse(rCU, "deleteConversationUnit");

        yield put(cuActions.apiResponseSuccess(CUActionTypes.DELETE, data.unit));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(cuActions.apiResponseError(CUActionTypes.DELETE, error));
        } else {
            yield put(cuActions.apiResponseValidationErrors(CUActionTypes.DELETE, error));
        }
    }
}

export function* watchConversationUnits() {
    yield takeEvery(CUActionTypes.UNITS, conversationUnits);
}

export function* watchInsert() {
    yield takeEvery(CUActionTypes.INSERT, insert);
}

export function* watchDelete() {
    yield takeEvery(CUActionTypes.DELETE, Delete);
}

function* cuSaga() {
    yield all([
        fork(watchConversationUnits),
        fork(watchInsert),
        fork(watchDelete),
    ]);
}

export default cuSaga;
