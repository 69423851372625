import {LogActionTypes} from './constants';

export type LogActionType = {
    type:
        | LogActionTypes.API_RESPONSE_SUCCESS
        | LogActionTypes.API_RESPONSE_ERROR
        | LogActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | LogActionTypes.GET_LOGS
        | LogActionTypes.GET_LOG

        | LogActionTypes.OPEN_SEARCH
        | LogActionTypes.CLOSE_SEARCH
        | LogActionTypes.SEARCH_LOG
        | LogActionTypes.SEARCH_TRANSACTION
        | LogActionTypes.CLEAR_SEARCH

        | LogActionTypes.RESET;
    payload: {} | string;
};

type Log = {
    id: number;
    name: string;
    guard_name: string;
};

type QueryParams = {
    page: number;
    limit: number;
    orderBy: string,
    sort: string,
    transactionName?: string,
    accountId?: string
}

class LogActions {

    apiResponseSuccess = (actionType: string, data: Log | {}): LogActionType => ({
        type: LogActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): LogActionType => ({
        type: LogActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): LogActionType => ({
        type: LogActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): LogActionType => ({
        type: LogActionTypes.RESET,
        payload: {},
    });

    getLogs = (queryParams: QueryParams): LogActionType => ({
        type: LogActionTypes.GET_LOGS,
        payload: { queryParams },
    });

    openSearch = (): LogActionType => ({
        type: LogActionTypes.OPEN_SEARCH,
        payload: {}
    });

    closeSearch = (): LogActionType => ({
        type: LogActionTypes.CLOSE_SEARCH,
        payload: {}
    });

    search = (queryParams: QueryParams): LogActionType => ({
        type: LogActionTypes.SEARCH_LOG,
        payload: { queryParams }
    });

    searchTransaction = (queryParams: QueryParams): LogActionType => ({
        type: LogActionTypes.SEARCH_TRANSACTION,
        payload: { queryParams }
    });

    clearSearch = (): LogActionType => ({
        type: LogActionTypes.CLEAR_SEARCH,
        payload: {}
    });

}

const logActions = new LogActions();
export { logActions };
