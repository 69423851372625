import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {graphql} from 'helpers';
import {checkResponseError, checkServerError, pluckResponse} from "../../helpers/functions";
import { BankCardActionTypes, BankCardErrors } from './constants';
import { bankCardActions } from './actions';

type BankCardData = {
    payload: {
        id: number,
        data: any;

        queryParams: {
            limit: number;
            page: number;

        }
    };
    type: string;
};

function* getBankCards({ payload: { queryParams } }: BankCardData): SagaIterator {

    try {
        const param:any = {
            query:`query BankCards($page: Int!, $limit: Int!, $orderBy: String!, $sort: String!, $status: String!, $action: String, $accountId: String) {
                    bankCards(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, status: $status, action: $action, accountId: $accountId) {
                        data {
                            id
                            user {
                                id
                                username
                                email
                                display_name
                            }
                            bank_name
                            number
                            status
                            created_at
                            updated_at
                        }
                        total
                        per_page
                        from
                        to
                        current_page
                        last_page
                        has_more_pages
                    }
                }`,
            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, BankCardErrors.RESPONSE_200);

        const rBankCards = response.data;

        checkServerError(rBankCards);

        const bankCards = pluckResponse(rBankCards, "bankCards");

        const result = {
            bankCards: {
                bankCards,
                queryParams
            }
        }

        yield put(bankCardActions.apiResponseSuccess(BankCardActionTypes.GET_BANK_CARDS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(bankCardActions.apiResponseError(BankCardActionTypes.GET_BANK_CARDS, error));
        } else {
            yield put(bankCardActions.apiResponseValidationErrors(BankCardActionTypes.GET_BANK_CARDS, error));
        }
    }
}

function* getBankCard({ payload: { id } }: BankCardData): SagaIterator {

    try {
        const param:any = {
            query:`query BankCard($id: String!) {
                bankCard(id: $id) {
                    user {
                        display_name
                    }
                    bank_name,
                    number,
                    status,
                }
            }`,
            variables: {
                id
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, BankCardErrors.RESPONSE_200);

        const rBankCard = response.data;

        checkServerError(rBankCard);

        const bankCard = pluckResponse(rBankCard, "bankCard");

        yield put(bankCardActions.apiResponseSuccess(BankCardActionTypes.GET_BANK_CARD, bankCard));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(bankCardActions.apiResponseError(BankCardActionTypes.GET_BANK_CARD, error));
        } else {
            yield put(bankCardActions.apiResponseValidationErrors(BankCardActionTypes.GET_BANK_CARD, error));
        }
    }
}

function* insertBankCard({ payload: {data} }: BankCardData): SagaIterator {
    try {
        const param:any = {
            query: `mutation CreateBankCard($action: String!, $cardNumber: String!, $userId: String!){
                createBankCard(action: $action, cardNumber: $cardNumber, userId: $userId)
            }`,
            variables: {
                ...data
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, BankCardErrors.RESPONSE_200);

        const rInsertBankCard = response.data;

        checkServerError(rInsertBankCard);

        pluckResponse(rInsertBankCard, "createBankCard");

        yield put(bankCardActions.apiResponseSuccess(BankCardActionTypes.INSERT_BANK_CARD, true));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(bankCardActions.apiResponseError(BankCardActionTypes.INSERT_BANK_CARD, error));
        } else {
            yield put(bankCardActions.apiResponseValidationErrors(BankCardActionTypes.INSERT_BANK_CARD, error));
        }
    }
}

function* updateBankCard({ payload: {data} }: BankCardData): SagaIterator {
    try {
        const param:any = {
            query: `mutation UpdateBankCard($id: String!, $cardNumber: String!, $status: String){
                updateBankCard(id: $id, cardNumber: $cardNumber, status: $status)
            }`,
            variables: {
                ...data,
                status: data.bankCardStatus
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, BankCardErrors.RESPONSE_200);

        const rUBC = response.data;

        checkServerError(rUBC);

        pluckResponse(rUBC, "updateBankCard");

        yield put(bankCardActions.apiResponseSuccess(BankCardActionTypes.UPDATE_BANK_CARD, true));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(bankCardActions.apiResponseError(BankCardActionTypes.UPDATE_BANK_CARD, error));
        } else {
            yield put(bankCardActions.apiResponseValidationErrors(BankCardActionTypes.UPDATE_BANK_CARD, error));
        }
    }
}

function* deleteBankCard({ payload: {id} }: BankCardData): SagaIterator {
    try {
        const param:any = {
            query: `mutation DeleteBankCard($id: String!){
                deleteBankCard(id: $id)
            }`,
            variables: {
                id: id
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, BankCardErrors.RESPONSE_200);

        const rDBC = response.data;

        checkServerError(rDBC);

        pluckResponse(rDBC, "deleteBankCard");

        yield put(bankCardActions.apiResponseSuccess(BankCardActionTypes.DELETE_BANK_CARD, id));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(bankCardActions.apiResponseError(BankCardActionTypes.DELETE_BANK_CARD, error));
        } else {
            yield put(bankCardActions.apiResponseValidationErrors(BankCardActionTypes.DELETE_BANK_CARD, error));
        }
    }
}

export function* watchGetBankCards(): any {
    yield takeEvery(BankCardActionTypes.GET_BANK_CARDS, getBankCards);
}

export function* watchGetBankCard(): any {
    yield takeEvery(BankCardActionTypes.GET_BANK_CARD, getBankCard);
}

export function* watchInsertBankCard(): any {
    yield takeEvery(BankCardActionTypes.INSERT_BANK_CARD, insertBankCard);
}

export function* watchUpdateBankCard(): any {
    yield takeEvery(BankCardActionTypes.UPDATE_BANK_CARD, updateBankCard);
}

export function* watchDeleteBankCard(): any {
    yield takeEvery(BankCardActionTypes.DELETE_BANK_CARD, deleteBankCard);
}

function* bankCardSaga() {
    yield all([
        fork(watchGetBankCards),
        fork(watchGetBankCard),
        fork(watchInsertBankCard),
        fork(watchUpdateBankCard),
        fork(watchDeleteBankCard),
    ]);
}

export default bankCardSaga;
