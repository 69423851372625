import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { graphql } from 'helpers';
import {PermissionActionTypes, PermissionErrors} from './constants';
import {checkResponseError, checkServerError, pluckResponse} from "../../helpers/functions";
import {permissionActions} from "./actions";

type PermissionData = {
    payload: {

        queryParams: {
            limit: string;
            page: string;
            orderBy: string;
            sort: string;

            permissionName?: string;
        };
    };
    type: string;
};

function* getPermissions({ payload: { queryParams } }: PermissionData): SagaIterator {

    try {
        const param = {
            query:`query permissions($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $permissionName: String) {
                permissions(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, permissionName: $permissionName) {
                    data {
                        id
                        name
                        category
                        created_at
                        updated_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PermissionErrors.RESPONSE_200);

        const rPermissions = response.data;

        checkServerError(rPermissions);

        const permissions = pluckResponse(rPermissions, "permissions");

        const result = {
            permissions,
            queryParams
        }

        yield put(permissionActions.apiResponseSuccess(PermissionActionTypes.GET_PERMISSIONS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(permissionActions.apiResponseError(PermissionActionTypes.GET_PERMISSIONS, error));
        } else {
            yield put(permissionActions.apiResponseValidationErrors(PermissionActionTypes.GET_PERMISSIONS, error));
        }
    }
}

function* searchPermission({ payload: { queryParams } }: PermissionData): SagaIterator {
    try {
        const param = {
            query:`query permissions($page: Int!, $limit: Int, $permissionName: String!, $orderBy: String!, $sort: String!) {
                permissions(page: $page, limit: $limit, permissionName: $permissionName, orderBy: $orderBy, sort: $sort) {
                    data {
                        id
                        name
                        category
                        created_at
                        updated_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PermissionErrors.RESPONSE_200);

        const rPermissions = response.data;

        checkServerError(rPermissions);

        const permissions = pluckResponse(rPermissions, "permissions");

        const result = {
            permissions,
            queryParams
        }

        yield put(permissionActions.apiResponseSuccess(PermissionActionTypes.SEARCH_PERMISSION, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(permissionActions.apiResponseError(PermissionActionTypes.SEARCH_PERMISSION, error));
        } else {
            yield put(permissionActions.apiResponseValidationErrors(PermissionActionTypes.SEARCH_PERMISSION, error));
        }
    }
}

export function* watchGetPermissions() {
    yield takeEvery(PermissionActionTypes.GET_PERMISSIONS, getPermissions);
}

export function* watchSearchPermissions() {
    yield takeEvery(PermissionActionTypes.SEARCH_PERMISSION, searchPermission);
}


function* permissionsSaga() {
    yield all([
        fork(watchGetPermissions),
        fork(watchSearchPermissions),
    ]);
}

export default permissionsSaga;
