import { ProviderActionTypes } from './constants';

const INIT_STATE = {
    error: "",
    loading: false,
    providers: [],
    provider: {},
    providerUpdated: false,
    statusChanged: false,

    statusError: "",
    statusLoading: false,
};

type ProviderActionType = {
    type:
        | ProviderActionTypes.API_RESPONSE_SUCCESS
        | ProviderActionTypes.API_RESPONSE_ERROR
        | ProviderActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | ProviderActionTypes.PROVIDERS
        | ProviderActionTypes.PROVIDER
        | ProviderActionTypes.UPDATE
        | ProviderActionTypes.CHANGE_STATUS
        | ProviderActionTypes.RESET;

    payload: {
        actionType?: string;
        data?: any;
        error?: string;
        validationErrors?: [];

        key: string;
        value?: string;

        page?: string;
    };
};

type State = {
    loading: boolean,
    providers: string[]
};

const Provider = (state: State = INIT_STATE, action: ProviderActionType) => {
    switch (action.type) {
        case ProviderActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case ProviderActionTypes.PROVIDERS: {
                    return {
                        ...state,
                        providers: action.payload.data,
                        loading: false,
                    };
                }

                case ProviderActionTypes.PROVIDER: {
                    return {
                        ...state,
                        provider: action.payload.data,
                        loading: false,
                    };
                }

                case ProviderActionTypes.UPDATE: {
                    return {
                        ...state,
                        providerUpdated: true,
                        loading: false,
                    };
                }
                case ProviderActionTypes.CHANGE_STATUS: {
                    return {
                        ...state,
                        statusChanged: true,
                        statusLoading: false,
                        providers: state.providers.map(function(item: any) {
                            if(item.id === action.payload.data.id) {
                                item.status = action.payload.data.status
                            }
                            return item;
                        })
                    };
                }
                default:
                    return { ...state };
            }

        case ProviderActionTypes.API_RESPONSE_ERROR:

            switch (action.payload.actionType) {

                case ProviderActionTypes.PROVIDER:
                case ProviderActionTypes.PROVIDERS:
                case ProviderActionTypes.UPDATE:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case ProviderActionTypes.CHANGE_STATUS:
                {
                    return {
                        ...state,
                        statusError: action.payload.error,
                        statusLoading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case ProviderActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case ProviderActionTypes.PROVIDERS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case ProviderActionTypes.UPDATE:
                case ProviderActionTypes.CHANGE_STATUS:
                case ProviderActionTypes.PROVIDER:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ProviderActionTypes.PROVIDERS:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case ProviderActionTypes.PROVIDER:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case ProviderActionTypes.UPDATE:
            return {
                ...state,
                providerUpdated: false,
                loading: true,
                error: "",
            };

        case ProviderActionTypes.CHANGE_STATUS:
            return {
                ...state,
                statusChanged: false,
                statusLoading: true,
                statusError: "",
            };

        case ProviderActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: "",

                providers: [],
                providerUpdated: false,
                statusChanged: false,

                statusError: "",
                statusLoading: false,
            };

        default:
            return { ...state };
    }
};

export default Provider;
