import { all, fork, put, takeEvery, call, take } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { graphql, graphqlUpload } from 'helpers';
import {checkResponseError, checkServerError, pluckResponse} from "../../helpers/functions";
import { TacActionTypes, TacErrors } from './constants';
import { tacActions } from './actions';
import { END, eventChannel } from 'redux-saga';

type TACData = {
    payload: {
        id: number,

        data: any;

        queryParams: {
            limit: number;
            page: number;
        }

        token: string;

        file: {fileId: string} & File;

        mobileNo: string;
        otp: string;
    };
    type: string;
};

function* getTACs({ payload: { queryParams } }: TACData): SagaIterator {

    try {
        const param:any = {
            query:`query TermsAndConditions($page: Int!, $limit: Int!, $orderBy: String!, $sort: String!, $verifyStatus: String) {
                    termsAndConditions(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, verifyStatus: $verifyStatus) {
                        data {
                            id
                            user {
                                id
                                display_name
                            }
                            status
                            created_at
                        }
                        total
                        per_page
                        from
                        to
                        current_page
                        last_page
                        has_more_pages
                    }
                }`,
            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, TacErrors.RESPONSE_200);

        const rTAC = response.data;

        checkServerError(rTAC);

        const termsAndConditions = pluckResponse(rTAC, "termsAndConditions");

        const result = {
            TACs: {
                TACs: termsAndConditions,
                queryParams
            }
        }

        yield put(tacActions.apiResponseSuccess(TacActionTypes.GET_TACs, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(tacActions.apiResponseError(TacActionTypes.GET_TACs, error));
        } else {
            yield put(tacActions.apiResponseValidationErrors(TacActionTypes.GET_TACs, error));
        }
    }
}

function* getTAC({ payload: { id } }: TACData): SagaIterator {

    try {
        const param:any = {
            query:`query TermAndCondition($id: String!) {
                termAndCondition(id: $id) {
                    id
                    user {
                        id
                        display_name
                    }
                    status,
                    media {
                        uuid
                        name
                        preview: url(size: "thumbnail")
                        formattedSize: size
                        type: mime_type
                        url
                    }
                }
            }`,
            variables: {
                id
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, TacErrors.RESPONSE_200);

        const rTAC = response.data;

        checkServerError(rTAC);

        const termAndCondition = pluckResponse(rTAC, "termAndCondition");

        yield put(tacActions.apiResponseSuccess(TacActionTypes.GET_TAC, termAndCondition));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(tacActions.apiResponseError(TacActionTypes.GET_TAC, error));
        } else {
            yield put(tacActions.apiResponseValidationErrors(TacActionTypes.GET_TAC, error));
        }
    }
}

function* approveTac({ payload: {data} }: TACData): SagaIterator {
    try {
        const param:any = {
            query: `mutation ApproveTermAndCondition($id: String!, $status: String){
                approveTermAndCondition(id: $id, status: $status)
            }`,
            variables: {
                ...data,
                status: data.TACStatus
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, TacErrors.RESPONSE_200);

        const rATC = response.data;

        checkServerError(rATC);

        pluckResponse(rATC, "approveTermAndCondition");

        yield put(tacActions.apiResponseSuccess(TacActionTypes.APPROVE_TAC, true));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(tacActions.apiResponseError(TacActionTypes.APPROVE_TAC, error));
        } else {
            yield put(tacActions.apiResponseValidationErrors(TacActionTypes.APPROVE_TAC, error));
        }
    }
}

function createUploader(file: File, query:any) {

    let emit: any;

    const chan = eventChannel((emitter) => {
        emit = emitter;
        return () => {};
    });

    const uploadProgressCb = ({ total, loaded }: any) => {
        const percentage = Math.round((loaded * 100) / total);
        emit(percentage);
        if (percentage === 100) emit(END);
    };

    // make form object
    let data = new FormData();
    data.set('operations', JSON.stringify(query));

    data.set('operationName', "");
    data.set('map', JSON.stringify({"file":["variables.file"]}));
    data.append('file', file);

    const uploadPromise = graphqlUpload(data, 'auth', uploadProgressCb);
    return [uploadPromise, chan];
}

function* TACUploadProgressWatcher(chan:any): SagaIterator {
    while (true) {
        const progress = yield take(chan);
        yield put(tacActions.uploadTACProgress(progress));
    }
}

function* uploadTermsAndConditions({ payload: { file } }: TACData): SagaIterator {
    try {
        const query = {
            // Mutation string
            'query': `mutation UploadTermsAndConditions($file: Upload!) {
                    uploadTermsAndConditions(file: $file)
                }`,
            'variables': {
                "file": file,
            }
        };

        const [uploadPromise, chan] = yield call(createUploader, file, query);
        yield fork(TACUploadProgressWatcher, chan);

        const response = yield call(() => uploadPromise);

        checkResponseError(response, TacErrors.RESPONSE_200);

        const rUTAC = response.data;

        checkServerError(rUTAC);

        const uploadTermsAndConditions = pluckResponse(rUTAC, "uploadTermsAndConditions");

        yield put(tacActions.apiResponseSuccess(TacActionTypes.UPLOAD_TERM_AND_CONDITION, uploadTermsAndConditions));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(tacActions.apiResponseError(TacActionTypes.UPLOAD_TERM_AND_CONDITION, error));
        } else {
            yield put(tacActions.apiResponseValidationErrors(TacActionTypes.UPLOAD_TERM_AND_CONDITION, error));
        }
    }
}

export function* watchGetTACs(): any {
    yield takeEvery(TacActionTypes.GET_TACs, getTACs);
}

export function* watchGetTAC(): any {
    yield takeEvery(TacActionTypes.GET_TAC, getTAC);
}

export function* watchApproveTac(): any {
    yield takeEvery(TacActionTypes.APPROVE_TAC, approveTac);
}

export function* watchUploadTermsAndConditions(): any {
    yield takeEvery(TacActionTypes.UPLOAD_TERM_AND_CONDITION, uploadTermsAndConditions);
}

function* TACSaga() {
    yield all([
        fork(watchGetTACs),
        fork(watchGetTAC),
        fork(watchApproveTac),
        fork(watchUploadTermsAndConditions),
    ]);
}

export default TACSaga;
