import {AccountSecurityActionTypes} from './constants';

const INIT_STATE = {
    loading: false,
    error: "",
    validationErrors: {},

    passwordChanged: false,
    lastPasswordUpdated: "",

    google2faSecret: "",
    google2faEnabled: false,

    disableGoogle2fa: false,
    verifyDisableGoogle2fa: false,
};

type AccountSecurityActionType = {
    type:
        | AccountSecurityActionTypes.API_RESPONSE_SUCCESS
        | AccountSecurityActionTypes.API_RESPONSE_ERROR
        | AccountSecurityActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | AccountSecurityActionTypes.RESET

        | AccountSecurityActionTypes.CHANGE_PASSWORD
        | AccountSecurityActionTypes.LAST_PASSWORD_UPDATED

        | AccountSecurityActionTypes.CREATE_GOOGLE_2FA
        | AccountSecurityActionTypes.VERIFY_GOOGLE_2FA

        | AccountSecurityActionTypes.DISABLE_GOOGLE_2FA
        | AccountSecurityActionTypes.VERIFY_DISABLE_GOOGLE_2FA;

    payload: {
        actionType?: string;
        data?: any;
        error?: any;
        validationErrors?: any;

        key?: any;
        value?: any;
    };
};

type State = {};

const AccountSecurity = (state: State = INIT_STATE, action: AccountSecurityActionType) => {
    switch (action.type) {
        case AccountSecurityActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case AccountSecurityActionTypes.CHANGE_PASSWORD: {
                    return {
                        ...state,
                        loading: false,
                        passwordChanged: true
                    };
                }
                case AccountSecurityActionTypes.LAST_PASSWORD_UPDATED: {
                    return {
                        ...state,
                        loading: false,
                        lastPasswordUpdated: action.payload.data
                    };
                }
                case AccountSecurityActionTypes.CREATE_GOOGLE_2FA: {
                    return {
                        ...state,
                        loading: false,
                        google2faSecret: action.payload.data
                    };
                }
                case AccountSecurityActionTypes.VERIFY_GOOGLE_2FA: {
                    return {
                        ...state,
                        loading: false,
                        google2faEnabled: action.payload.data
                    };
                }
                case AccountSecurityActionTypes.DISABLE_GOOGLE_2FA: {
                    return {
                        ...state,
                        loading: false,
                        disableGoogle2fa: action.payload.data
                    };
                }
                case AccountSecurityActionTypes.VERIFY_DISABLE_GOOGLE_2FA: {
                    return {
                        ...state,
                        loading: false,
                        verifyDisableGoogle2fa: action.payload.data
                    };
                }
                default:
                    return { ...state };
            }

        case AccountSecurityActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AccountSecurityActionTypes.CHANGE_PASSWORD:
                case AccountSecurityActionTypes.LAST_PASSWORD_UPDATED:
                case AccountSecurityActionTypes.CREATE_GOOGLE_2FA:
                case AccountSecurityActionTypes.VERIFY_GOOGLE_2FA:
                case AccountSecurityActionTypes.DISABLE_GOOGLE_2FA:
                case AccountSecurityActionTypes.VERIFY_DISABLE_GOOGLE_2FA:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };

                default:
                    return { ...state };
            }

        case AccountSecurityActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case AccountSecurityActionTypes.CHANGE_PASSWORD:
                case AccountSecurityActionTypes.LAST_PASSWORD_UPDATED:
                case AccountSecurityActionTypes.CREATE_GOOGLE_2FA:
                case AccountSecurityActionTypes.VERIFY_GOOGLE_2FA:
                case AccountSecurityActionTypes.DISABLE_GOOGLE_2FA:
                case AccountSecurityActionTypes.VERIFY_DISABLE_GOOGLE_2FA:
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                default:
                    return { ...state };
            }

        case AccountSecurityActionTypes.RESET:
            return {
                ...state,

                loading: false,
                error: "",
                validationErrors: {},

                passwordChanged: false,
                lastPasswordUpdated: "",

                google2faSecret: "",
                google2faEnabled: false,

                disableGoogle2fa: false,
                verifyDisableGoogle2fa: false,
            };

        case AccountSecurityActionTypes.CHANGE_PASSWORD:
            return {
                ...state,
                loading: true,
                error: "",
                passwordChanged: false
            };

        case AccountSecurityActionTypes.LAST_PASSWORD_UPDATED:
            return {
                ...state,
                loading: true,
                error: "",
                lastPasswordUpdated: ""
            };

        case AccountSecurityActionTypes.CREATE_GOOGLE_2FA:
            return {
                ...state,
                loading: true,
                error: "",
                google2faSecret: ""
            };

        case AccountSecurityActionTypes.VERIFY_GOOGLE_2FA:
            return {
                ...state,
                loading: true,
                error: "",
                google2faEnabled: false,
            };

        case AccountSecurityActionTypes.DISABLE_GOOGLE_2FA:
            return {
                ...state,
                loading: true,
                error: "",
                disableGoogle2fa: false,
            };

        case AccountSecurityActionTypes.VERIFY_DISABLE_GOOGLE_2FA:
            return {
                ...state,
                loading: true,
                error: "",
                verifyDisableGoogle2fa: false,
            };

        default:
            return { ...state };
    }
};

export default AccountSecurity;
