import {PaymentActionTypes} from './constants';
import { TransferActionTypes } from '../transfer/constants';
import { TransferActionType } from '../transfer/actions';

export type PaymentActionType = {
    type:
        | PaymentActionTypes.API_RESPONSE_SUCCESS
        | PaymentActionTypes.API_RESPONSE_ERROR
        | PaymentActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | PaymentActionTypes.GET_PAYMENTS
        | PaymentActionTypes.GET_PAYMENT
        | PaymentActionTypes.GET_TRANSACTIONS

        | PaymentActionTypes.OPEN_SEARCH
        | PaymentActionTypes.CLOSE_SEARCH
        | PaymentActionTypes.SEARCH_PAYMENT
        | PaymentActionTypes.SEARCH_TRANSACTION
        | PaymentActionTypes.CLEAR_SEARCH

        | PaymentActionTypes.RESET

        | PaymentActionTypes.EXPORT
        | PaymentActionTypes.EXPORT_TRANSACTIONS
        | PaymentActionTypes.CALLBACK;
    payload: {} | string;
};

type Payment = {
    id: number;
    name: string;
    guard_name: string;
};

type QueryParams = {
    page: number;
    limit: number;
    orderBy: string,
    sort: string,
    transactionName?: string
    accountId?: string
}

class PaymentActions {

    apiResponseSuccess = (actionType: string, data: Payment | {}): PaymentActionType => ({
        type: PaymentActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): PaymentActionType => ({
        type: PaymentActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): PaymentActionType => ({
        type: PaymentActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): PaymentActionType => ({
        type: PaymentActionTypes.RESET,
        payload: {},
    });

    getPayments = (queryParams: QueryParams): PaymentActionType => ({
        type: PaymentActionTypes.GET_PAYMENTS,
        payload: { queryParams },
    });

    getPayment = (id: string): PaymentActionType => ({
        type: PaymentActionTypes.GET_PAYMENT,
        payload: { id },
    });

    getTransactions = (queryParams: QueryParams): PaymentActionType => ({
        type: PaymentActionTypes.GET_TRANSACTIONS,
        payload: { queryParams },
    });

    openSearch = (): PaymentActionType => ({
        type: PaymentActionTypes.OPEN_SEARCH,
        payload: {}
    });

    closeSearch = (): PaymentActionType => ({
        type: PaymentActionTypes.CLOSE_SEARCH,
        payload: {}
    });

    search = (queryParams: QueryParams): PaymentActionType => ({
        type: PaymentActionTypes.SEARCH_PAYMENT,
        payload: { queryParams }
    });

    searchTransaction = (queryParams: QueryParams): PaymentActionType => ({
        type: PaymentActionTypes.SEARCH_TRANSACTION,
        payload: { queryParams }
    });

    clearSearch = (): PaymentActionType => ({
        type: PaymentActionTypes.CLEAR_SEARCH,
        payload: {}
    });

    exportTransactions = (queryParams: QueryParams): PaymentActionType => ({
        type: PaymentActionTypes.EXPORT_TRANSACTIONS,
        payload: { queryParams }
    });

    export = (queryParams: QueryParams): PaymentActionType => ({
        type: PaymentActionTypes.EXPORT,
        payload: { queryParams }
    });

    callback = (id: string): PaymentActionType => ({
        type: PaymentActionTypes.CALLBACK,
        payload: { id }
    });
}

const paymentActions = new PaymentActions();
export { paymentActions };
