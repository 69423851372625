export enum DashboardActionTypes {
    API_RESPONSE_SUCCESS = '@@dashboard/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@dashboard/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@dashboard/API_RESPONSE_VALIDATION_ERRORS',

    GET_DASHBOARD = '@@dashboard/GET_DASHBOARD',

    RESET = '@@dashboard/RESET',
}

export enum DashboardErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



