
export enum ProfileImageActionTypes {
    API_RESPONSE_SUCCESS = '@@profileImage/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@profileImage/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@profileImage/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@profileImage/RESET',

    GET_PROFILE_IMAGE = '@@profileImage/GET_PROFILE_IMAGE',
    UPLOAD_PROFILE_IMAGE = '@@profileImage/UPLOAD_PROFILE_IMAGE',
    UPLOAD_FILE_PROGRESS = '@@profileImage/UPLOAD_FILE_PROGRESS'
}

export enum ProfileImageErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



