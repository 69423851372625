import {MobileNumberActionTypes} from './constants';

const INIT_STATE = {
    loading: false,
    error: "",
    validationErrors: {},

    mobileNumbers: {},
    mobileNumbersQueryParams: {},

    mobileNumber: {},

    updatedMobileNumbers: false,

    otpSent: false,
    otp: "",
    otpVerified: false,
};

type MobileNumberActionType = {
    type:
        | MobileNumberActionTypes.API_RESPONSE_SUCCESS
        | MobileNumberActionTypes.API_RESPONSE_ERROR
        | MobileNumberActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | MobileNumberActionTypes.RESET

        | MobileNumberActionTypes.GET_MOBILE_NUMBERS
        | MobileNumberActionTypes.GET_MOBILE_NUMBER
        | MobileNumberActionTypes.UPDATE_MOBILE_NUMBER

        | MobileNumberActionTypes.SEND_OTP
        | MobileNumberActionTypes.SET_OTP
        | MobileNumberActionTypes.VERIFY_OTP
        | MobileNumberActionTypes.CLEAR_OTP_MOBILE
    ;

    payload: {
        actionType?: string;
        data?: any;
        error?: any;
        validationErrors?: any;

        otp: string;
    };
};

type State = {
    mobileNumber: any;
};

const MobileNumber = (state: State = INIT_STATE, action: MobileNumberActionType) => {
    switch (action.type) {
        case MobileNumberActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case MobileNumberActionTypes.GET_MOBILE_NUMBERS: {
                    return {
                        ...state,
                        loading: false,
                        mobileNumbers: action.payload.data.mobileNumbers.mobileNumbers,
                        mobileNumbersQueryParams: action.payload.data.mobileNumbers.queryParams,
                    };
                }

                case MobileNumberActionTypes.GET_MOBILE_NUMBER: {
                    return {
                        ...state,
                        loading: false,
                        mobileNumber: action.payload.data,
                    };
                }

                case MobileNumberActionTypes.UPDATE_MOBILE_NUMBER: {
                    return {
                        ...state,
                        loading: false,
                        updatedMobileNumbers: true,
                        mobileNumber: {
                            ...state.mobileNumber,
                            status: action.payload.data
                        }
                    };
                }

                case MobileNumberActionTypes.SEND_OTP: {
                    return {
                        ...state,
                        loading: false,
                        otpSent: action.payload.data,
                    };
                }
                case MobileNumberActionTypes.VERIFY_OTP: {
                    return {
                        ...state,
                        loading: false,
                        otpVerified: action.payload.data,
                    };
                }

                default:
                    return { ...state };
            }

        case MobileNumberActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case MobileNumberActionTypes.GET_MOBILE_NUMBERS:
                case MobileNumberActionTypes.UPDATE_MOBILE_NUMBER:
                case MobileNumberActionTypes.SEND_OTP:
                case MobileNumberActionTypes.VERIFY_OTP: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case MobileNumberActionTypes.GET_MOBILE_NUMBER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case MobileNumberActionTypes.API_RESPONSE_VALIDATION_ERRORS:
            switch (action.payload.actionType) {
                case MobileNumberActionTypes.GET_MOBILE_NUMBER:
                case MobileNumberActionTypes.UPDATE_MOBILE_NUMBER:
                case MobileNumberActionTypes.SEND_OTP:
                case MobileNumberActionTypes.VERIFY_OTP: {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case MobileNumberActionTypes.GET_MOBILE_NUMBERS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case MobileNumberActionTypes.RESET:
            return {
                ...state,

                loading: false,
                error: "",
                validationErrors: {},

                mobileNumbers: {},
                mobileNumbersQueryParams: {},

                mobileNumber: {},

                updatedMobileNumbers: false,

                otpSent: false,
                otpVerified: false,
            };

        case MobileNumberActionTypes.GET_MOBILE_NUMBERS:
            return {
                ...state,
                loading: true,
                error: "",
                // mobileNumbers: {},
            };
        case MobileNumberActionTypes.GET_MOBILE_NUMBER:
            return {
                ...state,
                loading: true,
                error: "",
                mobileNumber: {},
            };
        case MobileNumberActionTypes.UPDATE_MOBILE_NUMBER:
            return {
                ...state,
                loading: true,
                error: "",
                updatedMobileNumbers: false
            };


        case MobileNumberActionTypes.SEND_OTP:
            return {
                ...state,
                loading: true,
                otpSent: false,
                error: "",
            };

        case MobileNumberActionTypes.CLEAR_OTP_MOBILE:
            return {
                ...state,
                otpSent: false,
                error: "",
                otp: "",
            };

        case MobileNumberActionTypes.VERIFY_OTP:
            return {
                ...state,
                loading: true,
                error: "",
            };
        case MobileNumberActionTypes.SET_OTP:
            return {
                ...state,
                otp: action.payload.otp
            };
        default:
            return { ...state };
    }
};

export default MobileNumber;
