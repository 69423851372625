
export enum AccountSecurityActionTypes {
    API_RESPONSE_SUCCESS = '@@accountSecurity/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@accountSecurity/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@accountSecurity/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@accountSecurity/RESET',

    CHANGE_PASSWORD = '@@accountSecurity/CHANGE_PASSWORD',
    LAST_PASSWORD_UPDATED = '@@accountSecurity/LAST_PASSWORD_UPDATED',

    CREATE_GOOGLE_2FA = '@@accountSecurity/CREATE_GOOGLE_2FA',
    VERIFY_GOOGLE_2FA = '@@accountSecurity/VERIFY_GOOGLE_2FA',
    DISABLE_GOOGLE_2FA = '@@accountSecurity/DISABLE_GOOGLE_2FA',
    VERIFY_DISABLE_GOOGLE_2FA = '@@accountSecurity/VERIFY_DISABLE_GOOGLE_2FA',
}

export enum AccountSecurityErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



