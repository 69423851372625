import { APICore } from './api/apiCore';
import jwtDecode from 'jwt-decode';
import config from '../config';

const api = new APICore();

function timeAgo(inputDate: string, t: any): string {

    const date = new Date(inputDate);

    if(!inputDate) {
        return '';
    }

    const currentDate = new Date();

    const seconds = Math.floor((currentDate.getTime() - date.getTime()) / 1000);

    let interval = seconds / 31536000;
    if(interval > 1) {
        return Math.floor(interval) + ' ' + t("yearsAgo");
    }

    interval = seconds / 2592000;
    if(interval > 1) {
        return Math.floor(interval) + ' ' + t("monthAgo");
    }

    interval = seconds / 86400;
    if(interval > 1) {
        return Math.floor(interval) + ' ' + t("daysAgo");
    }

    interval = seconds / 3600;
    if(interval > 1) {
        return Math.floor(interval) + ' ' + t("hoursAgo");
    }

    interval = seconds / 60;
    if(interval > 1) {
        return Math.floor(interval) + ' ' + t("minutesAgo");
    }

    if(seconds < 10) return t('justNow');

    return Math.floor(seconds) + ' ' + t("secondsAgo");










    // const date = new Date(inputDate);
    // const dateTs = date.valueOf();
    //
    // let currentDate = new Date(new Date().toUTCString().slice(0, -3));
    // let currentDateTs = currentDate.valueOf() + 1;
    //
    // console.log(currentDateTs)
    //
    // // const seconds = Math.floor((new Date() - date) / 1000);
    // const seconds = Math.floor((currentDateTs - dateTs) / 1000);
    //
    // let interval = Math.floor(seconds / 31536000);
    // if(interval > 1) {
    //     return interval + ' ' + t("yearsAgo");
    // }
    //
    // interval = Math.floor(seconds / 2592000);
    // if(interval > 1) {
    //     return interval + ' ' + t("monthAgo");
    // }
    //
    // interval = Math.floor(seconds / 86400);
    // if(interval > 1) {
    //     return interval + ' ' + t("daysAgo");
    // }
    //
    // interval = Math.floor(seconds / 3600);
    // if(interval > 1) {
    //     return interval + ' ' + t("hoursAgo");
    // }
    //
    // interval = Math.floor(seconds / 60);
    // if(interval > 1) {
    //     return interval + ' ' + t("minutesAgo");
    // }
    //
    // if(seconds < 10) return t('justNow');
    //
    // return Math.floor(seconds) + ' ' + t("secondsAgo");
}


const flattenObject = (obj: any) => {
    const flattened : any = {}

    Object.keys(obj).forEach((key) => {
        const value = obj[key]

        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            Object.assign(flattened, flattenObject(value))
        } else {
            flattened[key] = value
        }
    })

    return flattened
}

const countToTimer = (number: number) => {
    let secondsRemaining = number;
    let secondsToDisplay = secondsRemaining % 60;
    let minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
    let minutesToDisplay = minutesRemaining % 60;
    let hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60;
    return {
        secondsRemaining, secondsToDisplay, minutesRemaining, minutesToDisplay, hoursToDisplay
    }
}

const flattenGraphqlErrorMessages = (response: any) => {
    let serverErrors: any = [];
    let validationErrors : {} = {};
    let length :number = 0;

    const errors = response.hasOwnProperty('errors')? response.errors: [];
    if(errors.length) {
        errors.map((error: any) => {
            switch (error?.extensions?.category) {
                case "validation":
                    // validations = Object.values(error.extensions.validation);

                    // validations.map((validation: any,) => {
                    //     validation.map((message: any) => {
                    //         return flattenErrors.push(message)
                    //     })
                    // })
                    validationErrors = error.extensions.validation
                    break;

                default:
                    serverErrors.push(error.message)

                    length++
            }

            return false;
        });
    }

    return {
        serverErrors,
        validationErrors,
        length
    };
}

const removeTrailingSlash = (string: string) => {
    return string.replace(/^\/|\/$/g, '');
}

const addTrailingSlash = (string: string) => {
    const cleanString = removeTrailingSlash(string);
    if(cleanString.length) {
        return cleanString + "/"
    }
    return '';
}

const isObject = (value: any) => {
    return (
        typeof value === 'object' &&
        !Array.isArray(value) &&
        value !== null
    );
}

const isEmptyObject = (objectName: any) => {
    return (
        objectName &&
        Object.keys(objectName).length === 0 &&
        objectName.constructor === Object
    );
}

const getObjectValueByString = (object: {}, keys: string) => keys.split('.').reduce((o: any, k: string): any => (o || {})[k], object);

const pluck = (arr: object[], key: string): any => arr.map((v:any) => {
    return v[key];
});

const getCurrentRolePermissions = () => {

    const session = api.getSession();

    return {
        name: session.user.defaultRole.name,
        permissions: session.user.permissions
    };
};

const getParentRolePermissions = () => {

    const session = api.getSession();

    return session.setting.parent_role;
};

const hasPermission = (permissions: string[]) => {

    if(permissions.indexOf("*") > -1) {
        return true;
    }

    const loggedInUser = api.getLoggedInUser();

    // let found = false;
    //
    // permissions.forEach((permission) => {
    //     if(loggedInUser.permissions.indexOf(permission) > -1) {
    //         found = true;
    //     }
    // })

    return searchArray(loggedInUser.permissions, permissions);
}

const nextRefreshTime = () => {
    const api = new APICore();
    const user = api.getLoggedInUser();
    if (!user || (user && !user.access_token)) {
        return 10;
    }
    const decoded: any = jwtDecode(user.access_token);
    const currentTime = Date.now() / 1000;
    return (decoded.exp - currentTime -  config.REFRESH_TIME) * 1000;
};

function checkResponseError(response:any, errorMessages: string) {
    if(response.status !== 200) {
        throw errorMessages;
    }
}

function checkServerError(data:any) {

    // handle validation errors
    const errors = flattenGraphqlErrorMessages(data);
    if(!isEmptyObject(errors.validationErrors)) {
        throw errors.validationErrors;
    }

    // handle server errors
    errors.serverErrors.map((error: any) => {
        throw error;
    });
}

function throwError(error: string) {
    throw error;
}

function pluckResponse(response: any, variable: string, error: string = "")  {
    if(!response.hasOwnProperty('data')) {
        throw error? error: "Response error";
    }

    if(!response.data.hasOwnProperty(variable)) {
        throw error? error: "Response error";
    }

    return response.data[variable];
}

const formatBytes = (bytes: number, decimals: number = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const searchArray = (array: string[], phrases: string[]) => {
    return phrases.some(phrase => {
        return array.includes(phrase);
    });
}

const handleServerValidationError = (validationErrors: any, setError: any) => {
    if(validationErrors) {

        Object.keys(validationErrors).forEach((key:any) => {
            validationErrors[key].forEach((v:string) => {
                setError(key, {
                    type: 'server',
                    message: v
                });
            });
        });
    }
}

export {
    flattenObject,
    countToTimer,
    flattenGraphqlErrorMessages,
    addTrailingSlash,
    removeTrailingSlash,
    isEmptyObject,
    getObjectValueByString,
    pluck,
    hasPermission,
    getCurrentRolePermissions,
    getParentRolePermissions,
    nextRefreshTime,
    checkResponseError,
    checkServerError,
    pluckResponse,
    throwError,
    formatBytes,
    searchArray,
    timeAgo,
    handleServerValidationError,
}
