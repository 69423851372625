
export enum MyAccountActionTypes {
    API_RESPONSE_SUCCESS = '@@myAccount/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@myAccount/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@myAccount/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@myAccount/RESET',

    SEND_VERIFICATION_EMAIL_LINK = '@@myAccount/SEND_VERIFICATION_EMAIL_LINK',
    VERIFY_EMAIL = '@@myAccount/VERIFY_EMAIL',

    GET_VERIFICATION_STEPS_STATUS = '@@myAccount/GET_VERIFICATION_STEPS_STATUS',

    GET_PROFILE_IMAGE = '@@myAccount/GET_PROFILE_IMAGE',
    UPLOAD_PROFILE_IMAGE = '@@myAccount/UPLOAD_PROFILE_IMAGE',

    UPDATE_ACCOUNT = '@@myAccount/UPDATE_ACCOUNT',

    UPDATE_PERSONAL_DETAIL = '@@myAccount/UPDATE_PERSONAL_DETAIL',
}

export enum MyAccountErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



