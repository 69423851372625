import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { checkResponseError, checkServerError, pluckResponse } from '../../helpers/functions';
import { graphql, graphqlUpload } from '../../helpers';
import { END, eventChannel } from 'redux-saga';
import { nCBackActions } from './actions';
import { NCardBackActionTypes, NCardBackErrors } from './constants';

type NCBackData = {
    payload: {
        id: number,

        data: any;

        queryParams: {
            limit: number;
            page: number;

        }

        token: string;

        file: {fileId: string} & File;

        fileId: string;
        uuid: string;
    };
    type: string;
};

function createUploader(file: File, query:any) {

    let emit: any;

    const chan = eventChannel((emitter) => {
        emit = emitter;
        return () => {};
    });

    const uploadProgressCb = ({ total, loaded }: any) => {
        const percentage = Math.round((loaded * 100) / total);
        emit(percentage);
        if (percentage === 100) emit(END);
    };

    // make form object
    let data = new FormData();
    data.set('operations', JSON.stringify(query));

    data.set('operationName', "");
    data.set('map', JSON.stringify({"file":["variables.file"]}));
    data.append('file', file);

    const uploadPromise = graphqlUpload(data, 'auth', uploadProgressCb);
    return [uploadPromise, chan];
}

function* uploadNcBackProgressWatcher(chan:any, fileId: string): SagaIterator {
    while (true) {
        const progress = yield take(chan);
        yield put(nCBackActions.uploadNationalCardBackProgress(progress, fileId));
    }
}

function* uploadNcBack({ payload: {id, file} }: NCBackData): SagaIterator {
    try {
        const query = {
            // Mutation string
            'query': `mutation UploadNcBack($id: String!, $file: Upload!) {
                    uploadNcBack(id: $id, file: $file) {
                        uuid
                    }
                }`,
            'variables': {
                "file": file,
                "id": id,
            }
        };

        const [uploadPromise, chan] = yield call(createUploader, file, query);
        yield fork(uploadNcBackProgressWatcher, chan, file.fileId);

        const response = yield call(() => uploadPromise);

        checkResponseError(response, NCardBackErrors.RESPONSE_200);

        const rUNF = response.data;

        checkServerError(rUNF);

        const uploadNcBack = pluckResponse(rUNF, "uploadNcBack");

        yield put(nCBackActions.apiResponseSuccess(NCardBackActionTypes.UPLOAD_NC_BACK, {fileId: file.fileId, uploadNcBack}));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(nCBackActions.apiResponseError(NCardBackActionTypes.UPLOAD_NC_BACK, {fileId: file.fileId, error}));
        } else {
            yield put(nCBackActions.apiResponseValidationErrors(NCardBackActionTypes.UPLOAD_NC_BACK, {fileId: file.fileId, error}));
        }
    }
}

function* update({ payload: {data} }: NCBackData): SagaIterator {
    try {
        const param = {
            query:`mutation UpdateNcBack($id: String!, $status: String!) {
                    updateNcBack(id: $id, status: $status)
                }`,
            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, NCardBackErrors.RESPONSE_200);

        const rUNF = response.data;

        checkServerError(rUNF);

        const updateNcBack = pluckResponse(rUNF, "updateNcBack");

        yield put(nCBackActions.apiResponseSuccess(NCardBackActionTypes.UPDATE, updateNcBack));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(nCBackActions.apiResponseError(NCardBackActionTypes.UPDATE, error));
        } else {
            yield put(nCBackActions.apiResponseValidationErrors(NCardBackActionTypes.UPDATE, error));
        }
    }
}

function* deleteNcBack({ payload: {id, fileId, uuid} }: NCBackData): SagaIterator {

    try {
        if(uuid) {
            const param = {
                query:`mutation DeleteNcBack($id: String!, $uuid: String!) {
                    deleteNcBack(id: $id, uuid: $uuid)
                }`,
                variables: {
                    "uuid": uuid,
                    "id": id,
                }
            };

            const response: any = yield call(graphql, param, 'auth');

            checkResponseError(response, NCardBackErrors.RESPONSE_200);

            const rDNB = response.data;

            checkServerError(rDNB);

            pluckResponse(rDNB, "deleteNcBack");
        }

        yield put(nCBackActions.apiResponseSuccess(NCardBackActionTypes.DELETE_NC_BACK, fileId));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(nCBackActions.apiResponseError(NCardBackActionTypes.DELETE_NC_BACK, error));
        } else {
            yield put(nCBackActions.apiResponseValidationErrors(NCardBackActionTypes.DELETE_NC_BACK, error));
        }
    }
}

export function* watchUploadNcBack(): any {
    yield takeEvery(NCardBackActionTypes.UPLOAD_NC_BACK, uploadNcBack);
}

export function* watchUpdateNcBack(): any {
    yield takeEvery(NCardBackActionTypes.UPDATE, update);
}

export function* watchDeleteNcBack(): any {
    yield takeEvery(NCardBackActionTypes.DELETE_NC_BACK, deleteNcBack);
}

function* nCBackSaga() {
    yield all([
        fork(watchUploadNcBack),
        fork(watchUpdateNcBack),
        fork(watchDeleteNcBack),
    ]);
}

export default nCBackSaga;
