import { TacActionTypes } from './constants';

export type TACActionType = {
    type:
        | TacActionTypes.API_RESPONSE_SUCCESS
        | TacActionTypes.API_RESPONSE_ERROR
        | TacActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | TacActionTypes.RESET

        | TacActionTypes.GET_TACs
        | TacActionTypes.GET_TAC
        | TacActionTypes.SEND_TAC
        | TacActionTypes.APPROVE_TAC
        | TacActionTypes.UPLOAD_TERM_AND_CONDITION
        | TacActionTypes.UPLOAD_TAC_PROGRESS
        | TacActionTypes.SET_DEVICES;

    payload: {} | string;
};

type BankCard = {
    process: any
};

class TermsAndConditionsActions {

    apiResponseSuccess = (actionType: string, data: BankCard | {}): TACActionType => ({
        type: TacActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: any): TACActionType => ({
        type: TacActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: any): TACActionType => ({
        type: TacActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): TACActionType => ({
        type: TacActionTypes.RESET,
        payload: {},
    });

    getTACs = (queryParams: any): TACActionType => ({
        type: TacActionTypes.GET_TACs,
        payload: {queryParams},
    });

    getTAC = (id: string): TACActionType => ({
        type: TacActionTypes.GET_TAC,
        payload: {id},
    });

    // insertBankCart = (data: any): TACActionType => ({
    //     type: TacActionTypes.INSERT_BANK_CARD,
    //     payload: {data},
    // });

    uploadTermAndCondition = (file: File): TACActionType => ({
        type: TacActionTypes.UPLOAD_TERM_AND_CONDITION,
        payload: { file },
    });

    uploadTACProgress = (progress: any): TACActionType => {
        return {
            type: TacActionTypes.UPLOAD_TAC_PROGRESS,
            payload: progress,
        }
    }

    approveTac = (data: any): TACActionType => ({
        type: TacActionTypes.APPROVE_TAC,
        payload: {data},
    });
    setDevices = (data: any): TACActionType => ({
        type: TacActionTypes.SET_DEVICES,
        payload: {data},
    });
}

const tacActions = new TermsAndConditionsActions();
export { tacActions };
