import {PassportActionTypes} from './constants';

const INIT_STATE = {
    loading: false,
    error: "",
    validationErrors: {},

    passport: [],
    passportStatus: "",

    updated: false,
};

type PassportActionType = {
    type:
        | PassportActionTypes.API_RESPONSE_SUCCESS
        | PassportActionTypes.API_RESPONSE_ERROR
        | PassportActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | PassportActionTypes.RESET

        | PassportActionTypes.SET_PASSPORT

        | PassportActionTypes.UPLOAD_PASSPORT
        | PassportActionTypes.UPLOAD_PASSPORT_PROGRESS
        | PassportActionTypes.DELETE_PASSPORT
        | PassportActionTypes.UPDATE;

    payload: {
        actionType?: string;
        data?: any;
        error?: any;
        validationErrors?: any;

        key?: any;
        value?: any;

        // for upload purpose
        file: {fileId: string} & File;
        fileId: string;
        progress: number;
        retry: boolean;
    };
};

type State = {
    passport: any;
};

type FileType = {
    uploaded?: boolean;
    progress?: number;
    fileId?: string;
    error?: string;
    uuid?: string;
} & File;

const Passport = (state: State = INIT_STATE, action: PassportActionType) => {
    switch (action.type) {
        case PassportActionTypes.API_RESPONSE_SUCCESS:

            switch (action.payload.actionType) {

                case PassportActionTypes.SET_PASSPORT: {
                    return {
                        ...state,
                        passport: action.payload.data?.media || [],
                        passportStatus: action.payload.data?.status || ""
                    };
                }

                case PassportActionTypes.UPLOAD_PASSPORT: {
                    return {
                        ...state,
                        passport: [
                            ...state.passport.map((file: FileType) => {
                                if(file.fileId === action.payload.data.fileId) {
                                    file.uploaded = true;
                                    file.progress = 0;
                                    file.uuid = action.payload.data.uploadPassport.uuid;
                                }
                                return file;
                            }),
                        ]
                    };
                }
                case PassportActionTypes.DELETE_PASSPORT: {
                    return {
                        ...state,
                        passport: [
                            ...state.passport.filter((file: FileType) => {
                                return file.fileId !== action.payload.data;
                            }),
                        ],
                        loading: false
                    };
                }

                case PassportActionTypes.UPDATE: {
                    return {
                        ...state,
                        updated: true,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case PassportActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case PassportActionTypes.UPDATE:
                case PassportActionTypes.DELETE_PASSPORT:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case PassportActionTypes.UPLOAD_PASSPORT: {
                    return {
                        ...state,
                        passport: [
                            ...state.passport.map((file: FileType) => {
                                if(file.fileId === action.payload.error.fileId) {
                                    file.error = action.payload.error.error;
                                    file.progress = 0;
                                }
                                return file;
                            }),
                        ]
                    };
                }

                default:
                    return { ...state };
            }

        case PassportActionTypes.API_RESPONSE_VALIDATION_ERRORS:
            switch (action.payload.actionType) {
                case PassportActionTypes.UPDATE: {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case PassportActionTypes.UPLOAD_PASSPORT: {
                    return {
                        ...state,
                        passport: [
                            ...state.passport.map((file: FileType) => {
                                if(file.fileId === action.payload.validationErrors.fileId) {
                                    file.error = action.payload.validationErrors.error.file;
                                }
                                return file;
                            })
                        ],
                    };
                }
                default:
                    return { ...state };
            }

        case PassportActionTypes.RESET:
            return {
                ...state,

                loading: false,
                error: "",
                validationErrors: {},

                passport: [],
                passportStatus: "",

                updated: false,
            };

        case PassportActionTypes.UPLOAD_PASSPORT:
            if(!action.payload.retry) {
                state.passport = [
                    action.payload.file,
                    ...state.passport
                ];
            } else {
                state.passport = [
                    ...state.passport.map((file: any) => {
                        if(file.fileId === action.payload.file.fileId) {
                            file.error = "";
                        }
                        return file;
                    })
                ];
            }
            return {
                ...state,
                error: ""
            };

        case PassportActionTypes.UPLOAD_PASSPORT_PROGRESS:
            return {
                ...state,
                passport: [
                    ...state.passport.map((file: FileType) => {
                        if(file.fileId === action.payload.fileId) {
                            file.progress = action.payload.progress;
                        }
                        return file;
                    })
                ],
            };

        case PassportActionTypes.DELETE_PASSPORT:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case PassportActionTypes.UPDATE:
            return {
                ...state,
                loading: true,
                error: "",
                updated: false
            };

        default:
            return { ...state };
    }
};

export default Passport;
