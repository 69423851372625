import {all, fork, put, takeEvery, call} from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { APICore, setAuthorization } from 'helpers/api/apiCore';
import { graphql } from 'helpers';
import { authAction } from './actions';
import {AuthActionTypes, AuthErrors} from './constants';
import {
    checkResponseError,
    checkServerError,
    pluckResponse
} from "../../helpers/functions";
import { changeLayoutDirection, changeLayoutLanguage } from '../layout/actions';

type UserData = {
    payload: {
        username: string;
        password: string;
        twoFaOtp: string;
        firstname: string;
        lastname: string;
        email: string;
        recaptchaToken: string;

        token: string,
        language: string,
    };
    type: string;
};

const api = new APICore();

// function* runRefreshToken():SagaIterator {
//     while (true) {
//         yield delay(nextRefreshTime());
//         yield call(refreshToken);
//     }
// }
//
// function* watchStartEndRefreshTokenLoop(): SagaIterator {
//     while (true) {
//         yield take(AuthActionTypes.START_REFRESH_TOKEN_LOOP);
//         const bgSyncTask = yield fork(runRefreshToken);
//         yield take(AuthActionTypes.END_REFRESH_TOKEN_LOOP);
//         yield cancel(bgSyncTask);
//     }
// }

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password, twoFaOtp, recaptchaToken } }: UserData): SagaIterator {
    try {
        const param = {
            query: `mutation Login($username: String!, $password: String!, $twoFaOtp: String, $recaptchaToken: String!) {
                login(username: $username, password: $password, twoFaOtp: $twoFaOtp, recaptchaToken: $recaptchaToken) {
                    access_token
                    user {
                        id
                        username
                        display_name
                        email
                        email_verified_at
                        mobile
                        mobile_verified_at
                        roles {
                            name
                        }
                        permissions
                        image
                        two_fa_enabled
                        defaultRole {
                            name
                        }
                        language
                        calendar
                        timezone
                        last_login_at
                        original_last_login_at
                        last_login_ip
                    }
                }
            }`,
            variables: {
                "username": username,
                "password": password,
                "twoFaOtp": twoFaOtp,
                "recaptchaToken": recaptchaToken
            }
        };

        const response: any = yield call(graphql, param, '');

        checkResponseError(response, AuthErrors.RESPONSE_200);

        const rLogin = response.data;

        checkServerError(rLogin);

        const session = pluckResponse(rLogin, "login");

        api.setSession(session);

        setAuthorization(session.access_token);

        yield put(changeLayoutDirection(session.user.language === "fa"? "rtl": "ltr"));
        yield put(changeLayoutLanguage(session.user.language));
        yield put(authAction.apiResponseSuccess(AuthActionTypes.LOGIN_USER, session.user));
        // yield put(settingActions.apiResponseSuccess(SettingActionTypes.SET_SETTING, session.setting));

    } catch (error: any) {

        api.setSession(null);
        setAuthorization(null);

        if(typeof error === "string") {
            yield put(authAction.apiResponseError(AuthActionTypes.LOGIN_USER, error));
        } else {
            yield put(authAction.apiResponseValidationErrors(AuthActionTypes.LOGIN_USER, error));
        }
    }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
    try {

        api.setSession(null);
        setAuthorization(null);

        const param = {
            query: `mutation Logout {
              logout
            }`
        };

        yield call(graphql, param, '');

        yield put(authAction.apiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(authAction.apiResponseError(AuthActionTypes.LOGOUT_USER, error));
        } else {
            yield put(authAction.apiResponseValidationErrors(AuthActionTypes.LOGOUT_USER, error));
        }
    }
}

function* signup({ payload: { firstname, lastname, email, password, recaptchaToken, language } }: UserData): SagaIterator {
    try {
        const param = {
            query: `mutation Register($firstname: String!, $lastname: String!, $email: String!, $password: String!, $recaptchaToken: String!, $language: String!) {
                register(firstname: $firstname, lastname: $lastname, email: $email, password: $password, recaptchaToken: $recaptchaToken, language: $language) {
                    email
                }
            }`,
            variables: {
                "firstname": firstname,
                "lastname": lastname,
                "email": email,
                "password": password,
                "recaptchaToken": recaptchaToken,
                "language": language
            }
        };

        const response: any = yield call(graphql, param, '');

        checkResponseError(response, AuthErrors.RESPONSE_200);

        const rRegister = response.data;

        checkServerError(rRegister);

        const register = pluckResponse(rRegister, "register");

        yield put(authAction.apiResponseSuccess(AuthActionTypes.SIGNUP_USER, register));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(authAction.apiResponseError(AuthActionTypes.SIGNUP_USER, error));
        } else {
            yield put(authAction.apiResponseValidationErrors(AuthActionTypes.SIGNUP_USER, error));
        }
    }
}

function* forgotPassword({ payload: { username, recaptchaToken } }: UserData): SagaIterator {
    try {

        const param = {
            query: `mutation ForgetPassword($username: String!, $recaptchaToken: String!) {
                forgetPassword(username: $username, recaptchaToken: $recaptchaToken)
            }`,
            variables: {
                "username": username,
                "recaptchaToken": recaptchaToken
            }
        };

        const response: any = yield call(graphql, param, '');

        checkResponseError(response, AuthErrors.RESPONSE_200);

        const rForgetPassword = response.data;

        checkServerError(rForgetPassword);

        const forgetPassword = pluckResponse(rForgetPassword, "forgetPassword");

        yield put(authAction.apiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, forgetPassword));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(authAction.apiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
        } else {
            yield put(authAction.apiResponseValidationErrors(AuthActionTypes.FORGOT_PASSWORD, error));
        }
    }
}

function* resetPassword({ payload: { token, password, recaptchaToken } }: UserData): SagaIterator {
    try {

        const param = {
            query: `mutation ResetPassword($token: String!, $password: String!, $recaptchaToken: String!) {
                resetPassword(token: $token, password: $password, recaptchaToken: $recaptchaToken)
            }`,
            variables: {
                "token": token,
                "password": password,
                "recaptchaToken": recaptchaToken
            }
        };

        const response: any = yield call(graphql, param, '');

        checkResponseError(response, AuthErrors.RESPONSE_200);

        const rResetPassword = response.data;

        checkServerError(rResetPassword);

        const resetPassword = pluckResponse(rResetPassword, "resetPassword");

        yield put(authAction.apiResponseSuccess(AuthActionTypes.RESET_PASSWORD, resetPassword));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(authAction.apiResponseError(AuthActionTypes.RESET_PASSWORD, error));
        } else {
            yield put(authAction.apiResponseValidationErrors(AuthActionTypes.RESET_PASSWORD, error));
        }
    }
}

function* getSocialCallbackUrl(): SagaIterator {
    try {
        const param = {
            query: `query {
                oauthRedirectUrls
            }`,
        };

        const response: any = yield call(graphql, param, '');

        checkResponseError(response, AuthErrors.RESPONSE_200);

        const rOauthRedirectUrls = response.data;

        checkServerError(rOauthRedirectUrls);

        const oauthRedirectUrls = pluckResponse(rOauthRedirectUrls, "oauthRedirectUrls");

        yield put(authAction.apiResponseSuccess(AuthActionTypes.SOCIAL_CALLBACK_URL, oauthRedirectUrls));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(authAction.apiResponseError(AuthActionTypes.SOCIAL_CALLBACK_URL, error));
        } else {
            yield put(authAction.apiResponseValidationErrors(AuthActionTypes.SOCIAL_CALLBACK_URL, error));
        }
    }
}


function* googleOauthSignin({ payload: { searchParameters } }: any): SagaIterator {

    try {
        const param = {
            query: `mutation GoogleOauthSignin($authuser: String!, $code: String!, $prompt: String!, $scope: String!) {
                googleOauthSignin(authuser: $authuser, code: $code, prompt: $prompt, scope: $scope) {
                    access_token
                    user {
                        id
                        username
                        display_name
                        email
                        email_verified_at
                        mobile
                        mobile_verified_at
                        roles {
                            name
                        }
                        permissions
                        image
                        two_fa_enabled
                        defaultRole {
                            name
                        }
                        language
                        calendar
                        timezone
                        last_login_at
                        last_login_ip
                    }
                }
            }`,
            variables: {
                ...searchParameters
            }
        };

        const response: any = yield call(graphql, param, '');

        checkResponseError(response, AuthErrors.RESPONSE_200);

        const rGoogleOauthSignin = response.data;

        checkServerError(rGoogleOauthSignin);

        const session = pluckResponse(rGoogleOauthSignin, "googleOauthSignin");

        api.setSession(session);

        setAuthorization(session.access_token);

        yield put(authAction.apiResponseSuccess(AuthActionTypes.GOOGLE_SIGN_IN, session.user));
        // yield put(settingActions.apiResponseSuccess(SettingActionTypes.SET_SETTING, session.setting));

    } catch (error: any) {

        api.setSession(null);
        setAuthorization(null);

        if(typeof error === "string") {
            yield put(authAction.apiResponseError(AuthActionTypes.GOOGLE_SIGN_IN, error));
        } else {
            yield put(authAction.apiResponseValidationErrors(AuthActionTypes.GOOGLE_SIGN_IN, error));
        }
    }
}

function* refreshToken(): SagaIterator {

    try {
        const param = {
            query: `mutation RefreshToken {
                        refreshToken
                    }`
        }

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, AuthErrors.RESPONSE_200);

        const rRefreshToken = response.data;

        checkServerError(rRefreshToken);

        const refreshToken = pluckResponse(rRefreshToken, "refreshToken");

        const session = api.getLoggedInUser();
        session.access_token = refreshToken.refreshToken;
        api.setSession(session);

        setAuthorization(refreshToken.refreshToken);

        yield put(authAction.apiResponseSuccess(AuthActionTypes.REFRESH_TOKEN, session.user));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(authAction.apiResponseError(AuthActionTypes.REFRESH_TOKEN, error));
        } else {
            yield put(authAction.apiResponseValidationErrors(AuthActionTypes.REFRESH_TOKEN, error));
        }
    }
}


export function* watchLoginUser() {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup() {
    yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword() {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchResetPassword() {
    yield takeEvery(AuthActionTypes.RESET_PASSWORD, resetPassword);
}

export function* watchGetSocialCallbackUrl() {
    yield takeEvery(AuthActionTypes.SOCIAL_CALLBACK_URL, getSocialCallbackUrl);
}

export function* watchGoogleOauthSignin() {
    yield takeEvery(AuthActionTypes.GOOGLE_SIGN_IN, googleOauthSignin);
}

export function* watchRefreshToken() {
    yield takeEvery(AuthActionTypes.REFRESH_TOKEN, refreshToken);
}

function* authSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogout),
        fork(watchSignup),
        fork(watchForgotPassword),
        fork(watchResetPassword),
        fork(watchGetSocialCallbackUrl),
        fork(watchGoogleOauthSignin),
        fork(watchRefreshToken)
    ]);
}

export default authSaga;
