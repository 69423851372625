
export enum PassportActionTypes {
    API_RESPONSE_SUCCESS = '@@passport/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@passport/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@passport/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@passport/RESET',

    SET_PASSPORT = '@@passport/SET_PASSPORT',

    UPLOAD_PASSPORT = '@@passport/UPLOAD_PASSPORT',
    UPLOAD_PASSPORT_PROGRESS = '@@passport/UPLOAD_PASSPORT_PROGRESS',
    DELETE_PASSPORT = '@@passport/DELETE_PASSPORT',

    UPDATE = '@@passport/UPDATE',
}

export enum PassportErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



