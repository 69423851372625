
export enum ManageVerificationActionTypes {
    API_RESPONSE_SUCCESS = '@@manageVerification/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@manageVerification/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@manageVerification/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@manageVerification/RESET',

    GET_VERIFICATION_ITEMS = '@@manageVerification/GET_VERIFICATION_ITEMS',
}

export enum ManageVerificationErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



