export enum LogActionTypes {
    API_RESPONSE_SUCCESS = '@@log/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@log/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@log/API_RESPONSE_VALIDATION_ERRORS',

    GET_LOGS = '@@log/GET_LOGS',
    GET_LOG = '@@log/GET_LOG',

    OPEN_SEARCH = '@@log/OPEN_SEARCH',
    CLOSE_SEARCH = '@@log/CLOSE_SEARCH',
    SEARCH_LOG = '@@log/SEARCH_LOG',
    SEARCH_TRANSACTION = '@@log/SEARCH_TRANSACTION',
    CLEAR_SEARCH = '@@log/CLEAR_SEARCH',

    RESET = '@@log/RESET',
}

export enum LogErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



