import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects';
import { graphql, graphqlUpload } from '../../helpers';
import { END, eventChannel } from 'redux-saga';
import { SagaIterator } from '@redux-saga/core';
import { checkResponseError, checkServerError, pluckResponse } from '../../helpers/functions';
import { passportActions } from './actions';
import { PassportActionTypes, PassportErrors } from './constants';

type PassportData = {
    payload: {
        id: number,

        data: any;

        queryParams: {
            limit: number;
            page: number;

        }

        token: string;

        file: {fileId: string} & File;

        fileId: string;
        uuid: string;
    };
    type: string;
};

function createUploader(file: File, query:any) {

    let emit: any;

    const chan = eventChannel((emitter) => {
        emit = emitter;
        return () => {};
    });

    const uploadProgressCb = ({ total, loaded }: any) => {
        const percentage = Math.round((loaded * 100) / total);
        emit(percentage);
        if (percentage === 100) emit(END);
    };

    // make form object
    let data = new FormData();
    data.set('operations', JSON.stringify(query));

    data.set('operationName', "");
    data.set('map', JSON.stringify({"file":["variables.file"]}));
    data.append('file', file);

    const uploadPromise = graphqlUpload(data, 'auth', uploadProgressCb);
    return [uploadPromise, chan];
}


function* uploadPassportProgressWatcher(chan:any, fileId: string): SagaIterator {
    while (true) {
        const progress = yield take(chan);
        yield put(passportActions.uploadPassportProgress(progress, fileId));
    }
}

function* uploadPassport({ payload: {id, file} }: PassportData): SagaIterator {
    try {
        const query = {
            'query': `mutation UploadPassport($id: String!, $file: Upload!) {
                    uploadPassport(id: $id, file: $file) {
                        uuid
                    }
                }`,
            'variables': {
                "file": file,
                "id": id,
            }
        };

        const [uploadPromise, chan] = yield call(createUploader, file, query);
        yield fork(uploadPassportProgressWatcher, chan, file.fileId);

        const response = yield call(() => uploadPromise);

        checkResponseError(response, PassportErrors.RESPONSE_200);

        const rUNF = response.data;

        checkServerError(rUNF);

        const uploadPassport = pluckResponse(rUNF, "uploadPassport");

        yield put(passportActions.apiResponseSuccess(PassportActionTypes.UPLOAD_PASSPORT, {fileId: file.fileId, uploadPassport}));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(passportActions.apiResponseError(PassportActionTypes.UPLOAD_PASSPORT, {fileId: file.fileId, error}));
        } else {
            yield put(passportActions.apiResponseValidationErrors(PassportActionTypes.UPLOAD_PASSPORT, {fileId: file.fileId, error}));
        }
    }
}

function* update({ payload: {data} }: PassportData): SagaIterator {
    try {
        const param = {
            query:`mutation UpdatePassport($id: String!, $status: String!) {
                    updatePassport(id: $id, status: $status)
                }`,
            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PassportErrors.RESPONSE_200);

        const rUNF = response.data;

        checkServerError(rUNF);

        const updatePassport = pluckResponse(rUNF, "updatePassport");

        yield put(passportActions.apiResponseSuccess(PassportActionTypes.UPDATE, updatePassport));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(passportActions.apiResponseError(PassportActionTypes.UPDATE, error));
        } else {
            yield put(passportActions.apiResponseValidationErrors(PassportActionTypes.UPDATE, error));
        }
    }
}

function* deletePassport({ payload: {id, fileId, uuid} }: PassportData): SagaIterator {

    try {
        if(uuid) {
            const param = {
                query:`mutation DeletePassport($id: String!, $uuid: String!) {
                    deletePassport(id: $id, uuid: $uuid)
                }`,
                variables: {
                    "uuid": uuid,
                    "id": id,
                }
            };

            const response: any = yield call(graphql, param, 'auth');

            checkResponseError(response, PassportErrors.RESPONSE_200);

            const rDP = response.data;

            checkServerError(rDP);

            pluckResponse(rDP, "deletePassport");
        }

        yield put(passportActions.apiResponseSuccess(PassportActionTypes.DELETE_PASSPORT, fileId));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(passportActions.apiResponseError(PassportActionTypes.DELETE_PASSPORT, error));
        } else {
            yield put(passportActions.apiResponseValidationErrors(PassportActionTypes.DELETE_PASSPORT, error));
        }
    }
}

export function* watchUploadPassport(): any {
    yield takeEvery(PassportActionTypes.UPLOAD_PASSPORT, uploadPassport);
}

export function* watchUpdatePassport(): any {
    yield takeEvery(PassportActionTypes.UPDATE, update);
}

export function* watchDeletePassport(): any {
    yield takeEvery(PassportActionTypes.DELETE_PASSPORT, deletePassport);
}

function* passportSaga() {
    yield all([
        fork(watchUploadPassport),
        fork(watchUpdatePassport),
        fork(watchDeletePassport),
    ]);
}

export default passportSaga;
