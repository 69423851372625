import { DepositActionTypes } from './constants';
import {isEmptyObject} from "../../helpers/functions";

const INIT_STATE = {
    deposits: {},
    queryParams: {},

    deposit: {},

    loading: false,
    error: "",
    validationErrors: {},
    selectedPermissions: [],

    depositInsertedUrl: "",
    depositInserted: false,
    transactionVerified: false,

    depositUpdated: false,

    insertedDepositId: "",

    searchModal: false,
    tmpDeposits: {},
    tmpQueryParams: {},
    searchMode: false,
    searchLoading: false,
    searchError: "",
    searchValidationErrors: {},

    previewDetail: {},
    previewDetailLoading: false,
    previewDetailError: "",

    exportUrl: "",
    exportLoading: false,
    exportError: "",
    exportValidationErrors: [],

    gateways: []
};

type DepositQueryParams = {
    limit: number;
    page: number;
    orderBy: string;
    sort: string;
    depositName: string;
};

type DepositsResult = {
    deposits: any
    queryParams: DepositQueryParams
}

type DepositActionType = {
    type:
        | DepositActionTypes.API_RESPONSE_SUCCESS
        | DepositActionTypes.API_RESPONSE_ERROR
        | DepositActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | DepositActionTypes.GET_DEPOSITS
        | DepositActionTypes.GET_DEPOSIT
        | DepositActionTypes.INSERT_DEPOSIT
        | DepositActionTypes.VERIFY_DEPOSIT
        | DepositActionTypes.UPDATE_DEPOSIT
        | DepositActionTypes.RESET
        | DepositActionTypes.MANUAL_DEPOSIT

        | DepositActionTypes.INSERT_DEPOSIT_ID

        | DepositActionTypes.OPEN_SEARCH
        | DepositActionTypes.CLOSE_SEARCH
        | DepositActionTypes.SEARCH_DEPOSIT
        | DepositActionTypes.CLEAR_SEARCH

        | DepositActionTypes.PREVIEW_DEPOSIT_DETAIL
        | DepositActionTypes.CLEAR_PREVIEW_DEPOSIT_DETAIL
        | DepositActionTypes.INQUIRY_PAYMENT
        | DepositActionTypes.EXPORT
        | DepositActionTypes.RESET_SEARCH
        | DepositActionTypes.GATEWAYS_STATUS;

    payload: {
        actionType?: string;
        data?: DepositsResult | any;
        error?: string;
        validationErrors?: [];

        key?: any
        value?: any
    };
};

type State = {
    deposits?: any;

    queryParams?: {};

    loading?: boolean;
    value?: boolean;
    tmpDeposits?: any;

    tmpQueryParams?: any;
};

const Deposit = (state: State = INIT_STATE, action: DepositActionType) => {
    switch (action.type) {
        case DepositActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case DepositActionTypes.GET_DEPOSITS: {
                    return {
                        ...state,
                        deposits: action.payload.data?.deposits,
                        queryParams: action.payload.data?.queryParams,
                        loading: false,
                    };
                }
                case DepositActionTypes.GET_DEPOSIT: {
                    return {
                        ...state,
                        deposit: action.payload.data,
                        loading: false,
                    };
                }
                case DepositActionTypes.GATEWAYS_STATUS: {
                    return {
                        ...state,
                        gateways: action.payload.data,
                        loading: false,
                    };
                }
                case DepositActionTypes.INSERT_DEPOSIT: {
                    return {
                        ...state,
                        depositInsertedUrl: action.payload.data,
                        loading: false,
                    };
                }
                case DepositActionTypes.VERIFY_DEPOSIT: {
                    return {
                        ...state,
                        transactionVerified: action.payload.data,
                        loading: false,
                    };
                }
                case DepositActionTypes.UPDATE_DEPOSIT: {
                    return {
                        ...state,
                        depositUpdated: true,
                        loading: false,
                    };
                }
                case DepositActionTypes.INSERT_DEPOSIT_ID: {
                    return {
                        ...state,
                        insertedDepositId: action.payload.data,
                        loading: false,
                    };
                }
                case DepositActionTypes.MANUAL_DEPOSIT: {
                    return {
                        ...state,
                        depositInserted: true,
                        loading: false,
                    };
                }
                case DepositActionTypes.SEARCH_DEPOSIT: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpDeposits: isEmptyObject(state.tmpDeposits)? state.deposits: state.tmpDeposits,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        deposits: action.payload.data?.deposits,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                case DepositActionTypes.PREVIEW_DEPOSIT_DETAIL: {
                    return {
                        ...state,
                        previewDetail: action.payload.data,
                        previewDetailLoading: false,
                    };
                }
                case DepositActionTypes.INQUIRY_PAYMENT: {

                    return {
                        ...state,
                        deposits: {
                            ...state.deposits,
                            data: state.deposits.data.filter(function(item: any) {
                                return item.transaction_id !== action.payload.data.transactionId;
                            })
                        },
                        loading: false
                    };
                }
                case DepositActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportUrl: action.payload.data,
                        exportLoading: false,

                        searchMode: false,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case DepositActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case DepositActionTypes.GET_DEPOSITS:
                case DepositActionTypes.GET_DEPOSIT:
                case DepositActionTypes.INSERT_DEPOSIT:
                case DepositActionTypes.VERIFY_DEPOSIT:
                case DepositActionTypes.UPDATE_DEPOSIT:
                case DepositActionTypes.INSERT_DEPOSIT_ID:
                case DepositActionTypes.MANUAL_DEPOSIT:
                case DepositActionTypes.INQUIRY_PAYMENT:
                case DepositActionTypes.GATEWAYS_STATUS:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case DepositActionTypes.SEARCH_DEPOSIT: {
                    return {
                        ...state,
                        searchError: action.payload.error,
                        searchLoading: false,
                    };
                }
                case DepositActionTypes.PREVIEW_DEPOSIT_DETAIL: {
                    return {
                        ...state,
                        previewDetailError: action.payload.error,
                        previewDetailLoading: false,
                    };
                }
                case DepositActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportError: action.payload.error,
                        exportLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case DepositActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case DepositActionTypes.GET_DEPOSITS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case DepositActionTypes.INSERT_DEPOSIT:
                case DepositActionTypes.VERIFY_DEPOSIT:
                case DepositActionTypes.UPDATE_DEPOSIT:
                case DepositActionTypes.MANUAL_DEPOSIT:
                case DepositActionTypes.GET_DEPOSIT:
                case DepositActionTypes.INSERT_DEPOSIT_ID:
                case DepositActionTypes.PREVIEW_DEPOSIT_DETAIL:
                case DepositActionTypes.INQUIRY_PAYMENT:
                case DepositActionTypes.GATEWAYS_STATUS:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case DepositActionTypes.SEARCH_DEPOSIT: {
                    return {
                        ...state,
                        searchValidationErrors: action.payload.validationErrors,
                        searchLoading: false,
                    };
                }
                case DepositActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportValidationErrors: action.payload.validationErrors,
                        exportLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case DepositActionTypes.GET_DEPOSITS:
            return {
                ...state,
                // deposits: {},
                loading: true,
                error: "",
            };

        case DepositActionTypes.GET_DEPOSIT:
            return {
                ...state,
                deposit: {},
                loading: true,
                error: "",
            };

        case DepositActionTypes.GATEWAYS_STATUS:
            return {
                ...state,
                gateways: [],
                loading: true,
                error: "",
            };

        case DepositActionTypes.INSERT_DEPOSIT:
            return {
                ...state,
                depositInsertedUrl: "",
                loading: true,
                error: "",
            };

        case DepositActionTypes.VERIFY_DEPOSIT:
            return {
                ...state,
                transactionVerified: false,
                loading: true,
                error: "",
            };

        case DepositActionTypes.MANUAL_DEPOSIT:
            return {
                ...state,
                depositInserted: false,
                loading: true,
                error: "",
            };

        case DepositActionTypes.UPDATE_DEPOSIT:
            return {
                ...state,
                depositUpdated: false,
                loading: true,
                error: "",
            };

        case DepositActionTypes.INSERT_DEPOSIT_ID:
            return {
                ...state,
                insertedDepositId: "",
                loading: true,
                error: "",
            };

        case DepositActionTypes.RESET:
            return {
                ...state,
                deposits: {},
                queryParams: {},

                deposit: {},

                loading: false,
                error: "",
                validationErrors: {},

                depositInsertedUrl: "",
                depositInserted: false,
                transactionVerified: false,

                depositUpdated: false,

                searchModal: false,
                searchLoading: false,
                searchMode: false,
                searchError: "",
                searchValidationErrors: {},

                tmpDeposits: {},
                tmpQueryParams: {},

                previewDetail: {},
                previewDetailLoading: false,
                previewDetailError: "",

                exportUrl: "",
                exportLoading: false,
                exportError: "",
                exportValidationErrors: [],

                gateways: []
            };


        // search actions
        case DepositActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case DepositActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},
            };
        case DepositActionTypes.SEARCH_DEPOSIT:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };

        case DepositActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                deposits: state.tmpDeposits,
                queryParams: state.tmpQueryParams,
                tmpDeposits: {},
                tmpQueryParams: {},
                searchMode: false,
            };

        case DepositActionTypes.PREVIEW_DEPOSIT_DETAIL:
            return {
                ...state,
                previewDetail: {},
                previewDetailLoading: true,
                previewDetailError: "",
            };

        case DepositActionTypes.CLEAR_PREVIEW_DEPOSIT_DETAIL:
            return {
                ...state,
                previewDetail: {},
                previewDetailLoading: false,
                previewDetailError: "",
            };

        case DepositActionTypes.INQUIRY_PAYMENT:
            return {
                ...state,
                loading: true,
                error: "",
            };
        case DepositActionTypes.EXPORT:

            return {
                ...state,
                exportUrl: "",
                exportLoading: true,
                exportError: "",
                exportValidationErrors: [],
            };

        case DepositActionTypes.RESET_SEARCH:

            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},

                exportUrl: "",
                exportLoading: false,
                exportError: "",
                exportValidationErrors: [],
            }

        default:
            return { ...state };
    }
};

export default Deposit;
