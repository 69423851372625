import { LogActionTypes } from './constants';
import {isEmptyObject} from "../../helpers/functions";

const INIT_STATE = {
    logs: {},
    log: {},
    queryParams: {},

    loading: false,
    error: "",
    validationErrors: {},

    searchModal: false,
    tmpLogs: {},
    tmpQueryParams: {},
    searchMode: false,
    searchLoading: false,
    searchError: "",
    searchValidationErrors: {},

};


type LogActionType = {
    type:
        | LogActionTypes.API_RESPONSE_SUCCESS
        | LogActionTypes.API_RESPONSE_ERROR
        | LogActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | LogActionTypes.GET_LOGS
        | LogActionTypes.GET_LOG
        | LogActionTypes.RESET

        | LogActionTypes.OPEN_SEARCH
        | LogActionTypes.CLOSE_SEARCH
        | LogActionTypes.SEARCH_LOG
        | LogActionTypes.SEARCH_TRANSACTION
        | LogActionTypes.CLEAR_SEARCH;

    payload: {
        actionType?: string;
        data?: any;
        error?: string;
        validationErrors?: [];
    };
};

type State = {
    logs?: {};
    queryParams?: {};


    loading?: boolean;
    value?: boolean;
    tmpLogs?: any;
    tmpQueryParams?: {};
};

const Log = (state: State = INIT_STATE, action: LogActionType) => {
    switch (action.type) {
        case LogActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case LogActionTypes.GET_LOGS: {
                    return {
                        ...state,
                        logs: action.payload.data?.logs,
                        queryParams: action.payload.data?.queryParams,
                        loading: false,
                    };
                }
                case LogActionTypes.GET_LOG: {
                    return {
                        ...state,
                        log: action.payload.data,
                        loading: false,
                    };
                }

                case LogActionTypes.SEARCH_LOG: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpLogs: isEmptyObject(state.tmpLogs)? state.logs: state.tmpLogs,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        logs: action.payload.data?.logs,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,
                        searchLoading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case LogActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case LogActionTypes.GET_LOGS:
                case LogActionTypes.GET_LOG:
                 {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case LogActionTypes.SEARCH_LOG:
                case LogActionTypes.SEARCH_TRANSACTION: {
                    return {
                        ...state,
                        searchError: action.payload.error,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case LogActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case LogActionTypes.GET_LOGS:
                {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case LogActionTypes.GET_LOG:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case LogActionTypes.SEARCH_LOG:
                case LogActionTypes.SEARCH_TRANSACTION: {
                    return {
                        ...state,
                        searchValidationErrors: action.payload.validationErrors,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case LogActionTypes.GET_LOGS:
            return {
                ...state,
                // logs: {},
                loading: true,
                error: "",
            };
        case LogActionTypes.GET_LOG:
            return {
                ...state,
                log: {},
                loading: true,
                error: "",
            };

        case LogActionTypes.RESET:
            return {
                ...state,
                transactions: {},

                log: {},
                logs: {},
                queryParams: {},

                loading: false,
                error: "",
                validationErrors: {},

                searchModal: false,
                searchLoading: false,
                searchMode: false,
                searchError: "",
                searchValidationErrors: {},

                tmpLogs: {},
                tmpQueryParams: {},

            };


        // search actions
        case LogActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case LogActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},
            };
        case LogActionTypes.SEARCH_LOG:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case LogActionTypes.SEARCH_TRANSACTION:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case LogActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                logs: state.tmpLogs,
                queryParams: state.tmpQueryParams,
                tmpLogs: {},
                tmpQueryParams: {},
                searchMode: false,
            };

        default:
            return { ...state };
    }
};

export default Log;
