import { IpActionTypes } from './constants';

export type IpActionType = {
    type:
        | IpActionTypes.API_RESPONSE_SUCCESS
        | IpActionTypes.API_RESPONSE_ERROR
        | IpActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | IpActionTypes.RESET

        | IpActionTypes.GET_IPS
        | IpActionTypes.GET_IP
        | IpActionTypes.INSERT_IP
        | IpActionTypes.DELETE_IP

        | IpActionTypes.OPEN_SEARCH
        | IpActionTypes.CLOSE_SEARCH
        | IpActionTypes.SEARCH_IP
        | IpActionTypes.CLEAR_SEARCH;

    payload: {} | string;
};

type Ip = {
    id: number;
    email: string;
    emailVerify: string;
    role: string;
    ip: string;

    process: any
};

type QueryParams = {
    page: number;
    limit: number;
    orderBy: string,
    sort: string,
    accountId?: string
}

class IpAction {

    apiResponseSuccess = (actionType: string, data: Ip | {}): IpActionType => ({
        type: IpActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: any): IpActionType => ({
        type: IpActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: any): IpActionType => ({
        type: IpActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): IpActionType => ({
        type: IpActionTypes.RESET,
        payload: {},
    });

    getIps = (queryParams: any): IpActionType => ({
        type: IpActionTypes.GET_IPS,
        payload: {queryParams},
    });

    getIp = (id: string): IpActionType => ({
        type: IpActionTypes.GET_IP,
        payload: {id},
    });

    insertIp = (data: any): IpActionType => ({
        type: IpActionTypes.INSERT_IP,
        payload: {data},
    });

    delete = (id: string): IpActionType => ({
        type: IpActionTypes.DELETE_IP,
        payload: {id},
    });

    openSearch = (): IpActionType => ({
        type: IpActionTypes.OPEN_SEARCH,
        payload: {}
    });

    closeSearch = (): IpActionType => ({
        type: IpActionTypes.CLOSE_SEARCH,
        payload: {}
    });

    search = (queryParams: QueryParams): IpActionType => ({
        type: IpActionTypes.SEARCH_IP,
        payload: { queryParams }
    });

    clearSearch = (): IpActionType => ({
        type: IpActionTypes.CLEAR_SEARCH,
        payload: {}
    });
}

const ipActions = new IpAction();
export { ipActions };
