import { DWTransactionActionTypes } from './constants';
import {isEmptyObject} from "../../helpers/functions";

const INIT_STATE = {
    DWTransactions: {},
    queryParams: {},

    DWTransaction: {},

    loading: false,
    error: "",
    validationErrors: {},

    searchModal: false,
    tmpDWTransactions: {},
    tmpQueryParams: {},
    searchMode: false,
    searchLoading: false,
    searchError: "",
    searchValidationErrors: {},

    exportUrl: "",
    exportLoading: false,
    exportError: "",
    exportValidationErrors: [],
};

type DWTransactionData = {
    id: number;
    name: string;
    guard_name: string;
};

type DWTransactionQueryParams = {
    limit: number;
    page: number;
    orderBy: string;
    sort: string;
    DWTransactionName: string;
};

type DWTransactionsResult = {
    DWTransactions: DWTransactionData | {},
    queryParams: DWTransactionQueryParams
}

type DWTransactionActionType = {
    type:
        | DWTransactionActionTypes.API_RESPONSE_SUCCESS
        | DWTransactionActionTypes.API_RESPONSE_ERROR
        | DWTransactionActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | DWTransactionActionTypes.GET_DWTRANSACTIONS
        | DWTransactionActionTypes.RESET

        | DWTransactionActionTypes.OPEN_SEARCH
        | DWTransactionActionTypes.CLOSE_SEARCH
        | DWTransactionActionTypes.SEARCH_DWTRANSACTION
        | DWTransactionActionTypes.CLEAR_SEARCH
        | DWTransactionActionTypes.EXPORT
        | DWTransactionActionTypes.RESET_SEARCH;
    payload: {
        actionType?: string;
        data?: DWTransactionsResult | any;
        error?: string;
        validationErrors?: [];

        key?: any
        value?: any
    };
};

type State = {
    DWTransactions?: DWTransactionData | {};
    queryParams?: {};

    loading?: boolean;
    value?: boolean;
    tmpDWTransactions?: any;
    tmpQueryParams?: any;
};

const DWTransaction = (state: State = INIT_STATE, action: DWTransactionActionType) => {
    switch (action.type) {
        case DWTransactionActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case DWTransactionActionTypes.GET_DWTRANSACTIONS: {
                    return {
                        ...state,
                        DWTransactions: action.payload.data?.DWTransactions,
                        queryParams: action.payload.data?.queryParams,
                        loading: false,
                    };
                }
                case DWTransactionActionTypes.SEARCH_DWTRANSACTION: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpDWTransactions: isEmptyObject(state.tmpDWTransactions)? state.DWTransactions: state.tmpDWTransactions,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        DWTransactions: action.payload.data?.DWTransactions,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                case DWTransactionActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportUrl: action.payload.data,
                        exportLoading: false,

                        searchMode: false,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case DWTransactionActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case DWTransactionActionTypes.GET_DWTRANSACTIONS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case DWTransactionActionTypes.SEARCH_DWTRANSACTION: {
                    return {
                        ...state,
                        searchError: action.payload.error,
                        searchLoading: false,
                    };
                }
                case DWTransactionActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportError: action.payload.error,
                        exportLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case DWTransactionActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case DWTransactionActionTypes.GET_DWTRANSACTIONS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                // case DWTransactionActionTypes.UPDATE_DWTRANSACTION: {
                //     return {
                //         ...state,
                //         validationErrors: action.payload.validationErrors,
                //         loading: false,
                //     };
                // }
                case DWTransactionActionTypes.SEARCH_DWTRANSACTION: {
                    return {
                        ...state,
                        searchValidationErrors: action.payload.validationErrors,
                        searchLoading: false,
                    };
                }
                case DWTransactionActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportValidationErrors: action.payload.validationErrors,
                        exportLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case DWTransactionActionTypes.GET_DWTRANSACTIONS:
            return {
                ...state,
                // DWTransactions: {},
                loading: true,
                error: "",
            };

        case DWTransactionActionTypes.RESET:
            return {
                ...state,
                DWTransactions: {},
                queryParams: {},

                DWTransaction: {},

                loading: false,
                error: "",
                validationErrors: {},

                searchModal: false,
                searchLoading: false,
                searchMode: false,
                searchError: "",
                searchValidationErrors: {},

                tmpDWTransactions: {},
                tmpQueryParams: {},

                exportUrl: "",
                exportLoading: false,
                exportError: "",
                exportValidationErrors: [],
            };


        // search actions
        case DWTransactionActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case DWTransactionActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},
            };
        case DWTransactionActionTypes.SEARCH_DWTRANSACTION:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case DWTransactionActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                DWTransactions: state.tmpDWTransactions,
                queryParams: state.tmpQueryParams,
                tmpDWTransactions: {},
                tmpQueryParams: {},
                searchMode: false,
            };

        case DWTransactionActionTypes.EXPORT:

            return {
                ...state,
                exportUrl: "",
                exportLoading: true,
                exportError: "",
                exportValidationErrors: [],
            };

        case DWTransactionActionTypes.RESET_SEARCH:

            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},

                exportUrl: "",
                exportLoading: false,
                exportError: "",
                exportValidationErrors: [],
            }

        default:
            return { ...state };
    }
};

export default DWTransaction;
