import {RoleActionTypes} from './constants';

export type RoleActionType = {
    type:
        | RoleActionTypes.API_RESPONSE_SUCCESS
        | RoleActionTypes.API_RESPONSE_ERROR
        | RoleActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | RoleActionTypes.FIND_ROLE
        | RoleActionTypes.GET_ROLES
        | RoleActionTypes.NEW_ROLE
        | RoleActionTypes.INSERT_ROLE
        | RoleActionTypes.DELETE_ROLE
        | RoleActionTypes.EDIT_ROLE
        | RoleActionTypes.UPDATE_ROLE

        | RoleActionTypes.OPEN_SEARCH
        | RoleActionTypes.CLOSE_SEARCH
        | RoleActionTypes.SEARCH_ROLE
        | RoleActionTypes.CLEAR_SEARCH

        | RoleActionTypes.SET_VARIABLE
        | RoleActionTypes.RESET;
    payload: {} | string;
};

type Role = {
    id: number;
    name: string;
    guard_name: string;
};

type QueryParams = {
    page: number;
    limit: number;
    orderBy: string,
    sort: string,
    roleName?: string
}

class RoleActions {

    apiResponseSuccess = (actionType: string, data: Role | {}): RoleActionType => ({
        type: RoleActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): RoleActionType => ({
        type: RoleActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): RoleActionType => ({
        type: RoleActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): RoleActionType => ({
        type: RoleActionTypes.RESET,
        payload: {},
    });

    setVariable = (key: string, value: any): RoleActionType => ({
        type: RoleActionTypes.SET_VARIABLE,
        payload: { key, value },
    });

    getRoles = (queryParams: QueryParams): RoleActionType => ({
        type: RoleActionTypes.GET_ROLES,
        payload: { queryParams },
    });

    newRole = (): RoleActionType => ({
        type: RoleActionTypes.NEW_ROLE,
        payload: {},
    });

    insertRole = (name: string, permissions: string[]): RoleActionType => ({
        type: RoleActionTypes.INSERT_ROLE,
        payload: {
            name,
            permissions
        }
    })

    delete = (id: number) : RoleActionType => ({
        type: RoleActionTypes.DELETE_ROLE,
        payload: {
            id,
        }
    })

    find = (id: number) : RoleActionType => ({
        type: RoleActionTypes.FIND_ROLE,
        payload: {
            id,
        }
    })

    edit = (id: number, withPermissions: boolean) : RoleActionType => ({
        type: RoleActionTypes.EDIT_ROLE,
        payload: {
            id,
            withPermissions
        }
    })

    update = (
        id: number,
        name: string,
        permissions: string[],

    ): RoleActionType => ({
        type: RoleActionTypes.UPDATE_ROLE,
        payload: {
            id,
            name,
            permissions
        }
    })

    openSearch = (): RoleActionType => ({
        type: RoleActionTypes.OPEN_SEARCH,
        payload: {}
    })
    closeSearch = (): RoleActionType => ({
        type: RoleActionTypes.CLOSE_SEARCH,
        payload: {}
    })

    search = (queryParams: QueryParams): RoleActionType => ({
        type: RoleActionTypes.SEARCH_ROLE,
        payload: { queryParams }
    })

    clearSearch = (): RoleActionType => ({
        type: RoleActionTypes.CLEAR_SEARCH,
        payload: {}
    })
}

const roleActions = new RoleActions();
export { roleActions };
