import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects';
import { graphql, graphqlUpload } from '../../helpers';
import { END, eventChannel } from 'redux-saga';
import { DLActionTypes, DLErrors } from './constants';
import { SagaIterator } from '@redux-saga/core';
import { checkResponseError, checkServerError, pluckResponse } from '../../helpers/functions';
import { dlActions } from './actions';

type DLData = {
    payload: {
        id: number,

        data: any;

        queryParams: {
            limit: number;
            page: number;

        }

        token: string;

        file: {fileId: string} & File;

        fileId: string;
        uuid: string;
    };
    type: string;
};

function createUploader(file: File, query:any) {

    let emit: any;

    const chan = eventChannel((emitter) => {
        emit = emitter;
        return () => {};
    });

    const uploadProgressCb = ({ total, loaded }: any) => {
        const percentage = Math.round((loaded * 100) / total);
        emit(percentage);
        if (percentage === 100) emit(END);
    };

    // make form object
    let data = new FormData();
    data.set('operations', JSON.stringify(query));

    data.set('operationName', "");
    data.set('map', JSON.stringify({"file":["variables.file"]}));
    data.append('file', file);

    const uploadPromise = graphqlUpload(data, 'auth', uploadProgressCb);
    return [uploadPromise, chan];
}

function* uploadDlProgressWatcher(chan:any, fileId: string): SagaIterator {
    while (true) {
        const progress = yield take(chan);
        yield put(dlActions.uploadDlProgress(progress, fileId));
    }
}

function* uploadDrivingLicence({ payload: {id, file} }: DLData): SagaIterator {
    try {
        const query = {
            'query': `mutation UploadDrivingLicence($id: String!, $file: Upload!) {
                    uploadDrivingLicence(id: $id, file: $file) {
                        uuid
                    }
                }`,
            'variables': {
                "file": file,
                "id": id,
            }
        };

        const [uploadPromise, chan] = yield call(createUploader, file, query);

        yield fork(uploadDlProgressWatcher, chan, file.fileId);

        const response = yield call(() => uploadPromise);

        checkResponseError(response, DLErrors.RESPONSE_200);

        const rUDL = response.data;

        checkServerError(rUDL);

        const uploadDrivingLicence = pluckResponse(rUDL, "uploadDrivingLicence");

        yield put(dlActions.apiResponseSuccess(DLActionTypes.UPLOAD_DRIVING_LICENCE, {fileId: file.fileId, uploadDrivingLicence}));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(dlActions.apiResponseError(DLActionTypes.UPLOAD_DRIVING_LICENCE, {fileId: file.fileId, error}));
        } else {
            yield put(dlActions.apiResponseValidationErrors(DLActionTypes.UPLOAD_DRIVING_LICENCE, {fileId: file.fileId, error}));
        }
    }
}

function* update({ payload: {data} }: DLData): SagaIterator {
    try {
        const param = {
            query:`mutation UpdateDrivingLicence($id: String!, $status: String!) {
                    updateDrivingLicence(id: $id, status: $status)
                }`,
            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, DLErrors.RESPONSE_200);

        const rUNF = response.data;

        checkServerError(rUNF);

        const updateDrivingLicence = pluckResponse(rUNF, "updateDrivingLicence");

        yield put(dlActions.apiResponseSuccess(DLActionTypes.UPDATE, updateDrivingLicence));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(dlActions.apiResponseError(DLActionTypes.UPDATE, error));
        } else {
            yield put(dlActions.apiResponseValidationErrors(DLActionTypes.UPDATE, error));
        }
    }
}

function* deleteDrivingLicence({ payload: {id, fileId, uuid} }: DLData): SagaIterator {
    try {
        if(uuid) {
            const param = {
                query:`mutation DeleteDrivingLicence($id: String!, $uuid: String!) {
                    deleteDrivingLicence(id: $id, uuid: $uuid)
                }`,
                variables: {
                    "uuid": uuid,
                    "id": id,
                }
            };

            const response: any = yield call(graphql, param, 'auth');

            checkResponseError(response, DLErrors.RESPONSE_200);

            const rDL = response.data;

            checkServerError(rDL);

            pluckResponse(rDL, "deleteDrivingLicence");
        }

        yield put(dlActions.apiResponseSuccess(DLActionTypes.DELETE_DRIVING_LICENCE, fileId));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(dlActions.apiResponseError(DLActionTypes.DELETE_DRIVING_LICENCE, error));
        } else {
            yield put(dlActions.apiResponseValidationErrors(DLActionTypes.DELETE_DRIVING_LICENCE, error));
        }
    }
}

export function* watchUploadDrivingLicence(): any {
    yield takeEvery(DLActionTypes.UPLOAD_DRIVING_LICENCE, uploadDrivingLicence);
}

export function* watchUpdateDrivingLicence(): any {
    yield takeEvery(DLActionTypes.UPDATE, update);
}

export function* watchDeleteDrivingLicence(): any {
    yield takeEvery(DLActionTypes.DELETE_DRIVING_LICENCE, deleteDrivingLicence);
}

function* dlSaga() {
    yield all([
        fork(watchUploadDrivingLicence),
        fork(watchUpdateDrivingLicence),
        fork(watchDeleteDrivingLicence),
    ]);
}

export default dlSaga;
