import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { graphql } from 'helpers';
import {UserActionTypes, UserErrors} from './constants';
import {
    checkResponseError,
    checkServerError,
    pluckResponse
} from "../../helpers/functions";
import {userActions} from "./actions";

type UserData = {
    payload: {
        id: number;
        accountId: string;
        mobile: string;
        verifyMobile: string;
        email: string;
        verifyEmail: string;
        userStatus: string;
        password: string;
        display_name: string;
        roles: string[];
        language: string;
        calendar: string;
        timezone: string;
        twoFaStatus: string;

        data: any;

        paymentDepositFee: string;
        paymentWithdrawFee: string;

        queryParams: {
            limit: number;
            page: number;

            name: string;
            registeredFrom: string;
            registeredTo: string;
            status: string;
        }
    };
    type: string;
};


function* getUsers({ payload: { queryParams } }: UserData): SagaIterator {
    try {
        const param: any = {
            query:`query Users(
                $page: Int!,
                $limit: Int!,
                $orderBy: String!,
                $sort: String!,
                $accountId: String,
                $name: String,
                $email: String,
                $registeredFrom: String,
                $registeredTo: String,
                $roles: [String],
                $userStatus: String
            ) {
                users(
                    page: $page,
                    limit: $limit,
                    orderBy: $orderBy,
                    sort: $sort,
                    accountId: $accountId,
                    name: $name,
                    email: $email,
                    registeredFrom: $registeredFrom,
                    registeredTo: $registeredTo,
                    roles: $roles,
                    userStatus: $userStatus
                ) {
                    data {
                        id
                        username
                        display_name
                        email
                        mobile
                        email_verified_at
                        mobile_verified_at
                        mobile_ownership_status
                        active
                        roles {
                            name
                        }
                        calendar
                        created_at
                        updated_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,
            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, UserErrors.RESPONSE_200);

        const rU = response.data;

        checkServerError(rU);

        const users = pluckResponse(rU, "users");

        const result = {
            users,
            queryParams
        }

        yield put(userActions.apiResponseSuccess(UserActionTypes.GET_USERS, result));

    } catch (error: any) {

        if(typeof error === "string") {
            yield put(userActions.apiResponseError(UserActionTypes.GET_USERS, error));
        } else {
            yield put(userActions.apiResponseValidationErrors(UserActionTypes.GET_USERS, error));
        }
    }
}

function* getUserByAccountId({ payload: { accountId } }: UserData): SagaIterator {
    try {
        let param: any = {
            query:`query UserByAccount($accountId: String!) {
                    userByAccount(accountId: $accountId) {
                        id
                        username
                        display_name
                        email
                        mobile
                        mobile_ownership_status
                        active
                        roles {
                            name
                        }
                        calendar
                        created_at
                        updated_at
                    }
                }`,
            variables: {
                accountId: accountId
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, UserErrors.RESPONSE_200);

        const rU = response.data;

        checkServerError(rU);

        const user = pluckResponse(rU, "userByAccount");


        yield put(userActions.apiResponseSuccess(UserActionTypes.GET_USER_BY_ACCOUNT_ID, user));

    } catch (error: any) {
        console.log(error)
        if(typeof error === "string") {
            yield put(userActions.apiResponseError(UserActionTypes.GET_USER_BY_ACCOUNT_ID, error));
        } else {
            yield put(userActions.apiResponseValidationErrors(UserActionTypes.GET_USER_BY_ACCOUNT_ID, error));
        }
    }
}

function* insertUser({ payload: {
    accountId,
    mobile,
    verifyMobile,
    email,
    verifyEmail,
    userStatus,
    password,
    display_name,
    roles,
    language,
    calendar,
    timezone,
} }: UserData): SagaIterator {
    try {
        const param = {
            query:`mutation CreateUser(
                $accountId: String,
                $mobile: String,
                $verifyMobile: String,
                $email: String!,
                $verifyEmail: String!,
                $userStatus: String!,
                $password: String!,
                $displayName: String
                $roles: [String!]
                $language: String!
                $calendar: String!
                $timezone: String!
            ) {
                createUser(
                    accountId: $accountId,
                    mobile: $mobile,
                    verifyMobile: $verifyMobile,
                    email: $email,
                    verifyEmail: $verifyEmail,
                    userStatus: $userStatus,
                    password: $password,
                    displayName: $displayName
                    roles: $roles
                    language: $language
                    calendar: $calendar
                    timezone: $timezone
                ) {
                    username
                }
            }`,

            variables: {
                "accountId": accountId,
                "mobile": mobile,
                "verifyMobile": verifyMobile,
                "email": email,
                "verifyEmail": verifyEmail,
                "userStatus": userStatus,
                "password": password,
                "displayName": display_name,
                "roles": roles,
                "language": language,
                "calendar": calendar,
                "timezone": timezone
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, UserErrors.RESPONSE_200);

        const rCU = response.data;

        checkServerError(rCU);

        const createUser = pluckResponse(rCU, "createUser");

        yield put(userActions.apiResponseSuccess(UserActionTypes.INSERT_USER, createUser));

    } catch (error: any) {

        if(typeof error === "string") {
            yield put(userActions.apiResponseError(UserActionTypes.INSERT_USER, error));
        } else {
            yield put(userActions.apiResponseValidationErrors(UserActionTypes.INSERT_USER, error));
        }
    }
}

function* getUser({ payload: { id } }: UserData): SagaIterator {
    try {
        const param:any = {
            query:`query User($id: String!) {
                    user(id: $id) {
                        id
                        username
                        email
                        mobile
                        display_name
                        active
                        email_verified_at
                        mobile_verified_at
                        mobile_ownership_status
                        roles {
                            name
                        }
                        language
                        calendar
                        timezone
                        two_fa_enabled
                        last_login_at
                        last_login_ip
                        created_at
                        updated_at
                        customerProfile {
                            id
                            first_name
                            last_name
                            first_name_en
                            last_name_en
                            gender
                            birthday
                            national_code
                            verification_method
                            status
                            nc_serial
                        }
                        wallets {
                            name
                            balanceFloatNum
                            meta {
                                currency
                            }
                        }
                        idNcFront {
                            status
                            media {
                                url
                            }
                            created_at
                        }
                        idNcBack {
                            status
                            media {
                                url
                            }
                            created_at
                        }
                        idPassport {
                            status
                            media {
                                url
                            }
                            created_at
                        }
                        idDrivingLicence {
                            status
                            media {
                                url
                            }
                            created_at
                        }
                        bankCards {
                            bank_name
                            number
                            type
                            status
                            created_at
                            updated_at
                        }
                        bankIBANs {
                            bank_name
                            number
                            type
                            status
                            created_at
                            updated_at
                        }
                        termsAndConditions {
                            id
                            status
                            media {
                                url
                            }
                            created_at
                        }
                    }
                }`,
            variables: {
                "id": id,
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, UserErrors.RESPONSE_200);

        const rU: any = response.data;

        checkServerError(rU);

        const user = pluckResponse(rU, "user");

        yield put(userActions.apiResponseSuccess(UserActionTypes.GET_USER, user));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(userActions.apiResponseError(UserActionTypes.GET_USER, error));
        } else {
            yield put(userActions.apiResponseValidationErrors(UserActionTypes.GET_USER, error));
        }
    }
}

function* updateUser({ payload: {
    id,
    accountId,
    email,
    verifyEmail,
    mobile,
    verifyMobile,
    userStatus,
    password,
    display_name,
    roles,
    language,
    calendar,
    timezone,
    twoFaStatus
} }: UserData): SagaIterator {
    try {
        const param = {
            query:`mutation UpdateUser(
                $id: String!,
                $accountId: String!,
                $email: String,
                $verifyEmail: String,
                $mobile: String,
                $verifyMobile: String,
                $userStatus: String!,
                $password: String,
                $displayName: String
                $roles: [String!]
                $language: String!,
                $calendar: String!,
                $timezone: String!,
                $twoFaStatus: String!
            ) {
                updateUser(
                    id: $id,
                    accountId: $accountId,
                    email: $email,
                    verifyEmail: $verifyEmail,
                    mobile: $mobile,
                    verifyMobile: $verifyMobile,
                    userStatus: $userStatus,
                    password: $password,
                    displayName: $displayName,
                    roles: $roles,
                    language: $language,
                    calendar: $calendar,
                    timezone: $timezone,
                    twoFaStatus: $twoFaStatus
                ) {
                    username
                }
            }`,

            variables: {
                "id": id,
                "accountId": accountId,
                "email": email,
                "verifyEmail": verifyEmail,
                "mobile": mobile,
                "verifyMobile": verifyMobile,
                "userStatus": userStatus,
                "password": password,
                "displayName": display_name,
                "roles": roles,
                "language": language,
                "calendar": calendar,
                "timezone": timezone,
                "twoFaStatus": twoFaStatus
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, UserErrors.RESPONSE_200);

        const rUU = response.data;

        checkServerError(rUU);

        const updateUser = pluckResponse(rUU, "updateUser");

        yield put(userActions.apiResponseSuccess(UserActionTypes.UPDATE_USER, updateUser));

    } catch (error: any) {

        if(typeof error === "string") {
            yield put(userActions.apiResponseError(UserActionTypes.UPDATE_USER, error));
        } else {
            yield put(userActions.apiResponseValidationErrors(UserActionTypes.UPDATE_USER, error));
        }
    }
}

function* searchUser({ payload: { queryParams } }: UserData): SagaIterator {
    try {
        const param = {
            query:`query Users(
                $page: Int!,
                $limit: Int!,
                $orderBy: String!,
                $sort: String!,
                $accountId: String,
                $name: String,
                $email: String,
                $mobile: String,
                $registeredFrom: String,
                $registeredTo: String,
                $roles: [String],
                $userStatus: String,
                
                $nationalCode: String,
                $firstName: String,
                $lastName: String,
                $firstNameEn: String,
                $lastNameEn: String,
                
            ) {
                users(
                    page: $page,
                    limit: $limit,
                    orderBy: $orderBy,
                    sort: $sort,
                    accountId: $accountId,
                    name: $name,
                    email: $email,
                    mobile: $mobile,
                    registeredFrom: $registeredFrom,
                    registeredTo: $registeredTo,
                    roles: $roles,
                    userStatus: $userStatus,
                    
                    nationalCode: $nationalCode,
                    firstName: $firstName,
                    lastName: $lastName,
                    firstNameEn: $firstNameEn,
                    lastNameEn: $lastNameEn
                ) {
                    data {
                        id
                        username
                        display_name
                        email
                        mobile
                        email_verified_at
                        mobile_verified_at
                        active
                        roles {
                            name
                        }
                        created_at
                        updated_at
                        language
                        calendar
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, UserErrors.RESPONSE_200);

        const responseUsers = response.data;

        checkServerError(responseUsers);

        const users = pluckResponse(responseUsers, "users");

        const result = {
            users,
            queryParams
        }

        yield put(userActions.apiResponseSuccess(UserActionTypes.SEARCH_USER, result));

    } catch (error: any) {

        if(typeof error === "string") {
            yield put(userActions.apiResponseError(UserActionTypes.SEARCH_USER, error));
        } else {
            yield put(userActions.apiResponseValidationErrors(UserActionTypes.SEARCH_USER, error));
        }
    }
}

function* getMerchantSettings({ payload: { id } }: UserData): SagaIterator {
    try {
        const param:any = {
            query:`query MerchantSettings($id: String!) {
                    merchantSettings(id: $id) {
                        payment_deposit_fee
                        payment_withdraw_fee
                    }
                }`,
            variables: {
                "id": id,
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, UserErrors.RESPONSE_200);

        const rU: any = response.data;

        checkServerError(rU);

        const merchantSettings = pluckResponse(rU, "merchantSettings");

        yield put(userActions.apiResponseSuccess(UserActionTypes.GET_MERCHANT_SETTING, merchantSettings));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(userActions.apiResponseError(UserActionTypes.GET_MERCHANT_SETTING, error));
        } else {
            yield put(userActions.apiResponseValidationErrors(UserActionTypes.GET_MERCHANT_SETTING, error));
        }
    }
}


function* updateMerchantSettings({ payload: {
    id,
    paymentDepositFee,
    paymentWithdrawFee,
} }: UserData): SagaIterator {
    try {
        const param = {
            query:`mutation UpdateMerchantSettings(
                $id: String!,
                $paymentDepositFee: String!,
                $paymentWithdrawFee: String!,
            ) {
                updateMerchantSettings(
                    id: $id,
                    paymentDepositFee: $paymentDepositFee,
                    paymentWithdrawFee: $paymentWithdrawFee,
                )
            }`,

            variables: {
                "id": id,
                "paymentDepositFee": paymentDepositFee,
                "paymentWithdrawFee": paymentWithdrawFee,
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, UserErrors.RESPONSE_200);

        const rUMS = response.data;

        checkServerError(rUMS);

        const updateMerchantSettings = pluckResponse(rUMS, "updateMerchantSettings");

        yield put(userActions.apiResponseSuccess(UserActionTypes.UPDATE_MERCHANT_SETTING, updateMerchantSettings));

    } catch (error: any) {

        if(typeof error === "string") {
            yield put(userActions.apiResponseError(UserActionTypes.UPDATE_MERCHANT_SETTING, error));
        } else {
            yield put(userActions.apiResponseValidationErrors(UserActionTypes.UPDATE_MERCHANT_SETTING, error));
        }
    }
}

function* updateMobileOwnership({ payload: {data} }: UserData): SagaIterator {
    try {
        const param = {
            query:`mutation UpdateMobileOwnership($id: String!, $status: String!) {
                    updateMobileOwnership(id: $id, status: $status)
                }`,
            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, UserErrors.RESPONSE_200);

        const rU = response.data;

        checkServerError(rU);

        const updateMobileOwnership = pluckResponse(rU, "updateMobileOwnership");

        yield put(userActions.apiResponseSuccess(UserActionTypes.UPDATE_MOBILE_OWNERSHIP, updateMobileOwnership));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(userActions.apiResponseError(UserActionTypes.UPDATE_MOBILE_OWNERSHIP, error));
        } else {
            yield put(userActions.apiResponseValidationErrors(UserActionTypes.UPDATE_MOBILE_OWNERSHIP, error));
        }
    }
}

function* exportUsers({ payload: { queryParams } }: UserData): SagaIterator {
    try {
        const param = {
            query:`query ExportUsers(
                $page: Int!,
                $limit: Int!,
                $orderBy: String!,
                $sort: String!,
                $accountId: String,
                $name: String,
                $email: String,
                $registeredFrom: String,
                $registeredTo: String,
                $roles: [String],
                $userStatus: String
            ) {
                exportUsers(
                    page: $page,
                    limit: $limit,
                    orderBy: $orderBy,
                    sort: $sort,
                    accountId: $accountId,
                    name: $name,
                    email: $email,
                    registeredFrom: $registeredFrom,
                    registeredTo: $registeredTo,
                    roles: $roles,
                    userStatus: $userStatus
                )
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, UserErrors.RESPONSE_200);

        const rTransactions = response.data;

        checkServerError(rTransactions);

        const users = pluckResponse(rTransactions, "exportUsers");

        yield put(userActions.apiResponseSuccess(UserActionTypes.EXPORT, users));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(userActions.apiResponseError(UserActionTypes.EXPORT, error));
        } else {
            yield put(userActions.apiResponseValidationErrors(UserActionTypes.EXPORT, error));
        }
    }
}

export function* watchGetUsers() {
    yield takeEvery(UserActionTypes.GET_USERS, getUsers);
}

export function* watchGetUser() {
    yield takeEvery(UserActionTypes.GET_USER, getUser);
}

export function* watchInsertUser() {
    yield takeEvery(UserActionTypes.INSERT_USER, insertUser);
}

export function* watchUpdateUser() {
    yield takeEvery(UserActionTypes.UPDATE_USER, updateUser);
}

export function* watchSearchUser() {
    yield takeEvery(UserActionTypes.SEARCH_USER, searchUser);
}

export function* watchGetUserByAccountId() {
    yield takeEvery(UserActionTypes.GET_USER_BY_ACCOUNT_ID, getUserByAccountId);
}

export function* watchGetMerchantSettings() {
    yield takeEvery(UserActionTypes.GET_MERCHANT_SETTING, getMerchantSettings);
}

export function* watchUpdateMerchantSettings() {
    yield takeEvery(UserActionTypes.UPDATE_MERCHANT_SETTING, updateMerchantSettings);
}

export function* watchUpdateMobileOwnership() {
    yield takeEvery(UserActionTypes.UPDATE_MOBILE_OWNERSHIP, updateMobileOwnership);
}

export function* watchExportUsers() {
    yield takeEvery(UserActionTypes.EXPORT, exportUsers);
}

function* userSaga() {
    yield all([
        fork(watchGetUsers),
        fork(watchGetUser),
        fork(watchGetUserByAccountId),
        fork(watchInsertUser),
        fork(watchUpdateUser),
        fork(watchSearchUser),
        fork(watchGetMerchantSettings),
        fork(watchUpdateMerchantSettings),
        fork(watchUpdateMobileOwnership),
        fork(watchExportUsers)
    ]);
}

export default userSaga;
