import { MyAccountActionTypes } from './constants';

export type MyAccountActionType = {
    type:
        | MyAccountActionTypes.API_RESPONSE_SUCCESS
        | MyAccountActionTypes.API_RESPONSE_ERROR
        | MyAccountActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | MyAccountActionTypes.RESET

        | MyAccountActionTypes.SEND_VERIFICATION_EMAIL_LINK
        | MyAccountActionTypes.VERIFY_EMAIL

        | MyAccountActionTypes.GET_VERIFICATION_STEPS_STATUS

        | MyAccountActionTypes.GET_PROFILE_IMAGE
        | MyAccountActionTypes.UPLOAD_PROFILE_IMAGE

        | MyAccountActionTypes.UPDATE_ACCOUNT;

    payload: {} | string;
};

type MyAccount = {
    id: number;
    email: string;
    emailVerify: string;
    role: string;
    token: string;

    process: any
};

class MyAccountActions {

    apiResponseSuccess = (actionType: string, data: MyAccount | {}): MyAccountActionType => ({
        type: MyAccountActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: any): MyAccountActionType => ({
        type: MyAccountActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: any): MyAccountActionType => ({
        type: MyAccountActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): MyAccountActionType => ({
        type: MyAccountActionTypes.RESET,
        payload: {},
    });

    sendActivationEmailLink = (): MyAccountActionType => ({
        type: MyAccountActionTypes.SEND_VERIFICATION_EMAIL_LINK,
        payload: {},
    });

    verifyEmail = (token: string): MyAccountActionType => ({
        type: MyAccountActionTypes.VERIFY_EMAIL,
        payload: {token},
    });

    getVerificationStepsStatus = (): MyAccountActionType => ({
        type: MyAccountActionTypes.GET_VERIFICATION_STEPS_STATUS,
        payload: {},
    });

    getProfileImage = (): MyAccountActionType => ({
        type: MyAccountActionTypes.GET_PROFILE_IMAGE,
        payload: {},
    });

    uploadProfileImage = (): MyAccountActionType => ({
        type: MyAccountActionTypes.UPLOAD_PROFILE_IMAGE,
        payload: {},
    });

    updateAccount = (data: any): MyAccountActionType => ({
        type: MyAccountActionTypes.UPDATE_ACCOUNT,
        payload: { data },
    });

}

const myAccountActions = new MyAccountActions();
export { myAccountActions };
