import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { graphql } from 'helpers';
import {ProductActionTypes, ProductErrors} from './constants';
import {
    checkResponseError,
    checkServerError,
    pluckResponse,
} from "../../helpers/functions";
import {productActions} from "./actions";

type ProductData = {
    payload: {

        queryParams: {
            limit: string;
            page: string;
            orderBy: string;
            sort: string;
        };

        data: any;
        id: any;
        field: any;
    };
    type: string;
};



function* getProducts({ payload: { queryParams } }: ProductData): SagaIterator {

    try {
        const param = {
            query:`query Products($page: Int!, $limit: Int, $orderBy: String!, $sort: String!) {
                products(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort) {
                    data {
                        id
                        name
                        description
                        summary
                        status
                        price
                        sale_price
                        currency
                        on_sale
                        sku
                        tax_status
                        weight
                        length
                        width
                        height
                        is_virtual
                        is_downloadable
                        stock
                        in_stock
                        
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ProductErrors.RESPONSE_200);

        const rProducts = response.data;

        checkServerError(rProducts);

        const products = pluckResponse(rProducts, "products");

        const result = {
            products,
            queryParams
        }

        yield put(productActions.apiResponseSuccess(ProductActionTypes.GET_PRODUCTS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(productActions.apiResponseError(ProductActionTypes.GET_PRODUCTS, error));
        } else {
            yield put(productActions.apiResponseValidationErrors(ProductActionTypes.GET_PRODUCTS, error));
        }
    }
}

function* getProduct({ payload: { id, field } }: ProductData): SagaIterator {
    try {
        const param = (field === "id")? {
            query:`query ProductById($id: String!) {
                productById(id: $id) {
                    id
                    name
                    description
                    summary
                    status
                    price
                    fee_percent
                    sale_price
                    currency
                    on_sale
                    sku
                    tax_status
                    weight
                    length
                    width
                    height
                    is_virtual
                    is_downloadable
                    stock
                    in_stock
                    created_at
                }
            }`,

            variables: {
                id: id
            }
        }: {
            query:`query Product($id: String!) {
                product(id: $id) {
                    id
                    name
                    description
                    summary
                    status
                    price
                    sale_price
                    currency
                    on_sale
                    on_sale_start_date
                    on_sale_end_date
                    sku
                    tax_status
                    fee_percent
                    weight
                    length
                    width
                    height
                    is_virtual
                    is_downloadable
                    stock
                    in_stock
                    created_at
                }
            }`,

            variables: {
                id: id
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ProductErrors.RESPONSE_200);

        const rT = response.data;

        checkServerError(rT);

        const product = (field === "id")? pluckResponse(rT, "productById"): pluckResponse(rT, "product");

        yield put(productActions.apiResponseSuccess(ProductActionTypes.GET_PRODUCT, product));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(productActions.apiResponseError(ProductActionTypes.GET_PRODUCT, error));
        } else {
            yield put(productActions.apiResponseValidationErrors(ProductActionTypes.GET_PRODUCT, error));
        }
    }
}
function* insertProduct({ payload: {data} }: ProductData): SagaIterator {

    try {
        const param = {
            query:`mutation CreateProduct(
                $name: String!,
                $description: String,
                $summary: String,
                $status: String!,
                $price: Float!,
                $currency: String!,
                $fee_percent: Float!,
                $on_sale: String,
                $sale_price: Float,
                $on_sale_start_date: String,
                $on_sale_end_date: String,
                $sku: String,
                $tax_status: String,
                $weight: String,
                $length: String,
                $width: String,
                $height: String,
                $is_virtual: String,
                $is_downloadable: String,
                $stock: Int,
                $in_stock: String,
                $categories: [Int]
            ) {
                createProduct(
                    name: $name
                    description: $description
                    summary: $summary
                    status: $status
                    price: $price
                    currency: $currency
                    fee_percent: $fee_percent
                    on_sale: $on_sale
                    sale_price: $sale_price
                    on_sale_start_date: $on_sale_start_date
                    on_sale_end_date: $on_sale_end_date
                    sku: $sku
                    tax_status: $tax_status
                    weight: $weight
                    length: $length
                    width: $width
                    height: $height
                    is_virtual: $is_virtual
                    is_downloadable: $is_downloadable
                    stock: $stock
                    in_stock: $in_stock
                    categories: $categories
                )
            }`,

            variables: {
                ...data,
                fee_percent: data.feePercent,
                on_sale: data.onSaleStatus,
                sale_price: data.salePrice,
                on_sale_start_date: data.onSaleStartDate || "",
                on_sale_end_date: data.onSaleStartDate || "",
                status: data.productStatus || "",
                in_stock: data.stockStatus || "",
                is_virtual: "yes",
                is_downloadable: "no",
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ProductErrors.RESPONSE_200);

        const rCD = response.data;

        checkServerError(rCD);

        const createProduct = pluckResponse(rCD, "createProduct");

        yield put(productActions.apiResponseSuccess(ProductActionTypes.INSERT_PRODUCT, createProduct));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(productActions.apiResponseError(ProductActionTypes.INSERT_PRODUCT, error));
        } else {
            yield put(productActions.apiResponseValidationErrors(ProductActionTypes.INSERT_PRODUCT, error));
        }
    }
}
function* updateProduct({ payload: {data} }: ProductData): SagaIterator {

    try {
        const param = {
            query:`mutation UpdateProduct(
                $id: Int!,
                $name: String!,
                $description: String,
                $summary: String,
                $status: String!,
                $price: Float!,
                $currency: String!,
                $fee_percent: Float!,
                $on_sale: String,
                $sale_price: Float,
                $on_sale_start_date: String,
                $on_sale_end_date: String,
                $sku: String,
                $tax_status: String,
                $weight: String,
                $length: String,
                $width: String,
                $height: String,
                $is_virtual: String,
                $is_downloadable: String,
                $stock: Int,
                $in_stock: String,
                $categories: [Int]
            ) {
                updateProduct(
                    id: $id
                    name: $name
                    description: $description
                    summary: $summary
                    status: $status
                    price: $price
                    currency: $currency
                    fee_percent: $fee_percent
                    on_sale: $on_sale
                    sale_price: $sale_price
                    on_sale_start_date: $on_sale_start_date
                    on_sale_end_date: $on_sale_end_date
                    sku: $sku
                    tax_status: $tax_status
                    weight: $weight
                    length: $length
                    width: $width
                    height: $height
                    is_virtual: $is_virtual
                    is_downloadable: $is_downloadable
                    stock: $stock
                    in_stock: $in_stock
                    categories: $categories
                )
            }`,

            variables: {
                ...data,
                fee_percent: data.feePercent,
                on_sale: data.onSaleStatus,
                sale_price: data.salePrice,
                on_sale_start_date: data.onSaleStartDate || "",
                on_sale_end_date: data.onSaleStartDate || "",
                status: data.productStatus || "",
                in_stock: data.stockStatus || "",
                is_virtual: "yes",
                is_downloadable: "no",
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ProductErrors.RESPONSE_200);

        const rCD = response.data;

        checkServerError(rCD);

        const updateProduct = pluckResponse(rCD, "updateProduct");

        yield put(productActions.apiResponseSuccess(ProductActionTypes.UPDATE_PRODUCT, updateProduct));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(productActions.apiResponseError(ProductActionTypes.UPDATE_PRODUCT, error));
        } else {
            yield put(productActions.apiResponseValidationErrors(ProductActionTypes.UPDATE_PRODUCT, error));
        }
    }
}

function* searchProduct({ payload: { queryParams } }: ProductData): SagaIterator {
    try {
        const param = {
            query:`query products(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $productId: String,
                $wallet: String, 
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $accountId: String
            ) {
                products(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort,
                    productId: $productId,
                    wallet: $wallet,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    accountId: $accountId,
                ) {
                    data {
                        id
                        from {
                            name
                            balanceFloatNum
                            meta {
                                sign
                                currency
                            }
                            holder {
                                username
                                email
                            }
                        }
                        to {
                            name
                            balanceFloatNum
                            meta {
                                sign
                                currency
                            }
                            holder {
                                username
                                email
                            }
                        }
                        withdraw {
                            amountFloat
                            meta {
                                description
                            }
                        }
                        deposit {
                            amountFloat
                            meta {
                                description
                            }
                            rate
                        }
                        fee
                        feeFloat
                        status
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ProductErrors.RESPONSE_200);

        const rProducts = response.data;

        checkServerError(rProducts);

        const products = pluckResponse(rProducts, "products");

        const result = {
            products,
            queryParams
        }

        yield put(productActions.apiResponseSuccess(ProductActionTypes.SEARCH_PRODUCT, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(productActions.apiResponseError(ProductActionTypes.SEARCH_PRODUCT, error));
        } else {
            yield put(productActions.apiResponseValidationErrors(ProductActionTypes.SEARCH_PRODUCT, error));
        }
    }
}

export function* watchGetProducts() {
    yield takeEvery(ProductActionTypes.GET_PRODUCTS, getProducts);
}

export function* watchGetProduct() {
    yield takeEvery(ProductActionTypes.GET_PRODUCT, getProduct);
}

export function* watchInsertProduct() {
    yield takeEvery(ProductActionTypes.INSERT_PRODUCT, insertProduct);
}

export function* watchUpdateProduct() {
    yield takeEvery(ProductActionTypes.UPDATE_PRODUCT, updateProduct);
}

export function* watchSearchProduct() {
    yield takeEvery(ProductActionTypes.SEARCH_PRODUCT, searchProduct);
}

function* productsSaga() {
    yield all([
        fork(watchGetProducts),
        fork(watchGetProduct),
        fork(watchInsertProduct),
        fork(watchUpdateProduct),
        fork(watchSearchProduct),
    ]);
}

export default productsSaga;
