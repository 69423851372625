import {ManageVerificationActionTypes} from './constants';

const INIT_STATE = {
    loading: false,
    error: "",
    validationErrors: {},

    user: {},
};

type ManageVerificationActionType = {
    type:
        | ManageVerificationActionTypes.API_RESPONSE_SUCCESS
        | ManageVerificationActionTypes.API_RESPONSE_ERROR
        | ManageVerificationActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | ManageVerificationActionTypes.RESET

        | ManageVerificationActionTypes.GET_VERIFICATION_ITEMS
    ;

    payload: {
        actionType?: string;
        data?: any;
        error?: any;
        validationErrors?: any;

        key?: any;
        value?: any;

        // for upload purpose
        file: {fileId: string} & File;
        fileId: string;
        progress: number;
        retry: boolean;
    };
};

type State = {
    user: any;
};

const ManageVerification = (state: State = INIT_STATE, action: ManageVerificationActionType) => {
    switch (action.type) {
        case ManageVerificationActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case ManageVerificationActionTypes.GET_VERIFICATION_ITEMS: {

                    return {
                        ...state,
                        loading: false,
                        user: action.payload.data,
                    };
                }

                default:
                    return { ...state };
            }

        case ManageVerificationActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ManageVerificationActionTypes.GET_VERIFICATION_ITEMS:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case ManageVerificationActionTypes.API_RESPONSE_VALIDATION_ERRORS:
            switch (action.payload.actionType) {
                case ManageVerificationActionTypes.GET_VERIFICATION_ITEMS:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ManageVerificationActionTypes.RESET:
            return {
                ...state,

                loading: false,
                error: "",
                validationErrors: {},

                user: {},
            };

        case ManageVerificationActionTypes.GET_VERIFICATION_ITEMS:
            return {
                ...state,
                loading: true,
                error: "",
                user: {},
            };

        default:
            return { ...state };
    }
};

export default ManageVerification;
