import { ExchangeActionTypes } from './constants';
import {isEmptyObject} from "../../helpers/functions";

const INIT_STATE = {
    transactions: {},

    exchange: {},

    exchanges: {},
    queryParams: {},

    loading: false,
    error: "",
    validationErrors: {},

    exchangeChecked: false,

    exchangeInserted: false,

    usdRate: {},
    usdRateLoading: false,
    usdRateError: "",

    otpSent: false,
    otpSendLoading: false,
    otpSendError: "",

    searchModal: false,
    tmpExchanges: {},
    tmpQueryParams: {},
    searchMode: false,
    searchLoading: false,
    searchError: "",
    searchValidationErrors: {},

    tmpTransactions: {},

    previewDetail: {},
    previewDetailLoading: false,
    previewDetailError: "",

    exportUrl: "",
    exportLoading: false,
    exportError: "",
    exportValidationErrors: [],
};


type ExchangeActionType = {
    type:
        | ExchangeActionTypes.API_RESPONSE_SUCCESS
        | ExchangeActionTypes.API_RESPONSE_ERROR
        | ExchangeActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | ExchangeActionTypes.GET_EXCHANGES
        | ExchangeActionTypes.GET_EXCHANGE
        | ExchangeActionTypes.GET_TRANSACTIONS
        | ExchangeActionTypes.CHECK_EXCHANGE
        | ExchangeActionTypes.INSERT_EXCHANGE
        | ExchangeActionTypes.RESET

        | ExchangeActionTypes.OPEN_SEARCH
        | ExchangeActionTypes.CLOSE_SEARCH
        | ExchangeActionTypes.SEARCH_EXCHANGE
        | ExchangeActionTypes.SEARCH_TRANSACTION
        | ExchangeActionTypes.CLEAR_SEARCH
        | ExchangeActionTypes.RESET_SEARCH

        | ExchangeActionTypes.CLEAR_CHECKED

        | ExchangeActionTypes.USD_RATE
        | ExchangeActionTypes.PREVIEW_EXCHANGE_DETAIL
        | ExchangeActionTypes.RESET_PREVIEW_DETAIL

        | ExchangeActionTypes.EXPORT_TRANSACTIONS
        | ExchangeActionTypes.EXPORT;

    payload: {
        actionType?: string;
        data?: any;
        error?: string;
        validationErrors?: [];
    };
};

type State = {
    exchanges?: {};

    queryParams?: {};

    loading?: boolean;
    value?: boolean;
    tmpExchanges?: any;

    tmpQueryParams?: any;

    transactions?: any;
    tmpTransactions?: any;
};

const Exchange = (state: State = INIT_STATE, action: ExchangeActionType) => {
    switch (action.type) {
        case ExchangeActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ExchangeActionTypes.GET_EXCHANGES: {
                    return {
                        ...state,
                        exchanges: action.payload.data?.exchanges,
                        queryParams: action.payload.data?.queryParams,
                        loading: false,
                    };
                }
                case ExchangeActionTypes.GET_EXCHANGE: {
                    return {
                        ...state,
                        exchange: action.payload.data,
                        loading: false,
                    };
                }
                case ExchangeActionTypes.GET_TRANSACTIONS: {
                    return {
                        ...state,
                        transactions: action.payload.data?.transactions,
                        queryParams: action.payload.data?.queryParams,
                        loading: false,
                    };
                }
                case ExchangeActionTypes.INSERT_EXCHANGE: {
                    return {
                        ...state,
                        exchangeInserted: action.payload.data,
                        loading: false,
                    };
                }
                case ExchangeActionTypes.CHECK_EXCHANGE: {
                    return {
                        ...state,
                        exchangeChecked: action.payload.data,
                        loading: false,
                    };
                }
                case ExchangeActionTypes.SEARCH_EXCHANGE: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpExchanges: isEmptyObject(state.tmpExchanges)? state.exchanges: state.tmpExchanges,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        exchanges: action.payload.data?.exchanges,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                case ExchangeActionTypes.EXPORT_TRANSACTIONS:
                case ExchangeActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportUrl: action.payload.data,
                        exportLoading: false,

                        searchMode: false,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                case ExchangeActionTypes.USD_RATE: {
                    return {
                        ...state,
                        usdRate: action.payload.data,
                        usdRateLoading: false,
                    };
                }
                case ExchangeActionTypes.PREVIEW_EXCHANGE_DETAIL: {
                    return {
                        ...state,
                        previewDetail: action.payload.data,
                        previewDetailLoading: false,
                    };
                }
                case ExchangeActionTypes.SEARCH_TRANSACTION: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpTransactions: isEmptyObject(state.tmpTransactions)? state.transactions: state.tmpTransactions,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        transactions: action.payload.data?.transactions,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ExchangeActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ExchangeActionTypes.GET_EXCHANGE:
                case ExchangeActionTypes.GET_EXCHANGES:
                case ExchangeActionTypes.CHECK_EXCHANGE:
                case ExchangeActionTypes.INSERT_EXCHANGE:
                case ExchangeActionTypes.GET_TRANSACTIONS:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case ExchangeActionTypes.USD_RATE:
                {
                    return {
                        ...state,
                        usdRateError: action.payload.error,
                        usdRateLoading: false,
                    };
                }
                case ExchangeActionTypes.PREVIEW_EXCHANGE_DETAIL:
                {
                    return {
                        ...state,
                        previewDetailError: action.payload.error,
                        previewDetailLoading: false,
                    };
                }
                case ExchangeActionTypes.SEARCH_EXCHANGE:
                case ExchangeActionTypes.SEARCH_TRANSACTION: {
                    return {
                        ...state,
                        searchError: action.payload.error,
                        searchLoading: false,
                    };
                }
                case ExchangeActionTypes.EXPORT_TRANSACTIONS:
                case ExchangeActionTypes.EXPORT:
                {
                    return {
                        ...state,
                        exportError: action.payload.error,
                        exportLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ExchangeActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case ExchangeActionTypes.GET_EXCHANGES:
                case ExchangeActionTypes.GET_TRANSACTIONS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case ExchangeActionTypes.CHECK_EXCHANGE:
                case ExchangeActionTypes.INSERT_EXCHANGE:
                case ExchangeActionTypes.USD_RATE:
                case ExchangeActionTypes.PREVIEW_EXCHANGE_DETAIL:
                case ExchangeActionTypes.GET_EXCHANGE:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case ExchangeActionTypes.SEARCH_EXCHANGE:
                case ExchangeActionTypes.SEARCH_TRANSACTION: {
                    return {
                        ...state,
                        searchValidationErrors: action.payload.validationErrors,
                        searchLoading: false,
                    };
                }
                case ExchangeActionTypes.EXPORT_TRANSACTIONS:
                case ExchangeActionTypes.EXPORT:
                {
                    return {
                        ...state,
                        exportValidationErrors: action.payload.validationErrors,
                        exportLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ExchangeActionTypes.GET_TRANSACTIONS:
            return {
                ...state,
                // transactions: {},
                loading: true,
                error: "",
            };

        case ExchangeActionTypes.GET_EXCHANGES:
            return {
                ...state,
                // exchanges: {},
                loading: true,
                error: "",
            };

        case ExchangeActionTypes.GET_EXCHANGE:
            return {
                ...state,
                exchange: {},
                loading: true,
                error: "",
            };

        case ExchangeActionTypes.INSERT_EXCHANGE:
            return {
                ...state,
                exchangeInserted: false,
                loading: true,
                error: "",
            };

        case ExchangeActionTypes.CHECK_EXCHANGE:
            return {
                ...state,
                exchangeChecked: false,
                loading: true,
                error: "",
            };

        case ExchangeActionTypes.RESET:
            return {
                ...state,
                transactions: {},

                exchange: {},

                exchanges: {},
                queryParams: {},

                loading: false,
                error: "",
                validationErrors: {},

                usdRateLoading: false,
                usdRateError: "",

                exchangeChecked: false,

                exchangeInserted: false,

                searchModal: false,
                searchLoading: false,
                searchMode: false,
                searchError: "",
                searchValidationErrors: {},

                tmpExchanges: {},
                tmpQueryParams: {},

                tmpTransactions: {},

                otpSent: false,
                otpSendLoading: false,
                otpSendError: "",

                previewDetail: {},
                previewDetailLoading: false,
                previewDetailError: "",

                exportUrl: "",
                exportLoading: false,
                exportError: "",
                exportValidationErrors: [],
            };


        // search actions
        case ExchangeActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case ExchangeActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},
            };
        case ExchangeActionTypes.SEARCH_EXCHANGE:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case ExchangeActionTypes.SEARCH_TRANSACTION:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case ExchangeActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                exchanges: state.tmpExchanges,
                transactions: state.tmpTransactions,
                queryParams: state.tmpQueryParams,
                tmpExchanges: {},
                tmpTransactions: {},
                tmpQueryParams: {},
                searchMode: false,
            };
        case ExchangeActionTypes.EXPORT:

            return {
                ...state,
                exportUrl: "",
                exportLoading: true,
                exportError: "",
                exportValidationErrors: [],
            };
        case ExchangeActionTypes.EXPORT_TRANSACTIONS:

            return {
                ...state,
                exportUrl: "",
                exportLoading: true,
                exportError: "",
                exportValidationErrors: [],
            };

        case ExchangeActionTypes.RESET_SEARCH:

            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},

                exportUrl: "",
                exportLoading: false,
                exportError: "",
                exportValidationErrors: [],
            }





        case ExchangeActionTypes.USD_RATE:
            return {
                ...state,
                usdRate: {},
                usdRateLoading: true,
                usdRateError: "",
            };


        case ExchangeActionTypes.PREVIEW_EXCHANGE_DETAIL:
            return {
                ...state,
                previewDetail: {},
                previewDetailLoading: true,
                previewDetailError: "",
            };

        case ExchangeActionTypes.RESET_PREVIEW_DETAIL:
            return {
                ...state,
                previewDetail: {},
                previewDetailLoading: false,
                previewDetailError: "",
            };

        case ExchangeActionTypes.CLEAR_CHECKED:
            return {
                ...state,
                exchangeChecked: false
            };

        default:
            return { ...state };
    }
};

export default Exchange;
