
export enum PersonalDetailActionTypes {
    API_RESPONSE_SUCCESS = '@@personalDetail/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@personalDetail/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@personalDetail/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@personalDetail/RESET',

    GET_PERSONAL_DETAILS = '@@personalDetail/GET_PERSONAL_DETAILS',
    GET_PERSONAL_DETAIL = '@@personalDetail/GET_PERSONAL_DETAIL',
    UPDATE_PERSONAL_DETAIL = '@@personalDetail/UPDATE_PERSONAL_DETAIL',

    UPDATE_STATUS = '@@personalDetail/UPDATE_STATUS',

    SET_PERSONAL_DETAIL = '@@personalDetail/SET_PERSONAL_DETAIL',

    UPDATE_MY_PERSONAL_DETAIL = '@@personalDetail/UPDATE_MY_PERSONAL_DETAIL',
}

export enum PersonalDetailErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



