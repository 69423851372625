export enum RoleActionTypes {
    API_RESPONSE_SUCCESS = '@@role/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@role/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@role/API_RESPONSE_VALIDATION_ERRORS',

    FIND_ROLE = '@@role/FIND_ROLE',
    GET_ROLES = '@@role/GET_ROLES',
    NEW_ROLE = '@@role/NEW_ROLE',
    INSERT_ROLE = '@@role/INSERT_ROLE',
    EDIT_ROLE = '@@role/EDIT_ROLE',
    UPDATE_ROLE = '@@role/UPDATE_ROLE',
    DELETE_ROLE = '@@role/DELETE_ROLE',

    OPEN_SEARCH = '@@role/OPEN_SEARCH',
    CLOSE_SEARCH = '@@role/CLOSE_SEARCH',
    SEARCH_ROLE = '@@role/SEARCH_ROLE',
    CLEAR_SEARCH = '@@role/CLEAR_SEARCH',

    SET_VARIABLE = '@@role/SET_VARIABLE',
    RESET = '@@role/RESET',
}

export enum RoleErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



