import {UserActionTypes} from './constants';

export type UserActionType = {
    type:
        | UserActionTypes.API_RESPONSE_SUCCESS
        | UserActionTypes.API_RESPONSE_ERROR
        | UserActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | UserActionTypes.GET_USERS
        | UserActionTypes.GET_USER
        | UserActionTypes.GET_USER_BY_ACCOUNT_ID
        | UserActionTypes.INSERT_USER
        | UserActionTypes.DELETE_USER
        | UserActionTypes.UPDATE_USER

        | UserActionTypes.OPEN_SEARCH
        | UserActionTypes.CLOSE_SEARCH
        | UserActionTypes.SEARCH_USER
        | UserActionTypes.CLEAR_SEARCH

        | UserActionTypes.GET_MERCHANT_SETTING
        | UserActionTypes.UPDATE_MERCHANT_SETTING

        | UserActionTypes.UPDATE_MOBILE_OWNERSHIP

        | UserActionTypes.EXPORT

        | UserActionTypes.RESET;

    payload: {} | string;
};

type User = {
    id: number;
    email: string;
    emailVerify: string;
    userStatus: string;
    accountId: string;
    password: string;
    role: string;
    token: string;
};

type QueryParams = {
    page: number;
    limit: number;
    orderBy: string,
    sort: string,

    accountId?: string,
    name?: string,
    email?: string,
    registeredFrom?: string,
    registeredTo?: string,
    roles?: string[],
    userStatus?: string,

    nationalCode?: string,
    firstName?: string,
    lastName?: string,
    firstNameEn?: string,
    lastNameEn?: string,
}

class UserActions {

    apiResponseSuccess = (actionType: string, data: User | {}): UserActionType => ({
        type: UserActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): UserActionType => ({
        type: UserActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): UserActionType => ({
        type: UserActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): UserActionType => ({
        type: UserActionTypes.RESET,
        payload: {},
    });

    getUsers = (queryParams: QueryParams): UserActionType => ({
        type: UserActionTypes.GET_USERS,
        payload: { queryParams },
    });

    get = (id: string) : UserActionType => ({
        type: UserActionTypes.GET_USER,
        payload: { id }
    })

    getUserByAccountId = (accountId: string): UserActionType => ({
        type: UserActionTypes.GET_USER_BY_ACCOUNT_ID,
        payload: { accountId },
    });

    insertUser = (
        accountId: string,
        email: string,
        verifyEmail: string,
        mobile: string,
        verifyMobile: string,
        userStatus: string,
        password: string,
        display_name: string,
        roles: string[],
        language: string,
        calendar: string,
        timezone: string,

    ): UserActionType => ({
        type: UserActionTypes.INSERT_USER,
        payload: {
            accountId,
            email,
            verifyEmail,
            mobile,
            verifyMobile,
            userStatus,
            password,
            display_name,
            roles,
            language,
            calendar,
            timezone,
        }
    })

    delete = (id: string) : UserActionType => ({
        type: UserActionTypes.DELETE_USER,
        payload: { id }
    })

    updateUser = (
        id: string,
        accountId: string,
        email: string,
        verifyEmail: string,
        mobile: string,
        verifyMobile: string,
        userStatus: string,
        password: string,
        display_name: string,
        roles: string[],
        language: string,
        calendar: string,
        timezone: string,
        twoFaStatus: string,

    ): UserActionType => ({
        type: UserActionTypes.UPDATE_USER,
        payload: {
            id,
            accountId,
            email,
            verifyEmail,
            mobile,
            verifyMobile,
            userStatus,
            password,
            display_name,
            roles,
            language,
            calendar,
            timezone,
            twoFaStatus,
        }
    })

    openSearch = (): UserActionType => ({
        type: UserActionTypes.OPEN_SEARCH,
        payload: {}
    })

    closeSearch = (): UserActionType => ({
        type: UserActionTypes.CLOSE_SEARCH,
        payload: {}
    })

    search = (queryParams: QueryParams): UserActionType => ({
        type: UserActionTypes.SEARCH_USER,
        payload: { queryParams }
    })

    clearSearch = (): UserActionType => ({
        type: UserActionTypes.CLEAR_SEARCH,
        payload: {}
    })

    getMerchantSettings = (id: string) : UserActionType => ({
        type: UserActionTypes.GET_MERCHANT_SETTING,
        payload: { id }
    })

    updateMerchantSettings = (id: string, paymentDepositFee: string, paymentWithdrawFee: string) : UserActionType => ({
        type: UserActionTypes.UPDATE_MERCHANT_SETTING,
        payload: { id, paymentDepositFee, paymentWithdrawFee }
    })

    updateMobileOwnershipStatus = (data: any): UserActionType => ({
        type: UserActionTypes.UPDATE_MOBILE_OWNERSHIP,
        payload: {data},
    });

    export = (queryParams: QueryParams): UserActionType => ({
        type: UserActionTypes.EXPORT,
        payload: { queryParams }
    });
}

const userActions = new UserActions();
export { userActions };
