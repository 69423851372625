import { MobileNumberActionTypes } from './constants';

export type MobileNumberActionType = {
    type:
        | MobileNumberActionTypes.API_RESPONSE_SUCCESS
        | MobileNumberActionTypes.API_RESPONSE_ERROR
        | MobileNumberActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | MobileNumberActionTypes.RESET

        | MobileNumberActionTypes.GET_MOBILE_NUMBERS
        | MobileNumberActionTypes.GET_MOBILE_NUMBER
        | MobileNumberActionTypes.UPDATE_MOBILE_NUMBER

        | MobileNumberActionTypes.SEND_OTP
        | MobileNumberActionTypes.CLEAR_OTP_MOBILE
        | MobileNumberActionTypes.VERIFY_OTP
        | MobileNumberActionTypes.SET_OTP;

    payload: {} | string;
};

type MobileNumber = {
    id: number;
    email: string;
    emailVerify: string;
    role: string;
    token: string;

    process: any
};

class MobileNumberActions {

    apiResponseSuccess = (actionType: string, data: MobileNumber | {}): MobileNumberActionType => ({
        type: MobileNumberActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: any): MobileNumberActionType => ({
        type: MobileNumberActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: any): MobileNumberActionType => ({
        type: MobileNumberActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): MobileNumberActionType => ({
        type: MobileNumberActionTypes.RESET,
        payload: {},
    });

    getMobileNumbers = (queryParams: any): MobileNumberActionType => ({
        type: MobileNumberActionTypes.GET_MOBILE_NUMBERS,
        payload: {queryParams},
    });

    getMobileNumber = (id: string): MobileNumberActionType => ({
        type: MobileNumberActionTypes.GET_MOBILE_NUMBER,
        payload: {id},
    });

    updateMobileNumber = (data: any): MobileNumberActionType => ({
        type: MobileNumberActionTypes.UPDATE_MOBILE_NUMBER,
        payload: {data},
    });

    sendOtp = (data: any): MobileNumberActionType => ({
        type: MobileNumberActionTypes.SEND_OTP,
        payload: {data},
    });

    clearOtpMobile = (): MobileNumberActionType => ({
        type: MobileNumberActionTypes.CLEAR_OTP_MOBILE,
        payload: {},
    });

    verifyOtp = (data: any): MobileNumberActionType => ({
        type: MobileNumberActionTypes.VERIFY_OTP,
        payload: {data},
    });

    setOtp = (otp: string): MobileNumberActionType => ({
        type: MobileNumberActionTypes.SET_OTP,
        payload: {otp},
    });
}

const mobileNumberActions = new MobileNumberActions();
export { mobileNumberActions };
