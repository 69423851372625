export enum OrderActionTypes {
    API_RESPONSE_SUCCESS = '@@order/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@order/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@order/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@order/RESET',

    ORDERS = '@@order/ORDERS',
    ORDER = '@@order/ORDER',
    CREATE = '@@order/CREATE',
    UPDATE = '@@order/UPDATE',
    DELETE = '@@order/DELETE',
    SEARCH = '@@order/SEARCH',
}

export enum OrderErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.'
}



