
export enum NCardFrontActionTypes {
    API_RESPONSE_SUCCESS = '@@nCFront/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@nCFront/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@nCFront/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@nCFront/RESET',

    SET_NC_FRONT = '@@nCFront/SET_NC_FRONT',

    UPLOAD_NC_FRONT = '@@nCFront/UPLOAD_NC_FRONT',
    UPLOAD_NC_FRONT_PROGRESS = '@@nCFront/UPLOAD_NC_FRONT_PROGRESS',
    DELETE_NC_FRONT = '@@nCFront/DELETE_NC_FRONT',

    UPDATE = '@@nCFront/UPDATE',
}

export enum NCardFrontErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



