import {UserActionTypes} from './constants';
import {isEmptyObject} from "../../helpers/functions";

const INIT_STATE = {
    users: {},
    queryParams: {},

    user: {},

    loading: false,
    error: "",
    validationErrors: {},
    userRegistered: false,
    userUpdated: false,

    searchModal: false,
    tmpUsers: {},
    tmpQueryParams: {},
    searchMode: false,
    searchLoading: false,
    searchError: "",
    searchValidationErrors: {},

    merchantSettings: {},

    merchantSettingsUpdated: false,

    updatedMobileOwnership: false,

    exportUrl: "",
    exportLoading: false,
    exportError: "",
    exportValidationErrors: [],
};

type UserData = {
    id: number;
    email: string;
    username: string;
    password: string;
    token: string;
};

type UserActionType = {
    type:
        | UserActionTypes.API_RESPONSE_SUCCESS
        | UserActionTypes.API_RESPONSE_ERROR
        | UserActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | UserActionTypes.GET_USERS
        | UserActionTypes.GET_USER
        | UserActionTypes.GET_USER_BY_ACCOUNT_ID
        | UserActionTypes.INSERT_USER
        | UserActionTypes.UPDATE_USER

        | UserActionTypes.OPEN_SEARCH
        | UserActionTypes.CLOSE_SEARCH
        | UserActionTypes.SEARCH_USER
        | UserActionTypes.CLEAR_SEARCH

        | UserActionTypes.GET_MERCHANT_SETTING

        | UserActionTypes.UPDATE_MERCHANT_SETTING

        | UserActionTypes.UPDATE_MOBILE_OWNERSHIP

        | UserActionTypes.RESET
        | UserActionTypes.EXPORT;

    payload: {
        actionType?: string;
        data?: any;
        error?: string;
        validationErrors?: [];

        key?: any
        value?: any
    };
};

type State = {
    users?: UserData | {};

    tmpUsers?: any;

    loading?: boolean;

    queryParams?: {};

    tmpQueryParams?: any;
};

const User = (state: State = INIT_STATE, action: UserActionType) => {
    switch (action.type) {
        case UserActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case UserActionTypes.GET_USERS: {
                    return {
                        ...state,
                        users: action.payload.data.users,
                        queryParams: action.payload.data.queryParams,
                        loading: false,
                    };
                }
                case UserActionTypes.GET_USER: {
                    return {
                        ...state,
                        user: action.payload.data,
                        loading: false,
                    };
                }
                case UserActionTypes.GET_USER_BY_ACCOUNT_ID: {
                    return {
                        ...state,
                        user: action.payload.data,
                        loading: false,
                    };
                }
                case UserActionTypes.INSERT_USER: {
                    return {
                        ...state,
                        userRegistered: true,
                        loading: false,
                    };
                }
                case UserActionTypes.UPDATE_USER: {
                    return {
                        ...state,
                        userUpdated: true,
                        loading: false,
                    };
                }
                case UserActionTypes.SEARCH_USER: {
                    return {
                        ...state,
                        tmpUsers: isEmptyObject(state.tmpUsers)? state.users: state.tmpUsers,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        users: action.payload.data.users,
                        queryParams: action.payload.data.queryParams,

                        searchMode: true,
                        searchModal: false,
                        searchLoading: false,
                    };
                }

                case UserActionTypes.GET_MERCHANT_SETTING: {
                    return {
                        ...state,
                        merchantSettings: action.payload.data,
                        loading: false,
                    };
                }
                case UserActionTypes.UPDATE_MERCHANT_SETTING: {
                    return {
                        ...state,
                        merchantSettingsUpdated: action.payload.data,
                        loading: false,
                    };
                }
                case UserActionTypes.UPDATE_MOBILE_OWNERSHIP: {
                    return {
                        ...state,
                        loading: false,
                        updatedMobileOwnership: true,
                    };
                }
                case UserActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportUrl: action.payload.data,
                        exportLoading: false,

                        searchMode: false,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case UserActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case UserActionTypes.GET_USERS:
                case UserActionTypes.GET_USER:
                case UserActionTypes.GET_USER_BY_ACCOUNT_ID:
                case UserActionTypes.INSERT_USER:
                case UserActionTypes.UPDATE_USER:
                case UserActionTypes.GET_MERCHANT_SETTING:
                case UserActionTypes.UPDATE_MERCHANT_SETTING:
                case UserActionTypes.UPDATE_MOBILE_OWNERSHIP:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case UserActionTypes.SEARCH_USER: {
                    return {
                        ...state,
                        searchError: action.payload.error,
                        searchLoading: false,
                    };
                }
                case UserActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportError: action.payload.error,
                        exportLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case UserActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case UserActionTypes.GET_USERS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case UserActionTypes.GET_USER:
                case UserActionTypes.GET_USER_BY_ACCOUNT_ID:
                case UserActionTypes.INSERT_USER:
                case UserActionTypes.UPDATE_USER:
                case UserActionTypes.GET_MERCHANT_SETTING:
                case UserActionTypes.UPDATE_MERCHANT_SETTING:
                case UserActionTypes.UPDATE_MOBILE_OWNERSHIP:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case UserActionTypes.SEARCH_USER: {
                    return {
                        ...state,
                        searchValidationErrors: action.payload.validationErrors,
                        searchLoading: false,
                    };
                }
                case UserActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportValidationErrors: action.payload.validationErrors,
                        exportLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case UserActionTypes.GET_USERS:
            return {
                ...state,
                // users: {},
                loading: true,
                error: "",
            };
        case UserActionTypes.GET_USER:
            return {
                ...state,
                user: {},
                loading: true,
                error: "",
            };
        case UserActionTypes.GET_USER_BY_ACCOUNT_ID:
            return {
                ...state,
                user: {},
                loading: true,
                error: "",
            };

        case UserActionTypes.INSERT_USER:
            return {
                ...state,
                userRegistered: false,
                loading: true,
                error: "",
            };

        case UserActionTypes.UPDATE_USER:
            return {
                ...state,
                userUpdated: false,
                loading: true,
                error: "",
            };

        case UserActionTypes.RESET:
            return {
                ...state,
                users: {},
                queryParams: {},

                user: {},

                loading: false,
                error: "",
                validationErrors: {},
                userRegistered: false,
                userUpdated: false,

                searchModal: false,
                searchLoading: false,
                searchMode: false,
                searchError: "",
                searchValidationErrors: {},

                tmpUsers: {},
                tmpQueryParams: {},

                merchantSettings: {},
                merchantSettingsUpdated: false,

                updatedMobileOwnership: false,

                exportUrl: "",
                exportLoading: false,
                exportError: "",
                exportValidationErrors: [],
            };


        // search actions
        case UserActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case UserActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},
            };
        case UserActionTypes.SEARCH_USER:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case UserActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                users: state.tmpUsers,
                queryParams: state.tmpQueryParams,
                tmpUsers: {},
                tmpQueryParams: {},
                searchMode: false,
            };

        case UserActionTypes.GET_MERCHANT_SETTING:
            return {
                ...state,
                merchantSettings: {},
                loading: true,
                error: "",
            };

        case UserActionTypes.UPDATE_MERCHANT_SETTING:
            return {
                ...state,
                merchantSettingsUpdated: false,
                loading: true,
                error: "",
            };
        case UserActionTypes.UPDATE_MOBILE_OWNERSHIP:
            return {
                ...state,
                loading: true,
                error: "",
                updatedMobileOwnership: false
            };

        case UserActionTypes.EXPORT:

            return {
                ...state,
                exportUrl: "",
                exportLoading: true,
                exportError: "",
                exportValidationErrors: [],
            };

        default:
            return { ...state };
    }
};

export default User;
