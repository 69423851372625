
export enum NCardBackActionTypes {
    API_RESPONSE_SUCCESS = '@@nCBack/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@nCBack/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@nCBack/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@nCBack/RESET',

    SET_NC_BACK = '@@nCBack/SET_NC_BACK',

    UPLOAD_NC_BACK = '@@nCBack/UPLOAD_NC_BACK',
    UPLOAD_NC_BACK_PROGRESS = '@@nCBack/UPLOAD_NC_BACK_PROGRESS',
    DELETE_NC_BACK = '@@nCBack/DELETE_NC_BACK',

    UPDATE = '@@nCBack/UPDATE',
}

export enum NCardBackErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



