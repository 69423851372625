export enum IpActionTypes {
    API_RESPONSE_SUCCESS = '@@ip/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@ip/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@ip/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@ip/RESET',

    GET_IPS = '@@ip/GET_IPS',
    GET_IP = '@@ip/GET_IP',
    INSERT_IP = '@@ip/INSERT_IP',
    DELETE_IP = '@@ip/DELETE_IP',

    OPEN_SEARCH = '@@ip/OPEN_SEARCH',
    CLOSE_SEARCH = '@@ip/CLOSE_SEARCH',
    SEARCH_IP = '@@ip/SEARCH_IP',
    CLEAR_SEARCH = '@@ip/CLEAR_SEARCH',
}

export enum IpErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



