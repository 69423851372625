import { NCardBackActionTypes } from './constants';

export type NCardBackActionType = {
    type:
        | NCardBackActionTypes.API_RESPONSE_SUCCESS
        | NCardBackActionTypes.API_RESPONSE_ERROR
        | NCardBackActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | NCardBackActionTypes.RESET

        | NCardBackActionTypes.UPLOAD_NC_BACK
        | NCardBackActionTypes.UPLOAD_NC_BACK_PROGRESS
        | NCardBackActionTypes.DELETE_NC_BACK

        | NCardBackActionTypes.UPDATE
    ;

    payload: {} | string;
};

class NCardBackActions {

    apiResponseSuccess = (actionType: string, data: any): NCardBackActionType => ({
        type: NCardBackActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: any): NCardBackActionType => ({
        type: NCardBackActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: any): NCardBackActionType => ({
        type: NCardBackActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): NCardBackActionType => ({
        type: NCardBackActionTypes.RESET,
        payload: {},
    });

    uploadNationalCardBack = (id:string, file: File, retry: boolean): NCardBackActionType => ({
        type: NCardBackActionTypes.UPLOAD_NC_BACK,
        payload: {id, file, retry},
    });

    uploadNationalCardBackProgress = (progress: any, fileId: string): NCardBackActionType => {
        return {
            type: NCardBackActionTypes.UPLOAD_NC_BACK_PROGRESS,
            payload: {fileId, progress},
        }
    }

    deleteNcBack = (id:string, fileId: string, uuid: string): NCardBackActionType => ({
        type: NCardBackActionTypes.DELETE_NC_BACK,
        payload: {id, fileId, uuid},
    });

    update = (data:any): NCardBackActionType => ({
        type: NCardBackActionTypes.UPDATE,
        payload: {data},
    });
}

const nCBackActions = new NCardBackActions();
export { nCBackActions };
