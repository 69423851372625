import {ApiServiceActionTypes} from './constants';

const INIT_STATE = {
    loading: false,
    error: "",
    validationErrors: {},

    apiToken: "",
    tokenCreated: false,
};

type ApiServiceActionType = {
    type:
        | ApiServiceActionTypes.API_RESPONSE_SUCCESS
        | ApiServiceActionTypes.API_RESPONSE_ERROR
        | ApiServiceActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | ApiServiceActionTypes.RESET

        | ApiServiceActionTypes.GET_API_TOKEN
        | ApiServiceActionTypes.CREATE_API_TOKEN;

    payload: {
        actionType?: string;
        data?: any;
        error?: any;
        validationErrors?: any;

        key?: any;
        value?: any;
    };
};

type State = {};

const ApiService = (state: State = INIT_STATE, action: ApiServiceActionType) => {
    switch (action.type) {
        case ApiServiceActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ApiServiceActionTypes.GET_API_TOKEN: {
                    return {
                        ...state,
                        loading: false,
                        apiToken: action.payload.data
                    };
                }
                case ApiServiceActionTypes.CREATE_API_TOKEN: {
                    return {
                        ...state,
                        loading: false,
                        tokenCreated: true,
                        apiToken: action.payload.data
                    };
                }
                default:
                    return { ...state };
            }

        case ApiServiceActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ApiServiceActionTypes.GET_API_TOKEN:
                case ApiServiceActionTypes.CREATE_API_TOKEN:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };

                default:
                    return { ...state };
            }

        case ApiServiceActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case ApiServiceActionTypes.GET_API_TOKEN:
                case ApiServiceActionTypes.CREATE_API_TOKEN:
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };

                default:
                    return { ...state };
            }

        case ApiServiceActionTypes.RESET:
            return {
                ...state,

                loading: false,
                error: "",
                validationErrors: {},

                tokenCreated: false,
            };

        case ApiServiceActionTypes.GET_API_TOKEN:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case ApiServiceActionTypes.CREATE_API_TOKEN:
            return {
                ...state,
                loading: true,
                error: "",
            };

        default:
            return { ...state };
    }
};

export default ApiService;
