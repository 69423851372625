export enum PermissionActionTypes {
    API_RESPONSE_SUCCESS = '@@permission/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@permission/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@permission/API_RESPONSE_VALIDATION_ERRORS',

    FIND_PERMISSION = '@@permission/FIND_PERMISSION',
    GET_PERMISSIONS = '@@permission/GET_PERMISSIONS',
    SET_VARIABLE = '@@permission/SET_VARIABLE',
    RESET = '@@permission/RESET',

    OPEN_SEARCH = '@@permission/OPEN_SEARCH',
    CLOSE_SEARCH = '@@permission/CLOSE_SEARCH',
    RESET_SEARCH = '@@permission/RESET_SEARCH',
    SEARCH_PERMISSION = '@@permission/SEARCH_PERMISSION',
    CLEAR_SEARCH = '@@permission/CLEAR_SEARCH',
}

export enum PermissionErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



