
export enum IBANActionTypes {
    API_RESPONSE_SUCCESS = '@@IBAN/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@IBAN/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@IBAN/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@IBAN/RESET',

    GET_IBANS = '@@IBAN/GET_IBANS',
    GET_IBAN = '@@IBAN/GET_IBAN',
    INSERT_IBAN = '@@IBAN/INSERT_IBAN',
    UPDATE_IBAN = '@@IBAN/UPDATE_IBAN',
    DELETE_IBAN = '@@IBAN/DELETE_IBAN',
}

export enum IBANErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



