import {OrderActionTypes} from './constants';

export type OrderActionType = {
    type:
        | OrderActionTypes.API_RESPONSE_SUCCESS
        | OrderActionTypes.API_RESPONSE_ERROR
        | OrderActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | OrderActionTypes.RESET
        | OrderActionTypes.ORDERS
        | OrderActionTypes.ORDER
        | OrderActionTypes.CREATE
        | OrderActionTypes.UPDATE
        | OrderActionTypes.DELETE
        | OrderActionTypes.SEARCH;
    payload: {} | string;
};

type Order = {
    id: number;
    name: string;
};

class OrderActions {
    apiResponseSuccess = (actionType: string, data: Order | {}): OrderActionType => ({
        type: OrderActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): OrderActionType => ({
        type: OrderActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): OrderActionType => ({
        type: OrderActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): OrderActionType => ({
        type: OrderActionTypes.RESET,
        payload: {},
    });

    getOrders = (queryParams: any): OrderActionType => ({
        type: OrderActionTypes.ORDERS,
        payload: {queryParams},
    });

    getOrder = (queryParams: any): OrderActionType => ({
        type: OrderActionTypes.ORDER,
        payload: {queryParams},
    });

    create = (data: any): OrderActionType => ({
        type: OrderActionTypes.CREATE,
        payload: {data},
    });

    update = (data: any): OrderActionType => ({
        type: OrderActionTypes.UPDATE,
        payload: {data},
    });

    delete = (id: any): OrderActionType => ({
        type: OrderActionTypes.DELETE,
        payload: {id},
    });

    search = (queryParams: any): OrderActionType => ({
        type: OrderActionTypes.SEARCH,
        payload: {queryParams},
    });
}

const orderActions = new OrderActions();
export { orderActions };
