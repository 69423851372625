import {CUActionTypes} from './constants';

export type CUActionType = {
    type:
        | CUActionTypes.API_RESPONSE_SUCCESS
        | CUActionTypes.API_RESPONSE_ERROR
        | CUActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | CUActionTypes.RESET
        | CUActionTypes.UNITS
        | CUActionTypes.INSERT
        | CUActionTypes.DELETE;
    payload: {} | string;
};

type CU = {
    id: number;
    name: string;
    guard_name: string;
};

class CUActions {

    apiResponseSuccess = (actionType: string, data: CU | {}): CUActionType => ({
        type: CUActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): CUActionType => ({
        type: CUActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): CUActionType => ({
        type: CUActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): CUActionType => ({
        type: CUActionTypes.RESET,
        payload: {},
    });

    getUnits = (): CUActionType => ({
        type: CUActionTypes.UNITS,
        payload: {},
    });

    createUnit = (data: any): CUActionType => ({
        type: CUActionTypes.INSERT,
        payload: {data},
    });

    deleteUnit = (data: any): CUActionType => ({
        type: CUActionTypes.DELETE,
        payload: {data},
    });
}

const cuActions = new CUActions();
export { cuActions };
