import {TransferActionTypes} from './constants';

export type TransferActionType = {
    type:
        | TransferActionTypes.API_RESPONSE_SUCCESS
        | TransferActionTypes.API_RESPONSE_ERROR
        | TransferActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | TransferActionTypes.GET_TRANSFERS
        | TransferActionTypes.GET_TRANSFER
        | TransferActionTypes.INSERT_TRANSFER
        | TransferActionTypes.GET_TRANSACTIONS

        | TransferActionTypes.CHECK_TRANSFER

        | TransferActionTypes.PREVIEW_TRANSFER_DETAIL
        | TransferActionTypes.RESET_PREVIEW_DETAIL

        | TransferActionTypes.OPEN_SEARCH
        | TransferActionTypes.CLOSE_SEARCH
        | TransferActionTypes.SEARCH_TRANSFER
        | TransferActionTypes.SEARCH_TRANSACTION
        | TransferActionTypes.CLEAR_SEARCH

        | TransferActionTypes.CLEAR_CHECKED

        | TransferActionTypes.RESET
        | TransferActionTypes.EXPORT
        | TransferActionTypes.EXPORT_TRANSACTIONS
    ;

    payload: {} | string;
};

type Transfer = {
    id: number;
    name: string;
    guard_name: string;
};

type QueryParams = {
    page: number;
    limit: number;
    orderBy: string,
    sort: string,
    transactionName?: string,
    accountId?: string
}

class TransferActions {

    apiResponseSuccess = (actionType: string, data: Transfer | {}): TransferActionType => ({
        type: TransferActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): TransferActionType => ({
        type: TransferActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): TransferActionType => ({
        type: TransferActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): TransferActionType => ({
        type: TransferActionTypes.RESET,
        payload: {},
    });

    getTransfers = (queryParams: QueryParams): TransferActionType => ({
        type: TransferActionTypes.GET_TRANSFERS,
        payload: { queryParams },
    });

    getTransfer = (id: string): TransferActionType => ({
        type: TransferActionTypes.GET_TRANSFER,
        payload: { id },
    });

    insertTransfer = (data: any): TransferActionType => ({
        type: TransferActionTypes.INSERT_TRANSFER,
        payload: { data }
    });

    getTransactions = (queryParams: QueryParams): TransferActionType => ({
        type: TransferActionTypes.GET_TRANSACTIONS,
        payload: { queryParams },
    });

    openSearch = (): TransferActionType => ({
        type: TransferActionTypes.OPEN_SEARCH,
        payload: {}
    });

    closeSearch = (): TransferActionType => ({
        type: TransferActionTypes.CLOSE_SEARCH,
        payload: {}
    });

    search = (queryParams: QueryParams): TransferActionType => ({
        type: TransferActionTypes.SEARCH_TRANSFER,
        payload: { queryParams }
    });

    searchTransaction = (queryParams: QueryParams): TransferActionType => ({
        type: TransferActionTypes.SEARCH_TRANSACTION,
        payload: { queryParams }
    });

    clearSearch = (): TransferActionType => ({
        type: TransferActionTypes.CLEAR_SEARCH,
        payload: {}
    });

    checkTransfer = (data: any): TransferActionType => ({
        type: TransferActionTypes.CHECK_TRANSFER,
        payload: { data }
    });

    previewTransferDetail = (data: any): TransferActionType => ({
        type: TransferActionTypes.PREVIEW_TRANSFER_DETAIL,
        payload: {data}
    });

    resetPreviewDetail = (): TransferActionType => ({
        type: TransferActionTypes.RESET_PREVIEW_DETAIL,
        payload: {}
    });

    clearChecked = (): TransferActionType => ({
        type: TransferActionTypes.CLEAR_CHECKED,
        payload: {}
    });

    export = (queryParams: QueryParams): TransferActionType => ({
        type: TransferActionTypes.EXPORT,
        payload: { queryParams }
    });

    exportTransactions = (queryParams: QueryParams): TransferActionType => ({
        type: TransferActionTypes.EXPORT_TRANSACTIONS,
        payload: { queryParams }
    });
}

const transferActions = new TransferActions();
export { transferActions };
