import { PersonalDetailActionTypes } from './constants';

export type PersonalDetailActionType = {
    type:
        | PersonalDetailActionTypes.API_RESPONSE_SUCCESS
        | PersonalDetailActionTypes.API_RESPONSE_ERROR
        | PersonalDetailActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | PersonalDetailActionTypes.RESET

        | PersonalDetailActionTypes.GET_PERSONAL_DETAILS
        | PersonalDetailActionTypes.GET_PERSONAL_DETAIL
        | PersonalDetailActionTypes.UPDATE_PERSONAL_DETAIL

        | PersonalDetailActionTypes.UPDATE_STATUS
        | PersonalDetailActionTypes.UPDATE_MY_PERSONAL_DETAIL;

    payload: {} | string;
};

type PersonalDetail = {
    id: number;
    email: string;
    emailVerify: string;
    role: string;
    token: string;

    process: any
};

class PersonalDetailActions {

    // PersonalDetailActionTypes.SET_PERSONAL_DETAIL, personalDetails
    apiResponseSuccess = (actionType: string, data: PersonalDetail | {}): PersonalDetailActionType => ({
        type: PersonalDetailActionTypes.API_RESPONSE_SUCCESS,
        payload: {
            actionType,
            data
        },
    });

    apiResponseError = (actionType: string, error: any): PersonalDetailActionType => ({
        type: PersonalDetailActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: any): PersonalDetailActionType => ({
        type: PersonalDetailActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): PersonalDetailActionType => ({
        type: PersonalDetailActionTypes.RESET,
        payload: {},
    });

    getPersonalDetails = (queryParams: any): PersonalDetailActionType => ({
        type: PersonalDetailActionTypes.GET_PERSONAL_DETAILS,
        payload: {queryParams},
    });

    getPersonalDetail = (id: string): PersonalDetailActionType => ({
        type: PersonalDetailActionTypes.GET_PERSONAL_DETAIL,
        payload: {id},
    });

    updatePersonalDetails = (data: any): PersonalDetailActionType => ({
        type: PersonalDetailActionTypes.UPDATE_PERSONAL_DETAIL,
        payload: {data},
    });

    updateStatus = (data: any): PersonalDetailActionType => ({
        type: PersonalDetailActionTypes.UPDATE_STATUS,
        payload: {data},
    });

    updateMyPersonalDetails = (data: any): PersonalDetailActionType => ({
        type: PersonalDetailActionTypes.UPDATE_MY_PERSONAL_DETAIL,
        payload: {data},
    });

}

const personalDetailActions = new PersonalDetailActions();
export { personalDetailActions };
