import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { graphql } from 'helpers';
import {RoleActionTypes, RoleErrors} from './constants';
import {
    checkResponseError,
    checkServerError,
    pluckResponse,
} from "../../helpers/functions";
import {roleActions} from "./actions";

type RoleData = {
    payload: {
        id: string;
        name: string;
        permissions: string[];

        queryParams: {
            limit: string;
            page: string;
            orderBy: string;
            sort: string;

            roleName?: string;
        };

        withPermissions: boolean
    };
    type: string;
};

function* getRoles({ payload: { queryParams } }: RoleData): SagaIterator {

    try {
        const param = {
            query:`query roles($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $roleName: String) {
                roles(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, roleName: $roleName) {
                    data {
                        id
                        name
                        guard_name
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, RoleErrors.RESPONSE_200);

        const rRoles = response.data;

        checkServerError(rRoles);

        const roles = pluckResponse(rRoles, "roles");

        const result = {
            roles,
            queryParams
        }

        yield put(roleActions.apiResponseSuccess(RoleActionTypes.GET_ROLES, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(roleActions.apiResponseError(RoleActionTypes.GET_ROLES, error));
        } else {
            yield put(roleActions.apiResponseValidationErrors(RoleActionTypes.GET_ROLES, error));
        }
    }
}

function* newRole(): SagaIterator {
    try {
        const param = {
            query:`query permissions($page: Int!, $limit: Int, $orderBy: String!, $sort: String!) {
                permissions(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort) {
                    data {
                        id
                        name
                        category
                        created_at
                        updated_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                "limit": 475,
                "page": 1,
                "orderBy": "category",
                "sort": "asc",
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, RoleErrors.RESPONSE_200);

        const rPermissions = response.data;

        checkServerError(rPermissions);

        const permissions = pluckResponse(rPermissions, "permissions");

        yield put(roleActions.apiResponseSuccess(RoleActionTypes.NEW_ROLE, permissions));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(roleActions.apiResponseError(RoleActionTypes.NEW_ROLE, error));
        } else {
            yield put(roleActions.apiResponseValidationErrors(RoleActionTypes.NEW_ROLE, error));
        }
    }
}

function* insertRole({ payload: {
    name,
    permissions,
} }: RoleData): SagaIterator {
    try {
        const param = {
            query:`mutation CreateRole(
                $name: String!,
                $permissions: [String!]
            ) {
                createRole(
                    name: $name,
                    permissions: $permissions
                ) {
                    name
                }
            }`,

            variables: {
                "name": name,
                "permissions": permissions
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, RoleErrors.RESPONSE_200);

        const rCreateRole = response.data;

        checkServerError(rCreateRole);

        const createRole = pluckResponse(rCreateRole, "createRole");

        yield put(roleActions.apiResponseSuccess(RoleActionTypes.INSERT_ROLE, createRole));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(roleActions.apiResponseError(RoleActionTypes.INSERT_ROLE, error));
        } else {
            yield put(roleActions.apiResponseValidationErrors(RoleActionTypes.INSERT_ROLE, error));
        }
    }
}

function* editRole({ payload: { id, withPermissions } }: RoleData): SagaIterator {
    try {

        const param: any = [
            {
                query:`query role($id: Int!) {
                    role(id: $id) {
                        id
                        name
                        permissions {
                            name
                        }
                    }
                }`,
                variables: {
                    "id": id,
                }
            },
        ];

        if(withPermissions) {
            param.push({
                query:`query permissions($page: Int!, $limit: Int, $orderBy: String!, $sort: String!) {
                    permissions(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort) {
                        data {
                            id
                            name
                            category
                            created_at
                            updated_at
                        }
                        total
                        per_page
                        from
                        to
                        current_page
                        last_page
                        has_more_pages
                    }
                }`,

                variables: {
                    "limit": 475,
                    "page": 1,
                    "orderBy": "category",
                    "sort": "asc",
                }
            });
        }

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, RoleErrors.RESPONSE_200);

        const [rRole, rPermissions] = response.data;

        checkServerError(rRole);

        const role = pluckResponse(rRole, "role");

        let permissions = {}

        if(withPermissions) {
            checkServerError(rPermissions);
            permissions = pluckResponse(rPermissions, "permissions");
        }

        yield put(roleActions.apiResponseSuccess(RoleActionTypes.EDIT_ROLE, {role, permissions}));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(roleActions.apiResponseError(RoleActionTypes.EDIT_ROLE, error));
        } else {
            yield put(roleActions.apiResponseValidationErrors(RoleActionTypes.EDIT_ROLE, error));
        }
    }
}

function* updateRole({ payload: {
    id,
    name,
    permissions,
} }: RoleData): SagaIterator {
    try {
        const param = {
            query:`mutation UpdateRole(
                $id: Int!,
                $name: String!,
                $permissions: [String!]
            ) {
                updateRole(
                    id: $id,
                    name: $name,
                    permissions: $permissions
                ) {
                    name
                }
            }`,
            variables: {
                "id": id,
                "name": name,
                "permissions": permissions
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, RoleErrors.RESPONSE_200);

        const rUpdateRole = response.data;

        checkServerError(rUpdateRole);

        const updateRole = pluckResponse(rUpdateRole, "updateRole");

        yield put(roleActions.apiResponseSuccess(RoleActionTypes.UPDATE_ROLE, updateRole));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(roleActions.apiResponseError(RoleActionTypes.UPDATE_ROLE, error));
        } else {
            yield put(roleActions.apiResponseValidationErrors(RoleActionTypes.UPDATE_ROLE, error));
        }
    }
}

function* searchRole({ payload: { queryParams } }: RoleData): SagaIterator {
    try {
        const param = {
            query:`query roles($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $roleName: String) {
                roles(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, roleName: $roleName) {
                    data {
                        id
                        name
                        guard_name
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, RoleErrors.RESPONSE_200);

        const rRoles = response.data;

        checkServerError(rRoles);

        const roles = pluckResponse(rRoles, "roles");

        const result = {
            roles,
            queryParams
        }

        yield put(roleActions.apiResponseSuccess(RoleActionTypes.SEARCH_ROLE, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(roleActions.apiResponseError(RoleActionTypes.SEARCH_ROLE, error));
        } else {
            yield put(roleActions.apiResponseValidationErrors(RoleActionTypes.SEARCH_ROLE, error));
        }
    }
}

export function* watchGetRoles() {
    yield takeEvery(RoleActionTypes.GET_ROLES, getRoles);
}

export function* watchNewRole() {
    yield takeEvery(RoleActionTypes.NEW_ROLE, newRole);
}

export function* watchInsertRole() {
    yield takeEvery(RoleActionTypes.INSERT_ROLE, insertRole);
}

export function* watchEditRole() {
    yield takeEvery(RoleActionTypes.EDIT_ROLE, editRole);
}

export function* watchUpdateRole() {
    yield takeEvery(RoleActionTypes.UPDATE_ROLE, updateRole);
}

export function* watchSearchRole() {
    yield takeEvery(RoleActionTypes.SEARCH_ROLE, searchRole);
}


function* rolesSaga() {
    yield all([
        fork(watchGetRoles),
        fork(watchNewRole),
        fork(watchInsertRole),
        fork(watchEditRole),
        fork(watchUpdateRole),
        fork(watchSearchRole)
    ]);
}

export default rolesSaga;
