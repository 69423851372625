import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { graphql } from 'helpers';
import {PaymentActionTypes, PaymentErrors} from './constants';
import {
    checkResponseError,
    checkServerError,
    pluckResponse,
} from "../../helpers/functions";
import {paymentActions} from "./actions";

type PaymentData = {
    payload: {

        queryParams: {
            limit: string;
            page: string;
            orderBy: string;
            sort: string;
        };

        data: any;
        id: string;
    };
    type: string;
};

function* getPayments({ payload: { queryParams } }: PaymentData): SagaIterator {

    try {
        const param = {
            query:`query Payments($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $accountId: String) {
                payments(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId) {
                    data {
                        id
                        from {
                            name
                            balanceFloatNum
                            meta {
                                sign
                                currency
                            }
                            holder {
                                id
                                username
                                email
                            }
                        }
                        to {
                            name
                            balanceFloatNum
                            meta {
                                sign
                                currency
                            }
                            holder {
                                id
                                username
                                email
                            }
                        }
                        withdraw {
                            name
                            amountFloat
                            meta {
                                description
                            }
                        }
                        deposit {
                            name
                            amountFloat
                            meta {
                                description
                            }
                            rate
                            status
                        }
                        fee
                        feeFloat
                        rate
                        uuid
                        status
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PaymentErrors.RESPONSE_200);

        const rPayments = response.data;

        checkServerError(rPayments);

        const payments = pluckResponse(rPayments, "payments");

        const result = {
            payments,
            queryParams
        }

        yield put(paymentActions.apiResponseSuccess(PaymentActionTypes.GET_PAYMENTS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(paymentActions.apiResponseError(PaymentActionTypes.GET_PAYMENTS, error));
        } else {
            yield put(paymentActions.apiResponseValidationErrors(PaymentActionTypes.GET_PAYMENTS, error));
        }
    }
}

function* getPayment({ payload: { id } }: PaymentData): SagaIterator {

    try {
        const param = {
            query:`query Payment($id: String!) {
                payment(id: $id) {
                    id
                    from {
                        name
                        meta {
                            sign
                            currency
                        }
                        holder {
                            id
                            username
                            email
                        }
                    }
                    to {
                        name
                        balanceFloatNum
                        meta {
                            sign
                            currency
                        }
                    }
                    withdraw {
                        name
                        amount
                        amountFloat
                        meta {
                            description
                        }
                    }
                    deposit {
                        name
                        payable {
                            username
                        }
                        amount
                        amountFloat
                        meta {
                            description
                        }
                        status
                    }
                    fee
                    feeFloat
                    rate
                    uuid
                    status
                    created_at
                }
            }`,

            variables: {
                id: id
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PaymentErrors.RESPONSE_200);

        const rP = response.data;

        checkServerError(rP);

        const payment = pluckResponse(rP, "payment");

        yield put(paymentActions.apiResponseSuccess(PaymentActionTypes.GET_PAYMENT, payment));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(paymentActions.apiResponseError(PaymentActionTypes.GET_PAYMENT, error));
        } else {
            yield put(paymentActions.apiResponseValidationErrors(PaymentActionTypes.GET_PAYMENT, error));
        }
    }
}

function* searchPayment({ payload: { queryParams } }: PaymentData): SagaIterator {
    try {
        const param = {
            query:`query Payments(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $transferId: String,
                $wallet: String, 
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $accountId: String,
                $transactionNames: [String]
            ) {
                payments(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort,
                    transferId: $transferId,
                    wallet: $wallet,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    accountId: $accountId
                    transactionNames: $transactionNames
                ) {
                    data {
                        id
                        from {
                            name
                            balanceFloatNum
                            meta {
                                sign
                                currency
                            }
                            holder {
                                id
                                username
                                email
                            }
                        }
                        to {
                            name
                            balanceFloatNum
                            meta {
                                sign
                                currency
                            }
                            holder {
                                id
                                username
                                email
                            }
                        }
                        withdraw {
                            name
                            amountFloat
                            meta {
                                description
                            }
                        }
                        deposit {
                            name
                            amountFloat
                            meta {
                                description
                            }
                            rate
                            status
                        }
                        fee
                        feeFloat
                        rate
                        uuid
                        status
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PaymentErrors.RESPONSE_200);

        const rPayments = response.data;

        checkServerError(rPayments);

        const payments = pluckResponse(rPayments, "payments");

        const result = {
            payments,
            queryParams
        }

        yield put(paymentActions.apiResponseSuccess(PaymentActionTypes.SEARCH_PAYMENT, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(paymentActions.apiResponseError(PaymentActionTypes.SEARCH_PAYMENT, error));
        } else {
            yield put(paymentActions.apiResponseValidationErrors(PaymentActionTypes.SEARCH_PAYMENT, error));
        }
    }
}

function* getTransactions({ payload: { queryParams } }: PaymentData): SagaIterator {

    try {
        const param = {
            query:`query PaymentTransactions($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $accountId: String) {
                paymentTransactions(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId) {
                    data {
                        wallet {
                            uuid
                            name
                            holder {
                                id
                                email
                                username
                                display_name
                            }
                            meta {
                                sign
                                currency
                            }
                        }
                        type
                        amountFloat
                        confirmed
                        meta {
                            description
                        }
                        name
                        transaction_id
                        thread_id
                        uuid
                        status
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PaymentErrors.RESPONSE_200);

        const rTransactions = response.data;

        checkServerError(rTransactions);

        const transactions = pluckResponse(rTransactions, "paymentTransactions");

        const result = {
            transactions,
            queryParams
        }

        yield put(paymentActions.apiResponseSuccess(PaymentActionTypes.GET_TRANSACTIONS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(paymentActions.apiResponseError(PaymentActionTypes.GET_TRANSACTIONS, error));
        } else {
            yield put(paymentActions.apiResponseValidationErrors(PaymentActionTypes.GET_TRANSACTIONS, error));
        }
    }
}

function* searchTransaction({ payload: { queryParams } }: PaymentData): SagaIterator {
    try {
        const param = {
            query:`query PaymentTransactions(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $wallet: String, 
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $transactionId: String,
                $threadId: String,
                $accountId: String,
                $transactionNames: [String],
                $transactionType: String
                
            ) {
                paymentTransactions(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort, 
                    wallet: $wallet,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    transactionId: $transactionId,
                    threadId: $threadId,
                    accountId: $accountId,
                    transactionNames: $transactionNames,
                    transactionType: $transactionType
                ) {
                    data {
                        wallet {
                            uuid
                            name
                            holder {
                                id
                                email
                                username
                                display_name
                            }
                            meta {
                                sign
                                currency
                            }
                        }
                        type
                        amountFloat
                        confirmed
                        meta {
                            description
                        }
                        name
                        transaction_id
                        thread_id
                        uuid
                        status
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PaymentErrors.RESPONSE_200);

        const rTransactions = response.data;

        checkServerError(rTransactions);

        const transactions = pluckResponse(rTransactions, "paymentTransactions");

        const result = {
            transactions,
            queryParams
        }

        yield put(paymentActions.apiResponseSuccess(PaymentActionTypes.SEARCH_TRANSACTION, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(paymentActions.apiResponseError(PaymentActionTypes.SEARCH_TRANSACTION, error));
        } else {
            yield put(paymentActions.apiResponseValidationErrors(PaymentActionTypes.SEARCH_TRANSACTION, error));
        }
    }
}

function* exportTransactions({ payload: { queryParams } }: PaymentData): SagaIterator {
    try {
        const param = {
            query:`query ExportPaymentTransactions(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $wallet: String, 
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $transactionId: String,
                $threadId: String,
                $accountId: String,
                $transactionNames: [String],
                $transactionType: String
            ) {
                exportPaymentTransactions(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort, 
                    wallet: $wallet,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    transactionId: $transactionId,
                    threadId: $threadId,
                    accountId: $accountId,
                    transactionNames: $transactionNames,
                    transactionType: $transactionType,
                )
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PaymentErrors.RESPONSE_200);

        const rTransactions = response.data;

        checkServerError(rTransactions);

        const transactions = pluckResponse(rTransactions, "exportPaymentTransactions");

        yield put(paymentActions.apiResponseSuccess(PaymentActionTypes.EXPORT_TRANSACTIONS, transactions));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(paymentActions.apiResponseError(PaymentActionTypes.EXPORT_TRANSACTIONS, error));
        } else {
            yield put(paymentActions.apiResponseValidationErrors(PaymentActionTypes.EXPORT_TRANSACTIONS, error));
        }
    }
}

function* exportPayments({ payload: { queryParams } }: PaymentData): SagaIterator {
    try {
        const param = {
            query:`query ExportPayments(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $transferId: String,
                $wallet: String, 
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $accountId: String
                $transactionNames: [String]
            ) {
                exportPayments(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort,
                    transferId: $transferId,
                    wallet: $wallet,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    accountId: $accountId
                    transactionNames: $transactionNames
                )
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PaymentErrors.RESPONSE_200);

        const rTransactions = response.data;

        checkServerError(rTransactions);

        const exportPayments = pluckResponse(rTransactions, "exportPayments");

        yield put(paymentActions.apiResponseSuccess(PaymentActionTypes.EXPORT, exportPayments));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(paymentActions.apiResponseError(PaymentActionTypes.EXPORT, error));
        } else {
            yield put(paymentActions.apiResponseValidationErrors(PaymentActionTypes.EXPORT, error));
        }
    }
}

function* callback({ payload: {id} }: PaymentData): SagaIterator {
    try {
        const param = {
            query:`query PaymentCallback(
                $trackId: String!
            ) {
                paymentCallback(
                    trackId: $trackId
                )
            }`,

            variables: { trackId: id }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PaymentErrors.RESPONSE_200);

        const rCD = response.data;

        checkServerError(rCD);

        const paymentCallback = pluckResponse(rCD, "paymentCallback");

        yield put(paymentActions.apiResponseSuccess(PaymentActionTypes.CALLBACK, paymentCallback));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(paymentActions.apiResponseError(PaymentActionTypes.CALLBACK, error));
        } else {
            yield put(paymentActions.apiResponseValidationErrors(PaymentActionTypes.CALLBACK, error));
        }
    }
}

export function* watchGetPayments() {
    yield takeEvery(PaymentActionTypes.GET_PAYMENTS, getPayments);
}

export function* watchGetPayment() {
    yield takeEvery(PaymentActionTypes.GET_PAYMENT, getPayment);
}

export function* watchSearchPayment() {
    yield takeEvery(PaymentActionTypes.SEARCH_PAYMENT, searchPayment);
}

export function* watchGetTransactions() {
    yield takeEvery(PaymentActionTypes.GET_TRANSACTIONS, getTransactions);
}

export function* watchSearchTransaction() {
    yield takeEvery(PaymentActionTypes.SEARCH_TRANSACTION, searchTransaction);
}

export function* watchExportTransactions() {
    yield takeEvery(PaymentActionTypes.EXPORT_TRANSACTIONS, exportTransactions);
}

export function* watchExport() {
    yield takeEvery(PaymentActionTypes.EXPORT, exportPayments);
}

export function* watchCallback() {
    yield takeEvery(PaymentActionTypes.CALLBACK, callback);
}

function* paymentsSaga() {
    yield all([
        fork(watchGetPayments),
        fork(watchGetPayment),
        fork(watchSearchPayment),
        fork(watchGetTransactions),
        fork(watchSearchTransaction),
        fork(watchExportTransactions),
        fork(watchExport),
        fork(watchCallback),
    ]);
}

export default paymentsSaga;
