import { DLActionTypes } from './constants';

export type DLActionType = {
    type:
        | DLActionTypes.API_RESPONSE_SUCCESS
        | DLActionTypes.API_RESPONSE_ERROR
        | DLActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | DLActionTypes.RESET

        | DLActionTypes.UPLOAD_DRIVING_LICENCE
        | DLActionTypes.UPLOAD_DL_PROGRESS
        | DLActionTypes.DELETE_DRIVING_LICENCE

        | DLActionTypes.UPDATE
    ;

    payload: {} | string;
};

class DLActions {

    apiResponseSuccess = (actionType: string, data: any): DLActionType => ({
        type: DLActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: any): DLActionType => ({
        type: DLActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: any): DLActionType => ({
        type: DLActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): DLActionType => ({
        type: DLActionTypes.RESET,
        payload: {},
    });

    uploadDrivingLicence = (id:string, file: File, retry: boolean): DLActionType => ({
        type: DLActionTypes.UPLOAD_DRIVING_LICENCE,
        payload: {id, file, retry},
    });

    uploadDlProgress = (progress: any, fileId: string): DLActionType => {
        return {
            type: DLActionTypes.UPLOAD_DL_PROGRESS,
            payload: {fileId, progress},
        }
    }

    deleteDrivingLicence = (id:string, fileId: string, uuid: string): DLActionType => ({
        type: DLActionTypes.DELETE_DRIVING_LICENCE,
        payload: {id, fileId, uuid},
    });

    update = (data:any): DLActionType => ({
        type: DLActionTypes.UPDATE,
        payload: {data},
    });
}

const dlActions = new DLActions();
export { dlActions };
