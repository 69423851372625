import { Navigate } from 'react-router-dom';

const Root = () => {
    const getRootUrl = () => {
        return 'dashboard';
    };

    const url = getRootUrl();
    //
    // if(!hasPermission(["View Dashboard"])) {
    //     return <Navigate to={`/account/logout`} />;
    // }

    return <Navigate to={`/${url}`} />;
};

export default Root;
