import {TokenActionTypes} from './constants';
import { isEmptyObject } from '../../helpers/functions';

const INIT_STATE = {
    loading: false,
    error: "",
    validationErrors: {},

    tokens: {},
    queryParams: {},

    token: {},
    tokenInserted: false,
    tokenUpdated: false,
    tokenDeleted: false,

    searchModal: false,
    tmpTokens: {},
    tmpQueryParams: {},
    searchMode: false,
    searchLoading: false,
    searchError: "",
    searchValidationErrors: {}
};

type TokenActionType = {
    type:
        | TokenActionTypes.API_RESPONSE_SUCCESS
        | TokenActionTypes.API_RESPONSE_ERROR
        | TokenActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | TokenActionTypes.RESET

        | TokenActionTypes.GET_TOKENS
        | TokenActionTypes.GET_TOKEN
        | TokenActionTypes.INSERT_TOKEN
        | TokenActionTypes.UPDATE_TOKEN
        | TokenActionTypes.DELETE_TOKEN

        | TokenActionTypes.OPEN_SEARCH
        | TokenActionTypes.CLOSE_SEARCH
        | TokenActionTypes.SEARCH_TOKEN
        | TokenActionTypes.CLEAR_SEARCH;

    payload: {
        actionType?: string;
        data?: any;
        error?: any;
        validationErrors?: any;

        otp: string;
    };
};

type State = {
    token: any;

    tokens: any;
    queryParams?: {};

    tmpTokens?: any;
    tmpQueryParams?: any;
};

const Token = (state: State = INIT_STATE, action: TokenActionType) => {
    switch (action.type) {
        case TokenActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case TokenActionTypes.GET_TOKENS: {
                    return {
                        ...state,
                        loading: false,
                        tokens: action.payload.data.tokens.tokens,
                        queryParams: action.payload.data.tokens.queryParams,
                    };
                }
                case TokenActionTypes.GET_TOKEN: {
                    return {
                        ...state,
                        loading: false,
                        token: action.payload.data,
                    };
                }
                case TokenActionTypes.INSERT_TOKEN: {
                    return {
                        ...state,
                        loading: false,
                        insertedToken: action.payload.data,
                    };
                }
                case TokenActionTypes.UPDATE_TOKEN: {
                    return {
                        ...state,
                        loading: false,
                        tokenUpdated: true,
                    };
                }

                case TokenActionTypes.DELETE_TOKEN: {
                    return {
                        ...state,
                        loading: false,
                        tokenDeleted: true,
                        tokens: {
                            ...state.tokens,
                            data: state.tokens.data.filter((item: any) => {
                                return item.id !== action.payload.data;
                            })
                        }
                    };
                }

                case TokenActionTypes.SEARCH_TOKEN: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpTokens: isEmptyObject(state.tmpTokens)? state.tokens: state.tmpTokens,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        tokens: action.payload.data?.tokens,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,
                        searchLoading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case TokenActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case TokenActionTypes.GET_TOKEN:
                case TokenActionTypes.INSERT_TOKEN:
                case TokenActionTypes.UPDATE_TOKEN:
                case TokenActionTypes.DELETE_TOKEN:
                case TokenActionTypes.GET_TOKENS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case TokenActionTypes.SEARCH_TOKEN: {
                    return {
                        ...state,
                        searchError: action.payload.error,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case TokenActionTypes.API_RESPONSE_VALIDATION_ERRORS:
            switch (action.payload.actionType) {
                case TokenActionTypes.GET_TOKEN:
                case TokenActionTypes.INSERT_TOKEN:
                case TokenActionTypes.UPDATE_TOKEN:
                case TokenActionTypes.DELETE_TOKEN: {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case TokenActionTypes.SEARCH_TOKEN: {
                    return {
                        ...state,
                        searchValidationErrors: action.payload.validationErrors,
                        searchLoading: false,
                    };
                }
                case TokenActionTypes.GET_TOKENS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case TokenActionTypes.RESET:
            return {
                ...state,

                tokens: {},
                queryParams: {},

                loading: false,
                error: "",
                validationErrors: {},

                token: {},
                insertedToken: '',
                tokenUpdated: false,
                tokenDeleted: false,

                // reset search
                searchModal: false,
                searchLoading: false,
                searchMode: false,
                searchError: "",
                searchValidationErrors: {},

                tmpTokens: {},
                tmpQueryParams: {},
            };

        case TokenActionTypes.GET_TOKENS:
            return {
                ...state,
                loading: true,
                error: "",
                // tokens: {},
            };
        case TokenActionTypes.GET_TOKEN:
            return {
                ...state,
                loading: true,
                error: "",
                token: {},
            };
        case TokenActionTypes.INSERT_TOKEN:
            return {
                ...state,
                loading: true,
                error: "",
                insertedToken: '',
            };
        case TokenActionTypes.UPDATE_TOKEN:
            return {
                ...state,
                loading: true,
                error: "",
                tokenUpdated: false,
            };
        case TokenActionTypes.DELETE_TOKEN:
            return {
                ...state,
                loading: true,
                error: "",
                tokenDeleted: false,
            };

        // search actions
        case TokenActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case TokenActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},
            };
        case TokenActionTypes.SEARCH_TOKEN:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case TokenActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                tokens: state.tmpTokens,
                queryParams: state.tmpQueryParams,
                tmpTokens: {},
                tmpQueryParams: {},
                searchMode: false,
            };

        default:
            return { ...state };
    }
};

export default Token;
