
export enum MobileNumberActionTypes {
    API_RESPONSE_SUCCESS = '@@mobileNumber/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@mobileNumber/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@mobileNumber/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@mobileNumber/RESET',

    GET_MOBILE_NUMBERS = '@@mobileNumber/GET_MOBILE_NUMBERS',
    GET_MOBILE_NUMBER = '@@mobileNumber/GET_MOBILE_NUMBER',

    UPDATE_MOBILE_NUMBER = '@@mobileNumber/UPDATE_MOBILE_NUMBER',

    SEND_OTP = '@@mobileNumber/SEND_OTP',
    SET_OTP = '@@mobileNumber/SET_OTP',
    VERIFY_OTP = '@@mobileNumber/VERIFY_OTP',
    CLEAR_OTP_MOBILE = '@@mobileNumber/CLEAR_OTP_MOBILE',

}

export enum MobileNumberErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



