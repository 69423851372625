import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {graphql} from '../../../helpers';
import {ProfileActionTypes, ProfileErrors} from './constants';
import {checkResponseError, checkServerError, formatBytes, pluckResponse} from "../../../helpers/functions";
import {profileActions} from "./actions";

type ProfileData = {
    payload: {
        data: any;
        queryParams: {
            limit: number;
            page: number;
        }
        token: string;
    };
    type: string;
};

function* sendVerificationEmailLink(): SagaIterator {
    try {

        let param: any = {
            query:`mutation SendVerificationEmailLink {
                    sendVerificationEmailLink
                }`,
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ProfileErrors.RESPONSE_200);

        const rSendVerificationEmailLink = response.data;

        checkServerError(rSendVerificationEmailLink);

        pluckResponse(rSendVerificationEmailLink, "sendVerificationEmailLink");

        yield put(profileActions.apiResponseSuccess(ProfileActionTypes.SEND_VERIFICATION_EMAIL_LINK, true));

    } catch (error: any) {

        if(typeof error === "string") {
            yield put(profileActions.apiResponseError(ProfileActionTypes.SEND_VERIFICATION_EMAIL_LINK, error));
        } else {
            yield put(profileActions.apiResponseValidationErrors(ProfileActionTypes.SEND_VERIFICATION_EMAIL_LINK, error));
        }
    }
}

function* verifyEmail({ payload: {token} }: ProfileData): SagaIterator {
    try {
        let param = {
            query:`mutation VerifyEmail($token: String!) {
                    verifyEmail(token: $token)
                }`,
            variables: {
                "token": token
            }
        };

        const response: any = yield call(graphql, param, '');

        checkResponseError(response, ProfileErrors.RESPONSE_200);

        const rVerifyEmail = response.data;

        checkServerError(rVerifyEmail);

        pluckResponse(rVerifyEmail, "verifyEmail");

        yield put(profileActions.apiResponseSuccess(ProfileActionTypes.VERIFY_EMAIL, true));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(profileActions.apiResponseError(ProfileActionTypes.VERIFY_EMAIL, error));
        } else {
            yield put(profileActions.apiResponseValidationErrors(ProfileActionTypes.VERIFY_EMAIL, error));
        }
    }
}

function* getProfileImage(): SagaIterator {
    try {
        const param:any = {
            query: `query UserProfileImage{
                userProfileImage {
                    name
                    preview: url(size: "thumbnail")
                    formattedSize: size
                    type: mime_type
                }
            }`,
        };

        // f.preview blob:http://localhost:3000/6f50e966-594e-4204-9f2c-65020cc998e0
        // f.name Screenshot 2023-10-25 114422.png
        // f.type image/png
        // f.formattedSize 13.95 KB

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ProfileErrors.RESPONSE_200);

        const rUPI = response.data;

        checkServerError(rUPI);

        let userProfileImage = pluckResponse(rUPI, "userProfileImage");

        userProfileImage = userProfileImage.map((v: any) => {
            v.formattedSize = formatBytes(v.formattedSize);
            v.uploaded = true;
            v.progress = 0;
            return v;
        });

        yield put(profileActions.apiResponseSuccess(ProfileActionTypes.GET_PROFILE_IMAGE, userProfileImage));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(profileActions.apiResponseError(ProfileActionTypes.GET_PROFILE_IMAGE, error));
        } else {
            yield put(profileActions.apiResponseValidationErrors(ProfileActionTypes.GET_PROFILE_IMAGE, error));
        }
    }
}

function* getCompleteProfile(): SagaIterator {
    try {
        const param:any = {
            query: `query CompleteProfile{
                completeProfile {
                    email {
                        id
                        status
                    },
                    mobile {
                        id
                        status
                    },
                    personal_detail {
                        id
                        status
                    },
                    bank_account {
                        id
                        status
                    },
                    terms_conditions {
                        id
                        status
                    },
                    current_step,
                    complete_progress,
                }
            }`
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, ProfileErrors.RESPONSE_200);

        const rCP = response.data;

        checkServerError(rCP);

        const completeProfile = pluckResponse(rCP, "completeProfile");

        yield put(profileActions.apiResponseSuccess(ProfileActionTypes.GET_COMPLETE_PROFILE, completeProfile));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(profileActions.apiResponseError(ProfileActionTypes.GET_COMPLETE_PROFILE, error));
        } else {
            yield put(profileActions.apiResponseValidationErrors(ProfileActionTypes.GET_COMPLETE_PROFILE, error));
        }
    }
}


export function* watchSendVerificationEmailLink() {
    yield takeEvery(ProfileActionTypes.SEND_VERIFICATION_EMAIL_LINK, sendVerificationEmailLink);
}

export function* watchVerifyEmail() {
    yield takeEvery(ProfileActionTypes.VERIFY_EMAIL, verifyEmail);
}

export function* watchGetProfileImage(): any {
    yield takeEvery(ProfileActionTypes.GET_PROFILE_IMAGE, getProfileImage);
}

export function* watchGetCompleteProfile(): any {
    yield takeEvery(ProfileActionTypes.GET_COMPLETE_PROFILE, getCompleteProfile);
}

function* profileSaga() {
    yield all([
        fork(watchSendVerificationEmailLink),
        fork(watchVerifyEmail),
        fork(watchGetCompleteProfile),
        fork(watchGetProfileImage),
    ]);
}

export default profileSaga;
