import { Navigate, useLocation } from 'react-router-dom';
import {APICore} from 'helpers/api/apiCore';
import React, {useCallback} from "react";
import {hasPermission} from "../helpers/functions";
import {useSelector} from "react-redux";
import {memoize} from "proxy-memoize";

type PrivateRouteProps = {
    component: React.ComponentType;
    roles?: string;
    permissions: string[];
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: RouteComponent, roles, permissions, ...rest }: PrivateRouteProps) => {
    let location = useLocation();

    useSelector(
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useCallback(
            memoize((state: any) => ({
                user: state.Auth.user,
            })),
            []
        )
    );

    const api = new APICore();

    /**
     * not logged in so redirect to login page with the return url
     */
    if (!api.isUserAuthenticated()) {
        return <Navigate to={'/account/login'} state={{ from: location }} replace />;
    }

    // check if route is restricted by role
    // if (roles && roles.indexOf(loggedInUser.role) === -1) {
    //     // role not authorised so redirect to home page
    //     return <Navigate to={{ pathname: '/' }} />;
    // }

    if(!hasPermission(permissions)) {
        return <Navigate to={{ pathname: '/access-denied' }} />;
    }

    return <RouteComponent />;
};

export default PrivateRoute;
