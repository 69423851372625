import { DashboardActionTypes } from './constants';

const INIT_STATE = {
    dashboard: {},

    loading: false,
    error: "",
    validationErrors: {},
};

type DashboardQueryParams = {
    limit: number;
    page: number;
    orderBy: string;
    sort: string;
    dashboardName: string;
};

type DashboardsResult = {
    dashboards: any
    queryParams: DashboardQueryParams
}

type DashboardActionType = {
    type:
        | DashboardActionTypes.API_RESPONSE_SUCCESS
        | DashboardActionTypes.API_RESPONSE_ERROR
        | DashboardActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | DashboardActionTypes.GET_DASHBOARD
        | DashboardActionTypes.RESET;

    payload: {
        actionType?: string;
        data?: DashboardsResult | any;
        error?: string;
        validationErrors?: [];

        key?: any
        value?: any
    };
};

type State = {
    dashboards?: any;

    queryParams?: {};

    loading?: boolean;
    value?: boolean;
    tmpDashboards?: any;

    tmpQueryParams?: any;
};

const Dashboard = (state: State = INIT_STATE, action: DashboardActionType) => {
    switch (action.type) {
        case DashboardActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case DashboardActionTypes.GET_DASHBOARD: {
                    return {
                        ...state,
                        dashboard: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case DashboardActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case DashboardActionTypes.GET_DASHBOARD:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case DashboardActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                // case DashboardActionTypes.GET_DASHBOARDS: {
                //     return {
                //         ...state,
                //         error: "Invalid input error",
                //         loading: false,
                //     };
                // }
                case DashboardActionTypes.GET_DASHBOARD:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case DashboardActionTypes.GET_DASHBOARD:
            return {
                ...state,
                dashboard: {},
                loading: true,
                error: "",
            };

        case DashboardActionTypes.RESET:
            return {
                ...state,
                dashboard: {},

                loading: false,
                error: "",
                validationErrors: {},
            };

        default:
            return { ...state };
    }
};

export default Dashboard;
