import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {graphql} from '../../../helpers';
import {checkResponseError, checkServerError, formatBytes, pluckResponse} from "../../../helpers/functions";
import {myAccountActions} from "./actions";
import { MyAccountActionTypes, MyAccountErrors } from './constants';

type ProfileData = {
    payload: {
        data: any;
        queryParams: {
            limit: number;
            page: number;
        }
        token: string;
    };
    type: string;
};

function* sendVerificationEmailLink(): SagaIterator {
    try {

        let param: any = {
            query:`mutation SendVerificationEmailLink {
                sendVerificationEmailLink
            }`,
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, MyAccountErrors.RESPONSE_200);

        const rSendVerificationEmailLink = response.data;

        checkServerError(rSendVerificationEmailLink);

        pluckResponse(rSendVerificationEmailLink, "sendVerificationEmailLink");

        yield put(myAccountActions.apiResponseSuccess(MyAccountActionTypes.SEND_VERIFICATION_EMAIL_LINK, true));

    } catch (error: any) {

        if(typeof error === "string") {
            yield put(myAccountActions.apiResponseError(MyAccountActionTypes.SEND_VERIFICATION_EMAIL_LINK, error));
        } else {
            yield put(myAccountActions.apiResponseValidationErrors(MyAccountActionTypes.SEND_VERIFICATION_EMAIL_LINK, error));
        }
    }
}

function* verifyEmail({ payload: {token} }: ProfileData): SagaIterator {
    try {
        let param = {
            query:`mutation VerifyEmail($token: String!) {
                    verifyEmail(token: $token)
                }`,
            variables: {
                "token": token
            }
        };

        const response: any = yield call(graphql, param, '');

        checkResponseError(response, MyAccountErrors.RESPONSE_200);

        const rVerifyEmail = response.data;

        checkServerError(rVerifyEmail);

        pluckResponse(rVerifyEmail, "verifyEmail");

        yield put(myAccountActions.apiResponseSuccess(MyAccountActionTypes.VERIFY_EMAIL, true));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(myAccountActions.apiResponseError(MyAccountActionTypes.VERIFY_EMAIL, error));
        } else {
            yield put(myAccountActions.apiResponseValidationErrors(MyAccountActionTypes.VERIFY_EMAIL, error));
        }
    }
}

function* getProfileImage(): SagaIterator {
    try {
        const param:any = {
            query: `query UserProfileImage{
                userProfileImage {
                    name
                    preview: url(size: "thumbnail")
                    formattedSize: size
                    type: mime_type
                }
            }`,
        };

        // f.preview blob:http://localhost:3000/6f50e966-594e-4204-9f2c-65020cc998e0
        // f.name Screenshot 2023-10-25 114422.png
        // f.type image/png
        // f.formattedSize 13.95 KB

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, MyAccountErrors.RESPONSE_200);

        const rUPI = response.data;

        checkServerError(rUPI);

        let userProfileImage = pluckResponse(rUPI, "userProfileImage");

        userProfileImage = userProfileImage.map((v: any) => {
            v.formattedSize = formatBytes(v.formattedSize);
            v.uploaded = true;
            v.progress = 0;
            return v;
        });

        yield put(myAccountActions.apiResponseSuccess(MyAccountActionTypes.GET_PROFILE_IMAGE, userProfileImage));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(myAccountActions.apiResponseError(MyAccountActionTypes.GET_PROFILE_IMAGE, error));
        } else {
            yield put(myAccountActions.apiResponseValidationErrors(MyAccountActionTypes.GET_PROFILE_IMAGE, error));
        }
    }
}

function* getVerificationStepsStatus(): SagaIterator {
    try {
        const param:any = {
            query: `query CompleteProfile{
                completeProfile {
                    email {
                        id
                        status
                    },
                    mobile {
                        id
                        status
                    },
                    personal_detail {
                        id
                        status
                    },
                    bank_account {
                        id
                        status
                    },
                    terms_conditions {
                        id
                        status
                    },
                    current_step,
                    complete_progress,
                }
            }`
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, MyAccountErrors.RESPONSE_200);

        const rCP = response.data;

        checkServerError(rCP);

        const completeProfile = pluckResponse(rCP, "completeProfile");

        yield put(myAccountActions.apiResponseSuccess(MyAccountActionTypes.GET_VERIFICATION_STEPS_STATUS, completeProfile));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(myAccountActions.apiResponseError(MyAccountActionTypes.GET_VERIFICATION_STEPS_STATUS, error));
        } else {
            yield put(myAccountActions.apiResponseValidationErrors(MyAccountActionTypes.GET_VERIFICATION_STEPS_STATUS, error));
        }
    }
}

function* updateAccount({ payload: {data} }: ProfileData): SagaIterator {
    try {
        const param = {
            query:`mutation UpdateAccount(
                $calendar: String!,
                $timezone: String!,
            ) {
                updateAccount(
                    calendar: $calendar,
                    timezone: $timezone,
                )
            }`,

            variables: {
                ...data
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, MyAccountErrors.RESPONSE_200);

        const rCP = response.data;

        checkServerError(rCP);

        const updateAccount = pluckResponse(rCP, "updateAccount");

        yield put(myAccountActions.apiResponseSuccess(MyAccountActionTypes.UPDATE_ACCOUNT, updateAccount));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(myAccountActions.apiResponseError(MyAccountActionTypes.UPDATE_ACCOUNT, error));
        } else {
            yield put(myAccountActions.apiResponseValidationErrors(MyAccountActionTypes.UPDATE_ACCOUNT, error));
        }
    }
}

export function* watchSendVerificationEmailLink() {
    yield takeEvery(MyAccountActionTypes.SEND_VERIFICATION_EMAIL_LINK, sendVerificationEmailLink);
}

export function* watchVerifyEmail() {
    yield takeEvery(MyAccountActionTypes.VERIFY_EMAIL, verifyEmail);
}

export function* watchGetProfileImage(): any {
    yield takeEvery(MyAccountActionTypes.GET_PROFILE_IMAGE, getProfileImage);
}

export function* watchGetVerificationStepsStatus(): any {
    yield takeEvery(MyAccountActionTypes.GET_VERIFICATION_STEPS_STATUS, getVerificationStepsStatus);
}

export function* watchUpdateAccount(): any {
    yield takeEvery(MyAccountActionTypes.UPDATE_ACCOUNT, updateAccount);
}

function* myAccountSaga() {
    yield all([
        fork(watchSendVerificationEmailLink),
        fork(watchVerifyEmail),
        fork(watchGetVerificationStepsStatus),
        fork(watchGetProfileImage),
        fork(watchUpdateAccount),
    ]);
}

export default myAccountSaga;
