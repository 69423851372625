import {IBANActionTypes} from './constants';

const INIT_STATE = {
    loading: false,
    error: "",
    validationErrors: {},

    IBANs: {},
    IBANsQueryParams: {},
    IBAN: {},
    IBANInserted: false,
    IBANUpdated: false,
    IBANDeleted: false
};

type IBANActionType = {
    type:
        | IBANActionTypes.API_RESPONSE_SUCCESS
        | IBANActionTypes.API_RESPONSE_ERROR
        | IBANActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | IBANActionTypes.RESET

        | IBANActionTypes.GET_IBANS
        | IBANActionTypes.GET_IBAN
        | IBANActionTypes.INSERT_IBAN
        | IBANActionTypes.UPDATE_IBAN
        | IBANActionTypes.DELETE_IBAN
    ;

    payload: {
        actionType?: string;
        data?: any;
        error?: any;
        validationErrors?: any;

        otp: string;
    };
};

type State = {
    IBAN: any;
    IBANs: any;
};

const IBAN = (state: State = INIT_STATE, action: IBANActionType) => {
    switch (action.type) {
        case IBANActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case IBANActionTypes.GET_IBANS: {
                    return {
                        ...state,
                        loading: false,
                        IBANs: action.payload.data.IBANs.IBANs,
                        IBANsQueryParams: action.payload.data.IBANs.queryParams,
                    };
                }
                case IBANActionTypes.GET_IBAN: {
                    return {
                        ...state,
                        loading: false,
                        IBAN: action.payload.data,
                    };
                }
                case IBANActionTypes.INSERT_IBAN: {
                    return {
                        ...state,
                        loading: false,
                        IBANInserted: true
                    };
                }
                case IBANActionTypes.UPDATE_IBAN: {
                    return {
                        ...state,
                        loading: false,
                        IBANUpdated: true,
                    };
                }

                case IBANActionTypes.DELETE_IBAN: {
                    return {
                        ...state,
                        loading: false,
                        IBANDeleted: true,
                        IBANs: {
                            ...state.IBANs,
                            data: state.IBANs.data.filter((item: any) => {
                                return item.id !== action.payload.data;
                            })
                        }
                    };
                }

                default:
                    return { ...state };
            }

        case IBANActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case IBANActionTypes.GET_IBAN:
                case IBANActionTypes.INSERT_IBAN:
                case IBANActionTypes.UPDATE_IBAN:
                case IBANActionTypes.DELETE_IBAN:
                case IBANActionTypes.GET_IBANS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case IBANActionTypes.API_RESPONSE_VALIDATION_ERRORS:
            switch (action.payload.actionType) {
                case IBANActionTypes.GET_IBAN:
                case IBANActionTypes.INSERT_IBAN:
                case IBANActionTypes.UPDATE_IBAN:
                case IBANActionTypes.DELETE_IBAN: {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case IBANActionTypes.GET_IBANS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case IBANActionTypes.RESET:
            return {
                ...state,

                loading: false,
                error: "",
                validationErrors: {},

                IBANs: {},
                IBANsQueryParams: {},
                IBAN: {},
                IBANInserted: false,
                IBANUpdated: false,
                IBANDeleted: false
            };

        case IBANActionTypes.GET_IBANS:
            return {
                ...state,
                loading: true,
                error: "",
                // IBANs: {},
            };
        case IBANActionTypes.GET_IBAN:
            return {
                ...state,
                loading: true,
                error: "",
                IBAN: {},
            };
        case IBANActionTypes.INSERT_IBAN:
            return {
                ...state,
                loading: true,
                error: "",
                IBANInserted: false,
            };
        case IBANActionTypes.UPDATE_IBAN:
            return {
                ...state,
                loading: true,
                error: "",
                IBANUpdated: false,
            };
        case IBANActionTypes.DELETE_IBAN:
            return {
                ...state,
                loading: true,
                error: "",
                IBANDeleted: false,
            };

        default:
            return { ...state };
    }
};

export default IBAN;
