import {SettingActionTypes} from './constants';

export type SettingActionType = {
    type:
        | SettingActionTypes.API_RESPONSE_SUCCESS
        | SettingActionTypes.API_RESPONSE_ERROR
        | SettingActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | SettingActionTypes.CHANGE_ROLE
        | SettingActionTypes.REFRESH_ROLE
        | SettingActionTypes.RESET
        | SettingActionTypes.GET_USER_ACTION;
    payload: {} | string;
};

type Setting = {
    id: number;
    name: string;
    guard_name: string;
};

class SettingActions {

    apiResponseSuccess = (actionType: string, data: Setting | {}): SettingActionType => ({
        type: SettingActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): SettingActionType => ({
        type: SettingActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): SettingActionType => ({
        type: SettingActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): SettingActionType => ({
        type: SettingActionTypes.RESET,
        payload: {},
    });

    changeRole = (roleName: string): SettingActionType => ({
        type: SettingActionTypes.CHANGE_ROLE,
        payload: { roleName },
    });

    refreshUser = (): SettingActionType => ({
        type: SettingActionTypes.REFRESH_ROLE,
        payload: {},
    });

    getUserAction = (): SettingActionType => ({
        type: SettingActionTypes.GET_USER_ACTION,
        payload: {},
    });
}

const settingActions = new SettingActions();
export { settingActions };
