import { ProductActionTypes } from './constants';
import {isEmptyObject} from "../../helpers/functions";

const INIT_STATE = {
    products: {},
    product: {},
    queryParams: {},

    loading: false,
    error: "",
    validationErrors: {},

    productInserted: false,
    productUpdated: false,

    searchModal: false,
    tmpProducts: {},
    tmpQueryParams: {},
    searchMode: false,
    searchLoading: false,
    searchError: "",
    searchValidationErrors: {}
};


type ProductActionType = {
    type:
        | ProductActionTypes.API_RESPONSE_SUCCESS
        | ProductActionTypes.API_RESPONSE_ERROR
        | ProductActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | ProductActionTypes.GET_PRODUCTS
        | ProductActionTypes.GET_PRODUCT
        | ProductActionTypes.INSERT_PRODUCT
        | ProductActionTypes.UPDATE_PRODUCT
        | ProductActionTypes.RESET

        | ProductActionTypes.OPEN_SEARCH
        | ProductActionTypes.CLOSE_SEARCH
        | ProductActionTypes.SEARCH_PRODUCT
        | ProductActionTypes.SEARCH_TRANSACTION
        | ProductActionTypes.CLEAR_SEARCH;

    payload: {
        actionType?: string;
        data?: any;
        error?: string;
        validationErrors?: [];
    };
};

type State = {
    products?: {};

    queryParams?: {};

    loading?: boolean;
    value?: boolean;
    tmpProducts?: any;

    tmpQueryParams?: any;
};

const Product = (state: State = INIT_STATE, action: ProductActionType) => {
    switch (action.type) {
        case ProductActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ProductActionTypes.GET_PRODUCTS: {
                    return {
                        ...state,
                        products: action.payload.data?.products,
                        queryParams: action.payload.data?.queryParams,
                        loading: false,
                    };
                }
                case ProductActionTypes.GET_PRODUCT: {
                    return {
                        ...state,
                        product: action.payload.data,
                        loading: false,
                    };
                }
                case ProductActionTypes.INSERT_PRODUCT: {
                    return {
                        ...state,
                        productInserted: action.payload.data,
                        loading: false,
                    };
                }
                case ProductActionTypes.UPDATE_PRODUCT: {
                    return {
                        ...state,
                        productUpdated: action.payload.data,
                        loading: false,
                    };
                }
                case ProductActionTypes.SEARCH_PRODUCT: {
                    return {
                        ...state,
                        tmpProducts: isEmptyObject(state.tmpProducts)? state.products: state.tmpProducts,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        products: action.payload.data?.products,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ProductActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ProductActionTypes.GET_PRODUCTS:
                case ProductActionTypes.GET_PRODUCT:
                case ProductActionTypes.INSERT_PRODUCT:
                case ProductActionTypes.UPDATE_PRODUCT:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case ProductActionTypes.SEARCH_PRODUCT:
                case ProductActionTypes.SEARCH_TRANSACTION: {
                    return {
                        ...state,
                        searchError: action.payload.error,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ProductActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case ProductActionTypes.GET_PRODUCTS:
                {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case ProductActionTypes.GET_PRODUCT:
                case ProductActionTypes.INSERT_PRODUCT:
                case ProductActionTypes.UPDATE_PRODUCT:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case ProductActionTypes.SEARCH_PRODUCT:
                {
                    return {
                        ...state,
                        searchValidationErrors: action.payload.validationErrors,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ProductActionTypes.GET_PRODUCTS:
            return {
                ...state,
                products: {},
                loading: true,
                error: "",
            };
        case ProductActionTypes.GET_PRODUCT:
            return {
                ...state,
                product: {},
                loading: true,
                error: "",
            };

        case ProductActionTypes.INSERT_PRODUCT:
            return {
                ...state,
                productInserted: false,
                loading: true,
                error: "",
            };

        case ProductActionTypes.UPDATE_PRODUCT:
            return {
                ...state,
                productUpdated: false,
                loading: true,
                error: "",
            };

        case ProductActionTypes.RESET:
            return {
                ...state,
                transactions: {},

                product: {},
                products: {},
                queryParams: {},

                loading: false,
                error: "",
                validationErrors: {},

                productInserted: false,
                productUpdated: false,

                searchModal: false,
                searchLoading: false,
                searchMode: false,
                searchError: "",
                searchValidationErrors: {},

                tmpProducts: {},
                tmpQueryParams: {},
            };


        // search actions
        case ProductActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case ProductActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},
            };
        case ProductActionTypes.SEARCH_PRODUCT:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case ProductActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                products: state.tmpProducts,
                queryParams: state.tmpQueryParams,
                tmpProducts: {},
                tmpQueryParams: {},
                searchMode: false,
            };

        default:
            return { ...state };
    }
};

export default Product;
