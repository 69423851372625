import {MyAccountActionTypes} from './constants';

const INIT_STATE = {
    loading: false,
    error: "",
    validationErrors: {},

    verificationEmailSent: false,
    emailVerified: false,

    verificationStepsStatus: {},

    profileImage: [],

    accountUpdated: false
};

type MyAccountActionType = {
    type:
        | MyAccountActionTypes.API_RESPONSE_SUCCESS
        | MyAccountActionTypes.API_RESPONSE_ERROR
        | MyAccountActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | MyAccountActionTypes.RESET

        | MyAccountActionTypes.SEND_VERIFICATION_EMAIL_LINK
        | MyAccountActionTypes.VERIFY_EMAIL

        | MyAccountActionTypes.GET_VERIFICATION_STEPS_STATUS

        | MyAccountActionTypes.GET_PROFILE_IMAGE
        | MyAccountActionTypes.UPLOAD_PROFILE_IMAGE

        | MyAccountActionTypes.UPDATE_ACCOUNT;

    payload: {
        actionType?: string;
        data?: any;
        error?: any;
        validationErrors?: any;

        key?: any;
        value?: any;

        // for upload purpose
        file: {fileId: string} & File;
        fileId: string;
        progress: number;
        retry: boolean;
    };
};

type State = {
    verificationStepsStatus: any;
};


const MyAccount = (state: State = INIT_STATE, action: MyAccountActionType) => {
    switch (action.type) {
        case MyAccountActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case MyAccountActionTypes.SEND_VERIFICATION_EMAIL_LINK: {
                    return {
                        ...state,
                        verificationEmailSent: true,
                        loading: false,
                    };
                }
                case MyAccountActionTypes.VERIFY_EMAIL: {
                    return {
                        ...state,
                        emailVerified: true,
                        loading: false,
                    };
                }
                case MyAccountActionTypes.GET_VERIFICATION_STEPS_STATUS: {
                    return {
                        ...state,
                        loading: false,
                        verificationStepsStatus: action.payload.data,
                    };
                }
                case MyAccountActionTypes.GET_PROFILE_IMAGE: {
                    return {
                        ...state,
                        loading: false,
                        profileImage: action.payload.data
                    };
                }
                case MyAccountActionTypes.UPDATE_ACCOUNT: {
                    return {
                        ...state,
                        loading: false,
                        accountUpdated: action.payload.data
                    };
                }

                default:
                    return { ...state };
            }

        case MyAccountActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case MyAccountActionTypes.SEND_VERIFICATION_EMAIL_LINK:
                case MyAccountActionTypes.VERIFY_EMAIL:
                case MyAccountActionTypes.GET_PROFILE_IMAGE:
                case MyAccountActionTypes.GET_VERIFICATION_STEPS_STATUS:
                case MyAccountActionTypes.UPDATE_ACCOUNT:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case MyAccountActionTypes.API_RESPONSE_VALIDATION_ERRORS:
            switch (action.payload.actionType) {
                case MyAccountActionTypes.SEND_VERIFICATION_EMAIL_LINK:
                case MyAccountActionTypes.VERIFY_EMAIL:
                case MyAccountActionTypes.GET_PROFILE_IMAGE:
                case MyAccountActionTypes.GET_VERIFICATION_STEPS_STATUS:
                case MyAccountActionTypes.UPDATE_ACCOUNT:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case MyAccountActionTypes.RESET:
            return {
                ...state,

                loading: false,
                error: "",
                validationErrors: {},

                verificationEmailSent: false,
                emailVerified: false,

                verificationStepsStatus: {},

                profileImage: [],

                accountUpdated: false,
            };


        case MyAccountActionTypes.SEND_VERIFICATION_EMAIL_LINK:
            return {
                ...state,
                verificationEmailSent: false,
                loading: true,
                error: "",
            };

        case MyAccountActionTypes.VERIFY_EMAIL:
            return {
                ...state,
                emailVerified: false,
                loading: true,
                error: "",
            };

        case MyAccountActionTypes.GET_VERIFICATION_STEPS_STATUS:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case MyAccountActionTypes.GET_PROFILE_IMAGE:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case MyAccountActionTypes.UPDATE_ACCOUNT:
            return {
                ...state,
                loading: true,
                error: "",
                accountUpdated: false
            };
        default:
            return { ...state };
    }
};

export default MyAccount;
