import {ProviderActionTypes} from './constants';

export type ProviderActionType = {
    type:
        | ProviderActionTypes.API_RESPONSE_SUCCESS
        | ProviderActionTypes.API_RESPONSE_ERROR
        | ProviderActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | ProviderActionTypes.RESET
        | ProviderActionTypes.PROVIDERS
        | ProviderActionTypes.PROVIDER
        | ProviderActionTypes.UPDATE
        | ProviderActionTypes.CHANGE_STATUS;
    payload: {} | string;
};

type Provider = {
    id: number;
    name: string;
    guard_name: string;
};

class ProviderActions {

    apiResponseSuccess = (actionType: string, data: Provider | {}): ProviderActionType => ({
        type: ProviderActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): ProviderActionType => ({
        type: ProviderActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): ProviderActionType => ({
        type: ProviderActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): ProviderActionType => ({
        type: ProviderActionTypes.RESET,
        payload: {},
    });

    getProviders = (): ProviderActionType => ({
        type: ProviderActionTypes.PROVIDERS,
        payload: {},
    });

    getProvider = (data: any): ProviderActionType => ({
        type: ProviderActionTypes.PROVIDER,
        payload: {data},
    });

    update = (data: any): ProviderActionType => ({
        type: ProviderActionTypes.UPDATE,
        payload: {data},
    });

    changeStatus = (data: any): ProviderActionType => ({
        type: ProviderActionTypes.CHANGE_STATUS,
        payload: {data},
    });
}

const providerActions = new ProviderActions();
export { providerActions };
