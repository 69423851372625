import {TacActionTypes} from './constants';

const INIT_STATE = {
    loading: false,
    error: "",
    validationErrors: {},

    TACs: {},
    TACsQueryParams: {},
    TAC: {},

    tacApproved: false,

    TACUploaded: false,
    tcVideoProgress: 0,
    devices: null
};

type TACActionType = {
    type:
        | TacActionTypes.API_RESPONSE_SUCCESS
        | TacActionTypes.API_RESPONSE_ERROR
        | TacActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | TacActionTypes.RESET

        | TacActionTypes.GET_TACs
        | TacActionTypes.GET_TAC
        | TacActionTypes.APPROVE_TAC
        | TacActionTypes.UPLOAD_TERM_AND_CONDITION
        | TacActionTypes.UPLOAD_TAC_PROGRESS
        | TacActionTypes.SET_DEVICES
    ;

    payload: {
        actionType?: string;
        data?: any;
        error?: any;
        validationErrors?: any;

        // for upload purpose
        file: {fileId: string} & File;
        fileId: string;
        progress: number;
        retry: boolean;
    };
};

type State = {
    TAC: any;
    TACUploaded: boolean;
};

const TAC = (state: State = INIT_STATE, action: TACActionType) => {
    switch (action.type) {
        case TacActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case TacActionTypes.GET_TACs: {
                    return {
                        ...state,
                        loading: false,
                        TACs: action.payload.data.TACs.TACs,
                        TACsQueryParams: action.payload.data.TACs.queryParams,
                    };
                }
                case TacActionTypes.GET_TAC: {
                    return {
                        ...state,
                        loading: false,
                        TAC: action.payload.data,
                    };
                }
                case TacActionTypes.APPROVE_TAC: {
                    return {
                        ...state,
                        loading: false,
                        tacApproved: true
                    };
                }
                case TacActionTypes.UPLOAD_TERM_AND_CONDITION: {
                    return {
                        ...state,
                        loading: false,
                        TACUploaded: action.payload.data
                    };
                }

                default:
                    return { ...state };
            }

        case TacActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case TacActionTypes.GET_TACs:
                case TacActionTypes.GET_TAC:
                case TacActionTypes.APPROVE_TAC:
                case TacActionTypes.UPLOAD_TERM_AND_CONDITION:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case TacActionTypes.API_RESPONSE_VALIDATION_ERRORS:
            switch (action.payload.actionType) {
                case TacActionTypes.GET_TAC:
                case TacActionTypes.APPROVE_TAC:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case TacActionTypes.GET_TACs: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case TacActionTypes.UPLOAD_TERM_AND_CONDITION: {
                    return {
                        ...state,
                        TACUploaded: false,
                    };
                }
                default:
                    return { ...state };
            }

        case TacActionTypes.RESET:
            return {
                ...state,

                loading: false,
                error: "",
                validationErrors: {},

                TACs: {},
                TACsQueryParams: {},
                TAC: {},

                tacApproved: false,

                TACUploaded: false,
                tcVideoProgress: 0,
            };

        case TacActionTypes.GET_TACs:
            return {
                ...state,
                loading: true,
                error: "",
                // TACs: {},
            };
        case TacActionTypes.GET_TAC:
            return {
                ...state,
                loading: true,
                error: "",
                TAC: {},
            };
        case TacActionTypes.APPROVE_TAC:
            return {
                ...state,
                loading: true,
                error: "",
                tacApproved: false,
            };
        case TacActionTypes.UPLOAD_TERM_AND_CONDITION:
            return {
                ...state,
                TACUploaded: false,
                loading: true,
                error: ""
            };
        case TacActionTypes.UPLOAD_TAC_PROGRESS:
            return {
                ...state,
                tcVideoProgress: action.payload.progress
            };
        case TacActionTypes.SET_DEVICES:
            return {
                ...state,
                devices: action.payload.data
            };

        default:
            return { ...state };
    }
};

export default TAC;
