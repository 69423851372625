import {PersonalDetailActionTypes} from './constants';

const INIT_STATE = {
    loading: false,
    error: "",
    validationErrors: {},

    personalDetails: {},
    personalDetailsQueryParams: {},

    personalDetail: {},

    updatedPersonalDetails: false,

    updated: false,

    myPersonalDetailUpdated: false
};

type PersonalDetailActionType = {
    type:
        | PersonalDetailActionTypes.API_RESPONSE_SUCCESS
        | PersonalDetailActionTypes.API_RESPONSE_ERROR
        | PersonalDetailActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | PersonalDetailActionTypes.RESET

        | PersonalDetailActionTypes.GET_PERSONAL_DETAILS
        | PersonalDetailActionTypes.GET_PERSONAL_DETAIL
        | PersonalDetailActionTypes.UPDATE_PERSONAL_DETAIL

        | PersonalDetailActionTypes.UPDATE_STATUS
        | PersonalDetailActionTypes.UPDATE_MY_PERSONAL_DETAIL
    ;

    payload: {
        actionType?: string;
        data?: any;
        error?: any;
        validationErrors?: any;

        key?: any;
        value?: any;

        // for upload purpose
        file: {fileId: string} & File;
        fileId: string;
        progress: number;
        retry: boolean;
    };
};

type State = {
    personalDetail: any;
};

const PersonalDetail = (state: State = INIT_STATE, action: PersonalDetailActionType) => {
    switch (action.type) {
        case PersonalDetailActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case PersonalDetailActionTypes.SET_PERSONAL_DETAIL: {
                    return {
                        ...state,
                        personalDetail: action.payload.data,
                    };
                }

                case PersonalDetailActionTypes.GET_PERSONAL_DETAILS: {
                    return {
                        ...state,
                        loading: false,
                        personalDetails: action.payload.data.personalDetails.personalDetails,
                        personalDetailsQueryParams: action.payload.data.personalDetails.queryParams,
                    };
                }

                case PersonalDetailActionTypes.GET_PERSONAL_DETAIL: {

                    return {
                        ...state,
                        loading: false,
                        personalDetail: action.payload.data,
                    };
                }

                case PersonalDetailActionTypes.UPDATE_PERSONAL_DETAIL: {
                    return {
                        ...state,
                        loading: false,
                        updatedPersonalDetails: true,
                        personalDetail: {
                            ...state.personalDetail,
                            ...action.payload.data
                        }
                    };
                }
                case PersonalDetailActionTypes.UPDATE_STATUS: {
                    return {
                        ...state,
                        loading: false,
                        updated: true,
                    };
                }
                case PersonalDetailActionTypes.UPDATE_MY_PERSONAL_DETAIL: {
                    return {
                        ...state,
                        loading: false,
                        myPersonalDetailUpdated: true,
                    };
                }

                default:
                    return { ...state };
            }

        case PersonalDetailActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case PersonalDetailActionTypes.GET_PERSONAL_DETAILS:
                case PersonalDetailActionTypes.UPDATE_PERSONAL_DETAIL:
                case PersonalDetailActionTypes.GET_PERSONAL_DETAIL:
                case PersonalDetailActionTypes.UPDATE_STATUS:
                case PersonalDetailActionTypes.UPDATE_MY_PERSONAL_DETAIL:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case PersonalDetailActionTypes.API_RESPONSE_VALIDATION_ERRORS:
            switch (action.payload.actionType) {
                case PersonalDetailActionTypes.GET_PERSONAL_DETAIL:
                case PersonalDetailActionTypes.UPDATE_PERSONAL_DETAIL:
                case PersonalDetailActionTypes.UPDATE_STATUS:
                case PersonalDetailActionTypes.UPDATE_MY_PERSONAL_DETAIL:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case PersonalDetailActionTypes.GET_PERSONAL_DETAILS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case PersonalDetailActionTypes.RESET:
            return {
                ...state,

                loading: false,
                error: "",
                validationErrors: {},

                personalDetails: {},
                personalDetailsQueryParams: {},

                personalDetail: {},

                updatedPersonalDetails: false,

                updated: false,

                myPersonalDetailUpdated: false
            };

        case PersonalDetailActionTypes.GET_PERSONAL_DETAILS:
            return {
                ...state,
                loading: true,
                error: "",
                // personalDetails: {},
            };
        case PersonalDetailActionTypes.GET_PERSONAL_DETAIL:
            return {
                ...state,
                loading: true,
                error: "",
                personalDetail: {},
            };
        case PersonalDetailActionTypes.UPDATE_PERSONAL_DETAIL:
            return {
                ...state,
                loading: true,
                error: "",
                updatedPersonalDetails: false
            };
        case PersonalDetailActionTypes.UPDATE_STATUS:
            return {
                ...state,
                loading: true,
                error: "",
                updated: false
            };
        case PersonalDetailActionTypes.UPDATE_MY_PERSONAL_DETAIL:
            return {
                ...state,
                loading: true,
                error: "",
                myPersonalDetailUpdated: false
            };

        default:
            return { ...state };
    }
};

export default PersonalDetail;
