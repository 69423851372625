const config:any = {
    NAME: process.env.REACT_APP_NAME,
    API_URL: process.env.REACT_APP_API_URL,
    RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    IDLE_TIME: process.env.REACT_APP_IDLE_TIME,
    REFRESH_TIME: process.env.REACT_APP_REFRESH_TIME,
    OTP_TIME: process.env.REACT_APP_OTP_TIME,
};

export default config;
