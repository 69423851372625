import {APICore} from 'helpers/api/apiCore';
import {AuthActionTypes} from './constants';

const api = new APICore();

const INIT_STATE = {
    user: api.getLoggedInUser(),
    loading: false,

    oauthRedirectUrls: [],
    confirmUser: {}
};

type UserData = {
    id: number;
    email: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    token: string;
};

type SocialCallbackType = {
    google?: string;
    facebook?: string;
    github?: string;
    apple?: string;
}

type AuthActionType = {
    type:
        | AuthActionTypes.API_RESPONSE_SUCCESS
        | AuthActionTypes.API_RESPONSE_ERROR
        | AuthActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | AuthActionTypes.LOGIN_USER
        | AuthActionTypes.SIGNUP_USER
        | AuthActionTypes.LOGOUT_USER
        | AuthActionTypes.RESET
        | AuthActionTypes.SOCIAL_CALLBACK_URL
        | AuthActionTypes.SET_ERROR
        | AuthActionTypes.REFRESH_TOKEN;
    payload: {
        actionType?: string;
        data?: any;
        socialCallback?: SocialCallbackType
        error?: string;
        validationErrors?: [];
    };
};

type State = {
    user?: UserData | {};
    loading?: boolean;
    value?: boolean;
};

const Auth = (state: State = INIT_STATE, action: AuthActionType) => {
    switch (action.type) {
        case AuthActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AuthActionTypes.LOGIN_USER: {
                    return {
                        ...state,
                        user: action.payload.data,
                        userLoggedIn: true,
                        loading: false,
                    };
                }
                case AuthActionTypes.SIGNUP_USER: {
                    return {
                        ...state,
                        userSignUp: true,
                        loading: false,
                        confirmUser: action.payload.data,
                    };
                }
                case AuthActionTypes.LOGOUT_USER: {
                    return {
                        ...state,
                        user: null,
                        loading: false,
                        userLogout: true,
                        userLoggedIn: false,
                    };
                }
                case AuthActionTypes.FORGOT_PASSWORD: {
                    return {
                        ...state,
                        forgotPasswordSuccess: action.payload.data,
                        loading: false,
                    };
                }
                case AuthActionTypes.RESET_PASSWORD: {
                    return {
                        ...state,
                        resetPasswordSuccess: action.payload.data,
                        loading: false,
                    };
                }
                case AuthActionTypes.SOCIAL_CALLBACK_URL: {
                    return {
                        ...state,
                        oauthRedirectUrls: action.payload.data,
                        loading: false,
                    };
                }
                case AuthActionTypes.REFRESH_TOKEN: {
                    return {
                        ...state,
                        loading: false,
                        user: action.payload.data,
                    };
                }
                default:
                    return { ...state };
            }

        case AuthActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AuthActionTypes.LOGIN_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        userLoggedIn: false,
                        loading: false,
                    };
                }
                case AuthActionTypes.SIGNUP_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        userSignUp: false,
                        loading: false,
                    };
                }
                case AuthActionTypes.FORGOT_PASSWORD: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case AuthActionTypes.RESET_PASSWORD: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case AuthActionTypes.SOCIAL_CALLBACK_URL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        oauthRedirectUrls: false,
                    };
                }
                case AuthActionTypes.REFRESH_TOKEN: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case AuthActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case AuthActionTypes.LOGIN_USER: {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        userLoggedIn: false,
                        loading: false,
                    };
                }
                case AuthActionTypes.SIGNUP_USER: {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        userSignUp: false,
                        loading: false,
                    };
                }
                case AuthActionTypes.FORGOT_PASSWORD: {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case AuthActionTypes.RESET_PASSWORD: {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case AuthActionTypes.LOGIN_USER:
            return { ...state, loading: true, userLoggedIn: false };
        case AuthActionTypes.SIGNUP_USER:
            return { ...state, loading: true, userSignUp: false };
        case AuthActionTypes.LOGOUT_USER:
            return { ...state, loading: true, userLogout: false };
        case AuthActionTypes.SOCIAL_CALLBACK_URL:
            return { ...state, loading: true };
        case AuthActionTypes.REFRESH_TOKEN:
            return { ...state, loading: true };
        case AuthActionTypes.SET_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case AuthActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                userSignUp: false,
                userLoggedIn: false,
                passwordChange: false,
                forgotPasswordSuccess: null,
                resetPasswordSuccess: null,
            };
        default:
            return { ...state };
    }
};

export default Auth;
