import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { graphql } from 'helpers';
import {TransferActionTypes, TransferErrors} from './constants';
import {
    checkResponseError,
    checkServerError,
    pluckResponse,
} from "../../helpers/functions";
import {transferActions} from "./actions";
import { APICore } from '../../helpers/api/apiCore';

type TransferData = {
    payload: {

        queryParams: {
            limit: string;
            page: string;
            orderBy: string;
            sort: string;
        };

        data: any;
        id: any;
    };
    type: string;
};

function* getTransfers({ payload: { queryParams } }: TransferData): SagaIterator {

    try {
        const param = {
            query:`query Transfers($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $accountId: String) {
                transfers(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId) {
                    data {
                        id
                        from {
                            name
                            balanceFloatNum
                            meta {
                                sign
                                currency
                            }
                            holder {
                                id
                                username
                                email
                            }
                        }
                        to {
                            name
                            balanceFloatNum
                            meta {
                                sign
                                currency
                            }
                            holder {
                                id
                                username
                                email
                            }
                        }
                        withdraw {
                            amountFloat
                            meta {
                                description
                            }
                        }
                        deposit {
                            amountFloat
                            meta {
                                description
                            }
                            rate
                        }
                        fee
                        feeFloat
                        status
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, TransferErrors.RESPONSE_200);

        const rTransfers = response.data;

        checkServerError(rTransfers);

        const transfers = pluckResponse(rTransfers, "transfers");

        const result = {
            transfers,
            queryParams
        }

        yield put(transferActions.apiResponseSuccess(TransferActionTypes.GET_TRANSFERS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(transferActions.apiResponseError(TransferActionTypes.GET_TRANSFERS, error));
        } else {
            yield put(transferActions.apiResponseValidationErrors(TransferActionTypes.GET_TRANSFERS, error));
        }
    }
}

function* getTransfer({ payload: { id } }: TransferData): SagaIterator {

    try {
        const param = {
            query:`query Transfer($id: String!) {
                transfer(id: $id) {
                    id
                    from {
                        name
                        balanceFloatNum
                        meta {
                            sign
                            currency
                        }
                        holder {
                            id
                            username
                            email
                        }
                    }
                    to {
                        name
                        balanceFloatNum
                        meta {
                            sign
                            currency
                        }
                        holder {
                            id
                            username
                            email
                        }
                    }
                    withdraw {
                        amountFloat
                        meta {
                            description
                        }
                    }
                    deposit {
                        amountFloat
                        meta {
                            description
                        }
                        rate
                        payable {
                            username
                        }
                        status
                        amount
                    }
                    fee
                    feeFloat
                    status
                    created_at
                }
            }`,

            variables: {
                id: id
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, TransferErrors.RESPONSE_200);

        const rT = response.data;

        checkServerError(rT);

        const transfer = pluckResponse(rT, "transfer");

        yield put(transferActions.apiResponseSuccess(TransferActionTypes.GET_TRANSFER, transfer));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(transferActions.apiResponseError(TransferActionTypes.GET_TRANSFER, error));
        } else {
            yield put(transferActions.apiResponseValidationErrors(TransferActionTypes.GET_TRANSFER, error));
        }
    }
}

function* insertTransfer({ payload: {data} }: TransferData): SagaIterator {

    try {
        const param = {
            query:`mutation CreateTransfer(
                $accountId: String!,
                $wallet: String!,
                $amount: String!
                $twoFaOtp: String
                $otp: String
            ) {
                createTransfer(
                    accountId: $accountId,
                    wallet: $wallet,
                    amount: $amount,
                    twoFaOtp: $twoFaOtp
                    otp: $otp
                )
            }`,

            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, TransferErrors.RESPONSE_200);

        const rCD = response.data;

        checkServerError(rCD);

        const createTransfer = pluckResponse(rCD, "createTransfer");

        const api = new APICore();
        const user = api.getLoggedInUser();

        yield put(transferActions.getTransfers({
            page: 1,
            limit: 5,
            orderBy: "id",
            sort: "desc",
            accountId: user.permissions.includes("View All Transfers")? "all": "",
        }));

        yield put(transferActions.apiResponseSuccess(TransferActionTypes.INSERT_TRANSFER, createTransfer));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(transferActions.apiResponseError(TransferActionTypes.INSERT_TRANSFER, error));
        } else {
            yield put(transferActions.apiResponseValidationErrors(TransferActionTypes.INSERT_TRANSFER, error));
        }
    }
}

function* checkTransfer({ payload: {data} }: TransferData): SagaIterator {

    try {
        const param = {
            query:`mutation CheckTransfer(
                $accountId: String!,
                $wallet: String!,
                $amount: String!
            ) {
                checkTransfer(
                    accountId: $accountId,
                    wallet: $wallet,
                    amount: $amount,
                )
            }`,

            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, TransferErrors.RESPONSE_200);

        const rCT = response.data;

        checkServerError(rCT);

        const checkTransfer = pluckResponse(rCT, "checkTransfer");

        yield put(transferActions.apiResponseSuccess(TransferActionTypes.CHECK_TRANSFER, checkTransfer));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(transferActions.apiResponseError(TransferActionTypes.CHECK_TRANSFER, error));
        } else {
            yield put(transferActions.apiResponseValidationErrors(TransferActionTypes.CHECK_TRANSFER, error));
        }
    }
}

function* searchTransfer({ payload: { queryParams } }: TransferData): SagaIterator {
    try {
        const param = {
            query:`query Transfers(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $transferId: String,
                $wallet: String, 
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $accountId: String,
                $transactionNames: [String]
            ) {
                transfers(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort,
                    transferId: $transferId,
                    wallet: $wallet,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    accountId: $accountId,
                    transactionNames: $transactionNames
                ) {
                    data {
                        id
                        from {
                            name
                            balanceFloatNum
                            meta {
                                sign
                                currency
                            }
                            holder {
                                id
                                username
                                email
                            }
                        }
                        to {
                            name
                            balanceFloatNum
                            meta {
                                sign
                                currency
                            }
                            holder {
                                id
                                username
                                email
                            }
                        }
                        withdraw {
                            amountFloat
                            meta {
                                description
                            }
                        }
                        deposit {
                            amountFloat
                            meta {
                                description
                            }
                            rate
                        }
                        fee
                        feeFloat
                        status
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, TransferErrors.RESPONSE_200);

        const rTransfers = response.data;

        checkServerError(rTransfers);

        const transfers = pluckResponse(rTransfers, "transfers");

        const result = {
            transfers,
            queryParams
        }

        yield put(transferActions.apiResponseSuccess(TransferActionTypes.SEARCH_TRANSFER, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(transferActions.apiResponseError(TransferActionTypes.SEARCH_TRANSFER, error));
        } else {
            yield put(transferActions.apiResponseValidationErrors(TransferActionTypes.SEARCH_TRANSFER, error));
        }
    }
}

function* getTransactions({ payload: { queryParams } }: TransferData): SagaIterator {

    try {
        const param = {
            query:`query TransferTransactions($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $accountId: String) {
                transferTransactions(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId) {
                    data {
                        wallet {
                            uuid
                            name
                            holder {
                                id
                                email
                                username
                                display_name
                            }
                            meta {
                                sign
                                currency
                            }
                        }
                        type
                        amountFloat
                        balanceFloat
                        confirmed
                        meta {
                            description
                        }
                        name
                        transaction_id
                        thread_id
                        status
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, TransferErrors.RESPONSE_200);

        const rTransactions = response.data;

        checkServerError(rTransactions);

        const transactions = pluckResponse(rTransactions, "transferTransactions");

        const result = {
            transactions,
            queryParams
        }

        yield put(transferActions.apiResponseSuccess(TransferActionTypes.GET_TRANSACTIONS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(transferActions.apiResponseError(TransferActionTypes.GET_TRANSACTIONS, error));
        } else {
            yield put(transferActions.apiResponseValidationErrors(TransferActionTypes.GET_TRANSACTIONS, error));
        }
    }
}

function* searchTransaction({ payload: { queryParams } }: TransferData): SagaIterator {
    try {
        const param = {
            query:`query TransferTransactions(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $wallet: String, 
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $transactionId: String,
                $threadId: String,
                $accountId: String,
                $transactionNames: [String],
                $transactionType: String
            ) {
                transferTransactions(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort, 
                    wallet: $wallet,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    transactionId: $transactionId,
                    threadId: $threadId,
                    accountId: $accountId,
                    transactionNames: $transactionNames,
                    transactionType: $transactionType
                ) {
                    data {
                        wallet {
                            uuid
                            name
                            holder {
                                id
                                email
                                username
                                display_name
                            }
                            meta {
                                sign
                                currency
                            }
                        }
                        type
                        amountFloat
                        balanceFloat
                        confirmed
                        meta {
                            description
                        }
                        name
                        transaction_id
                        thread_id
                        status
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, TransferErrors.RESPONSE_200);

        const rTransactions = response.data;

        checkServerError(rTransactions);

        const transactions = pluckResponse(rTransactions, "transferTransactions");

        const result = {
            transactions,
            queryParams
        }

        yield put(transferActions.apiResponseSuccess(TransferActionTypes.SEARCH_TRANSACTION, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(transferActions.apiResponseError(TransferActionTypes.SEARCH_TRANSACTION, error));
        } else {
            yield put(transferActions.apiResponseValidationErrors(TransferActionTypes.SEARCH_TRANSACTION, error));
        }
    }
}

function* previewTransferDetail({ payload: { data } }: TransferData): SagaIterator {
    try {
        const param = {
            query:`query PreviewTransferDetail($wallet: String!, $amount: String!) {
                previewTransferDetail(wallet: $wallet, amount: $amount) {
                    subTotal
                    fee
                    total
                }
            }`,

            variables: {
                ...data
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, TransferErrors.RESPONSE_200);

        const rED = response.data;

        checkServerError(rED);

        const previewTransferDetail = pluckResponse(rED, "previewTransferDetail");

        yield put(transferActions.apiResponseSuccess(TransferActionTypes.PREVIEW_TRANSFER_DETAIL, previewTransferDetail));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(transferActions.apiResponseError(TransferActionTypes.PREVIEW_TRANSFER_DETAIL, error));
        } else {
            yield put(transferActions.apiResponseValidationErrors(TransferActionTypes.PREVIEW_TRANSFER_DETAIL, error));
        }
    }
}

function* exportTransactions({ payload: { queryParams } }: TransferData): SagaIterator {
    try {
        const param = {
            query:`query ExportTransferTransactions(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $wallet: String, 
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $transactionId: String,
                $threadId: String,
                $accountId: String,
                $transactionNames: [String],
                $transactionType: String
            ) {
                exportTransferTransactions(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort, 
                    wallet: $wallet,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    transactionId: $transactionId,
                    threadId: $threadId,
                    accountId: $accountId,
                    transactionNames: $transactionNames,
                    transactionType: $transactionType
                )
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, TransferErrors.RESPONSE_200);

        const rTransactions = response.data;

        checkServerError(rTransactions);

        const transactions = pluckResponse(rTransactions, "exportTransferTransactions");

        yield put(transferActions.apiResponseSuccess(TransferActionTypes.EXPORT_TRANSACTIONS, transactions));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(transferActions.apiResponseError(TransferActionTypes.EXPORT_TRANSACTIONS, error));
        } else {
            yield put(transferActions.apiResponseValidationErrors(TransferActionTypes.EXPORT_TRANSACTIONS, error));
        }
    }
}

function* exportTransfers({ payload: { queryParams } }: TransferData): SagaIterator {
    try {
        const param = {
            query:`query ExportTransfers(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $transferId: String,
                $wallet: String, 
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $accountId: String
                $transactionNames: [String]
            ) {
                exportTransfers(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort,
                    transferId: $transferId,
                    wallet: $wallet,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    accountId: $accountId,
                    transactionNames: $transactionNames
                )
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, TransferErrors.RESPONSE_200);

        const rTransactions = response.data;

        checkServerError(rTransactions);

        const exportTransfers = pluckResponse(rTransactions, "exportTransfers");

        yield put(transferActions.apiResponseSuccess(TransferActionTypes.EXPORT, exportTransfers));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(transferActions.apiResponseError(TransferActionTypes.EXPORT, error));
        } else {
            yield put(transferActions.apiResponseValidationErrors(TransferActionTypes.EXPORT, error));
        }
    }
}

export function* watchGetTransfers() {
    yield takeEvery(TransferActionTypes.GET_TRANSFERS, getTransfers);
}

export function* watchGetTransfer() {
    yield takeEvery(TransferActionTypes.GET_TRANSFER, getTransfer);
}

export function* watchInsertTransfer() {
    yield takeEvery(TransferActionTypes.INSERT_TRANSFER, insertTransfer);
}

export function* watchCheckTransfer() {
    yield takeEvery(TransferActionTypes.CHECK_TRANSFER, checkTransfer);
}

export function* watchSearchTransfer() {
    yield takeEvery(TransferActionTypes.SEARCH_TRANSFER, searchTransfer);
}

export function* watchGetTransactions() {
    yield takeEvery(TransferActionTypes.GET_TRANSACTIONS, getTransactions);
}

export function* watchSearchTransaction() {
    yield takeEvery(TransferActionTypes.SEARCH_TRANSACTION, searchTransaction);
}

export function* watchPreviewTransferDetail() {
    yield takeEvery(TransferActionTypes.PREVIEW_TRANSFER_DETAIL, previewTransferDetail);
}

export function* watchExportTransfers() {
    yield takeEvery(TransferActionTypes.EXPORT, exportTransfers);
}

export function* watchExportTransactions() {
    yield takeEvery(TransferActionTypes.EXPORT_TRANSACTIONS, exportTransactions);
}

function* transfersSaga() {
    yield all([
        fork(watchGetTransfers),
        fork(watchGetTransfer),
        fork(watchInsertTransfer),
        fork(watchCheckTransfer),
        fork(watchSearchTransfer),
        fork(watchGetTransactions),
        fork(watchSearchTransaction),
        fork(watchPreviewTransferDetail),
        fork(watchExportTransfers),
        fork(watchExportTransactions)
    ]);
}

export default transfersSaga;
