
export enum ApiServiceActionTypes {
    API_RESPONSE_SUCCESS = '@@apiService/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@apiService/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@apiService/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@apiService/RESET',

    CREATE_API_TOKEN = '@@apiService/CREATE_API_TOKEN',
    GET_API_TOKEN = '@@apiService/GET_API_TOKEN',
}

export enum ApiServiceErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



