import {NCardBackActionTypes} from './constants';

const INIT_STATE = {
    loading: false,
    error: "",
    validationErrors: {},

    nationalCardBack: [],
    nationalCardBackStatus: "",

    updated: false
};

type NCardBackActionType = {
    type:
        | NCardBackActionTypes.API_RESPONSE_SUCCESS
        | NCardBackActionTypes.API_RESPONSE_ERROR
        | NCardBackActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | NCardBackActionTypes.RESET

        | NCardBackActionTypes.SET_NC_BACK

        | NCardBackActionTypes.UPLOAD_NC_BACK
        | NCardBackActionTypes.UPLOAD_NC_BACK_PROGRESS
        | NCardBackActionTypes.DELETE_NC_BACK

        | NCardBackActionTypes.UPDATE
    ;

    payload: {
        actionType?: string;
        data?: any;
        error?: any;
        validationErrors?: any;

        key?: any;
        value?: any;

        // for upload purpose
        file: {fileId: string} & File;
        fileId: string;
        progress: number;
        retry: boolean;
    };
};

type State = {
    nationalCardBack: any;
};

type FileType = {
    uploaded?: boolean;
    progress?: number;
    fileId?: string;
    error?: string;
    uuid?: string;
} & File;

const NCardBack = (state: State = INIT_STATE, action: NCardBackActionType) => {
    switch (action.type) {
        case NCardBackActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case NCardBackActionTypes.SET_NC_BACK: {
                    return {
                        ...state,
                        nationalCardBack: action.payload.data?.media || [],
                        nationalCardBackStatus: action.payload.data?.status || ""
                    };
                }

                case NCardBackActionTypes.UPLOAD_NC_BACK: {
                    return {
                        ...state,
                        nationalCardBack: [
                            ...state.nationalCardBack.map((file: FileType) => {
                                if(file.fileId === action.payload.data.fileId) {
                                    file.uploaded = true;
                                    file.progress = 0;
                                    file.uuid = action.payload.data.uploadNcBack.uuid;
                                }
                                return file;
                            }),
                        ]
                    };
                }
                case NCardBackActionTypes.DELETE_NC_BACK: {
                    return {
                        ...state,
                        nationalCardBack: [
                            ...state.nationalCardBack.filter((file: FileType) => {
                                return file.fileId !== action.payload.data;
                            }),
                        ],
                        loading: false
                    };
                }
                case NCardBackActionTypes.UPDATE: {
                    return {
                        ...state,
                        updated: true,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case NCardBackActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case NCardBackActionTypes.UPDATE:
                case NCardBackActionTypes.DELETE_NC_BACK:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case NCardBackActionTypes.UPLOAD_NC_BACK: {
                    return {
                        ...state,
                        nationalCardBack: [
                            ...state.nationalCardBack.map((file: FileType) => {
                                if(file.fileId === action.payload.error.fileId) {
                                    file.error = action.payload.error.error;
                                    file.progress = 0;
                                }
                                return file;
                            }),
                        ]
                    };
                }
                default:
                    return { ...state };
            }

        case NCardBackActionTypes.API_RESPONSE_VALIDATION_ERRORS:
            switch (action.payload.actionType) {
                case NCardBackActionTypes.UPDATE: {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case NCardBackActionTypes.UPLOAD_NC_BACK: {
                    return {
                        ...state,
                        nationalCardBack: [
                            ...state.nationalCardBack.map((file: FileType) => {
                                if(file.fileId === action.payload.validationErrors.fileId) {
                                    file.error = action.payload.validationErrors.error.file;
                                }
                                return file;
                            })
                        ],
                    };
                }
                default:
                    return { ...state };
            }

        case NCardBackActionTypes.RESET:
            return {
                ...state,

                loading: false,
                error: "",
                validationErrors: {},

                nationalCardBack: [],
                nationalCardBackStatus: "",

                updated: false,
            };

        case NCardBackActionTypes.UPLOAD_NC_BACK:
            if(!action.payload.retry) {
                state.nationalCardBack = [
                    action.payload.file,
                    ...state.nationalCardBack
                ];
            } else {
                state.nationalCardBack = [
                    ...state.nationalCardBack.map((file: any) => {
                        if(file.fileId === action.payload.file.fileId) {
                            file.error = "";
                        }
                        return file;
                    })
                ];
            }
            return {
                ...state,
                error: ""
            };

        case NCardBackActionTypes.UPLOAD_NC_BACK_PROGRESS:
            return {
                ...state,
                nationalCardBack: [
                    ...state.nationalCardBack.map((file: FileType) => {
                        if(file.fileId === action.payload.fileId) {
                            file.progress = action.payload.progress;
                        }
                        return file;
                    })
                ],
                tcVideoProgress: 0
            };

        case NCardBackActionTypes.DELETE_NC_BACK:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case NCardBackActionTypes.UPDATE:
            return {
                ...state,
                loading: true,
                error: "",
                updated: false
            };

        default:
            return { ...state };
    }
};

export default NCardBack;
