import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {graphql} from 'helpers';
import {checkResponseError, checkServerError, formatBytes, pluckResponse} from "../../helpers/functions";
import {manageVerificationActions} from "./actions";
import { nCFrontActions } from '../NCFront/actions';
import { NCardFrontActionTypes } from '../NCFront/constants';
import { nCBackActions } from '../NCBack/actions';
import { NCardBackActionTypes } from '../NCBack/constants';
import { passportActions } from '../passport/actions';
import { PassportActionTypes } from '../passport/constants';
import { dlActions } from '../drivingLicence/actions';
import { DLActionTypes } from '../drivingLicence/constants';
import { personalDetailActions } from '../personalDetails/actions';
import { ManageVerificationActionTypes, ManageVerificationErrors } from './constants';
import { PersonalDetailActionTypes } from '../personalDetails/constants';

type PersonalDetailData = {
    payload: {
        id: number,

        data: any;

        queryParams: {
            limit: number;
            page: number;

        }

        token: string;

        file: {fileId: string} & File;

        fileId: string;
        uuid: string;
    };
    type: string;
};

function formattingFile(file: any) {
    file.formattedSize = formatBytes(file.formattedSize);
    file.uploaded = true;
    file.progress = 0;
    file.fileId = "f" + (Math.random() + 1).toString(36).substring(2);
    return file;
}

function* getVerificationItems({ payload: { id } }: PersonalDetailData): SagaIterator {
    try {
        const param:any = {
            query:`query User($id: String!) {
                    user(id: $id) {
                        id
                        mobile
                        mobile_ownership_status
                        email
                        customerProfile {
                            id
                            verification_method
                            status
                            first_name
                            last_name
                        }
                        idNcFront {
                            id
                            status
                            media {
                                 url
                            }
                        }
                        idNcBack {
                            id
                            status
                            media {
                                 url
                            }
                        }
                        idPassport {
                            id
                            status
                            media {
                                 url
                            }
                        }
                        idDrivingLicence {
                            id
                            status
                            media {
                                 url
                            }
                        }
                    }
                }`,
            variables: {
                "id": id,
            }
        };
        // const param = {
        //         query:`query User($id: String!) {
        //             personalDetail(id: $id) {
        //                 first_name
        //                 last_name
        //                 first_name_en
        //                 last_name_en
        //                 gender
        //                 birthday
        //                 national_code
        //                 country
        //                 state
        //                 city
        //                 status
        //                 description
        //                 nc_serial
        //                 user {
        //                     mobile
        //                     mobile_ownership_status
        //                 }
        //                 verification_method
        //             }
        //         }`,
        //         variables: {
        //             id
        //         }
        //     };
        //
        //
        //
        //     [
        //     {
        //         query:`query PersonalDetail($id: String!) {
        //             personalDetail(id: $id) {
        //                 first_name
        //                 last_name
        //                 first_name_en
        //                 last_name_en
        //                 gender
        //                 birthday
        //                 national_code
        //                 country
        //                 state
        //                 city
        //                 status
        //                 description
        //                 nc_serial
        //                 user {
        //                     mobile
        //                     mobile_ownership_status
        //                 }
        //                 verification_method
        //             }
        //         }`,
        //         variables: {
        //             id
        //         }
        //     },
        //     {
        //         query: `query IdNationalCardFront($id: String!){
        //             idNationalCardFront(id: $id) {
        //                 status,
        //                 media {
        //                     uuid
        //                     name
        //                     preview: url(size: "thumbnail")
        //                     url
        //                     formattedSize: size
        //                     type: mime_type
        //                 }
        //             }
        //         }`,
        //         variables: {
        //             id
        //         }
        //     },
        //     {
        //         query: `query IdNationalCardBack($id: String!){
        //             idNationalCardBack(id: $id) {
        //                 status,
        //                 media {
        //                     uuid
        //                     name
        //                     preview: url(size: "thumbnail")
        //                     url
        //                     formattedSize: size
        //                     type: mime_type
        //                 }
        //             }
        //         }`,
        //         variables: {
        //             id
        //         }
        //     },
        //     {
        //         query: `query IdPassport($id: String!){
        //             idPassport(id: $id) {
        //                 status,
        //                 media {
        //                     uuid
        //                     name
        //                     preview: url(size: "thumbnail")
        //                     url
        //                     formattedSize: size
        //                     type: mime_type
        //                 }
        //             }
        //         }`,
        //         variables: {
        //             id
        //         }
        //     },
        //     {
        //         query: `query IdDrivingLicence($id: String!){
        //             idDrivingLicence(id: $id) {
        //                 status,
        //                 media {
        //                     uuid
        //                     name
        //                     preview: url(size: "thumbnail")
        //                     url
        //                     formattedSize: size
        //                     type: mime_type
        //                 }
        //             }
        //         }`,
        //         variables: {
        //             id
        //         }
        //     }
        // ];

        // f.preview blob:http://localhost:3000/6f50e966-594e-4204-9f2c-65020cc998e0
        // f.name Screenshot 2023-10-25 114422.png
        // f.type image/png
        // f.formattedSize 13.95 KB

        const response: any = yield call(graphql, param, 'auth');
        checkResponseError(response, ManageVerificationErrors.RESPONSE_200);

        const rU: any = response.data;

        checkServerError(rU);

        const user = pluckResponse(rU, "user");


        //
        // const [
        //     RPD,
        //     RINCF,
        //     RINCB,
        //     RIP,
        //     RIDL
        // ] = response.data;
        //
        // checkServerError(RPD);
        // const personalDetails = pluckResponse(RPD, "personalDetail");
        //
        // checkServerError(RINCF);
        // const idNationalCardFront = pluckResponse(RINCF, "idNationalCardFront");
        // if(idNationalCardFront) {
        //     idNationalCardFront.media = idNationalCardFront.media.length? [formattingFile(idNationalCardFront.media[0])]: [];
        // }
        //
        // checkServerError(RINCB);
        // const idNationalCardBack = pluckResponse(RINCB, "idNationalCardBack");
        // if(idNationalCardBack) {
        //     idNationalCardBack.media = idNationalCardBack.media.length? [formattingFile(idNationalCardBack.media[0])]: [];
        // }
        //
        // checkServerError(RIP);
        // const idPassport = pluckResponse(RIP, "idPassport");
        // if(idPassport) {
        //     idPassport.media = idPassport.media.length? [formattingFile(idPassport.media[0])]: [];
        // }
        //
        // checkServerError(RIDL);
        // const idDrivingLicence = pluckResponse(RIDL, "idDrivingLicence");
        // if(idDrivingLicence) {
        //     idDrivingLicence.media = idDrivingLicence.media.length? [formattingFile(idDrivingLicence.media[0])]: [];
        // }

        yield put(manageVerificationActions.apiResponseSuccess(ManageVerificationActionTypes.GET_VERIFICATION_ITEMS, user));

        // yield put(personalDetailActions.apiResponseSuccess(PersonalDetailActionTypes.SET_PERSONAL_DETAIL, personalDetails));
        // yield put(nCFrontActions.apiResponseSuccess(NCardFrontActionTypes.SET_NC_FRONT, idNationalCardFront));
        // yield put(nCBackActions.apiResponseSuccess(NCardBackActionTypes.SET_NC_BACK, idNationalCardBack));
        // yield put(passportActions.apiResponseSuccess(PassportActionTypes.SET_PASSPORT, idPassport));
        // yield put(dlActions.apiResponseSuccess(DLActionTypes.SET_DL, idDrivingLicence));

    } catch (error: any) {

        if(typeof error === "string") {
            yield put(manageVerificationActions.apiResponseError(ManageVerificationActionTypes.GET_VERIFICATION_ITEMS, error));
        } else {
            yield put(manageVerificationActions.apiResponseValidationErrors(ManageVerificationActionTypes.GET_VERIFICATION_ITEMS, error));
        }
    }
}


export function* watchGetVerificationItems() {
    yield takeEvery(ManageVerificationActionTypes.GET_VERIFICATION_ITEMS, getVerificationItems);
}

function* manageVerificationSaga() {
    yield all([
        fork(watchGetVerificationItems),
    ]);
}

export default manageVerificationSaga;
