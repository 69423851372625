import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {graphql} from 'helpers';
import {PersonalDetailActionTypes, PersonalDetailErrors} from './constants';
import {checkResponseError, checkServerError, formatBytes, pluckResponse} from "../../helpers/functions";
import {personalDetailActions} from "./actions";
import { nCFrontActions } from '../NCFront/actions';
import { NCardFrontActionTypes } from '../NCFront/constants';
import { nCBackActions } from '../NCBack/actions';
import { NCardBackActionTypes } from '../NCBack/constants';
import { passportActions } from '../passport/actions';
import { PassportActionTypes } from '../passport/constants';
import { dlActions } from '../drivingLicence/actions';
import { DLActionTypes } from '../drivingLicence/constants';

type PersonalDetailData = {
    payload: {
        id: number,

        data: any;

        queryParams: {
            limit: number;
            page: number;

        }

        token: string;

        file: {fileId: string} & File;

        fileId: string;
        uuid: string;
    };
    type: string;
};

function* getPersonalDetails({ payload: { queryParams } }: PersonalDetailData): SagaIterator {
    try {
        const param:any = [
            {
                query:`query PersonalDetails($page: Int!, $limit: Int!, $orderBy: String!, $sort: String!) {
                    personalDetails(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort) {
                        data {
                            id
                            first_name
                            last_name
                            first_name_en
                            last_name_en
                            gender
                            birthday
                            national_code
                            country
                            state
                            city
                            description
                            status
                            description
                            nc_serial
                            verification_method
                            user {
                                id
                                username
                            }
                            created_at
                            updated_at
                        }
                        total
                        per_page
                        from
                        to
                        current_page
                        last_page
                        has_more_pages
                    }
                }`,
                variables: {
                    ...queryParams
                }
            }
        ];

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PersonalDetailErrors.RESPONSE_200);

        const [rPersonalDetails] = response.data;

        checkServerError(rPersonalDetails);

        const personalDetails = pluckResponse(rPersonalDetails, "personalDetails");

        yield put(personalDetailActions.apiResponseSuccess(PersonalDetailActionTypes.GET_PERSONAL_DETAILS, {
            personalDetails: {
                personalDetails,
                queryParams
            }
        }));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(personalDetailActions.apiResponseError(PersonalDetailActionTypes.GET_PERSONAL_DETAILS, error));
        } else {
            yield put(personalDetailActions.apiResponseValidationErrors(PersonalDetailActionTypes.GET_PERSONAL_DETAILS, error));
        }
    }
}

function formattingFile(file: any) {
    file.formattedSize = formatBytes(file.formattedSize);
    file.uploaded = true;
    file.progress = 0;
    file.fileId = "f" + (Math.random() + 1).toString(36).substring(2);
    return file;
}

function* getPersonalDetail({ payload: { id } }: PersonalDetailData): SagaIterator {
    try {
        const param = [
            {
                query:`query PersonalDetail($id: String!) {
                    personalDetail(id: $id) {
                        id
                        first_name
                        last_name
                        first_name_en
                        last_name_en
                        gender
                        birthday
                        national_code
                        country
                        state
                        city
                        status
                        description
                        nc_serial
                        user {
                            mobile
                            mobile_ownership_status
                        }
                        verification_method
                    }
                }`,
                variables: {
                    id
                }
            },
            {
                query: `query IdNationalCardFront($id: String!){
                    idNationalCardFront(id: $id) {
                        status,
                        media {
                            uuid
                            name
                            preview: url(size: "thumbnail")
                            url
                            formattedSize: size
                            type: mime_type
                        }
                    }
                }`,
                variables: {
                    id
                }
            },
            {
                query: `query IdNationalCardBack($id: String!){
                    idNationalCardBack(id: $id) {
                        status,
                        media {
                            uuid
                            name
                            preview: url(size: "thumbnail")
                            url
                            formattedSize: size
                            type: mime_type
                        }
                    }
                }`,
                variables: {
                    id
                }
            },
            {
                query: `query IdPassport($id: String!){
                    idPassport(id: $id) {
                        status,
                        media {
                            uuid
                            name
                            preview: url(size: "thumbnail")
                            url
                            formattedSize: size
                            type: mime_type
                        }
                    }
                }`,
                variables: {
                    id
                }
            },
            {
                query: `query IdDrivingLicence($id: String!){
                    idDrivingLicence(id: $id) {
                        status,
                        media {
                            uuid
                            name
                            preview: url(size: "thumbnail")
                            url
                            formattedSize: size
                            type: mime_type
                        }
                    }
                }`,
                variables: {
                    id
                }
            }
        ];

        // f.preview blob:http://localhost:3000/6f50e966-594e-4204-9f2c-65020cc998e0
        // f.name Screenshot 2023-10-25 114422.png
        // f.type image/png
        // f.formattedSize 13.95 KB

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PersonalDetailErrors.RESPONSE_200);

        const [RPD, RINCF, RINCB, RIP, RIDL] = response.data;

        checkServerError(RPD);
        const personalDetail = pluckResponse(RPD, "personalDetail");

        checkServerError(RINCF);
        const idNationalCardFront = pluckResponse(RINCF, "idNationalCardFront");
        if(idNationalCardFront) {
            idNationalCardFront.media = idNationalCardFront.media.length? [formattingFile(idNationalCardFront.media[0])]: [];
        }

        checkServerError(RINCB);
        const idNationalCardBack = pluckResponse(RINCB, "idNationalCardBack");
        if(idNationalCardBack) {
            idNationalCardBack.media = idNationalCardBack.media.length? [formattingFile(idNationalCardBack.media[0])]: [];
        }

        checkServerError(RIP);
        const idPassport = pluckResponse(RIP, "idPassport");
        if(idPassport) {
            idPassport.media = idPassport.media.length? [formattingFile(idPassport.media[0])]: [];
        }

        checkServerError(RIDL);
        const idDrivingLicence = pluckResponse(RIDL, "idDrivingLicence");
        if(idDrivingLicence) {
            idDrivingLicence.media = idDrivingLicence.media.length? [formattingFile(idDrivingLicence.media[0])]: [];
        }

        yield put(personalDetailActions.apiResponseSuccess(PersonalDetailActionTypes.GET_PERSONAL_DETAIL, personalDetail));
        yield put(nCFrontActions.apiResponseSuccess(NCardFrontActionTypes.SET_NC_FRONT, idNationalCardFront));
        yield put(nCBackActions.apiResponseSuccess(NCardBackActionTypes.SET_NC_BACK, idNationalCardBack));
        yield put(passportActions.apiResponseSuccess(PassportActionTypes.SET_PASSPORT, idPassport));
        yield put(dlActions.apiResponseSuccess(DLActionTypes.SET_DL, idDrivingLicence));

    } catch (error: any) {

        if(typeof error === "string") {
            yield put(personalDetailActions.apiResponseError(PersonalDetailActionTypes.GET_PERSONAL_DETAIL, error));
        } else {
            yield put(personalDetailActions.apiResponseValidationErrors(PersonalDetailActionTypes.GET_PERSONAL_DETAIL, error));
        }
    }
}

function* updatePersonalDetail({ payload: {data} }: PersonalDetailData): SagaIterator {
    try {
        const param = {
            query:`mutation UpdatePersonalDetail(
                $id: String!,
                $firstName: String!,
                $firstNameEn: String!,
                $lastName: String!,
                $lastNameEn: String!,
                $gender: String!,
                $birthday: String!,
                $nationalCode: String!,
                $verificationMethod: String!,
            ) {
                updatePersonalDetail(
                    id: $id
                    firstName: $firstName
                    firstNameEn: $firstNameEn
                    lastName: $lastName
                    lastNameEn: $lastNameEn
                    gender: $gender
                    birthday: $birthday
                    nationalCode: $nationalCode
                    verificationMethod: $verificationMethod
                ) {
                    first_name
                    last_name
                    first_name_en
                    last_name_en
                    gender
                    birthday
                    national_code
                    status
                    nc_serial
                    verification_method
                }
            }`,
            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PersonalDetailErrors.RESPONSE_200);

        const rUPI = response.data;

        checkServerError(rUPI);

        const updatePersonalDetail = pluckResponse(rUPI, "updatePersonalDetail");

        yield put(personalDetailActions.apiResponseSuccess(PersonalDetailActionTypes.UPDATE_PERSONAL_DETAIL, updatePersonalDetail));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(personalDetailActions.apiResponseError(PersonalDetailActionTypes.UPDATE_PERSONAL_DETAIL, error));
        } else {
            yield put(personalDetailActions.apiResponseValidationErrors(PersonalDetailActionTypes.UPDATE_PERSONAL_DETAIL, error));
        }
    }
}

function* updateStatus({ payload: {data} }: PersonalDetailData): SagaIterator {
    try {
        const param = {
            query:`mutation UpdatePersonalDetailStatus($id: String!) {
                    updatePersonalDetailStatus(id: $id)
                }`,
            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PersonalDetailErrors.RESPONSE_200);

        const rUNF = response.data;

        checkServerError(rUNF);

        const updatePersonalDetailStatus = pluckResponse(rUNF, "updatePersonalDetailStatus");

        yield put(personalDetailActions.apiResponseSuccess(PersonalDetailActionTypes.UPDATE_STATUS, updatePersonalDetailStatus));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(personalDetailActions.apiResponseError(PersonalDetailActionTypes.UPDATE_STATUS, error));
        } else {
            yield put(personalDetailActions.apiResponseValidationErrors(PersonalDetailActionTypes.UPDATE_STATUS, error));
        }
    }
}

function* updateMyPersonalDetail({ payload: {data} }: PersonalDetailData): SagaIterator {
    try {
        const param = {
            query:`mutation UpdateMyPersonalDetail(
                $firstNameEn: String!,
                $lastNameEn: String!
            ) {
                updateMyPersonalDetail(
                    firstNameEn: $firstNameEn
                    lastNameEn: $lastNameEn
                )
            }`,
            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PersonalDetailErrors.RESPONSE_200);

        const rUPI = response.data;

        checkServerError(rUPI);

        const updateMyPersonalDetail = pluckResponse(rUPI, "updateMyPersonalDetail");

        yield put(personalDetailActions.apiResponseSuccess(PersonalDetailActionTypes.UPDATE_MY_PERSONAL_DETAIL, updateMyPersonalDetail));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(personalDetailActions.apiResponseError(PersonalDetailActionTypes.UPDATE_MY_PERSONAL_DETAIL, error));
        } else {
            yield put(personalDetailActions.apiResponseValidationErrors(PersonalDetailActionTypes.UPDATE_MY_PERSONAL_DETAIL, error));
        }
    }
}

export function* watchGetPersonalDetails() {
    yield takeEvery(PersonalDetailActionTypes.GET_PERSONAL_DETAILS, getPersonalDetails);
}

export function* watchGetPersonalDetail() {
    yield takeEvery(PersonalDetailActionTypes.GET_PERSONAL_DETAIL, getPersonalDetail);
}

export function* watchUpdatePersonalDetail() {
    yield takeEvery(PersonalDetailActionTypes.UPDATE_PERSONAL_DETAIL, updatePersonalDetail);
}

export function* watchUpdateStatus() {
    yield takeEvery(PersonalDetailActionTypes.UPDATE_STATUS, updateStatus);
}

export function* watchUpdateMyPersonalDetail() {
    yield takeEvery(PersonalDetailActionTypes.UPDATE_MY_PERSONAL_DETAIL, updateMyPersonalDetail);
}

function* personalDetailSaga() {
    yield all([
        fork(watchGetPersonalDetails),
        fork(watchGetPersonalDetail),
        fork(watchUpdatePersonalDetail),
        fork(watchUpdateStatus),
        fork(watchUpdateMyPersonalDetail),
    ]);
}

export default personalDetailSaga;
