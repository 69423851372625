import {APICore} from "./apiCore";

const api = new APICore();

function graphql(params: any, url: any): any {
    const baseUrl = (!url)? '/graphql': '/graphql/' + url;
    return api.create(`${baseUrl}`, params);
}

function graphqlUpload(params: any, url: any, onUploadProgress: any): any {
    const baseUrl = (!url)? '/graphql': '/graphql/' + url;
    return api.upload(`${baseUrl}`, params, onUploadProgress);
}

export {
    graphql,
    graphqlUpload
};