import {ProfileActionTypes} from './constants';

const INIT_STATE = {
    loading: false,
    error: "",
    validationErrors: {},

    verificationEmailSent: false,
    emailVerified: false,

    completeProfile: {},

};

type ProfileActionType = {
    type:
        | ProfileActionTypes.API_RESPONSE_SUCCESS
        | ProfileActionTypes.API_RESPONSE_ERROR
        | ProfileActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | ProfileActionTypes.RESET

        | ProfileActionTypes.SEND_VERIFICATION_EMAIL_LINK
        | ProfileActionTypes.VERIFY_EMAIL

        | ProfileActionTypes.GET_COMPLETE_PROFILE

        | ProfileActionTypes.GET_PROFILE_IMAGE
        | ProfileActionTypes.UPLOAD_PROFILE_IMAGE;

    payload: {
        actionType?: string;
        data?: any;
        error?: any;
        validationErrors?: any;

        key?: any;
        value?: any;

        // for upload purpose
        file: {fileId: string} & File;
        fileId: string;
        progress: number;
        retry: boolean;
    };
};

type State = {
    completeProfile: any;
};


const Profile = (state: State = INIT_STATE, action: ProfileActionType) => {
    switch (action.type) {
        case ProfileActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ProfileActionTypes.SEND_VERIFICATION_EMAIL_LINK: {
                    return {
                        ...state,
                        verificationEmailSent: true,
                        loading: false,
                    };
                }
                case ProfileActionTypes.VERIFY_EMAIL: {
                    return {
                        ...state,
                        emailVerified: true,
                        loading: false,
                    };
                }
                case ProfileActionTypes.GET_COMPLETE_PROFILE: {
                    return {
                        ...state,
                        loading: false,
                        completeProfile: action.payload.data,
                    };
                }
                case ProfileActionTypes.GET_PROFILE_IMAGE: {
                    return {
                        ...state,
                        loading: false,
                        profileImage: action.payload.data
                    };
                }

                default:
                    return { ...state };
            }

        case ProfileActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ProfileActionTypes.SEND_VERIFICATION_EMAIL_LINK:
                case ProfileActionTypes.VERIFY_EMAIL:
                case ProfileActionTypes.GET_PROFILE_IMAGE:
                case ProfileActionTypes.GET_COMPLETE_PROFILE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case ProfileActionTypes.API_RESPONSE_VALIDATION_ERRORS:
            switch (action.payload.actionType) {
                case ProfileActionTypes.SEND_VERIFICATION_EMAIL_LINK:
                case ProfileActionTypes.VERIFY_EMAIL:
                case ProfileActionTypes.GET_PROFILE_IMAGE:
                case ProfileActionTypes.GET_COMPLETE_PROFILE: {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ProfileActionTypes.RESET:
            return {
                ...state,

                loading: false,
                error: "",
                validationErrors: {},

                verificationEmailSent: false,
                emailVerified: false,

                completeProfile: {},

            };


        case ProfileActionTypes.SEND_VERIFICATION_EMAIL_LINK:
            return {
                ...state,
                verificationEmailSent: false,
                loading: true,
                error: "",
            };

        case ProfileActionTypes.VERIFY_EMAIL:
            return {
                ...state,
                emailVerified: false,
                loading: true,
                error: "",
            };

        case ProfileActionTypes.GET_COMPLETE_PROFILE:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case ProfileActionTypes.GET_PROFILE_IMAGE:
            return {
                ...state,
                loading: true,
                error: "",
            };
        default:
            return { ...state };
    }
};

export default Profile;
