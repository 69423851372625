import { PaymentActionTypes } from './constants';
import { isEmptyObject } from '../../helpers/functions';

const INIT_STATE = {

    transactions: {},

    payment: {},

    payments: {},
    queryParams: {},

    loading: false,
    error: "",
    validationErrors: {},

    searchModal: false,
    tmpPayments: {},
    tmpQueryParams: {},
    searchMode: false,
    searchLoading: false,
    searchError: "",
    searchValidationErrors: {},

    tmpTransactions: {},

    exportUrl: "",
    exportLoading: false,
    exportError: "",
    exportValidationErrors: [],
};


type PaymentActionType = {
    type:
        | PaymentActionTypes.API_RESPONSE_SUCCESS
        | PaymentActionTypes.API_RESPONSE_ERROR
        | PaymentActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | PaymentActionTypes.GET_PAYMENTS
        | PaymentActionTypes.GET_PAYMENT
        | PaymentActionTypes.GET_TRANSACTIONS
        | PaymentActionTypes.RESET

        | PaymentActionTypes.OPEN_SEARCH
        | PaymentActionTypes.CLOSE_SEARCH
        | PaymentActionTypes.SEARCH_PAYMENT
        | PaymentActionTypes.SEARCH_TRANSACTION
        | PaymentActionTypes.CLEAR_SEARCH
        | PaymentActionTypes.EXPORT_TRANSACTIONS
        | PaymentActionTypes.EXPORT
        | PaymentActionTypes.RESET_SEARCH
        | PaymentActionTypes.CALLBACK;

    payload: {
        actionType?: string;
        data?: any;
        error?: string;
        validationErrors?: [];

        key?: any
        value?: any
    };
};

type State = {
    payments?: {};
    transactions?: any;

    queryParams?: {};

    loading?: boolean;
    value?: boolean;

    tmpPayments?: any;
    tmpQueryParams?: any;
    tmpTransactions?: any;
};

const Payment = (state: State = INIT_STATE, action: PaymentActionType) => {
    switch (action.type) {
        case PaymentActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case PaymentActionTypes.GET_PAYMENTS: {
                    return {
                        ...state,
                        payments: action.payload.data?.payments,
                        queryParams: action.payload.data?.queryParams,
                        loading: false,
                    };
                }
                case PaymentActionTypes.GET_PAYMENT: {
                    return {
                        ...state,
                        payment: action.payload.data,
                        loading: false,
                    };
                }
                case PaymentActionTypes.GET_TRANSACTIONS: {
                    return {
                        ...state,
                        transactions: action.payload.data?.transactions,
                        queryParams: action.payload.data?.queryParams,
                        loading: false,
                    };
                }
                case PaymentActionTypes.SEARCH_PAYMENT: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpPayments: isEmptyObject(state.tmpPayments)? state.payments: state.tmpPayments,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        payments: action.payload.data?.payments,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                case PaymentActionTypes.SEARCH_TRANSACTION: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpTransactions: isEmptyObject(state.tmpTransactions)? state.transactions: state.tmpTransactions,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        transactions: action.payload.data?.transactions,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                case PaymentActionTypes.EXPORT_TRANSACTIONS: {
                    return {
                        ...state,
                        exportUrl: action.payload.data,
                        exportLoading: false,

                        searchMode: false,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                case PaymentActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportUrl: action.payload.data,
                        exportLoading: false,

                        searchMode: false,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                case PaymentActionTypes.CALLBACK: {
                    return {
                        ...state,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case PaymentActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case PaymentActionTypes.GET_PAYMENTS:
                case PaymentActionTypes.GET_PAYMENT:
                case PaymentActionTypes.GET_TRANSACTIONS:
                case PaymentActionTypes.CALLBACK: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case PaymentActionTypes.SEARCH_PAYMENT:
                case PaymentActionTypes.SEARCH_TRANSACTION: {
                    return {
                        ...state,
                        searchError: action.payload.error,
                        searchLoading: false,
                    };
                }
                case PaymentActionTypes.EXPORT_TRANSACTIONS: {
                    return {
                        ...state,
                        exportError: action.payload.error,
                        exportLoading: false,
                    };
                }
                case PaymentActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportError: action.payload.error,
                        exportLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case PaymentActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case PaymentActionTypes.GET_PAYMENTS:
                case PaymentActionTypes.GET_TRANSACTIONS:
                {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case PaymentActionTypes.GET_PAYMENT:
                case PaymentActionTypes.CALLBACK:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case PaymentActionTypes.SEARCH_PAYMENT:
                case PaymentActionTypes.SEARCH_TRANSACTION: {
                    return {
                        ...state,
                        searchValidationErrors: action.payload.validationErrors,
                        searchLoading: false,
                    };
                }
                case PaymentActionTypes.EXPORT_TRANSACTIONS: {
                    return {
                        ...state,
                        exportValidationErrors: action.payload.validationErrors,
                        exportLoading: false,
                    };
                }
                case PaymentActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportValidationErrors: action.payload.validationErrors,
                        exportLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case PaymentActionTypes.GET_PAYMENTS:
            return {
                ...state,
                // payments: {},
                loading: true,
                error: "",
            };

        case PaymentActionTypes.GET_PAYMENT:
            return {
                ...state,
                payment: {},
                loading: true,
                error: "",
            };
        case PaymentActionTypes.GET_TRANSACTIONS:
            return {
                ...state,
                // transactions: {},
                loading: true,
                error: "",
            };

        case PaymentActionTypes.RESET:
            return {
                ...state,
                transactions: {},

                payment: {},

                payments: {},
                queryParams: {},

                loading: false,
                error: "",
                validationErrors: {},

                searchModal: false,
                searchLoading: false,
                searchMode: false,
                searchError: "",
                searchValidationErrors: {},

                tmpPayments: {},
                tmpQueryParams: {},
                tmpTransactions: {},

                exportUrl: "",
                exportLoading: false,
                exportError: "",
                exportValidationErrors: [],
            };


        // search actions
        case PaymentActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case PaymentActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},
            };
        case PaymentActionTypes.SEARCH_PAYMENT:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case PaymentActionTypes.SEARCH_TRANSACTION:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case PaymentActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                payments: state.tmpPayments,
                transactions: state.tmpTransactions,
                queryParams: state.tmpQueryParams,
                tmpPayments: {},
                tmpTransactions: {},
                tmpQueryParams: {},
                searchMode: false,
            };
        case PaymentActionTypes.EXPORT_TRANSACTIONS:

            return {
                ...state,
                exportUrl: "",
                exportLoading: true,
                exportError: "",
                exportValidationErrors: [],
            };
        case PaymentActionTypes.EXPORT:

            return {
                ...state,
                exportUrl: "",
                exportLoading: true,
                exportError: "",
                exportValidationErrors: [],
            };

        case PaymentActionTypes.RESET_SEARCH:

            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},

                exportUrl: "",
                exportLoading: false,
                exportError: "",
                exportValidationErrors: [],
            }

    case PaymentActionTypes.CALLBACK:
        return {
            ...state,
            loading: true,
            error: "",
        };

        default:
            return { ...state };
    }
};

export default Payment;
