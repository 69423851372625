import { ProfileImageActionTypes } from './constants';

export type ProfileImageActionType = {
    type:
        | ProfileImageActionTypes.API_RESPONSE_SUCCESS
        | ProfileImageActionTypes.API_RESPONSE_ERROR
        | ProfileImageActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | ProfileImageActionTypes.RESET

        | ProfileImageActionTypes.GET_PROFILE_IMAGE
        | ProfileImageActionTypes.UPLOAD_PROFILE_IMAGE
        | ProfileImageActionTypes.UPLOAD_FILE_PROGRESS

    payload: {} | string;
};

type ProfileImage = {
    id: number;
};

class ProfileImageActions {

    apiResponseSuccess = (actionType: string, data: ProfileImage | {}): ProfileImageActionType => ({
        type: ProfileImageActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: any): ProfileImageActionType => ({
        type: ProfileImageActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: any): ProfileImageActionType => ({
        type: ProfileImageActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): ProfileImageActionType => ({
        type: ProfileImageActionTypes.RESET,
        payload: {},
    });

    getProfileImage = (): ProfileImageActionType => ({
        type: ProfileImageActionTypes.GET_PROFILE_IMAGE,
        payload: {},
    });

    uploadProfileImage = (file: File): ProfileImageActionType => ({
        type: ProfileImageActionTypes.UPLOAD_PROFILE_IMAGE,
        payload: {file},
    });

    uploadFilesProgress = (progress: any, fileId: string): ProfileImageActionType => {
        return {
            type: ProfileImageActionTypes.UPLOAD_FILE_PROGRESS,
            payload: {fileId, progress},
        }
    }
}

const profileImageActions = new ProfileImageActions();
export { profileImageActions };
