import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import './i18n';
import App from './App';
import { store } from './redux/store';
import { ThemeProvider } from 'react-bootstrap';
import { NotificationProvider } from './common/context/useNotificationContext';

// Create a root.
// @ts-ignore
const root = createRoot(document.getElementById('root'));

// Initial render
root.render(
    <ThemeProvider dir="rtl">
        <Provider store={store({})}>
            <NotificationProvider>
                <App />
            </NotificationProvider>
        </Provider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
