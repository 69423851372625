import { WalletActionTypes } from './constants';
import { isEmptyObject } from '../../helpers/functions';

const INIT_STATE = {
    wallets: {},
    queryParams: {},

    wallet: {},

    loading: false,
    error: "",
    validationErrors: {},

    searchModal: false,
    tmpWallets: {},
    tmpQueryParams: {},

    searchMode: false,
    searchLoading: false,
    searchError: "",
    searchValidationErrors: {},

    exportUrl: "",
    exportLoading: false,
    exportError: "",
    exportValidationErrors: []
};


type WalletsResult = {
    wallets: any,
    queryParams: any
}

type WalletActionType = {
    type:
        | WalletActionTypes.API_RESPONSE_SUCCESS
        | WalletActionTypes.API_RESPONSE_ERROR
        | WalletActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | WalletActionTypes.GET_WALLETS
        | WalletActionTypes.GET_WALLET
        | WalletActionTypes.GET_WALLET_BY_ACCOUNT_ID
        | WalletActionTypes.RESET
        | WalletActionTypes.RESET_SEARCH
        | WalletActionTypes.EXPORT

        | WalletActionTypes.OPEN_SEARCH
        | WalletActionTypes.CLOSE_SEARCH
        | WalletActionTypes.SEARCH_WALLET
        | WalletActionTypes.CLEAR_SEARCH;

    payload: {
        actionType?: string;
        data?: WalletsResult | any;
        error?: string;
        validationErrors?: [];

        key?: any
        value?: any
    };
};

type State = {
    wallets?: any;

    queryParams?: {};

    loading?: boolean;
    value?: boolean;
    tmpWallets?: any;

    tmpQueryParams?: any;
};

const Wallet = (state: State = INIT_STATE, action: WalletActionType) => {
    switch (action.type) {
        case WalletActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case WalletActionTypes.GET_WALLETS: {
                    return {
                        ...state,
                        wallets: action.payload.data?.wallets,
                        queryParams: action.payload.data?.queryParams,
                        loading: false,
                    };
                }
                case WalletActionTypes.GET_WALLET: {
                    return {
                        ...state,
                        wallet: action.payload.data,
                        loading: false,
                    };
                }
                case WalletActionTypes.GET_WALLET_BY_ACCOUNT_ID: {
                    return {
                        ...state,
                        wallet: action.payload.data,
                        loading: false,
                    };
                }
                case WalletActionTypes.SEARCH_WALLET: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpWallets: isEmptyObject(state.tmpWallets)? state.wallets: state.tmpWallets,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        wallets: action.payload.data?.wallets,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                case WalletActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportUrl: action.payload.data,
                        exportLoading: false,

                        searchMode: false,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case WalletActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case WalletActionTypes.GET_WALLETS:
                case WalletActionTypes.GET_WALLET:
                case WalletActionTypes.GET_WALLET_BY_ACCOUNT_ID: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case WalletActionTypes.SEARCH_WALLET: {
                    return {
                        ...state,
                        searchError: action.payload.error,
                        searchLoading: false,
                    };
                }
                case WalletActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportError: action.payload.error,
                        exportLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case WalletActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case WalletActionTypes.GET_WALLETS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case WalletActionTypes.GET_WALLET_BY_ACCOUNT_ID:
                case WalletActionTypes.GET_WALLET:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case WalletActionTypes.SEARCH_WALLET: {
                    return {
                        ...state,
                        searchValidationErrors: action.payload.validationErrors,
                        searchLoading: false,
                    };
                }
                case WalletActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportValidationErrors: action.payload.validationErrors,
                        exportLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case WalletActionTypes.GET_WALLETS:
            return {
                ...state,
                // wallets: {},
                loading: true,
                error: "",
            };

        case WalletActionTypes.GET_WALLET:
            return {
                ...state,
                wallet: {},
                loading: true,
                error: "",
            };

        case WalletActionTypes.GET_WALLET_BY_ACCOUNT_ID:
            return {
                ...state,
                wallet: {},
                loading: true,
                error: "",
            };


        case WalletActionTypes.RESET:
            return {
                ...state,
                wallets: {},
                queryParams: {},

                wallet: {},

                loading: false,
                error: "",
                validationErrors: {},

                searchModal: false,
                searchLoading: false,
                searchMode: false,
                searchError: "",
                searchValidationErrors: {},

                tmpWallets: {},
                tmpQueryParams: {},

                exportUrl: "",
                exportLoading: false,
                exportError: "",
                exportValidationErrors: [],
            };

        // search actions
        case WalletActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case WalletActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},
            };
        case WalletActionTypes.SEARCH_WALLET:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case WalletActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                wallets: state.tmpWallets,
                queryParams: state.tmpQueryParams,
                tmpWallets: {},
                tmpQueryParams: {},
                searchMode: false,
            };

        case WalletActionTypes.EXPORT:

            return {
                ...state,
                exportUrl: "",
                exportLoading: true,
                exportError: "",
                exportValidationErrors: [],
            };
        case WalletActionTypes.RESET_SEARCH:

            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},

                exportUrl: "",
                exportLoading: false,
                exportError: "",
                exportValidationErrors: [],
            }

        default:
            return { ...state };
    }
};

export default Wallet;
