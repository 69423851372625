import { ProfileActionTypes } from './constants';

export type ProfileActionType = {
    type:
        | ProfileActionTypes.API_RESPONSE_SUCCESS
        | ProfileActionTypes.API_RESPONSE_ERROR
        | ProfileActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | ProfileActionTypes.RESET

        | ProfileActionTypes.SEND_VERIFICATION_EMAIL_LINK
        | ProfileActionTypes.VERIFY_EMAIL

        | ProfileActionTypes.GET_COMPLETE_PROFILE

        | ProfileActionTypes.GET_PROFILE_IMAGE
        | ProfileActionTypes.UPLOAD_PROFILE_IMAGE;

    payload: {} | string;
};

type Profile = {
    id: number;
    email: string;
    emailVerify: string;
    role: string;
    token: string;

    process: any
};

class ProfileActions {

    apiResponseSuccess = (actionType: string, data: Profile | {}): ProfileActionType => ({
        type: ProfileActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: any): ProfileActionType => ({
        type: ProfileActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: any): ProfileActionType => ({
        type: ProfileActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): ProfileActionType => ({
        type: ProfileActionTypes.RESET,
        payload: {},
    });

    sendActivationEmailLink = (): ProfileActionType => ({
        type: ProfileActionTypes.SEND_VERIFICATION_EMAIL_LINK,
        payload: {},
    });

    verifyEmail = (token: string): ProfileActionType => ({
        type: ProfileActionTypes.VERIFY_EMAIL,
        payload: {token},
    });

    getCompleteProfile = (): ProfileActionType => ({
        type: ProfileActionTypes.GET_COMPLETE_PROFILE,
        payload: {},
    });

    getProfileImage = (): ProfileActionType => ({
        type: ProfileActionTypes.GET_PROFILE_IMAGE,
        payload: {},
    });

    uploadProfileImage = (): ProfileActionType => ({
        type: ProfileActionTypes.UPLOAD_PROFILE_IMAGE,
        payload: {},
    });

}

const profileActions = new ProfileActions();
export { profileActions };
