export enum DepositActionTypes {
    API_RESPONSE_SUCCESS = '@@deposit/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@deposit/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@deposit/API_RESPONSE_VALIDATION_ERRORS',

    FIND_DEPOSIT = '@@deposit/FIND_DEPOSIT',
    GET_DEPOSITS = '@@deposit/GET_DEPOSITS',
    GET_DEPOSIT = '@@deposit/GET_DEPOSIT',
    INSERT_DEPOSIT = '@@deposit/INSERT_DEPOSIT',
    VERIFY_DEPOSIT = '@@deposit/VERIFY_DEPOSIT',
    UPDATE_DEPOSIT = '@@deposit/UPDATE_DEPOSIT',

    INSERT_DEPOSIT_ID = '@@deposit/INSERT_DEPOSIT_ID',

    OPEN_SEARCH = '@@deposit/OPEN_SEARCH',
    CLOSE_SEARCH = '@@deposit/CLOSE_SEARCH',
    SEARCH_DEPOSIT = '@@deposit/SEARCH_DEPOSIT',
    CLEAR_SEARCH = '@@deposit/CLEAR_SEARCH',

    RESET = '@@deposit/RESET',

    MANUAL_DEPOSIT = '@@deposit/MANUAL_DEPOSIT',
    PREVIEW_DEPOSIT_DETAIL = '@@deposit/PREVIEW_DEPOSIT_DETAIL',
    CLEAR_PREVIEW_DEPOSIT_DETAIL = '@@deposit/CLEAR_PREVIEW_DEPOSIT_DETAIL',

    INQUIRY_PAYMENT = '@@deposit/INQUIRY_PAYMENT',
    EXPORT = '@@deposit/EXPORT',
    RESET_SEARCH = '@@deposit/RESET_SEARCH',
    GATEWAYS_STATUS = '@@deposit/GATEWAYS_STATUS',
}

export enum DepositErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



