import Routes from 'routes/Routes';

// For Default import Saas.scss
import 'assets/scss/Saas.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { memoize } from 'proxy-memoize';
import { useEffect } from 'react';
// import 'assets/scss/Creative.scss';
// import 'assets/scss/Modern.scss';

const App = () => {
    const { i18n } = useTranslation();

    const {
        layoutDirection,
        layoutLanguage,
    } = useSelector(memoize((state: any) => ({
        layoutDirection: state.Layout.layoutDirection,
        layoutLanguage: state.Layout.layoutLanguage,
    })));

    useEffect(() => {
        document.dir = layoutDirection;
        document.documentElement.setAttribute('lang', layoutLanguage);
        i18n.changeLanguage(layoutLanguage);
    }, [layoutDirection, layoutLanguage]);

    return <Routes />;
};

export default App;
