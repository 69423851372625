import {DashboardActionTypes} from './constants';

export type DashboardActionType = {
    type:
        | DashboardActionTypes.API_RESPONSE_SUCCESS
        | DashboardActionTypes.API_RESPONSE_ERROR
        | DashboardActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | DashboardActionTypes.GET_DASHBOARD

        | DashboardActionTypes.RESET

    payload: {} | string;
};

type Dashboard = {
    id: number;
    name: string;
    guard_name: string;
};

type QueryParams = {
    page: number;
    limit: number;
    orderBy: string,
    sort: string,
    dashboardName?: string
}

class DashboardActions {

    apiResponseSuccess = (actionType: string, data: Dashboard | {}): DashboardActionType => ({
        type: DashboardActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): DashboardActionType => ({
        type: DashboardActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): DashboardActionType => ({
        type: DashboardActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): DashboardActionType => ({
        type: DashboardActionTypes.RESET,
        payload: {},
    });

    getDashboard = (items: any): DashboardActionType => ({
        type: DashboardActionTypes.GET_DASHBOARD,
        payload: { items },
    });
}

const dashboardActions = new DashboardActions();
export { dashboardActions };
