
export enum TacActionTypes {
    API_RESPONSE_SUCCESS = '@@tac/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@tac/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@tac/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@tac/RESET',

    GET_TACs = '@@tac/GET_TACs',
    GET_TAC = '@@tac/GET_TAC',

    UPLOAD_TERM_AND_CONDITION = '@@tac/UPLOAD_TERM_AND_CONDITION',
    UPLOAD_TAC_PROGRESS = '@@tac/UPLOAD_TAC_PROGRESS',

    SEND_TAC = '@@tac/SEND_TAC',
    APPROVE_TAC = '@@tac/APPROVE_TAC',
    SET_DEVICES = '@@tac/SET_DEVICES',
}

export enum TacErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



