export enum WalletActionTypes {
    API_RESPONSE_SUCCESS = '@@wallet/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@wallet/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@wallet/API_RESPONSE_VALIDATION_ERRORS',

    FIND_WALLET = '@@wallet/FIND_WALLET',
    GET_WALLETS = '@@wallet/GET_WALLETS',
    GET_WALLET = '@@wallet/GET_WALLET',
    GET_WALLET_BY_ACCOUNT_ID = '@@wallet/GET_WALLET_BY_ACCOUNT_ID',

    OPEN_SEARCH = '@@wallet/OPEN_SEARCH',
    CLOSE_SEARCH = '@@wallet/CLOSE_SEARCH',
    SEARCH_WALLET = '@@wallet/SEARCH_WALLET',
    CLEAR_SEARCH = '@@wallet/CLEAR_SEARCH',

    EXPORT = '@@wallet/EXPORT',

    RESET = '@@wallet/RESET',
    RESET_SEARCH = '@@wallet/RESET_SEARCH',
}

export enum WalletErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



