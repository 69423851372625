
export enum UserActionTypes {
    API_RESPONSE_SUCCESS = '@@user/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@user/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@user/API_RESPONSE_VALIDATION_ERRORS',

    GET_USERS = '@@user/GET_USERS',
    GET_USER = '@@user/GET_USER',
    GET_USER_BY_ACCOUNT_ID = '@@user/GET_USER_BY_ACCOUNT_ID',
    INSERT_USER = '@@user/INSERT_USER',
    UPDATE_USER = '@@user/UPDATE_USER',
    DELETE_USER = '@@user/DELETE_USER',

    OPEN_SEARCH = '@@user/OPEN_SEARCH',
    CLOSE_SEARCH = '@@user/CLOSE_SEARCH',
    SEARCH_USER = '@@user/SEARCH_USER',
    CLEAR_SEARCH = '@@user/CLEAR_SEARCH',

    GET_MERCHANT_SETTING = '@@user/GET_MERCHANT_SETTING',
    UPDATE_MERCHANT_SETTING = '@@user/UPDATE_MERCHANT_SETTING',

    UPDATE_MOBILE_OWNERSHIP = '@@user/UPDATE_MOBILE_OWNERSHIP',

    RESET = '@@user/RESET',

    EXPORT = '@@user/EXPORT',
}

export enum UserErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



