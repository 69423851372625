import { TokenActionTypes } from './constants';

export type TokenActionType = {
    type:
        | TokenActionTypes.API_RESPONSE_SUCCESS
        | TokenActionTypes.API_RESPONSE_ERROR
        | TokenActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | TokenActionTypes.RESET

        | TokenActionTypes.GET_TOKENS
        | TokenActionTypes.GET_TOKEN
        | TokenActionTypes.INSERT_TOKEN
        | TokenActionTypes.UPDATE_TOKEN
        | TokenActionTypes.DELETE_TOKEN

        | TokenActionTypes.OPEN_SEARCH
        | TokenActionTypes.CLOSE_SEARCH
        | TokenActionTypes.SEARCH_TOKEN
        | TokenActionTypes.CLEAR_SEARCH;

    payload: {} | string;
};

type Token = {
    id: number;
    email: string;
    emailVerify: string;
    role: string;
    token: string;

    process: any
};

type QueryParams = {
    page: number;
    limit: number;
    orderBy: string,
    sort: string,
    accountId?: string
}

class TokenAction {

    apiResponseSuccess = (actionType: string, data: Token | {}): TokenActionType => ({
        type: TokenActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: any): TokenActionType => ({
        type: TokenActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: any): TokenActionType => ({
        type: TokenActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): TokenActionType => ({
        type: TokenActionTypes.RESET,
        payload: {},
    });

    getTokens = (queryParams: any): TokenActionType => ({
        type: TokenActionTypes.GET_TOKENS,
        payload: {queryParams},
    });

    getToken = (id: string): TokenActionType => ({
        type: TokenActionTypes.GET_TOKEN,
        payload: {id},
    });

    insertToken = (data: any): TokenActionType => ({
        type: TokenActionTypes.INSERT_TOKEN,
        payload: {data},
    });

    delete = (id: string): TokenActionType => ({
        type: TokenActionTypes.DELETE_TOKEN,
        payload: {id},
    });

    openSearch = (): TokenActionType => ({
        type: TokenActionTypes.OPEN_SEARCH,
        payload: {}
    });

    closeSearch = (): TokenActionType => ({
        type: TokenActionTypes.CLOSE_SEARCH,
        payload: {}
    });

    search = (queryParams: QueryParams): TokenActionType => ({
        type: TokenActionTypes.SEARCH_TOKEN,
        payload: { queryParams }
    });

    clearSearch = (): TokenActionType => ({
        type: TokenActionTypes.CLEAR_SEARCH,
        payload: {}
    });
}

const tokenActions = new TokenAction();
export { tokenActions };
