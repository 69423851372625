import { APICore } from 'helpers/api/apiCore';
import {getCurrentRolePermissions} from "../helpers/functions";

export default function useSession() {
    const api = new APICore();

    const session = api.getSession();

    const currentRolePermissions = getCurrentRolePermissions();

    return {session, currentRolePermissions};
}
