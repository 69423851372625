import { PassportActionTypes } from './constants';

export type PassportActionType = {
    type:
        | PassportActionTypes.API_RESPONSE_SUCCESS
        | PassportActionTypes.API_RESPONSE_ERROR
        | PassportActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | PassportActionTypes.RESET

        | PassportActionTypes.UPLOAD_PASSPORT
        | PassportActionTypes.UPLOAD_PASSPORT_PROGRESS
        | PassportActionTypes.DELETE_PASSPORT
        | PassportActionTypes.UPDATE
    ;

    payload: {} | string;
};

class PassportActions {

    apiResponseSuccess = (actionType: string, data: any): PassportActionType => ({
        type: PassportActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: any): PassportActionType => ({
        type: PassportActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: any): PassportActionType => ({
        type: PassportActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): PassportActionType => ({
        type: PassportActionTypes.RESET,
        payload: {},
    });

    uploadPassport = (id:string, file: File, retry: boolean): PassportActionType => ({
        type: PassportActionTypes.UPLOAD_PASSPORT,
        payload: {id, file, retry},
    });

    uploadPassportProgress = (progress: any, fileId: string): PassportActionType => {
        return {
            type: PassportActionTypes.UPLOAD_PASSPORT_PROGRESS,
            payload: {fileId, progress},
        }
    }

    deletePassport = (id:string, fileId: string, uuid: string): PassportActionType => ({
        type: PassportActionTypes.DELETE_PASSPORT,
        payload: {id, fileId, uuid},
    });

    update = (data:any): PassportActionType => ({
        type: PassportActionTypes.UPDATE,
        payload: {data},
    });

}

const passportActions = new PassportActions();
export { passportActions };
