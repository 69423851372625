
export enum AuthActionTypes {
    API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@auth/API_RESPONSE_VALIDATION_ERRORS',

    LOGIN_USER = '@@auth/LOGIN_USER',
    LOGOUT_USER = '@@auth/LOGOUT_USER',
    SIGNUP_USER = '@@auth/SIGNUP_USER',
    FORGOT_PASSWORD = '@@auth/FORGOT_PASSWORD',
    FORGOT_PASSWORD_CHANGE = '@@auth/FORGOT_PASSWORD_CHANGE',

    RESET_PASSWORD = '@@auth/RESET_PASSWORD',
    RESET_PASSWORD_CHANGE = '@@auth/RESET_PASSWORD_CHANGE',


    RESET = '@@auth/RESET',
    SOCIAL_CALLBACK_URL = '@@auth/SOCIAL_CALLBACK_URL',
    GOOGLE_SIGN_IN = '@@auth/GOOGLE_SIGN_IN',
    SET_ERROR = '@@auth/SET_ERROR',

    REFRESH_TOKEN = '@@auth/REFRESH_TOKEN',
    START_REFRESH_TOKEN_LOOP = '@@auth/START_REFRESH_TOKEN_LOOP',
    END_REFRESH_TOKEN_LOOP = '@@auth/END_REFRESH_TOKEN_LOOP',
}

export enum AuthErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



