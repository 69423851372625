import { AccountSecurityActionTypes } from './constants';

export type AccountSecurityActionType = {
    type:
        | AccountSecurityActionTypes.API_RESPONSE_SUCCESS
        | AccountSecurityActionTypes.API_RESPONSE_ERROR
        | AccountSecurityActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | AccountSecurityActionTypes.RESET

        | AccountSecurityActionTypes.CHANGE_PASSWORD
        | AccountSecurityActionTypes.LAST_PASSWORD_UPDATED

        | AccountSecurityActionTypes.CREATE_GOOGLE_2FA
        | AccountSecurityActionTypes.VERIFY_GOOGLE_2FA
        | AccountSecurityActionTypes.DISABLE_GOOGLE_2FA
        | AccountSecurityActionTypes.VERIFY_DISABLE_GOOGLE_2FA;

    payload: {} | string;
};

type AccountSecurity = {
    id: number;
    email: string;
    emailVerify: string;
    role: string;
    token: string;

    process: any
};

class AccountSecurityActions {

    apiResponseSuccess = (actionType: string, data: AccountSecurity | {}): AccountSecurityActionType => ({
        type: AccountSecurityActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: any): AccountSecurityActionType => ({
        type: AccountSecurityActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: any): AccountSecurityActionType => ({
        type: AccountSecurityActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): AccountSecurityActionType => ({
        type: AccountSecurityActionTypes.RESET,
        payload: {},
    });

    updatePassword = (data: any): AccountSecurityActionType => ({
        type: AccountSecurityActionTypes.CHANGE_PASSWORD,
        payload: {data},
    });

    getLastPasswordChanged = (): AccountSecurityActionType => ({
        type: AccountSecurityActionTypes.LAST_PASSWORD_UPDATED,
        payload: {},
    });

    create2faSecret = (): AccountSecurityActionType =>  ({
        type: AccountSecurityActionTypes.CREATE_GOOGLE_2FA,
        payload: {},
    });

    verifyGoogle2Fa = (data: any): AccountSecurityActionType =>  ({
        type: AccountSecurityActionTypes.VERIFY_GOOGLE_2FA,
        payload: {data},
    });

    disableGoogle2Fa = (): AccountSecurityActionType =>  ({
        type: AccountSecurityActionTypes.DISABLE_GOOGLE_2FA,
        payload: {},
    });

    verifyDisableGoogle2Fa = (data: any): AccountSecurityActionType => ({
        type: AccountSecurityActionTypes.VERIFY_DISABLE_GOOGLE_2FA,
        payload: {data},
    });

}

const accountSecurityActions = new AccountSecurityActions();
export { accountSecurityActions };
