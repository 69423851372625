import {PermissionActionTypes} from './constants';

export type PermissionActionType = {
    type:
        | PermissionActionTypes.API_RESPONSE_SUCCESS
        | PermissionActionTypes.API_RESPONSE_ERROR
        | PermissionActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | PermissionActionTypes.FIND_PERMISSION
        | PermissionActionTypes.GET_PERMISSIONS
        | PermissionActionTypes.SET_VARIABLE
        | PermissionActionTypes.RESET

        | PermissionActionTypes.OPEN_SEARCH
        | PermissionActionTypes.SEARCH_PERMISSION
        | PermissionActionTypes.CLEAR_SEARCH
        | PermissionActionTypes.CLOSE_SEARCH;
    payload: {} | string;
};

type Permission = {
    id: number;
    name: string;
    guard_name: string;
};

type QueryParams = {
    page: number;
    limit: number;
    orderBy: string,
    sort: string,
    permissionName?: string
}

class PermissionActions {

    apiResponseSuccess = (actionType: string, data: Permission | {}): PermissionActionType => ({
        type: PermissionActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): PermissionActionType => ({
        type: PermissionActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): PermissionActionType => ({
        type: PermissionActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): PermissionActionType => ({
        type: PermissionActionTypes.RESET,
        payload: {},
    });

    setVariable = (key: string, value: any): PermissionActionType => ({
        type: PermissionActionTypes.SET_VARIABLE,
        payload: { key, value },
    });

    getPermissions = (queryParams: QueryParams): PermissionActionType => ({
        type: PermissionActionTypes.GET_PERMISSIONS,
        payload: { queryParams },
    });

    find = (id: number) : PermissionActionType => ({
        type: PermissionActionTypes.FIND_PERMISSION,
        payload: {
            id,
        }
    });

    openSearch = (): PermissionActionType => ({
        type: PermissionActionTypes.OPEN_SEARCH,
        payload: {}
    });
    closeSearch = (): PermissionActionType => ({
        type: PermissionActionTypes.CLOSE_SEARCH,
        payload: {}
    });
    search = (queryParams: QueryParams): PermissionActionType => ({
        type: PermissionActionTypes.SEARCH_PERMISSION,
        payload: { queryParams }
    });
    clearSearch = (): PermissionActionType => ({
        type: PermissionActionTypes.CLEAR_SEARCH,
        payload: {}
    });
}

const permissionActions = new PermissionActions();
export { permissionActions };
