import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {graphql} from 'helpers';
import {MobileNumberActionTypes, MobileNumberErrors} from './constants';
import {checkResponseError, checkServerError, pluckResponse} from "../../helpers/functions";
import {mobileNumberActions} from "./actions";
import { APICore } from '../../helpers/api/apiCore';

type MobileNumberData = {
    payload: {
        id: number,

        data: any;

        queryParams: {
            limit: number;
            page: number;

        }

        token: string;

        file: {fileId: string} & File;

        fileId: string;
        uuid: string;

        mobileNo: string;
        otp: string;
    };
    type: string;
};

function* getMobileNumbers({ payload: { queryParams } }: MobileNumberData): SagaIterator {
    try {
        const param:any = [
            {
                query:`query MobileNumbers($page: Int!, $limit: Int!, $orderBy: String!, $sort: String!) {
                    mobileNumbers(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort) {
                        data {
                            id
                            display_name
                            mobile
                            mobile_verified_at
                        }
                        total
                        per_page
                        from
                        to
                        current_page
                        last_page
                        has_more_pages
                    }
                }`,
                variables: {
                    ...queryParams
                }
            }
        ];

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, MobileNumberErrors.RESPONSE_200);

        const [rMobileNumbers] = response.data;

        checkServerError(rMobileNumbers);

        const mobileNumbers = pluckResponse(rMobileNumbers, "mobileNumbers");

        yield put(mobileNumberActions.apiResponseSuccess(MobileNumberActionTypes.GET_MOBILE_NUMBERS, {
            mobileNumbers: {
                mobileNumbers,
                queryParams
            }
        }));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(mobileNumberActions.apiResponseError(MobileNumberActionTypes.GET_MOBILE_NUMBERS, error));
        } else {
            yield put(mobileNumberActions.apiResponseValidationErrors(MobileNumberActionTypes.GET_MOBILE_NUMBERS, error));
        }
    }
}

function* getMobileNumber({ payload: { id } }: MobileNumberData): SagaIterator {
    try {
        const param = [{
            query:`query MobileNumber($id: String!) {
                mobileNumber(id: $id) {
                    id
                    mobile
                    mobile_verified_at
                }
            }`,
            variables: {
                id
            }
        }];

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, MobileNumberErrors.RESPONSE_200);

        const [RPD] = response.data;

        checkServerError(RPD);

        const mobileNumber = pluckResponse(RPD, "mobileNumber");

        yield put(mobileNumberActions.apiResponseSuccess(MobileNumberActionTypes.GET_MOBILE_NUMBER, mobileNumber));

    } catch (error: any) {

        if(typeof error === "string") {
            yield put(mobileNumberActions.apiResponseError(MobileNumberActionTypes.GET_MOBILE_NUMBER, error));
        } else {
            yield put(mobileNumberActions.apiResponseValidationErrors(MobileNumberActionTypes.GET_MOBILE_NUMBER, error));
        }
    }
}


function* updateMobileNumber({ payload: {data} }: MobileNumberData): SagaIterator {
    try {
        const param = {
            query:`mutation UpdateMobileNumber(
                $id: Int!,
                $mobile: String!,
            ) {
                updateMobileNumber(
                    id: $id
                    mobile: $mobile
                )
            }`,
            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, MobileNumberErrors.RESPONSE_200);

        const rUPI = response.data;

        checkServerError(rUPI);

        const updateMobileNumber = pluckResponse(rUPI, "updateMobileNumber");

        yield put(mobileNumberActions.apiResponseSuccess(MobileNumberActionTypes.UPDATE_MOBILE_NUMBER, updateMobileNumber));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(mobileNumberActions.apiResponseError(MobileNumberActionTypes.UPDATE_MOBILE_NUMBER, error));
        } else {
            yield put(mobileNumberActions.apiResponseValidationErrors(MobileNumberActionTypes.UPDATE_MOBILE_NUMBER, error));
        }
    }
}

function* sendOtp({ payload: {data} }: MobileNumberData): SagaIterator {

    try {
        const param:any = {
            query: `mutation SendOtpCode($id: String!, $mobileNo: String!){
                sendOtpCode(id: $id, mobileNo: $mobileNo)
            }`,
            variables: {
                ...data
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, MobileNumberErrors.RESPONSE_200);

        const rSendOtpCode = response.data;

        checkServerError(rSendOtpCode);

        pluckResponse(rSendOtpCode, "sendOtpCode");

        yield put(mobileNumberActions.apiResponseSuccess(MobileNumberActionTypes.SEND_OTP, true));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(mobileNumberActions.apiResponseError(MobileNumberActionTypes.SEND_OTP, error));
        } else {
            yield put(mobileNumberActions.apiResponseValidationErrors(MobileNumberActionTypes.SEND_OTP, error));
        }
    }
}

function* verifyOtp({ payload: {data} }: MobileNumberData): SagaIterator {
    try {
        const param:any = {
            query: `mutation VerifyOtpCode($id: String!, $otp: String!){
                verifyOtpCode(id: $id, otp: $otp)
            }`,
            variables: {
                ...data
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, MobileNumberErrors.RESPONSE_200);

        const rVerifyOtpCode = response.data;

        checkServerError(rVerifyOtpCode);

        const verifyOtpCode = pluckResponse(rVerifyOtpCode, "verifyOtpCode");

        const api = new APICore();
        const session = api.getSession();
        session.user.mobile = data.mobileNo;
        session.user.mobile_verified_at = verifyOtpCode;
        api.setSession(session);

        yield put(mobileNumberActions.apiResponseSuccess(MobileNumberActionTypes.VERIFY_OTP, true));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(mobileNumberActions.apiResponseError(MobileNumberActionTypes.VERIFY_OTP, error));
        } else {
            yield put(mobileNumberActions.apiResponseValidationErrors(MobileNumberActionTypes.VERIFY_OTP, error));
        }
    }
}

export function* watchGetMobileNumbers() {
    yield takeEvery(MobileNumberActionTypes.GET_MOBILE_NUMBERS, getMobileNumbers);
}

export function* watchGetMobileNumber() {
    yield takeEvery(MobileNumberActionTypes.GET_MOBILE_NUMBER, getMobileNumber);
}

export function* watchUpdateMobileNumber() {
    yield takeEvery(MobileNumberActionTypes.UPDATE_MOBILE_NUMBER, updateMobileNumber);
}

export function* watchSendOtp(): any {
    yield takeEvery(MobileNumberActionTypes.SEND_OTP, sendOtp);
}

export function* watchVerifyOtp(): any {
    yield takeEvery(MobileNumberActionTypes.VERIFY_OTP, verifyOtp);
}

function* mobileNumberSaga() {
    yield all([
        fork(watchGetMobileNumbers),
        fork(watchGetMobileNumber),
        fork(watchUpdateMobileNumber),
        fork(watchSendOtp),
        fork(watchVerifyOtp),
    ]);
}

export default mobileNumberSaga;
