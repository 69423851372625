export enum TransactionActionTypes {
    API_RESPONSE_SUCCESS = '@@transaction/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@transaction/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@transaction/API_RESPONSE_VALIDATION_ERRORS',

    GET_TRANSACTIONS = '@@transaction/GET_TRANSACTIONS',
    GET_TRANSACTION = '@@transaction/GET_TRANSACTION',

    OPEN_SEARCH = '@@transaction/OPEN_SEARCH',
    CLOSE_SEARCH = '@@transaction/CLOSE_SEARCH',
    SEARCH_TRANSACTION = '@@transaction/SEARCH_TRANSACTION',
    CLEAR_SEARCH = '@@transaction/CLEAR_SEARCH',

    RESET = '@@transaction/RESET',

    EXPORT = '@@transaction/EXPORT',
    RESET_SEARCH = '@@transaction/RESET_SEARCH',
}

export enum TransactionErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



