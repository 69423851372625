import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { graphql } from 'helpers';
import {DWTransactionActionTypes, DWTransactionErrors} from './constants';
import {
    checkResponseError,
    checkServerError,
    pluckResponse,
} from "../../helpers/functions";
import {DWTransactionActions} from "./actions";

type DWTransactionData = {
    payload: {
        id: string;
        name: string;
        permissions: string[];

        queryParams: {
            limit: string;
            page: string;
            orderBy: string;
            sort: string;
        };

        data: any;
        trackId: string;
    };
    type: string;
};

function* getDWTransactions({ payload: { queryParams } }: DWTransactionData): SagaIterator {
    try {
        const param = {
            query:`query DWTransactions($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $accountId: String) {
                depositsWithdraws(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId) {
                    data {
                        wallet {
                            uuid
                            name
                            holder {
                                id
                                email
                                username
                                display_name
                            }
                            meta {
                                sign
                                currency
                            }
                        }
                        type
                        amountFloat
                        confirmed
                        meta {
                            gateway_slug
                            order_id
                            description
                        }
                        balanceFloat
                        iban
                        bank_name
                        card_number
                        bank_account_number
                        
                        name
                        transaction_id
                        thread_id
                        status
                        created_at
                        updated_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, DWTransactionErrors.RESPONSE_200);

        const rDWTransactions = response.data;

        checkServerError(rDWTransactions);

        const DWTransactions = pluckResponse(rDWTransactions, "depositsWithdraws");

        const result = {
            DWTransactions,
            queryParams
        }

        yield put(DWTransactionActions.apiResponseSuccess(DWTransactionActionTypes.GET_DWTRANSACTIONS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(DWTransactionActions.apiResponseError(DWTransactionActionTypes.GET_DWTRANSACTIONS, error));
        } else {
            yield put(DWTransactionActions.apiResponseValidationErrors(DWTransactionActionTypes.GET_DWTRANSACTIONS, error));
        }
    }
}

function* searchDWTransaction({ payload: { queryParams } }: DWTransactionData): SagaIterator {
    try {
        const param = {
            query:`query DWTransactions(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $wallet: String, 
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $transactionId: String,
                $threadId: String,
                $accountId: String,
                $transactionNames: [String],
                $transactionType: String
            ) {
                depositsWithdraws(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort, 
                    wallet: $wallet,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    transactionId: $transactionId,
                    threadId: $threadId,
                    accountId: $accountId,
                    transactionNames: $transactionNames,
                    transactionType: $transactionType
                ) {
                    data {
                        wallet {
                            uuid
                            name
                            holder {
                                id
                                email
                                username
                                display_name
                            }
                            meta {
                                sign
                                currency
                            }
                        }
                        type
                        amountFloat
                        confirmed
                        meta {
                            description
                        }
                        balanceFloat
                        iban
                        bank_name
                        card_number
                        bank_account_number
                        name
                        transaction_id
                        thread_id
                        status
                        created_at
                        updated_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, DWTransactionErrors.RESPONSE_200);

        const rDWTransactions = response.data;

        checkServerError(rDWTransactions);

        const DWTransactions = pluckResponse(rDWTransactions, "depositsWithdraws");

        const result = {
            DWTransactions,
            queryParams
        }

        yield put(DWTransactionActions.apiResponseSuccess(DWTransactionActionTypes.SEARCH_DWTRANSACTION, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(DWTransactionActions.apiResponseError(DWTransactionActionTypes.SEARCH_DWTRANSACTION, error));
        } else {
            yield put(DWTransactionActions.apiResponseValidationErrors(DWTransactionActionTypes.SEARCH_DWTRANSACTION, error));
        }
    }
}

function* exportTransactions({ payload: { queryParams } }: DWTransactionData): SagaIterator {
    try {
        const param = {
            query:`query ExportDepositsWithdraws(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $wallet: String, 
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $transactionId: String,
                $threadId: String,
                $accountId: String,
                $transactionNames: [String],
                $transactionType: String
            ) {
                exportDepositsWithdraws(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort, 
                    wallet: $wallet,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    transactionId: $transactionId,
                    threadId: $threadId,
                    accountId: $accountId,
                    transactionNames: $transactionNames,
                    transactionType: $transactionType
                )
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, DWTransactionErrors.RESPONSE_200);

        const rTransactions = response.data;

        checkServerError(rTransactions);

        const transactions = pluckResponse(rTransactions, "exportDepositsWithdraws");

        yield put(DWTransactionActions.apiResponseSuccess(DWTransactionActionTypes.EXPORT, transactions));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(DWTransactionActions.apiResponseError(DWTransactionActionTypes.EXPORT, error));
        } else {
            yield put(DWTransactionActions.apiResponseValidationErrors(DWTransactionActionTypes.EXPORT, error));
        }
    }
}

export function* watchGetDWTransactions() {
    yield takeEvery(DWTransactionActionTypes.GET_DWTRANSACTIONS, getDWTransactions);
}

export function* watchSearchDWTransaction() {
    yield takeEvery(DWTransactionActionTypes.SEARCH_DWTRANSACTION, searchDWTransaction);
}

export function* watchExportTransactions() {
    yield takeEvery(DWTransactionActionTypes.EXPORT, exportTransactions);
}

function* DWTransactionsSaga() {
    yield all([
        fork(watchGetDWTransactions),
        fork(watchSearchDWTransaction),
        fork(watchExportTransactions),
    ]);
}

export default DWTransactionsSaga;
