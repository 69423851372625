import jwtDecode from 'jwt-decode';
import axios from 'axios';
import config from '../../config';

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = config.API_URL;

const AUTH_SESSION_KEY = 'session';

const getSession = () => {
    const session = localStorage.getItem(AUTH_SESSION_KEY);
    return session ? (typeof session == 'object' ? session : JSON.parse(session)) : null;
};

const getUserFromSession = () => {
    const session = getSession();
    return (session && typeof session.user === 'object')? session.user: null;
}

// intercepting to capture errors
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;

        if (error && error.response && error.response.status === 503) {
            window.location.href = '/maintenance';
        } else if (error && error.response && error.response.status === 404) {
            // window.location.href = '/not-found';
            message = 'Api endpoint not found';
        } else if (error && error.response && error.response.status === 403) {
            // window.location.href = '/access-denied';
            message = 'Access Forbidden';
        } else {
            if(error?.response) {
                switch (error.response.status) {
                    case 401:
                        message = 'Invalid credentials';
                        if(getUserFromSession()) {
                            localStorage.removeItem(AUTH_SESSION_KEY);
                            window.location.href = '/access-denied';
                        }
                        break;
                    case 403:
                        message = 'Access Forbidden';
                        break;
                    case 404:
                        message = 'Sorry! the data you are looking for could not be found';
                        break;
                    default: {
                        message =
                            error.response && error.response.data ? error.response.data['message'] : error.message || error;
                    }
                }
            }

        }
        return Promise.reject(message);
    }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
    if (token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    else delete axios.defaults.headers.common['Authorization'];
};

const setHeaderLang = () => {
    axios.defaults.headers.common['X-Locale'] = document.documentElement.getAttribute('lang');
}

class APICore {
    /**
     * Fetches data from given url
     */
    get = (url: string, params: any) => {
        setHeaderLang();

        let response;
        if (params) {
            const queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, params);
        } else {
            response = axios.get(`${url}`, params);
        }
        return response;
    };

    getFile = (url: string, params: any) => {
        setHeaderLang();

        let response;
        if (params) {
            const queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, { responseType: 'blob' });
        } else {
            response = axios.get(`${url}`, { responseType: 'blob' });
        }
        return response;
    };

    getMultiple = (urls: string, params: any) => {
        setHeaderLang();

        const reqs = [];
        let queryString = '';
        if (params) {
            queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
        }

        for (const url of urls) {
            reqs.push(axios.get(`${url}?${queryString}`));
        }
        return axios.all(reqs);
    };

    /**
     * post given data to url
     */
    create = (url: string, data: any) => {
        setHeaderLang();
        return axios.post(url, data);
    };

    /**
     * Updates patch data
     */
    updatePatch = (url: string, data: any) => {
        setHeaderLang();
        return axios.patch(url, data);
    };

    /**
     * Updates data
     */
    update = (url: string, data: any) => {
        setHeaderLang();
        return axios.put(url, data);
    };

    /**
     * Deletes data
     */
    delete = (url: string) => {
        setHeaderLang();
        return axios.delete(url);
    };

    /**
     * post given data to url with file
     */
    createWithFile = (url: string, data: any) => {
        setHeaderLang();

        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config: any = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post(url, formData, config);
    };

    /**
     * post given data to url with file
     */
    updateWithFile = (url: string, data: any) => {
        setHeaderLang();

        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config: any = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.patch(url, formData, config);
    };

    isUserAuthenticated = () => {
        const session = this.getSession();

        if (!session || (session && !session.access_token)) {
            return false;
        }
        const decoded: any = jwtDecode(session.access_token);
        const currentTime = Date.now() / 1000;

        return decoded.exp >= currentTime;
    };

    setSession = (session: any) => {
        if (session) localStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
        else {
            localStorage.removeItem(AUTH_SESSION_KEY);
        }
    };

    /**
     * Returns the logged-in user
     */
    getLoggedInUser = () => {
        return getUserFromSession();
    };

    /**
     * Returns the current session
     */
    getSession = () => {
        return getSession();
    };

    // setUserInSession = (modifiedUser: any) => {
    //     let userInfo = localStorage.getItem(AUTH_SESSION_KEY);
    //     if (userInfo) {
    //         const { access_token, user } = JSON.parse(userInfo);
    //         this.setSession({ access_token, ...user, ...modifiedUser });
    //     }
    // };

    /**
     * upload file
     */
    upload = (url: string, data: any, onUploadProgress: any) => {
        setHeaderLang();

        const config : any = {
            headers: {
                ...axios.defaults.headers,
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress
        };

        return axios.post(url, data, config);
    };
}

/*
Check if token available in session
*/
const session = getSession();
if(session) {
    const { access_token } = session;
    if(access_token) {
        setAuthorization(access_token);
    }
}

export { APICore, setAuthorization, getUserFromSession, AUTH_SESSION_KEY };
