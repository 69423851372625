
export enum BankCardActionTypes {
    API_RESPONSE_SUCCESS = '@@bankCard/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@bankCard/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@bankCard/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@bankCard/RESET',

    GET_BANK_CARDS = '@@bankCard/GET_BANK_CARDS',
    GET_BANK_CARD = '@@bankCard/GET_BANK_CARD',
    INSERT_BANK_CARD = '@@bankCard/INSERT_BANK_CARD',
    UPDATE_BANK_CARD = '@@bankCard/UPDATE_BANK_CARD',
    DELETE_BANK_CARD = '@@bankCard/DELETE_BANK_CARD',
}

export enum BankCardErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



