export enum WithdrawActionTypes {
    API_RESPONSE_SUCCESS = '@@withdraw/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@withdraw/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@withdraw/API_RESPONSE_VALIDATION_ERRORS',

    FIND_WITHDRAW = '@@withdraw/FIND_WITHDRAW',
    GET_WITHDRAWS = '@@withdraw/GET_WITHDRAWS',
    GET_WITHDRAW_REQUESTS = '@@withdraw/GET_WITHDRAW_REQUESTS',
    GET_WITHDRAW = '@@withdraw/GET_WITHDRAW',
    INSERT_WITHDRAW = '@@withdraw/INSERT_WITHDRAW',
    VERIFY_WITHDRAW = '@@withdraw/VERIFY_WITHDRAW',
    UPDATE_WITHDRAW = '@@withdraw/UPDATE_WITHDRAW',

    CHECK_WITHDRAW = '@@withdraw/CHECK_WITHDRAW',

    MANUAL_WITHDRAW = '@@withdraw/MANUAL_WITHDRAW',
    PREVIEW_WITHDRAW_DETAIL = '@@withdraw/PREVIEW_WITHDRAW_DETAIL',

    CLEAR_CHECKED = '@@withdraw/CLEAR_CHECKED',

    OPEN_SEARCH = '@@withdraw/OPEN_SEARCH',
    CLOSE_SEARCH = '@@withdraw/CLOSE_SEARCH',
    SEARCH_WITHDRAW = '@@withdraw/SEARCH_WITHDRAW',
    CLEAR_SEARCH = '@@withdraw/CLEAR_SEARCH',

    RESET = '@@withdraw/RESET',

    EXPORT = '@@withdraw/EXPORT',
    RESET_SEARCH = '@@withdraw/RESET_SEARCH',
}

export enum WithdrawErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



