export enum PaymentActionTypes {
    API_RESPONSE_SUCCESS = '@@payment/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@payment/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@payment/API_RESPONSE_VALIDATION_ERRORS',

    GET_PAYMENTS = '@@payment/GET_PAYMENTS',
    GET_PAYMENT = '@@payment/GET_PAYMENT',
    GET_TRANSACTIONS = '@@payment/GET_TRANSACTIONS',

    OPEN_SEARCH = '@@payment/OPEN_SEARCH',
    CLOSE_SEARCH = '@@payment/CLOSE_SEARCH',
    SEARCH_PAYMENT = '@@payment/SEARCH_PAYMENT',
    SEARCH_TRANSACTION = '@@payment/SEARCH_TRANSACTION',
    CLEAR_SEARCH = '@@payment/CLEAR_SEARCH',

    RESET = '@@payment/RESET',
    EXPORT_TRANSACTIONS = '@@payment/EXPORT_TRANSACTIONS',
    EXPORT = '@@payment/EXPORT',
    RESET_SEARCH = '@@payment/RESET_SEARCH',

    CALLBACK = '@@payment/CALLBACK',
}

export enum PaymentErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



