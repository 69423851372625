import { CUActionTypes } from './constants';

const INIT_STATE = {
    error: "",
    loading: false,
    conversationUnits: [],
    unitInserted: false,
    unitDeleted: false,

    insertError: "",
    insertLoading: false,
};

type CUActionType = {
    type:
        | CUActionTypes.API_RESPONSE_SUCCESS
        | CUActionTypes.API_RESPONSE_ERROR
        | CUActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | CUActionTypes.UNITS
        | CUActionTypes.INSERT
        | CUActionTypes.DELETE
        | CUActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
        validationErrors?: [];

        key: string;
        value?: string;

        page?: string;
    };
};

type State = {
    loading: boolean,
    conversationUnits: string[]
};

const CU = (state: State = INIT_STATE, action: CUActionType) => {
    switch (action.type) {
        case CUActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case CUActionTypes.UNITS: {
                    return {
                        ...state,
                        conversationUnits: action.payload.data,
                        loading: false,
                    };
                }

                case CUActionTypes.INSERT: {
                    return {
                        ...state,
                        unitInserted: true,
                        insertLoading: false,
                        conversationUnits: [
                            ...state.conversationUnits,
                            action.payload.data
                        ]
                    };
                }
                case CUActionTypes.DELETE: {
                    return {
                        ...state,
                        unitDeleted: true,
                        loading: false,
                        conversationUnits: state.conversationUnits.filter(function(item) {
                            return item !== action.payload.data
                        })
                    };
                }
                default:
                    return { ...state };
            }

        case CUActionTypes.API_RESPONSE_ERROR:

            switch (action.payload.actionType) {

                case CUActionTypes.UNITS:
                case CUActionTypes.DELETE:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

                case CUActionTypes.INSERT:
                {
                    return {
                        ...state,
                        insertError: action.payload.error,
                        insertLoading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case CUActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case CUActionTypes.UNITS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case CUActionTypes.INSERT:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        insertLoading: false,
                    };
                }
                case CUActionTypes.DELETE:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case CUActionTypes.UNITS:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case CUActionTypes.INSERT:
            return {
                ...state,
                unitInserted: false,
                insertLoading: true,
                insertError: "",
            };
        case CUActionTypes.DELETE:
            return {
                ...state,
                unitDeleted: false,
                loading: true,
                error: "",
            };

        case CUActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: "",

                conversationUnits: [],
                unitInserted: false,
                unitDeleted: false,

                insertError: "",
                insertLoading: false,
            };

        default:
            return { ...state };
    }
};

export default CU;
